import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import {
  ATTRIBUTEDelete,
  ATTRIBUTE_VALUE_Get,
  SetATTRIBUTE_VALUEObj,
  ATTRIBUTEGet,setAttributeValueStatus
} from "../../../redux/actions/Attribute/Attribute.actions";

import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import SearchBox from "../../Utility/SearchBox";
import AddAttribute from "./AddAttribute";
import { getAttribute } from "../../../services/Attribute.service";
import { toastError } from "../../Utility/ToastUtils";

function Attribute() {
  const dispatch = useDispatch();
  const attributeArr = useSelector((state) => state.attribute.attributes);

  const attributePaginationObject = useSelector(
    (state) => state.attribute.attributePaginationObj
  );

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [attributeArray, setAttributeArray] = useState([]);
  const [mainArray, setMainArray] = useState([]);
  const [ModalData, setModalData] = useState({});
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const fetchData = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      console.log(queryOf, "==queryOf=");
      // dispatch(ATTRIBUTEGet(queryOf));
      let { data: get } = await getAttribute(queryOf);
      console.log(get, "===get attribute");
      if (get.data) {
        setAttributeArray(get?.data);
        setMainArray(get?.data);
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  }   ;

  useEffect(() => {
    fetchData();
  }, [page, perPage, query]);

  useEffect(() => {
    setAttributeArray(attributeArr);
    setMainArray(attributeArr);
  }, [attributeArr]);

  useEffect(() => {
    // console.log(attributePaginationObject,"==attributePaginationObject==")

    if (attributePaginationObject) {
      setTotalPages(attributePaginationObject?.totalPages);
      setTotalCount(attributePaginationObject?.totalCount);
      setPage(attributePaginationObject?.page);
    }
    return ()=>{
      dispatch(setAttributeValueStatus(null))
    }
  }, [attributePaginationObject]);


  const handleEdit = (row) => {
    // let queryOf = `page=${page}&perPage=${perPage}`;
    row.page = page;
    row.perPage = perPage;
    dispatch(SetATTRIBUTE_VALUEObj(row));
  };

  
  const handleDeleteById = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(ATTRIBUTEDelete(id, query));
    }
  };

  const product_sale_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "ATTRIBUTE NAME",
      selector: (row) => row.name,
    },
    {
      name: "DESCRIPTION",
      selector: (row) => row.description,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            edit
            deletePath="/Product/Attribute"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => handleEdit(row)}
            editPath="/Product/Attribute"
          />
          <AddModal
            ModalBox={ModalBox}
            setModalBox={setModalBox}
            name={ModalName}
            ModalType={ModalType}
            width="min-content"
          />
        </>
      ),
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        width="600px"
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row gy-4 gy-xxl-0">
            <div className="col-12 col-xxl-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Attribute</h5>
                <CustomButton
                  isLink
                  iconName="fa-solid fa-plus"
                  btnName="Add Attribute Value"
                  path="/Product/Attribute-Value"
                  small
                />
              </div>
              <DashboardBox>
                <AddAttribute />
              </DashboardBox>
            </div>
            <div className="col-12 col-xxl-8">
              <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                <h5 className="blue-1 m-0">Attribute Lists</h5>
                <SearchBox
                  setQuery={(e) => {
                    handleFilterByQuery(e, ["name"]);
                  }}
                  query={query}
                  extraClass="bg-white"
                />
              </div>
              <DashboardTable>
                <DataTable
                  columns={product_sale_columns}
                  data={
                    attributeArray && attributeArray.length > 0
                      ? attributeArray
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Attribute;
