import {
  // addHsnTax
  // get
  // deleteById

  // updateHsnTax
  // getAllBySellerId
  addHsnTax,
  deleteById,
  get,
  updateHsnTax,
} from "../../../services/HsnTax.service";

export const HSNTAX_ADD = "HSNTAX_ADD";
export const HSNTAX_ADD_SUCCESS = "HSNTAX_ADD_SUCCESS";
export const HSNTAX_ADD_FAIL = "HSNTAX_ADD_FAIL";

export const GET_ALL_HSNTAXS = "GET_ALL_HSNTAXS";
export const GET_ALL_HSNTAXS_SUCCESS = "GET_ALL_HSNTAXS_SUCCESS";
export const GET_ALL_HSNTAXS_FAIL = "GET_ALL_HSNTAXS_FAIL";

export const UPDATE_HSNTAX_BY_ID = "UPDATE_HSNTAX_BY_ID";
export const UPDATE_HSNTAX_BY_ID_SUCCESS = "UPDATE_HSNTAX_BY_ID_SUCCESS";
export const UPDATE_HSNTAX_BY_ID_FAIL = "UPDATE_HSNTAX_BY_ID_FAIL";

export const SET_HSNTAX_OBJ = "SET_HSNTAX_OBJ";
export const SET_HSNTAX_OBJ_SUCCESS = "SET_HSNTAX_OBJ_SUCCESS";
export const SET_HSNTAX_OBJ_FAIL = "SET_HSNTAX_OBJ_FAIL";

export const GET_HSNTAX_BY_ID = "GET_HSNTAX_BY_ID";
export const GET_HSNTAX_BY_ID_SUCCESS = "GET_HSNTAX_BY_ID_SUCCESS";
export const GET_HSNTAX_BY_ID_FAIL = "GET_HSNTAX_BY_ID_FAIL";

export const DELETE_HSNTAX_BY_ID = "DELETE_HSNTAX_BY_ID";
export const DELETE_HSNTAX_BY_ID_SUCCESS = "DELETE_HSNTAX_BY_ID_SUCCESS";
export const DELETE_HSNTAX_BY_ID_FAIL = "DELETE_HSNTAX_BY_ID_FAIL";


export const UPDATE_HSNTAX_STATUS_SUCCESS = "UPDATE_HSNTAX_STATUS_SUCCESS";



export const HSNTAXGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_HSNTAXS });
    let { data: response } = await get(formData);
    // console.log(response, "action data");
    if (response) {
      dispatch({
        type: GET_ALL_HSNTAXS_SUCCESS,
        payload: {
          data: response.data,
          message: response.message,
          hsnTaxPagination: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: HSNTAX_ADD_FAIL, payload: err });
  }
};

export const HSNTAXADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: HSNTAX_ADD });
    let { data: response } = await addHsnTax(formData);
    if (response) {
      dispatch({
        type: HSNTAX_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch({
        type: UPDATE_HSNTAX_STATUS_SUCCESS,
        payload: response,
      });
      dispatch(HSNTAXGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: HSNTAX_ADD_FAIL, payload: err });
  }
};

export const SETHSNTAXOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_HSNTAX_OBJ });
    if (formData) {
      dispatch({
        type: SET_HSNTAX_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_HSNTAX_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_HSNTAX_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const HSNTAXUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_HSNTAX_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateHsnTax(id, formData);
    if (response) {
      // console.log(response,"updatein action ");
      dispatch({
        type: UPDATE_HSNTAX_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch({
        type: UPDATE_HSNTAX_STATUS_SUCCESS,
        payload: response,
      });
      dispatch(HSNTAXGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_HSNTAX_BY_ID_FAIL, payload: err });
  }
};

export const HSNTAXDELETE = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_HSNTAX_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_HSNTAX_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(HSNTAXGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_HSNTAX_BY_ID_FAIL, payload: err });
  }
};



export const setHSnTaxStatus = (formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_HSNTAX_STATUS_SUCCESS, payload: formData  });
  } catch (err) {
   console.log(err)
  }
};
