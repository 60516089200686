import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
// import { getNewsletters } from "../../services/newsletter.service2";
import { useSelector, useDispatch } from "react-redux";
import {
  newsletterGet,
  SetnewsletterObj,
  newsletterDelete,
} from "../../redux/actions/newsLetter/NewsLetter.action";

function EmailTemplate() {
  const newsLettterArr = useSelector((state) => state.newsLetter.NewsLetterArr);
  // console.log(newsLettterArr, "newsleeter");
  const dispatch = useDispatch();

  

  useEffect(() => {
    dispatch(newsletterGet());
  }, []);
  const handleEdit = (obj) => {
    dispatch(SetnewsletterObj(obj));
  };
  const handleDelete = (id) => {
    dispatch(newsletterDelete(id));
  };
  const blog_columns = [
    {
      name: "S.NO.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "description",
      cell: (row) => <p>{row.description}</p>,
      sortable: false,
      searchable: false,
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/Email/post/create"
          onDeleteClick={() => handleDelete(row._id)}
          deletePath="/Email/post"
          remove
          Uniquekey={row._id}
        />
      ),
    },
  ];

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Emails</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW Email"
                    path="/Email/post/create"
                  />
                  <SearchBox extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  data={
                    newsLettterArr && newsLettterArr.length > 0
                      ? newsLettterArr
                      : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default EmailTemplate;
