import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/companyAddress";

export const addCompanyAddress = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getCompanyAddress = (query) => {
    return axiosApiInstance.get(`${serverUrl}/get?${query}`);
};

export const deleteCompanyAddress = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateCompanyAddress = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

