import {
  addFaqCategory,
  deleteFaqCategory,
  getFaqCategory,
  updateFaqCategory,
} from "../../../services/FaqCategory.service";

export const FAQCATEGORY_ADD = "FAQCATEGORY_ADD";
export const FAQCATEGORY_ADD_SUCCESS = "FAQCATEGORY_ADD_SUCCESS";
export const FAQCATEGORY_ADD_FAIL = "FAQCATEGORY_ADD_FAIL";

export const GET_ALL_FAQCATEGORYS = "GET_ALL_FAQCATEGORYS";
export const GET_ALL_FAQCATEGORYS_SUCCESS = "GET_ALL_FAQCATEGORYS_SUCCESS";
export const GET_ALL_FAQCATEGORYS_FAIL = "GET_ALL_FAQCATEGORYS_FAIL";

export const UPDATE_FAQCATEGORY_BY_ID = "UPDATE_FAQCATEGORY_BY_ID";
export const UPDATE_FAQCATEGORY_BY_ID_SUCCESS =
  "UPDATE_FAQCATEGORY_BY_ID_SUCCESS";
export const UPDATE_FAQCATEGORY_BY_ID_FAIL = "UPDATE_FAQCATEGORY_BY_ID_FAIL";

export const SET_FAQCATEGORY_OBJ = "SET_FAQCATEGORY_OBJ";
export const SET_FAQCATEGORY_OBJ_SUCCESS = "SET_FAQCATEGORY_OBJ_SUCCESS";
export const SET_FAQCATEGORY_OBJ_FAIL = "SET_FAQCATEGORY_OBJ_FAIL";

export const GET_FAQCATEGORY_BY_ID = "GET_FAQCATEGORY_BY_ID";
export const GET_FAQCATEGORY_BY_ID_SUCCESS = "GET_FAQCATEGORY_BY_ID_SUCCESS";
export const GET_FAQCATEGORY_BY_ID_FAIL = "GET_FAQCATEGORY_BY_ID_FAIL";

export const DELETE_FAQCATEGORY_BY_ID = "DELETE_FAQCATEGORY_BY_ID";
export const DELETE_FAQCATEGORY_BY_ID_SUCCESS =
  "DELETE_FAQCATEGORY_BY_ID_SUCCESS";
export const DELETE_FAQCATEGORY_BY_ID_FAIL = "DELETE_FAQCATEGORY_BY_ID_FAIL";

export const UPDATE_FAQCATEGORY_STATUS_SUCCESS =
  "UPDATE_FAQCATEGORY_STATUS_SUCCESS";


  
export const FAQCATEGORYGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FAQCATEGORYS });
    let { data: response } = await getFaqCategory(formData);
    // console.log(response, "response356")
    if (response) {
      dispatch({
        type: GET_ALL_FAQCATEGORYS_SUCCESS,
        payload: {
          data: response.data,
          message: response.message,
          faqcategoryPaginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: FAQCATEGORY_ADD_FAIL, payload: err });
  }
};

export const FAQCATEGORYADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: FAQCATEGORY_ADD });
    let { data: response } = await addFaqCategory(formData);
    if (response) {
      dispatch({
        type: FAQCATEGORY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch({
        type: UPDATE_FAQCATEGORY_STATUS_SUCCESS,
        payload: response,
      });
      dispatch(FAQCATEGORYGET());
      return response;
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: FAQCATEGORY_ADD_FAIL, payload: err });
  }
};

export const SETFAQCATEGORYOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_FAQCATEGORY_OBJ });
    if (formData) {
      dispatch({
        type: SET_FAQCATEGORY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_FAQCATEGORY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_FAQCATEGORY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const FAQCATEGORYUPDATE = (id, formData, query) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAQCATEGORY_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateFaqCategory(id, formData);
    if (response) {
      // console.log(response);
      dispatch({
        type: UPDATE_FAQCATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch({
        type: UPDATE_FAQCATEGORY_STATUS_SUCCESS,
        payload: response,
      });
      // console.lo
      dispatch(FAQCATEGORYGET());
      // return response;
    }
  } catch (err) {
    console.error(err, "get faq caregorey 2134213");
    dispatch({ type: UPDATE_FAQCATEGORY_BY_ID_FAIL, payload: err });
  }
};

export const FAQCATEGORYDELETE = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FAQCATEGORY_BY_ID });
    let { data: response } = await deleteFaqCategory(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_FAQCATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(FAQCATEGORYGET(query ));
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_FAQCATEGORY_BY_ID_FAIL, payload: err });
  }
};





export const setFaqCategoryStatus = (formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FAQCATEGORY_STATUS_SUCCESS, payload: formData  });
  } catch (err) {
   console.log(err)
  }
};
