import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch, Tooltip } from "@mui/material";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import { AddModal, EditModal } from "../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";

import {
  PRODUCTDelete,
  PRODUCTGet,
  PRODUCTUpdate,
  SetPRODUCTObj,
} from "../../redux/actions/Product/Product.actions";

import { generateFilePath } from "../Utility/utils";
import { toastError, toastSuccess } from "../../utils/toastUtils";

import {
  approveProductsInBulk,
  deleteProductsInBulk,
  productCloneApi,
  unapproveProductsInBulk,
  excelUploadProduct,
  sampleProductFileDownload,
} from "../../services/product.service";
import { getProducts } from "../../services/product.service";
import SearchBox from "../Utility/SearchBox";

function ProductList() {
  const dispatch = useDispatch();

  const productArr = useSelector((state) => state.product.products);
  const [displayProductArr, setDisplayProductArr] = useState([]);

  const productPaginationObject = useSelector(
    (state) => state.product.productPaginationObj
  );
  const location = useLocation();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [query, setQuery] = useState("");
  const [displayButtons, setDisplayButtons] = useState(false);
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleGetProducts = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(PRODUCTGet(queryOf));
      let { data: get } = await getProducts(queryOf);
      if (get.success) {
        if (get.data && get.data && get.data.length >= 0) {
          setDisplayProductArr([
            ...get.data.map((el) => {
              el.checked = false;
              return el;
            }),
          ]);
        }
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProducts();
  }, [page, perPage, query]);

  useEffect(() => {
    // console.log("perPage 23424234");
    if (productArr && productArr.length >= 0) {
      setDisplayProductArr([
        ...productArr.map((el) => {
          el.checked = false;
          return el;
        }),
      ]);
    }
    if (productPaginationObject) {
      setTotalPages(productPaginationObject?.totalPages);
      setTotalCount(productPaginationObject?.totalCount);
      setPage(productPaginationObject?.page);
    }
  }, [productArr, productPaginationObject]);

  const handleEdit = (row) => {
    // dispatch(SetPRODUCTObj(row));
  };

  const handleDeleteById = async (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let queryOf = `page=${page}&perPage=${perPage}`;

      dispatch(PRODUCTDelete(id, queryOf));
    }
  };

  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalData, setModalData] = useState({});
  const [ModalBox, setModalBox] = useState(false);

  const handleStatusActuve = (id, status) => {
    try {
      let obj = {
        active: status,
      };
      dispatch(PRODUCTUpdate(obj, id));
      handleGetProducts();
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckProduct = (row, index, e) => {
    try {
      let tempArr = displayProductArr.map((el) => {
        if (row._id == el._id) {
          el.checked = !el.checked;
        }
        return el;
      });
      handleGetProductCheckedStatus(tempArr);
      setDisplayProductArr([...tempArr]);
    } catch (err) {
      toastError(err);
    }
    // console.log(index, row, e.target.checked)
  };

  const handleGetProductCheckedStatus = (tempArr) => {
    try {
      if (tempArr.some((el) => el.checked == true)) {
        setDisplayButtons(true);
      } else {
        setDisplayButtons(false);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleCloneProduct = async (product) => {
    try {
      if (product) {
        try {
          delete product._id;
          let { data: res } = await productCloneApi(product);
          if (res.data) {
            toastSuccess(res.message);
            dispatch(SetPRODUCTObj(res.data));
            // navigate("/Product/Update-Product");
            navigate("/Product/AddProduct");
          }
        } catch (error) {
          toastError(error);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleDeleteSelected = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await deleteProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleSetSelectedAsActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }
      let { data: res } = await approveProductsInBulk(selectedArr);
      // console.log(res, "response")
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleSetSelectedAsInActive = async () => {
    try {
      let selectedArr = displayProductArr
        .filter((el) => el.checked)
        .map((el) => {
          let obj = {
            productId: el._id,
          };
          return obj;
        });
      if (!selectedArr.length > 0) {
        toastError("Please select atleast one product to perform this action");
        return;
      }

      let { data: res } = await unapproveProductsInBulk(selectedArr);
      if (res.message) {
        toastSuccess(res.message);
        handleGetProducts();
      }
    } catch (e) {
      toastError(e);
    }
  };

  const handleClearSelection = () => {
    let tempArr = displayProductArr.map((el) => {
      el.checked = false;
      return el;
    });
    setDisplayProductArr(tempArr);
  };

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  const product_sale_columns = [
    {
      name: "SL",
      cell: (row, index) => (
        <>
          <span onClick={(e) => handleCheckProduct(row, index, e)}>
            {row.checked == true ? (
              <i className="fa fa-check-square" />
            ) : (
              <i className="fa fa-square" />
            )}
          </span>
        </>
      ),
      sortable: true,
      width: "1%",
    },
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "SKU",
      selector: (row) => row?.sku,
      sortable: true,
      width: "8%",
    },
    {
      name: "Internal Code",
      selector: (row) => row?.internalCode,
      width: "8%",
    },
    {
      name: "Mrp",
      selector: (row) =>
        row?.mrp ? (
          parseInt(row?.mrp).toLocaleString("en-IN")
        ) : (
          <>
            {row?.attributesArr[0]?.price > 0
              ? parseFloat(
                  Math.min(...row.attributesArr.map((obj) => obj.price))
                )
              : ""}
            --
            {row?.attributesArr[0]?.price > 0
              ? parseFloat(
                  Math.max(...row.attributesArr.map((obj) => obj.price))
                )
              : ""}
          </>
        ),
      width: "8%",
    },
    {
      name: "Gst",
      selector: (row) => (row?.gst ? row?.gst : ""),
      width: "8%",
    },

    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img
          height={100}
          width={100}
          style={{ height: 100, width: 100 }}
          // alt={row?.attributesArr[0]&&        row?.attributesArr[0]?.imageArr[0]&&row?.attributesArr[0]?.imageArr[0]?.imageAlt ? row?.attributesArr[0]?.imageArr[0]?.imageAlt : "ALT"}
          src={generateFilePath(
            row?.attributesArr &&
              row?.attributesArr?.length > 0 &&
              row?.attributesArr[0] &&
              row?.attributesArr[0]?.imageArr?.length > 0 &&
              row?.attributesArr[0]?.imageArr[0] &&
              row?.attributesArr[0]?.imageArr[0]?.image
              ? row?.attributesArr[0]?.imageArr[0]?.image
              : ""
          )}
        />
      ),
      width: "12%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/Product/Product-List"
            onDeleteClick={() => handleDeleteById(row?._id)}
            isRedirected={true}
            onEditClick={() => {
              setModalBox(true);
              setModalType("product-detail");
              setModalName(row.Name);
              dispatch(SetPRODUCTObj(row));
              setModalData(row);
              // handleEdit(row)
            }}
            edit
            editPath="/Product/Update-Product"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
          {/* <Tooltip title="Clone Product" placement="top" arrow>
            <button
              className="btn btn-light btn-sm rounded "
              onClick={() => {
                handleCloneProduct(row);
              }}
            >
              <i className="fa fa-clipboard"> </i>{" "}
            </button>
          </Tooltip> */}
        </>
      ),
    },
    // {
    //   name: "Status",
    //   button: true,
    //   cell: (row) => (
    //     <Switch
    //       onChange={(e) => handleStatusActuve(row._id, !row.active)}
    //       checked={row.active}
    //     />
    //   ),
    //   width: "8%",
    // },

    // {
    //   name: "Clone",
    //   width: "15%",
    //   cell: (row) => (
    //     <>
    //       {/* <CustomButton btntype="button" isRedirected={true} editPath={`/Product/clone-Product`} edit ClickEvent={(e) => { e.preventDefault(); dispatch(SetPRODUCTObj(row)) }} iconName="fa-solid fa-pen-to-square" btnName="Clone Product" /> */}
    //       <CustomButton btntype="button" isLink path={`/Product/clone-Product`} edit ClickEvent={() => { dispatch(SetPRODUCTObj(row)) }} iconName="fa-solid fa-pen-to-square" btnName="Clone Product" />

    //     </>
    //   ),
    // },
  ];

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSampleFileDownload = async () => {
    try {
      await sampleProductFileDownload();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        data={ModalData}
        ModalType={ModalType}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1">Product List</h5>
                <div className="d-flex gap-3 align-items-center">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>

                <CustomButton
                  isLink
                  iconName="fa-solid fa-plus"
                  btnName="BULK PRODUCT UPLOAD"
                  path="/Product/Bulk-Product-Upload"
                  small
                  roundedPill
                />

                {/* <CustomButton 
                         isBtn 
                         btntype="button" ClickEvent={() => handleSampleFileDownload()} downloadAble noIcon changeClass="btn p-0 m-0 fs-12 text-primary border-0" btnName="SAMPLE FILE DOWNLOAD" /> */}
                <CustomButton
                  isLink
                  iconName="fa-solid fa-plus"
                  btnName="ADD NEW PRODUCT"
                  path="/Product/AddProduct"
                />
              </div>
              {displayButtons && (
                <div className="d-flex align-items-center justify-content-end mb-4">
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton
                      isLink
                      iconName="fa-solid fa-minus"
                      ClickEvent={() => handleDeleteSelected()}
                      btnName="Delete Selected"
                      path="/Product/Product-List"
                    />
                  </div>
                  {/* <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsActive()} noIcon btnName="Set selected as Active" path="/Product/Product-List" />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton isLink iconName="fa-solid fa-plus" ClickEvent={() => handleSetSelectedAsInActive()} noIcon btnName="Set selected as In-Active" path="/Product/Product-List" />
                  </div> */}
                  <div style={{ marginLeft: 15 }}>
                    <CustomButton
                      isLink
                      iconName="fa-solid fa-plus"
                      ClickEvent={() => handleClearSelection()}
                      noIcon
                      btnName="Clear Selection"
                      path="/Product/Product-List"
                    />
                  </div>
                </div>
              )}
              {/* {console.log(displayProductArr, "displayProductArr234 get")} */}
              <DashboardTable>
                <DataTable
                  columns={product_sale_columns}
                  data={
                    displayProductArr && displayProductArr.length > 0
                      ? displayProductArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
      <EditModal
        ModalBox={ModalBox}
        data={ModalData}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
      />
    </main>
  );
}

export default ProductList;
