import {
  // getData
  // getObjById

  // updateById
  // getAllBySellerId

  add,
  deleteById,
  getData,
  updateById,
} from "../../../services/sellerCategorys.service";

export const SELLERCATEGORY_ADD = "SELLERCATEGORY_ADD";
export const SELLERCATEGORY_ADD_SUCCESS = "SELLERCATEGORY_ADD_SUCCESS";
export const SELLERCATEGORY_ADD_FAIL = "SELLERCATEGORY_ADD_FAIL";

export const GET_ALL_SELLERCATEGORYS = "GET_ALL_SELLERCATEGORYS";
export const GET_ALL_SELLERCATEGORYS_SUCCESS =
  "GET_ALL_SELLERCATEGORYS_SUCCESS";

export const GET_PAGINATION_DATA_SUCCESS = "GET_PAGINATION_DATA_SUCCESS";

export const GET_ALL_SELLERCATEGORYS_FAIL = "GET_ALL_SELLERCATEGORYS_FAIL";

export const UPDATE_SELLERCATEGORY_BY_ID = "UPDATE_SELLERCATEGORY_BY_ID";
export const UPDATE_SELLERCATEGORY_BY_ID_SUCCESS =
  "UPDATE_SELLERCATEGORY_BY_ID_SUCCESS";
export const UPDATE_SELLERCATEGORY_BY_ID_FAIL =
  "UPDATE_SELLERCATEGORY_BY_ID_FAIL";

export const SET_SELLERCATEGORY_OBJ = "SET_SELLERCATEGORY_OBJ";
export const SET_SELLERCATEGORY_OBJ_SUCCESS = "SET_SELLERCATEGORY_OBJ_SUCCESS";
export const SET_SELLERCATEGORY_OBJ_FAIL = "SET_SELLERCATEGORY_OBJ_FAIL";

export const GET_SELLERCATEGORY_BY_ID = "GET_SELLERCATEGORY_BY_ID";
export const GET_SELLERCATEGORY_BY_ID_SUCCESS =
  "GET_SELLERCATEGORY_BY_ID_SUCCESS";
export const GET_SELLERCATEGORY_BY_ID_FAIL = "GET_SELLERCATEGORY_BY_ID_FAIL";

export const DELETE_SELLERCATEGORY_BY_ID = "DELETE_SELLERCATEGORY_BY_ID";
export const DELETE_SELLERCATEGORY_BY_ID_SUCCESS =
  "DELETE_SELLERCATEGORY_BY_ID_SUCCESS";
export const DELETE_SELLERCATEGORY_BY_ID_FAIL =
  "DELETE_SELLERCATEGORY_BY_ID_FAIL";

export const SELLERCATEGORYGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_SELLERCATEGORYS });
    // console.log(formData, "formDatain seller ");
    let { data: response } = await getData(formData);
    // console.log(response, "action data");
    if (response) {
      dispatch({
        type: GET_ALL_SELLERCATEGORYS_SUCCESS,
        payload: {
          data: response.data,
          paginationObj:{
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
          message: response.message,
        },
      });
      // dispatch({
      //   type: GET_PAGINATION_DATA_SUCCESS,
      //   payload: {
      //     data: {
      //       page: response.page,
      //       perPage: response.perPage,
      //       totalCount: response.totalCount,
      //       totalPages: response.totalPages,
      //     },
      //   },
      // });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: SELLERCATEGORY_ADD_FAIL, payload: err });
  }
};

export const SELLERCATEGORYADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SELLERCATEGORY_ADD });
    let { data: response } = await add(formData);
    if (response) {
      dispatch({
        type: SELLERCATEGORY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(SELLERCATEGORYGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: SELLERCATEGORY_ADD_FAIL, payload: err });
  }
};

export const SETSELLERCATEGORYOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_SELLERCATEGORY_OBJ });
    if (formData) {
      dispatch({
        type: SET_SELLERCATEGORY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_SELLERCATEGORY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_SELLERCATEGORY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const SELLERCATEGORYUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_SELLERCATEGORY_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateById(id, formData);
    if (response) {
      // console.log(response,"updatein action ");
      dispatch({
        type: UPDATE_SELLERCATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(SELLERCATEGORYGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_SELLERCATEGORY_BY_ID_FAIL, payload: err });
  }
};

export const SELLERCATEGORYDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_SELLERCATEGORY_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_SELLERCATEGORY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(SELLERCATEGORYGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_SELLERCATEGORY_BY_ID_FAIL, payload: err });
  }
};
