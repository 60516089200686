import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import { PRODUCTAdd } from "../../../redux/actions/Product/Product.actions";
// import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import { HSNTAXGET } from "../../../redux/actions/HsnTax/HsnTax.actions";
import QuillEditor from "../../../utils/QuillEditor";
import { generateFilePath } from "../../Utility/utils";
import { toastError } from "../../Utility/ToastUtils";
import { getAllAtrributefordisplay } from "../../../services/Attribute.service";

function GeneralProduct() {
  const dispatch = useDispatch();
  const [internalCode, setInternalCode] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  const [productCode, setProductCode] = useState("");
  ///////manufacturer
  // const [companyName, setCompanyName] = useState("");
  // const [companyAddress, setCompanyAddress] = useState("");
  // const [companyCity, setCompanyCity] = useState("");
  // const [companyCountry, setCompanyCountry] = useState("");
  // const [companyPinCode, setCompanyPinCode] = useState("");
  ///////Country of Origin
  //////product details
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [sku, setSku] = useState("");
  const [despcription, setDespcription] = useState("");
  const [material, setMaterial] = useState("");
  const [Specifications, setSpecifications] = useState("");
  const [availableInventory, setAvailableInventory] = useState(0);
  const [productType, setProductType] = useState("");
  const [gst, setGst] = useState("");
  const [hsn, setHsn] = useState("");
  const [mrp, setMrp] = useState(0);
  const [stock, setStock] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [ships_in_days, setShips_in_days] = useState(0);
  const [returnPolicy, setReturnPolicy] = useState("");
  const [dom, setDom] = useState("");
  const [sizeChart, setsizeChart] = useState();
  const [shelfLife, setShelfLife] = useState(0);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  //////size of product
  const [product_dimension_width, setProduct_Dimension_width] = useState(0);
  const [product_dimension_height, setProduct_Dimension_height] = useState(0);
  const [product_dimension_length, setProduct_Dimension_length] = useState(0);
  const [product_dimension_weight, setProduct_Dimension_weight] = useState(0);
  const [dimensionWeightType, setDimensionSetweightType] = useState("grams");
  const [dimensionHeightType, setDimensionSetHeightType] = useState("cm");
  ///////size of packaging
  const [packaging_Length, setPackaging_Length] = useState(0);
  const [packaging_Width, setPackaging_Width] = useState(0);
  const [packaging_Height, setPackaging_Height] = useState(0);
  ///////dead weight
  const [dead_weight, setDead_weight] = useState(0);
  ///////tags
  const [tags, setTags] = useState("");
  //////brand
  const [selectedBrandId, setSelectedBrandId] = useState("");
  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  const [subAttributesArr, setSubAttributesArr] = useState([]);
  const [hsnTaxArr, setHsnTaxArr] = useState([]);

  ///////////////////////////////////////////////////////////////
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  ///////////////////////////////////////////////////////////////
  const [videoLink, setVideoLink] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");
  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [artisan, setArtisan] = useState("");
  const [craft, setCraft] = useState("");
  const [bestSeller, setBestSeller] = useState(false);
  const [productDisplay, setproductDisplay] = useState("");
  const [selectedHscTaxId, setSelectedHscTaxId] = useState("");

  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");

  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth.user);
  const brands = useSelector((state) => state.brand.brands);
  const hsnTaxes = useSelector((state) => state.hsnTax.hsnTaxs);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  // const attributes = useSelector((state) => state.attribute.attributes);
  const [attributes, setAttributes] = useState([]);

  const productStat = useSelector((state) => state.product.productStatus);
  const productStatusChangeSuccessFul = useSelector(
    (state) => state.product.productStatusChangeSuccessFully
  );

  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return {
            ...el,
            categoryId: el._id,
            subCategoryArr: handleFilterChecked(el.subCategoryArr),
          };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };

  const clearFunction = () => {
    try {
      setInternalCode("");
      setVendorCode("");
      setProductCode("");
      setName("");
      setSlug("");
      setSku("");
      setDespcription("");
      setMaterial("");
      setSpecifications("");
      setAvailableInventory(0);
      setProductType("");
      setGst("");
      setHsn("");
      setMrp(0);
      setStock(0);
      setSellingPrice(0);
      setShips_in_days(0);
      setReturnPolicy("");
      setDom("");
      setsizeChart();
      setShelfLife(0);
      setImageArr([{ image: "", imageAlt: "" }]);
      setProduct_Dimension_width(0);
      setProduct_Dimension_height(0);
      setProduct_Dimension_length(0);
      setProduct_Dimension_weight(0);
      setDimensionSetweightType("grams");
      setDimensionSetHeightType("cm");
      setPackaging_Length(0);
      setPackaging_Width(0);
      setPackaging_Height(0);
      setDead_weight(0);
      setTags("");
      setSelectedBrandId("");
      setMainCategoryArr([]);
      setMainAttributesArr([]);
      setSubAttributesArr([]);
      setHsnTaxArr([]);

      setModalBox(false);
      setModalType("");
      setModalName("");
      setVideoLink("");
      setProductImageStr("");
      setSpecificationFile("");
      setDisplayAttributesArr([]);
      setMetaTitle("");
      setMetaDescription("");
      setMetaImage("");
      setStatus("Publish");
      setSelectedCategoryArr([]);
      setArtisan("");
      setCraft("");
      setBestSeller(false);
      setproductDisplay("");
      setSelectedHscTaxId("");

      setDiscountType("");
      setDiscountValue("");
      dispatch(getAllNestedCategories());
      dispatch(BrandGet());

      // dispatch(ATTRIBUTEGet());
      dispatch(HSNTAXGET());
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(
    //   productStatusChangeSuccessFul,
    //   "==productStatusChangeSuccessFul=="
    // );
    if (
      productStatusChangeSuccessFul &&
      productStatusChangeSuccessFul.message
    ) {
      clearFunction();
      handleGetAllAtrributefordisplay();
    }
  }, [productStatusChangeSuccessFul]);

  const handleGetAllAtrributefordisplay = async () => {
    try {
      let { data: get } = await getAllAtrributefordisplay();
      // console.log( get," attributes23 get get get")
      if (get.data) {
        setAttributes(get.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  // useEffect(() => {
  //   // console.log(productStat, "productStat==-");
  //   if (productStat) {
  //     // console.log(productStat, "productStat");
  //     clearFunction();
  //     handleGetAllAtrributefordisplay();
  //   }
  // }, [productStat]);

  const handleSubmit = async () => {
    try {
      let cat_arr = returnSelectedCategories(mainCategoryArr);

      let tempCategoryArr = handleFilterChecked(mainCategoryArr);
      // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
      // console.log(selectedCategoryArr, "selectedCategoryArr");
      // console.log(selectedCategoryArr, "selectedCategoryArr")

      // if (name == "") {
      //   toastError("Product name is mandatory");
      //   return;
      // } else if (slug == "") {
      //   toastError("Slug is mandatory");
      //   return;
      // } else if (internalCode == "") {
      //   toastError("Internal Code is mandatory");
      //   return;
      // }

      // else if (hsn == "") {
      //   toastError("hsn code is madatory");
      //   return;
      // } 


      // else if (dom == "") {
      //   toastError("date of manufacturing is mandatory");
      //   return;
      // } 
      //     else if (sku == "") {
      //   toastError("sku is mandatry");
      //   return;
      // }
      // else if (productType == "") {
      //   toastError("Product Type is mandatory");
      //   return;
      // }
      // else if (productDisplay == "") {
      //   toastError("Product Display is mandatory");
      //   return;
      // }
      // else if (!displayAttributesArr || !(displayAttributesArr?.length > 0)) {
      //   toastError("Kindly atleast add one Product variant");
      //   return;
      // }
      // const hasImage = imageArr.some(image => image !== "");
      // const allAttributesHavePicture = displayAttributesArr.some(
      //   (el) => el?.imageArr.some(elx => elx.image == "")
      // );

      // if (allAttributesHavePicture) {
      //   toastError("Kindly add images for every Product's variant");
      //   return;
      // }
      // else if (tags == "") {
      //   toastError("Tags is mandatory");
      //   return;
      // }

      //=====================

      //==================
      // else if (material == "") {
      //   toastError("Material is mandatory");
      //   return;
      // } else if (despcription == "") {
      //   toastError("Description is mandatory");
      //   return;
      // } else if (Specifications == "") {
      //   toastError("Specifications is mandatory");
      //   return;
      // }
      // else if (metaTitle == "") {
      //   toastError("MetaTitle is mandatory");
      //   return;
      // }
      // else if (imageArr[0]?.image == "") {
      //   toastError(" Product image is mandatory");
      //   return;
      // } else if (imageArr[0]?.imageAlt == "") {
      //   toastError("Product Image alt is mandatory");
      //   return;
      // }

      // if (!(displayAttributesArr.length > 0)) {
      //
      //   if (sellingPrice == "") {
      //     toastError("Selling Price is mandatory");
      //     return;
      //   } else if (mrp == "") {
      //     toastError("Mrp is mandatory  ");
      //     return;
      //   } else if (stock == "") {
      //     toastError("Stock is mandatory");
      //     return;
      //   } else if (sellingPrice == 0) {
      //     toastError("Selling Price must be greater than 0");
      //     return;
      //   } else if (mrp == 0) {
      //     toastError("Mrp must be greater than 0");
      //     return;
      //   } else if (stock == 0) {
      //     toastError("Stock must be greater than 0");
      //     return;
      //   }
      //   if (parseInt(sellingPrice) > parseInt(mrp)) {
      //     toastError("Selling Price must be less than Mrp");
      //     return;
      //   }
      // }

      let obj = {
        internalCode,
        categoryArr: cat_arr.map((el) => {
          return { categoryId: el._id };
        }),
        productCode,
        name,
        productType,
        // createdBy: authUser?._id,
        sku: sku,
        slug,
        description: despcription,
        material,
        specification: Specifications,
        discountType,
        discountValue,
        gst,
        hsn,
        ships_in_days,
        returnPolicy,
        dom,
        shelfLife,
        imageArr,
        product_dimension_width,
        product_dimension_height,
        product_dimension_length,
        product_dimension_weight,
        dimensionWeightType,
        packaging_Length,
        packaging_Width,
        packaging_Height,
        dimensionHeightType,
        dead_weight,
        tags,
        videoLink,
        artisan,
        craft,
        productImageStr,
        specificationFile,
        status,
        metaTitle,
        metaDescription,
        metaImage,
        sizeChart,
        // sellingPrice: sellingPrice,
        brandId: selectedBrandId,
        productDisplay,
      };

      if (displayAttributesArr && displayAttributesArr.length > 0) {
        obj.attributesArr = displayAttributesArr;
      }

      // console.log(obj, "add product ");
      dispatch(PRODUCTAdd(obj));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet());
    // dispatch(ATTRIBUTEGet());
    handleGetAllAtrributefordisplay();
    dispatch(HSNTAXGET(`status=All`));
  }, []);

  useEffect(() => {
    if (hsnTaxes && hsnTaxes.length > 0) {
      setHsnTaxArr(hsnTaxes);
    }
  }, [hsnTaxes]);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    if (attributes && attributes.length > 0) {
      // console.log(attributes,"attributes23")
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    let subCategories = arr.reduce(
      (acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)],
      []
    );
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };

  const handleBrandSelection = (obj) => {
    // console.log(obj);
    setSelectedBrandId(obj?._id);
  };
  const handleHsnTaxSelection = (obj) => {
    setHsn(obj._id);
    // setSelectedHscTaxId(obj._id);
  };

  const handleFileSet = (value, index, indexMain) => {
    // console.log(index, "===oidenxnn");
    let temparray = [...displayAttributesArr];
    // console.log(temparray,"===displayAttributesArr---=")
    // console.log(el ,"elele",index,"===el index---=")
    let findObj = temparray.find((el, inx) => inx == indexMain);
    findObj.imageArr[index].image = value;
    temparray[indexMain] = findObj;

    setDisplayAttributesArr(temparray);
    //=============================================
    // let tempArr = imageArr;
    // tempArr[index].image = value;
    // setImageArr([...tempArr]);
  };

  const handleproductImageAltEntry = (value, index, indexMain) => {
    // console.log(value, index);
    let temparray = [...displayAttributesArr];
    let findObj = temparray.find((el, inx) => inx == indexMain);
    findObj.imageArr[index].imageAlt = value;
    temparray[indexMain] = findObj;
    setDisplayAttributesArr(temparray);
  };

  const handlePdfFileSet = (value) => {
    // console.log(value);
    setSpecificationFile(value);
  };

  const handleAttributeVariantChange = (e, index) => {
    // console.log(e,index, el,"attributes23 ==")
    let tempArr = [...mainAttributesArr];
    tempArr[index].checked = !tempArr[index].checked;
    // console.log(tempArr, " attributes23 sub attribute");
    setMainAttributesArr(tempArr);
  };

  const handleInnerAttributeVariantChange = (index, innerIndex, el) => {
    let tempArr = [...mainAttributesArr];
    // console.log(index,"index234",innerIndex,"inder",el," attributes23 ")
    // console.log(
    //   tempArr,
    //   "tempArr[index] attributes23 Index]"
    // );
    tempArr[index].attributeValueArr[innerIndex].checked =
      !tempArr[index].attributeValueArr[innerIndex].checked;
    // console.log(tempArr, "sub attributes23  -0-0");
    setMainAttributesArr([...tempArr]);
    generateCombinations(tempArr);
  };

  ////////////p n c generator
  const generateCombinations = (tempattributeArr) => {
    // console.log(tempattributeArr,"325tempattributeArr")
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeId: el.attributeId,
          attributeValueArr: [...el.attributeValueArr],
        }));
      }
      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeId: h.attributeId,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: 0,
          }))
        );
      }, []);
      return combine([combined, ...tailTail]);
    };
    let finalArr = [];
    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked);
        // console.log(tempArr,"tempArr attributes23")
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => ({
              name: el.name,
              attributeId: el.attributeId,
              attributeValueArr: [
                {
                  attributeId: el._id,
                },
              ],
              price: 0,
              imageArr: [{ image: "" }],
            }))
          );
        }
      }
    });
    // console.log(finalArr,"tempArr 2==2==")
    if (finalArr.length) {
      finalArr = combine(finalArr);
      // console.log(finalArr, "tempArr 2==45=");
      // setCombinationArr([...finalArr])
    } else {
      setDisplayAttributesArr([]);
      // setCombinationArr = []
    }

    // finalArr
    if (finalArr.length) {
      // console.log(finalArr, "tempArr 2====");
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          imageArr: [{ image: "" }],
          price: 0,
          discount: 0,
          currentStock: 0,
          stockNo: 0,
        })),
      ]);
    }
  };

  const handleDisplayAttributesArrChange = (e, index, key, el) => {
    if (e < 0) {
      toastError("Value must be greater than 0");
      return;
    }
    // console.log(
    //   e,
    //   "ee=",
    //   index,
    //   "idnex",
    //   key,
    //   "keykey",
    //   el,
    //   "attributes23 index, key"
    // );
    let tempArr = [...displayAttributesArr];
    // console.log(tempArr, "tempArr 235234");
    tempArr[index][key] = e;
    // console.log(tempArr, "tempArr 235");
    setDisplayAttributesArr([...tempArr]);
  };

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  const handleImageObjAdd = (el, index) => {
    let temparray = [...displayAttributesArr];
    // console.log(temparray,"===displayAttributesArr---=")
    // console.log(el ,"elele",index,"===el index---=")
    let findObj = temparray.find((el, inx) => inx == index);
    if (findObj.imageArr) {
      findObj.imageArr.push({ image: "", imageAlt: "" });
    } else {
      findObj.imageArr = [];
      findObj.imageArr.push({ image: "", imageAlt: "" });
    }
    // console.log(findObj,"findObj========")
    temparray[index] = findObj;
    // console.log(temparray, "++temparray");
    setDisplayAttributesArr(temparray);
  };

  const handleImageObjRemove = (el, index) => {
    let temparray = [...displayAttributesArr];
    let findObj = temparray.find((el, inx) => inx == index);
    if (findObj.imageArr && (findObj.imageArr.length - 1) > 0) {
      findObj.imageArr.pop();
    }

    temparray[index] = findObj;
    setDisplayAttributesArr(temparray);

  };






  const handleImageAdd = () => {
    let temparray = [...imageArr];
    temparray.push({ image: "", imageAlt: "" });
    setImageArr(temparray);
  };

  const handleImageRemove = () => {
    let temparray = [...imageArr];
    if (temparray && (temparray.length - 1) > 0) {
      temparray.pop();
    }
    setImageArr(temparray);

  };


  const handleImageFileSet = (value, index) => {
    let temparray = [...imageArr];
    temparray[index].image = value;
    setImageArr(temparray);
  };


  const handleImageAltEntry = (value, index) => {
    let temparray = [...imageArr];
    temparray[index].imageAlt = value;
    setImageArr(temparray);
  };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(
            el.subCategoryArr,
            id,
            value
          );
          return el;
        } else {
          return el;
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };

  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div className="col-12 mb-3" style={{ marginLeft: `${obj.level + 5}px` }}>
        <input
          className="form-check-input pointer"
          checked={obj.checked}
          value={tags}
          onChange={(event) =>
            handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)
          }
          type="checkbox"
        />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };

  useEffect(() => {
    setDiscountValue("");
  }, [discountType]);

  function handleCheckDiscountValue(e) {
    try {
      e.preventDefault();
      if (e.target.value < 0) {
        toastError("Value can't be less than 0");
        return;
      }
      if (discountType == "Percentage") {
        if (e.target.value < 0 || e.target.value > 100) {
          toastError("Discount Value cannot be more than 100 % or less than 0");
          return;
        } else {
          setDiscountValue(e.target.value);
        }
      } else if (discountType == "None" || discountType == "") {
        setDiscountValue("");
      } else {
        setDiscountValue(e.target.value);
      }
    } catch (e) {
      toastError(e);
    }
  }

  return (
    <form className="form">
      <div className="row gy-4 gy-xxl-0">
        <div className="col-8">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>
              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Produc Name <span className="red">*</span>
                  </label>
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Slug <span className="red">*</span>
                  </label>
                  <input
                    value={slug}
                    onChange={(event) => setSlug(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Internal Code <span className="red">*</span>
                  </label>
                  <input
                    value={internalCode}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Internal code cannot be negative");
                        return;
                      } else {
                        setInternalCode(event.target.value);
                      }
                    }}
                    type="text"
                    className="form-control"
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <label>BRAND</label>
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-circle-plus"
                      btnName="ADD NEW"
                      changeClass="green fs-12 border-0 bg-white"
                      ClickEvent={(e) => {
                        e.preventDefault();
                        setModalBox(true);
                        setModalType("addBrandModal");
                        setModalName("Create Brand");
                      }}
                    />
                    <AddModal
                      ModalBox={ModalBox}
                      setModalBox={setModalBox}
                      name={ModalName}
                      ModalType={ModalType}
                    />
                  </div>
                  {brands && brands.length > 0 && (
                    <Select
                      onChange={handleBrandSelection}
                      options={
                        brands && brands.length > 0
                          ? brands.map((el) => ({
                            ...el,
                            label: el.name,
                            value: el._id,
                          }))
                          : []
                      }
                    />
                  )}
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    GST
                    {/* <span className="red">*</span> */}
                  </label>
                  <input
                    value={gst}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Gst cannot be negative");
                        return;
                      } else {
                        setGst(event.target.value);
                      }
                    }}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    HSN CODE<span className="red">*</span>
                  </label>
                  {hsnTaxArr && hsnTaxArr.length > 0 && (
                    <Select
                      onChange={handleHsnTaxSelection}
                      options={
                        hsnTaxArr && hsnTaxArr.length > 0
                          ? hsnTaxArr.map((el) => ({
                            ...el,
                            label: el.hsn,
                            value: el._id,
                          }))
                          : []
                      }
                    />
                  )}
                  {/* <input
                    value={hsn}
                    onChange={(event) => setHsn(event.target.value)}
                    type="text"
                    className="form-control"
                  /> */}
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Date of manufacturing
                  </label>
                  <input
                    value={dom}
                    onChange={(event) => setDom(event.target.value)}
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    SKU<span className="red">*</span>
                  </label>
                  <input
                    value={sku}
                    onChange={(event) => setSku(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Per Type (Meter,Piece)<span className="red">*</span>
                  </label>
                  <input
                    value={productType}
                    onChange={(event) => setProductType(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Product Display<span className="red">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={productDisplay}
                    onChange={(e) => {
                      setproductDisplay(e.target.value);
                    }}
                  >
                    <option value="">None </option>
                    <option value="arrival">New Arrival</option>
                    <option value="bestseller">BestSeller</option>
                    <option value="Samagri">Samagri</option>
                  </select>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>Discount type</label>
                  <select
                    className="form-control"
                    value={discountType}
                    onChange={(e) => {
                      setDiscountType(e.target.value);
                    }}
                  >
                    <option value="None">None </option>
                    <option value="FlatOff">FlatOff</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Discount Value</label>
                  <input
                    value={discountValue}
                    onChange={(event) => handleCheckDiscountValue(event)}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Shipping & Dimentions Info
                  </h5>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>Ships In (Days)</label>
                  <input
                    value={ships_in_days}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Value can't be less than 0 ");
                        return;
                      }
                      setShips_in_days(event.target.value);
                    }}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Packaging Length</label>
                  <input
                    value={packaging_Length}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Value can't be less than 0 ");
                        return;
                      }
                      setPackaging_Length(event.target.value);
                    }}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Packaging Width</label>
                  <input
                    value={packaging_Width}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Value can't be less than 0 ");
                        return;
                      }
                      setPackaging_Width(event.target.value);
                    }}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Packaging Height</label>
                  <input
                    value={packaging_Height}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Value can't be less than 0 ");
                        return;
                      }
                      setPackaging_Height(event.target.value);
                    }}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Dead weight</label>
                  <input
                    value={dead_weight}
                    onChange={(event) => {
                      if (event.target.value < 0) {
                        toastError("Value can't be less than 0 ");
                        return;
                      }
                      setDead_weight(event.target.value);
                    }}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes
                </label>
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={el._id}>
                        <input
                          checked={el.checked}
                          onChange={(e) =>
                            handleAttributeVariantChange(e, index, el)
                          }
                          className="form-check-input pointer"
                          type="checkbox"
                        />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>
                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input
                                      checked={ele.checked}
                                      onChange={(event) =>
                                        handleInnerAttributeVariantChange(
                                          index,
                                          index2
                                        )
                                      }
                                      className="form-check-input pointer"
                                      type="checkbox"
                                    />
                                    <label style={{ paddingLeft: 5 }}>
                                      {ele.label}
                                    </label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>

              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Variants Info <span className="red">*</span>
                  </h5>
                </div>
              )}

              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12" key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 mb-3">
                          <label>Variant Price</label>
                          <input
                            value={el?.price}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "price",
                                el
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                          <label>Variant Stock</label>
                          <input
                            value={el?.currentStock}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "currentStock"
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>

                        <div className="col-12 col-md-12 mb-3">
                          <h5 className="blue-1 mb-3">
                            Image Info <span className="red">*</span>
                          </h5>
                          <div className="row">
                            <div className="col-2 me-5">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() => handleImageObjAdd(el, index)}
                                isBtn
                                noIcon
                                btnName="+"
                              />
                            </div>
                            <div className="col-2">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() =>
                                  handleImageObjRemove(el, index)
                                }
                                isBtn
                                noIcon
                                btnName="-"
                              />
                            </div>
                          </div>
                          <div className="row">

                            {el?.imageArr &&
                              el?.imageArr.length > 0 &&
                              el?.imageArr.map((ele, indx) => {
                                return (
                                  <div
                                    key={indx}
                                    className="col-4 mb-3">
                                    <label>
                                      Image {indx + 1}<span className="red">*</span>
                                    </label>
                                    <div
                                      style={{
                                        marginTop: 20,
                                        // borderBottom: "grey 1px solid",
                                        paddingBottom: 15,
                                      }}
                                      className="row"
                                    >
                                      <div className="col-12">
                                        <img
                                          src={
                                            `${ele.image}`.includes("base64")
                                              ? ele.image
                                              : generateFilePath(ele.image)
                                          }
                                          style={{ height: 80 }}
                                        />
                                        <br />
                                        <br />
                                        <FileUpload
                                          onFileChange={(val) =>
                                            handleFileSet(val, indx, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-12">
                                        <label>
                                          Variant Image alt
                                          <span className="red">*</span>
                                        </label>
                                        <input
                                          onChange={(e) =>
                                            handleproductImageAltEntry(
                                              e.target.value,
                                              indx, index
                                            )
                                          }
                                          value={ele?.imageAlt}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">
                  Tags (Comma Separated)
                </h5>
                <div className="col-12 mb-3">
                  <input
                    value={tags}
                    onChange={(event) => setTags(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Product Category
              </h5>
              {mainCategoryArr &&
                mainCategoryArr.length > 0 &&
                mainCategoryArr.map((el) => {
                  return handleRenderCheckboxCategory(el);
                })}
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <div className="col-12 mb-0">
                <h5 className="blue-1 mb-4">
                  Product (Dimentions) Weight Height Info{" "}
                </h5>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight</label>
                <input
                  value={product_dimension_weight}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setProduct_Dimension_weight(event.target.value);
                  }}
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight Type</label>
                <select
                  className="form-control"
                  value={dimensionWeightType}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setDimensionSetweightType(e.target.value);
                  }}
                >
                  <option value="grams">grams</option>
                  <option value="kg">Kg</option>
                </select>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Length </label>
                <input
                  value={product_dimension_length}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setProduct_Dimension_length(event.target.value);
                  }}
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Breadth </label>
                <input
                  value={product_dimension_width}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setProduct_Dimension_width(event.target.value);
                  }}
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Height </label>
                <input
                  type="number"
                  value={product_dimension_height}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setProduct_Dimension_height(event.target.value);
                  }}
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Dimensions Type</label>
                <select
                  className="form-control"
                  value={dimensionHeightType}
                  onChange={(e) => {
                    setDimensionSetHeightType(e.target.value);
                  }}
                >
                  <option value="cm">Cm</option>
                  <option value="inches">Inches</option>
                </select>
              </div>
            </div>

            <div className="border-bottom pb-3 mb-4 row">
              {/* {displayAttributesArr && !(displayAttributesArr.length > 0) && ( */}
              <>
                {/* {console.log(displayAttributesArr, "displayAttributesArr213")} */}
                {/* {displayAttributesArr && !(displayAttributesArr.length > 0) && (
                  <>
                                      <h5 className="blue-1 mb-4">
                      Price Info And Stock <span className="red">*</span>
                    </h5>
                                      <div className="col-12 col-md-6 mb-3">
                      <label>
                        SELLING PRICE<span className="red">*</span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        value={sellingPrice}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Value can't be less than 0 ");
                            return;
                          } else {
                            setSellingPrice(event.target.value);
                          }
                        }}
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        MRP<span className="red">*</span>
                      </label>
                      <input
                        value={mrp}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Mrp cannot be negative ");
                            return;
                          } else {
                            setMrp(event.target.value);
                          }
                        }}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        STOCK<span className="red">*</span>
                      </label>
                      <input
                        value={stock}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Stock cannot negative");
                            return;
                          }
                          setStock(event.target.value);
                        }}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </>
                )} */}
              </>
            </div>
            {/* <div className="col-12 mb-3">
              <label>Size Chart</label>
              <FileUpload onFileChange={(val) => setsizeChart(val)} />
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Artisan <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor handleChange={(e) => setArtisan(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Craft <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor handleChange={(e) => setCraft(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Return Policy </h5>
              <div className="col-12 mb-3">
                <QuillEditor handleChange={(e) => setReturnPolicy(e)} />
              </div>
            </div> */}
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Material
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={material}
                  handleChange={(e) => setMaterial(e)}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Description <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={despcription}
                  handleChange={(e) => setDespcription(e)}
                />
                {/* <ReactQuill theme="snow" onChange={(e) => setDespcription(e)} /> */}
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Specifications
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={Specifications}
                  handleChange={(e) => setSpecifications(e)}
                />
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-4">
                SEO info
              </h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input
                  onChange={(e) => setMetaTitle(e.target.value)}
                  value={metaTitle}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea
                  onChange={(e) => setMetaDescription(e.target.value)}
                  value={metaDescription}
                  name="META DESCRIPTION"
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <br />
                <br />
                <img
                  src={
                    `${metaImage}`.includes("base64")
                      ? metaImage
                      : generateFilePath(metaImage)
                  }
                  style={{ height: 80 }}
                />
                <br />
                <br />
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              <div className="col-12">
                <CustomButton
                  btntype="button"
                  ClickEvent={handleSubmit}
                  isBtn
                  iconName="fa-solid fa-check"
                  btnName="Save"
                />
              </div>
            </div>
          </DashboardBox>
        </div>
        {/* <div className="col-12 col-xxl-4">
          <DashboardBox> */}
        <div className="col-4">
          <DashboardBox>

            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">
                Product Image Info
              </h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageAdd}
                    isBtn
                    noIcon
                    btnName="+"
                  />
                </div>
                <div className="col-2">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageRemove}
                    isBtn
                    noIcon
                    btnName="-"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                {imageArr &&
                  imageArr.length > 0 &&
                  imageArr.map((el, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: 20,
                          borderBottom: "grey 1px solid",
                          paddingBottom: 15,
                        }}
                        className="row"
                      >
                        <div className="col-12">
                          <img
                            src={
                              `${el.image}`.includes("base64")
                                ? el.image
                                : generateFilePath(el.image)
                            }
                            style={{ height: 80 }}
                          />
                          <br />
                          <br />
                          <FileUpload
                            onFileChange={(val) => handleImageFileSet(val, index)}
                          />
                        </div>
                        <div className="col-12">
                          <label>
                            Product Image alt<span className="red">*</span>
                          </label>
                          <input
                            onChange={(e) =>
                              handleImageAltEntry(e.target.value, index)
                            }
                            value={el?.imageAlt}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </DashboardBox>
        </div>

        {/* <div className="row">
              <h5 className="blue-1 mb-3">Others Info</h5>
              <div className="col-12 mb-3">
                <label>
                  STATUS<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Publish")} checked={status == "Publish"} className="form-check-input pointer" type="radio" name="product-status" value="option1" id="product-publish" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-publish">
                      Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setStatus("Pending")} checked={status == "Pending"} className="form-check-input pointer" type="radio" name="product-status" value="option2" id="product-pending" />
                    <label className="form-check-label fs-14 pointer" htmlFor="product-pending">
                      Pending
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  IS BEST SELLER<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setBestSeller(true)} checked={bestSeller == true} className="form-check-input pointer" type="radio" name="product-status1" value="option1" id="bestSeller1" />
                    <label className="form-check-label fs-14 pointer" htmlFor="bestSeller1">
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input onChange={(e) => setBestSeller(false)} checked={bestSeller == false} className="form-check-input pointer" type="radio" name="product-status1" value="option2" id="bestSeller" />
                    <label className="form-check-label fs-14 pointer" htmlFor="bestSeller">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div> */}

      </div>
    </form>
  );
}

export default GeneralProduct;
