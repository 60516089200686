import React, { useEffect, useState } from 'react'
import { getSystemSetting, updateSystemSetting } from '../../services/system.service';
import CustomButton from '../Utility/Button'
import { DashboardBox } from '../Utility/DashboardBox'
import FileUpload from "../Utility/FileUpload";
import { toastError, toastSuccess } from '../Utility/ToastUtils';
import { generateFilePath } from '../Utility/utils';

const Maintenance = () => {

    const [title, setTitle] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [image, setImage] = useState("")
    const [status, setStatus] = useState(true)
    const [systemSetting, setSystemSetting] = useState([])
    const [systemSettingId, setSystemSettingId] = useState("")
    const handleGetAllUsers =async () => {
     
        try 
        {
           const { data: res } = await getSystemSetting();
           setSystemSetting(res?.data[0]);
       } catch (error) {
           console.error(error);
       }  
   
         };
   
   
         useEffect(() => {
           handleGetAllUsers();
         }, []);

         useEffect(() => {
           if(systemSetting){
          
            setTitle(systemSetting?.maintenanceMode?.title);
            setSubTitle(systemSetting?.maintenanceMode?.subTitle);
            setImage(systemSetting?.maintenanceMode?.image);
            setStatus(systemSetting?.maintenanceMode?.status);
            setSystemSettingId(systemSetting._id)
            
           }
          }, [systemSetting]);
          
          
          
          const handleSubmit = async (e) => {
            e.preventDefault();
            try {

        if(`${title}` == ''){
            toastError('Title Id is mandatory');
            return
          }


          if(`${subTitle}` == ''){
            toastError('subTitle Id is mandatory');
            return
          }

          if(`${status}` == ''){
            toastError('status Id is mandatory');
            return
          }


      

          let obj = {
            maintenanceMode:{
                    title,
                    subTitle,
                   
                    status
                    }
           }

          if(`${image}` !=""  ){
            obj.image = image
          }
            const { data: res } =     await updateSystemSetting(systemSettingId,obj)
            toastSuccess(res?.message);

            handleGetAllUsers();
        } catch (error) {
            toastError(error)
        }
    };


  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <h5 className="blue-1 mb-4">Maintenance Setting</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-md-6 mb-3">
                    <label className="blue-1 fs-12">
                    Title<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Title"
                      className="form-control"
                      value={title}
                      onChange={(event) => setTitle(event.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="blue-1 fs-12">
                  Sub  Title<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Sub Title"
                      className="form-control"
                      value={subTitle}
                      onChange={(event) => setSubTitle(event.target.value)}
                    />
                  </div>
                <div className="col-6 mb-3">
                <label>Maintenance Page Banner (1300x920)PX </label>
                
                {/* <img src={`${image}`.includes("base64") ? image : generateFilePath(image)} style={{ height: 80 }} /> */}
                <FileUpload onFileChange={(val) => setImage(val)} />
                

              </div>
              <div className="col-6">
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                onClick={() => setStatus(true)}
                type="radio"
                name="status"
                checked={status}

              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="status"

                checked={status == false}
                onClick={() => setStatus(false)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
           
          </div>
        </div>
      </section>
    </main>
  )
}

export default Maintenance