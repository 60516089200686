import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as TAG from "../../actions/Tag/Tag.actions";

const initialState = {
  tags: null,
  tagObj: null,
  loading: false,
  error: null,
  tagsPaginationObj:  null,
};

export const tagReducer = (state = initialState, action) => {
  switch (action.type) {
    case TAG.TAG_ADD:
      return {
        ...state,
        loading: true,
      };
    case TAG.TAG_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case TAG.TAG_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TAG.GET_ALL_TAGS:
      return {
        ...state,
        loading: true,
      };
    case TAG.GET_ALL_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        tags: action.payload.data,
        tagsPaginationObj: action.payload.paginationObj,
      };
    case TAG.GET_ALL_TAGS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TAG.UPDATE_TAG_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case TAG.UPDATE_TAG_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TAG.UPDATE_TAG_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TAG.DELETE_TAG_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case TAG.DELETE_TAG_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TAG.DELETE_TAG_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TAG.SET_TAG_OBJ:
      return {
        ...state,
        loading: true,
      };
    case TAG.SET_TAG_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        tagObj: action.payload.data,
        loading: false,
        error: null,
      };
    case TAG.SET_TAG_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
