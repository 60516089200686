import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import {
  SETSTATEOBJ,
  STATEADD,
  STATEGET,
  STATEUPDATE,
} from "../../../redux/actions/State/States.actions";
import {
  CITYADD,
  CITYUPDATE,
  SETCITYOBJ,
  setCityStatus,
} from "../../../redux/actions/City/City.actions";
import { toastError } from "../../../utils/toastUtils";

function AddCity({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [stateId, setStateId] = useState("");
  const [stateArr, setStateArr] = useState([]);
  const [stateObj, setStateObj] = useState({});
  const [cityObject, setCityObject] = useState({});
  const statesObj = useSelector((state) => state.states.statesObj);
  const cityObj = useSelector((state) => state.city.citiesObj);
  const statesArr = useSelector((state) => state.states.states);
  const [query, setQuery] = useState("");

  const cityStatusCheck = useSelector((state) => state.city.cityStatusUpdate);
  const clearFunc = () => {
    setName("");
    setStatus(generalModelStatuses.APPROVED);
    // setStateId("");
    // setStateArr([]);
    setCityObject({});
    setStateObj({});
    dispatch(STATEGET());
  };

  useEffect(() => {
    if (cityStatusCheck && cityStatusCheck?.message) {
      dispatch(SETCITYOBJ(null));
      clearFunc();
    }
    return () => {
      dispatch(setCityStatus(null));
    };
  }, [cityStatusCheck]);

  const handleAddCategory = async () => {
    try {
      let obj = {
        name,
        stateId: stateId,
        status,
      };
      // console.log(obj, "category obj");

      if (cityObject && cityObject?._id) {
        dispatch(CITYUPDATE(cityObject?._id, obj, query));
      } else {
        dispatch(CITYADD(obj));
      }
      // console.log(responseGet, "response G get");
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (cityObj && cityObj?._id) {
      setQuery(cityObj?.query);
      setName(cityObj?.name);
      setStatus(cityObj?.status);
      setStateId(cityObj?.stateId);
      setCityObject(cityObj);
      if (stateArr && stateArr.length > 0) {
        setStateObj(
          ...stateArr
            .filter((el) => el?._id == cityObj?.stateId)
            .map((el) => {
              return { ...el, label: el.name, value: el?._id };
            })
        );
      }
    }
    return () => {
      dispatch(SETCITYOBJ(null));
    };
  }, [cityObj, stateArr]);

  // useEffect(() => {
  //   if (stateArr && stateArr.length > 0) {
  //     setStateObj(
  //       ...stateArr
  //         .filter((el) => el?._id == cityObj?.stateId)
  //         .map((el) => {
  //           return { ...el, label: el.name, value: el?._id };
  //         })
  //     );
  //   }
  // }, [stateArr]);

  useEffect(() => {
    dispatch(STATEGET());
  }, []);

  useEffect(() => {
    if (statesArr) {
      let activStateArr = statesArr.filter((el) => el?.status == "APPROVED");
      setStateArr(activStateArr);
    }
  }, [statesArr]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5>{cityObject && cityObject?._id ? "Update" : "Add"} City</h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-12 col-md-12">
          <label>
            State <span className="red">*</span>
          </label>
          <Select
            options={stateArr.map((el) => {
              return { ...el, value: el._id, label: el.name };
            })}
            placeholder="Select from options"
            defaultInputValue={stateId}
            value={stateObj}
            onChange={(e) => {
              // console.log(e, "asd");
              setStateId(e.value);
              setStateObj(e);
            }}
          />
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddCity;
