import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { CATEGORYGet } from "../../redux/actions/Category/Category.actions";
import { addBuyNgetN } from "../../services/BuyNgetN.service";
// import { getProductForSales } from "../../services/flashSales.service";

import { toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { schemeType, schemeTypeArr } from "../Utility/utils";

import {
  PRODUCTDelete,
  PRODUCTGet,
  PRODUCTUpdate,
  SetPRODUCTObj,
} from "../../redux/actions/Product/Product.actions";

import {
  BUYNANDGETNGET,
  BUYNANDGETNADD,
  SETBUYNANDGETNOBJ,
  BUYNANDGETNUPDATE,
  BUYNANDGETNDELETE,
} from "../../redux/actions/BuyN&GetN/BuyN&GetN.actions";

import moment from "moment";

export default function AddBuyNgetN() {

  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [name, setName] = useState("");
  const [buyAmount, setBuyAmount] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [email, SetEmail] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, SetAddress] = useState("");
  const [country, SetCountry] = useState("");
  const [state, SetState] = useState("");
  const [city, SetCity] = useState("");
  const [postalCode, SetPostalCode] = useState("");
  const [prevObjId, SetPrevObjId] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [shippingCharges, setShippingCharges] = useState(0);
  const [selectedSchemeType, setSelectedSchemeType] = useState({
    label: schemeType.BUY_GET_PRODUCT,
    value: schemeType.BUY_GET_PRODUCT,
  });
  const [offerArr, setOfferArr] = useState([
    { productToBuyArr: [], productToGetArr: [] },
  ]);
  const [displayProductInternalArr, setDisplayProductInternalArr] = useState(    []  );
  const [minimumAmount, setMinimumAmount] = useState(0);
  const [maximumAmount, setMaximumAmount] = useState(0);

  const [discountValue, setDiscountValue] = useState(0);
  const [price, setPrice] = useState(0);

  const [discountTypeObj, setDiscountTypeObj] = useState({});

  const buyAndgetObj = useSelector((state) => state.buyNandGetN.buyNAndGetNObj);
  const categoryArr = useSelector((state) => state.category.categories);
  const productArr = useSelector((state) => state.product.products);


  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);


  const handleGet = () => {
    dispatch(CATEGORYGet());
    dispatch(PRODUCTGet());
  };

  useEffect(() => {
    handleGet();
  }, []);




  useEffect(() => {
    if (buyAndgetObj && buyAndgetObj._id) {
      // console.log(buyAndgetObj?.offerArr, " product buyAndgetObj123");
      setName(buyAndgetObj?.name);

      setBuyAmount(buyAndgetObj?.schemeObj?.buyAmount);
      setGstAmount(buyAndgetObj?.schemeObj?.gstAmount);
      setMinimumAmount(buyAndgetObj?.minimumAmount);
      setMaximumAmount(buyAndgetObj?.maximumAmount);
      setOfferArr([...buyAndgetObj?.offerArr]);

      setValidTill(moment(buyAndgetObj?.validTill).format("YYYY-MM-DD"));
      setValidFrom(moment(buyAndgetObj?.validFrom).format("YYYY-MM-DD"));
      setSelectedProductArr([
        ...buyAndgetObj?.offerArr.map((el) => {
          el?.productToBuyArr.map((le) => {
            // console.log(le,"le product To buy")
            // console.log(le?.name,"le product To buy")
            return {
              // ...le,
              label: `${le?.name}|${le?.sku}`,
              value: `${le?.name} |${le?.sku} `,
            };
          });
          // return {
          //   ...el,
          //   label: `${el?.name} | ${el?.sku}`,
          //   value: `${el?.name} | ${el?.sku}`,
          // };
        }),
      ]);

      setSelectedCategoryArr([...buyAndgetObj?.selectedCategoryArr?.map((le)=>{
        return {
          label:`${le?.name}`||`${le?.slug}`,
          value:`${le?.name}`||`${le?.slug}`
        } })]);
      SetPrevObjId(buyAndgetObj?._id);
    }
    return () => {
      dispatch(SETBUYNANDGETNOBJ(null));
    };
  }, [buyAndgetObj]);


  const handleProductSelect = (val, index, check) => {
    // console.log(val, "valalvavlavlavl");
    try{

      for (let el of val) {
        if (el && el._id) el.productId = el._id;
      }
      
      let tempOfferArr = [...offerArr];
      if (check == "BUY") {
      tempOfferArr[index].productToBuyArr = val;
    } else {
      tempOfferArr[index].productToGetArr = val;
    }
    setOfferArr([...tempOfferArr]);
  }catch(err){
    toastError(err)
  }
  };


  
  useEffect(() => {
    if (productArr && productArr.length >= 0) {
      setProductInternalArr(productArr);
      setDisplayProductInternalArr(productArr);
    }
  }, [productArr]);



  const clearFunc = () => {
    try{

      setSelectedProductArr([]);
      setName("");
      setBuyAmount("");
    setSelectedSchemeType({});
    setGstAmount("");
    setMinimumAmount("");
    setMaximumAmount("");
    setOfferArr([]);
    setValidFrom("");
    setValidTill("");
    setDisplayProductInternalArr([]);
    
    setSelectedCategoryArr([]);
    dispatch(CATEGORYGet());
    dispatch(PRODUCTGet());
  }catch(err){
    toastError(err)
  }
}
  
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (name == "") {
        toastError("Name is mandatory");
        return;
      } else if (buyAmount == "") {
        toastError("Buy Amount is mandatory");
        return;
      } else if (gstAmount == "") {
        toastError("Gst Amount is mandatory");
        return;
      } else if (minimumAmount == "") {
        toastError("Minimum Amount is mandatory");
        return;
      } else if (maximumAmount == "") {
        toastError("Maximum Amount is mandatory");
        return;
      } else if (validFrom == "") {
        toastError("Valid From is mandatory");
        return;
      } else if (validTill == "") {
        toastError("Valid Till is mandatory");
        return;
      }

      let obj = {
        productArr: selectedProductArr.map((el) => {
          let tempObj = {
            productId: el.productId,
            variantId: el.variantId,
          };
          return tempObj;
        }),
        name,
        schemeObj: {
          buyAmount: buyAmount,
          schemeType: selectedSchemeType.value,
          gstAmount: gstAmount,
        },
        minimumAmount,
        maximumAmount,
        offerArr,
        validFrom,
        validTill,
        sellerId: authObj?.user?._id,
        selectedCategoryArr,
      };
      // console.log(obj, "object234");
      if (buyAndgetObj && buyAndgetObj._id) {
        dispatch(BUYNANDGETNUPDATE(obj, buyAndgetObj._id));
      } else {
        dispatch(BUYNANDGETNADD(obj));
      }
      clearFunc();
    } catch (error) {
      toastError(error);
    }
  };

  const handleOfferArrAdd = () => {
    try{

      let tempArr = [...offerArr];
      tempArr.push({ productToBuyArr: [], productToGetArr: [] });
      setOfferArr([...tempArr]);
    }catch(err){
      toastError(err)
    }
  };

  const handleOfferArrDelete = () => {
    let tempArr = [...offerArr];
    tempArr.pop();
    setOfferArr([...tempArr]);
  };


  const handleCategorySelection = (arr) => {
    try{

      let tempArr = [...productInternalArr];
      tempArr = tempArr.filter((el) =>
      arr.some((elx) => el.categoryArr.some((elz) => elz.categoryId == elx._id))
      );
    for(let el of arr ){
      el.categoryId = el._id;
    }
    // console.log(arr,"temp21313 ")

    setDisplayProductInternalArr([...tempArr]);
    setSelectedCategoryArr(arr);
  }catch(err){
    toastError(err)
  }
  };



  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="blue-1 m-0"> Products</h5>
                    {/* <div style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}>
                      <input checked={selectAll} onChange={(e) => setSelectAll((prev) => !prev)} type="checkbox" />
                    </div>
                    <label>{selectAll == false ? "Select" : "Un-Select"} All</label> */}
                  </div>
                  {/* {console.log(offerArr ,"offerArr 1234")} */}
                  {offerArr &&
                    offerArr.length > 0 &&
                    offerArr.map((el, i) => {
                      // console.log(el,"elelelele")
                      return (
                        <div key={i}> 
                          <div style={{ marginTop: 5 }}>
                            <label>
                              Product To Buy
                              <span className="red">*</span>
                            </label>
                     {/* {console.log(displayProductInternalArr,"displayProductInternalArr")} */}
                            <Select
                              options={[
                                ...displayProductInternalArr.map((el) => {
                                  // return { ...el, label: `${el?.productName} | ${el?.variantName}`, value: `${el?.productName} | ${el?.variantName}` };
                                  return {
                                    ...el,
                                    label: `${el?.name} | ${el?.sku}`,
                                    value: `${el?.name} | ${el?.sku}`,
                                  };
                                }),
                              ]}
                              isMulti
                              placeholder="Select from Products "
                              value={el?.productToBuyArr}
                              onChange={(e) => {
                                handleProductSelect(e, i, "BUY");
                              }}
                            />
                          </div>

                          <div style={{ marginTop: 5 }}>
                            <label>
                              Product Get Buy
                              <span className="red">*</span>
                            </label>

                            <Select
                              options={[
                                ...displayProductInternalArr.map((el) => {
                                  // return { ...el, label: `${el?.productName} | ${el?.variantName}`, value: `${el?.productName} | ${el?.variantName}` };
                                  return {
                                    ...el,
                                    label: `${el?.name} | ${el?.sku}`,
                                    value: `${el?.name} | ${el?.sku}`,
                                  };
                                }),
                              ]}
                              isMulti
                              placeholder="Select from Products "
                              value={el.productToGetArr}
                              onChange={(e) => {
                                handleProductSelect(e, i, "GET");
                              }}
                            />
                          </div>
                          {/* )} */}
                        </div>
                      );
                    })}
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Add More"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={() => handleOfferArrAdd()}
                    />
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Delete"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={() => handleOfferArrDelete()}
                    />
                  </div>
                </div>

                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-1 ">Other Info</h5>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Scheme Type <span className="red">*</span>
                      </label>
                      <Select
                        options={schemeTypeArr}
                        placeholder="Select Scheme Type "
                        value={selectedSchemeType}
                        onChange={(e) => {
                          setSelectedSchemeType(e);
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>Categories</label>
                      {categoryArr && categoryArr.length > 0 && (
                        <Select
                          options={[
                            ...categoryArr.map((el) => ({
                              ...el,
                              label: el.name,
                              value: el._id,
                            })),
                          ]}
                          placeholder="Select Category "
                          isMulti
                          value={selectedCategoryArr}
                          onChange={(e) => {
                            handleCategorySelection(e);
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Buy Amount <span className="red">*</span>
                      </label>
                      <input
                        value={buyAmount}
                        onChange={(event) => setBuyAmount(event.target.value)}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Gst Amount <span className="red">*</span>
                      </label>
                      <input
                        value={gstAmount}
                        onChange={(event) => setGstAmount(event.target.value)}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Minimum Amount <span className="red">*</span>
                      </label>
                      <input
                        value={minimumAmount}
                        onChange={(event) =>
                          setMinimumAmount(event.target.value)
                        }
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Maximum Amount <span className="red">*</span>
                      </label>
                      <input
                        value={maximumAmount}
                        onChange={(event) =>
                          setMaximumAmount(event.target.value)
                        }
                        type="number"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid From <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid Till <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validTill}
                        onChange={(e) => setValidTill(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </div>

                <hr />
                {/* <div className="row">
                  <div className="col-9">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Variant</th>
                          <th scope="col">Selling Price</th>
                          <th scope="col">Discount Value(%)</th>
                          <th scope="col">Current Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el.productName}</th>
                                <th scope="row">{el.variantName}</th>

                                <td>
                                  <input disabled type="number" className="form-control" value={el?.sellingPrice} />
                                </td>

                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      !(e.target.value > 100) && handleValueChange(index, e.target.value, "discountValue");
                                    }}
                                    value={el?.discountValue}
                                  />
                                </td>
                                <td>
                                  <input type="number" className="form-control" onChange={(e) => handleValueChange(index, e.target.value, "quantity")} disabled value={el?.quantity} />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
