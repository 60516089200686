import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
// import { FLASH_SALESDelete, FLASH_SALESGet } from "../../redux/actions/FlashSales/FlashSales.actions";
// import { getProductStockBySellerId } from "../../redux/actions/ProductStock/ProductStock.actions";
// import { deleteLogoById, getMediaManagerImages } from "../../services/MediaManager.service";
import { add, getData, deleteById } from "../../services/MediaManager.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { addRupeeSymbol } from "../Utility/utils";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { generateFilePath } from "../Utility/utils";

export default function ViewMedia() {
  // ==============================================================================================================

  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  // const stockArr = useSelector((state) => state.FlashSale.sales);
  const stockArr = useSelector((state) => state.FlashSale);
  const [mediaArr, setOrderArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [tableIsVisible, setTableIsVisible] = useState(true);


  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (page) => {

    setPage(page);
  };


  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(TEAMGET(queryOf));
      // console.log(queryOf,"queryOf23423")
      let { data: res } = await getData(queryOf);
      // console.log(res, "resmedia manager");
      setPage(res?.page);
      setPerPage(res?.perPage);
      setTotalCount(res?.totalCount);
      setTotalPages(res?.totalPages);
      if (res.data) {
        setOrderArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(page,"queryOf23423")
    handleGet();
  }, [page, perPage, query]);

  const handleDelete = async (id) => {
    try {
      let confirm = window.confirm("Do you really want to delete this item?");
      if (confirm) {
        let { data: res } = await deleteById(id);
        // console.log(res,"rsdelerte")
        if (res.success) {
          toastSuccess(res?.message);
          handleGet();
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  // useEffect(() => {
  //   if (stockArr&&stockArr.length>=0) {
  //     setOrderArr(stockArr);
  //     setMainArr(stockArr);
  //   }
  // }, [stockArr]);

  const media_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Image",
      // selector: (row) => <img src={row?.link} />,
      cell: (row) => (
        <img
          height="84px"
          width="56px"
          alt={row?.link}
          src={generateFilePath(row?.link)}
        />
      ),
    },
    {
      name: "Created At",
      selector: (row) => new Date(row?.createdAt).toDateString(),
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row._id}
            remove
            deletePath="/ViewMedia"
            onDeleteClick={() => handleDelete(row._id)}
            isRedirected={true}
            // onEditClick={() => {
            //   dispatch(SetPRODUCTObj(row))
            //   // handleEdit(row)
            // }}
            // edit
            // editPath="/Product/Update-Product"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
        </>
      ),
    },
  ];

  const handleDeleteById = (id) => {
    // dispatch(FLASH_SALESDelete(id));
  };

  // ==============================================================================================================

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">View Media</h5>
                <div className="d-flex gap-3 align-items-center">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-download"
                    btnName="Upload"
                    path="/AddMedia"
                    small
                    roundedPill
                    downloadAble
                  />
                  {/* <CustomButton isLink ClickEvent={() => setTableIsVisible(true)} 
                  iconName="fa-solid fa-table"
                   btnName="Table"
                    path="/ViewMedia" small roundedPill downloadAble /> */}
                  {/* <CustomButton isLink ClickEvent={() => setTableIsVisible(false)} iconName="fa-solid fa-grip" btnName="Grid" 
                  path="/ViewMedia" small roundedPill downloadAble /> */}
                  {/* <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  /> */}
                </div>
              </div>
              <DashboardTable>
                {tableIsVisible ? (
                  <DataTable
                    columns={media_columns}
                    data={mediaArr && mediaArr.length > 0 ? mediaArr : []}
                    pagination
                    paginationServer
                    paginationTotalRows={totalCount}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                 
                  />
          

                ) : (
                  <div className="container-fluid">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "space-evenly",
                      }}
                    >
                      {mediaArr &&
                        mediaArr.length > 0 &&
                        mediaArr.map((el, index) => {
                          return (
                            <div
                              className="m-1 grid-layout-container mb-4"
                              key={index}
                            >
                              <button
                                onClick={() => {
                                  handleDelete(el._id);
                                }}
                              >
                                X
                              </button>
                              <img
                                style={{ height: 150, width: 150 }}
                                src={el.link}
                                alt=""
                              />

                              <p style={{ textAlign: "center", fontSize: 10 }}>
                                {el?.name}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
