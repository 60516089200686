import React, { useMemo, useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import AuthorizedRoutes from "./AuthorizedRoutes";
import { useSelector } from "react-redux";
import UnauthorizedRoutes from "./UnauthorizedRoutes";
import { axiosApiInstance } from "../App";
import { logoutUser } from "../redux/actions/auth/auth.actions";
import { refreshToken } from "../services/users.service";
import axios from "axios";
import { toastError } from "../utils/toastUtils";
import jwtDecode from "jwt-decode";



export default function RootRouter() {
  const authObj = useSelector((state) => state.auth);
  let token = useSelector((state) => state.auth.token)




  useEffect(() => {
    // console.log(temptoken, "temptoken")
    axiosApiInstance.interceptors.request.use(
      async (config) => {
        let temptoken = localStorage.getItem('token')
        // console.log(token)
        // let token = localStorage.getItem('token')
        if (temptoken) {
          config.headers['authorization'] = 'Bearer ' + temptoken;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
      },
      error => {
        // console.log(error)
        Promise.reject(error)
      });
    axiosApiInstance.interceptors.response.use(
      (res) => {
        // Add configurations here
        return res;
      },
      async (err) => {
        console.log("INterceptor error")
        await logoutUser()
        return Promise.reject(err);
      }
    );

  }, [])







  return <Router>{authObj?.isAuthorized ? <AuthorizedRoutes /> : <UnauthorizedRoutes />}</Router>;
}
