import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
// import * as CONTACTINFO from "../../actions/ContactInfo/ContactInfo.actions";
import * as FAQCATEGORY from "../../actions/FaqCategory/FaqCategory.actions";

const initialState = {
  faqCategorys: null,
  faqCategoryObj: null,
  loading: false,
  error: null,
  faqcategoriesPaginationObj: null,
  FaqCategoryStatusUpdate:null
};

export const faqCategoryReducer = (state = initialState, action) => {
  // console.log(action, "reduscer");
  switch (action.type) {
    case FAQCATEGORY.FAQCATEGORY_ADD:
      return {
        ...state,
        loading: true,
      };
    case FAQCATEGORY.FAQCATEGORY_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case FAQCATEGORY.FAQCATEGORY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FAQCATEGORY.GET_ALL_FAQCATEGORYS:
      return {
        ...state,
        loading: true,
      };
    case FAQCATEGORY.GET_ALL_FAQCATEGORYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        faqCategorys: action.payload.data,
        faqcategoriesPaginationObj: action.payload.faqcategoryPaginationObj,
      };
    case FAQCATEGORY.GET_ALL_FAQCATEGORYS_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FAQCATEGORY.DELETE_FAQCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FAQCATEGORY.DELETE_FAQCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FAQCATEGORY.DELETE_FAQCATEGORY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FAQCATEGORY.SET_FAQCATEGORY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case FAQCATEGORY.SET_FAQCATEGORY_OBJ_SUCCESS:
      // toastSuccess(action.payload.message);
      return {
        ...state,
        faqCategoryObj: action.payload.data,
        loading: false,
        error: null,
        FaqCategoryStatusUpdate:null
      };
    case FAQCATEGORY.SET_FAQCATEGORY_OBJ_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FAQCATEGORY.UPDATE_FAQCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FAQCATEGORY.UPDATE_FAQCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FAQCATEGORY.UPDATE_FAQCATEGORY_BY_ID_FAIL:
      // console.log(action,"action.payload. get")
      toastError(action?.payload?.response?.data?.message);
      // toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FAQCATEGORY.UPDATE_FAQCATEGORY_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: action.payload,
        FaqCategoryStatusUpdate: action.payload,
        faqcategoriesPaginationObj:null
      };

    default:
      return state;
  }
};
