import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  CATEGORYAdd,
  CAT,
  CATEGORYGet,
} from "../../../redux/actions/Category/Category.actions";

import {
  SETSTATEOBJ,
  STATEGET,
} from "../../../redux/actions/State/States.actions";

import { CITYGET } from "../../../redux/actions/City/City.actions";

import {
  AREAADD,
  AREAUPDATE,
  SETAREAOBJ,
} from "../../../redux/actions/Area/Area.actions";
import { toastError } from "../../Utility/ToastUtils";

function AddArea({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [stateArr, setStateArr] = useState([]);
  const [stateId, setStateId] = useState("");
  const [stateObject, setStateObject] = useState({});
  const [cityArr, setCityArr] = useState([]);
  const [cityId, setCityId] = useState("");
  const [query, setQuery] = useState("");
  const [citiesObj, setCitiesObj] = useState({});
  const [areaObject, setAreaObject] = useState({});

 
  const statesArr = useSelector((state) => state.states.states);
  const citiesArr = useSelector((state) => state.city.cities);
  const areaObj = useSelector((state) => state.area.areasObj);

  const clearFunc = () => {
    setName("");
    setStatus(generalModelStatuses.APPROVED);
    dispatch(SETAREAOBJ(null));
    setStateObject({});
    setAreaObject({});
    setCityArr([]);
    setCitiesObj({});
    setStateArr([]);

    dispatch(STATEGET(null));
  };

  const handleAddCategory = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      } else if (stateId == "") {
        // console.log(stateId, "stateId234");
        toastError("State is mandatory");
        return;
      } else if (cityId == "") {
        toastError("City is mandatory");
        return;
      }

      let obj = {
        name,
        status,
        stateId,
        cityId,
      };
      let response = {};
      // console.log(obj, "============ obj---");
      if (areaObject && areaObject?._id) {
        response = await dispatch(AREAUPDATE(areaObject?._id, obj,query));
      } else {
        response = await dispatch(AREAADD(obj));
      }
      // console.log(response,"response get area")
      if (response && response.message) {
        dispatch(SETAREAOBJ(null));
        clearFunc();
      }
    } catch (err) {
      toastError(err);
    }
  }; 





  useEffect(() => {
    if (areaObj && areaObj._id) {
      // console.log(areaObj, "areaObj2314");
      setQuery(areaObj?.query)
      setAreaObject(areaObj);
      setName(areaObj?.name);
      setStatus(areaObj?.status);
      setStateId(areaObj?.stateId);
      // setStateObject
      setStateObject({
        value: areaObj?.stateObj?._id,
        label: areaObj?.stateObj?.name,
      });
      setCityId(areaObj?.cityId);
      setCitiesObj({
        value: areaObj?.cityObj?._id,
        label: areaObj?.cityObj?.name,
      });
      // value: el._id, label: el.name
    }
    return () => {
      dispatch(SETAREAOBJ(null));
    };


    // console.log(stateArr, "stateArr12");
    // if (stateArr && stateArr.length > 0) {
    //   // console.log(
    //   //   ...stateArr.filter((el) => el._id == areaObj?.stateId),
    //   //   "stateArr.filter(el => el._id == cityObj.stateId)"
    //   // );
    //   setStateObject(
    //     ...stateArr
    //       .filter((el) => el._id == areaObj?.stateId)
    //       .map((el) => {
    //         return { ...el, label: el.name, value: el._id };
    //       })
    //   );
    // }
    // console.log(cityArr, "cityArr234");
    // if (cityArr && cityArr.length > 0) {
    //   // console.log(
    //   //   ...cityArr.filter((el) => el._id == areaObj?.cityId),
    //   //   "stateArr.filter(el => el._id == cityObj.stateId)"
    //   // );
    //   setCitiesObj(
    //     ...cityArr
    //       .filter((el) => el._id == areaObj?.cityId)
    //       .map((el) => {
    //         return { ...el, label: el.name, value: el._id };
    //       })
    //   );
    // }
  }, [areaObj]);

  useEffect(() => {
    dispatch(STATEGET());
    dispatch(CITYGET());
  }, []);

  useEffect(() => {
    if (statesArr) {
      setStateArr(statesArr);
    }
    if (citiesArr) {
      setCityArr(citiesArr);
    }
  }, [statesArr, citiesArr]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
        <h5 className="blue-1 m-0">
           {areaObject&&areaObject?._id? "Update" : "Add"} Area
          </h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-12 col-md-12">
          <label>
            State <span className="red">*</span>
          </label>
          <Select
            options={stateArr.map((el) => {
              return { ...el, value: el._id, label: el.name };
            })}
            placeholder="Select from options"
            // defaultInputValue={stateId}
            value={stateObject}
            onChange={(e) => {
              // console.log(e, "asd")
              setStateId(e.value);
              setStateObject(e);
              dispatch(CITYGET(`stateId=${e.value}`));
            }}
          />
        </div>
        <div className="col-12 col-md-12">
          <label>
            City <span className="red">*</span>
          </label>
          <Select
            options={cityArr.map((el) => {
              return { ...el, value: el._id, label: el.name };
            })}
            placeholder="Select from options"
            // defaultInputValue={cityId}
            value={citiesObj}
            onChange={(e) => {
              // console.log(e, "asd")
              setCityId(e.value);
              setCitiesObj(e);
            }}
          />
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddArea;
