import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  SETTEAMOBJ,
  TEAMADD,
  TEAMUPDATE,
} from "../../redux/actions/Team/Team.actions";
import { generateFilePath } from "../Utility/utils";
import { toastError } from "../Utility/ToastUtils";



function AddTeam({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [post, setpost] = useState("");
  const [teamId, setTeamId] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [imageStr, setImageStr] = useState("");
  const [teamObject, setTeamObject] = useState({});
  const [query, setQuery] = useState("");

  const teamObj = useSelector((state) => state.team.teamObj);

  const clearFunc = () => {
    setName("");
    setDescription("");
    setpost("");
    setTeamId("");
    setImageStr("");
    setTeamObject({});
    setStatus(generalModelStatuses.APPROVED);
  };

  const handleAddCategory = () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      if (description == "") {
        toastError("Description is mandatory");
        return;
      }
      let obj = {
        name,
        description,
        status,
        image: imageStr,
      };
      // console.log(obj, "category obj", teamId);
      if (teamObject && teamObject?._id) {
        dispatch(TEAMUPDATE(teamId, obj,query));
        dispatch(SETTEAMOBJ(null));
      } else {
        dispatch(TEAMADD(obj));
      }
      clearFunc();
    } catch (err) {
      toastError(err);
    }
  };

  const handleFileSet = (value) => {
    // console.log(value);
    setImageStr(value);
  };

  useEffect(() => {
    if (teamObj&&teamObj._id) {
      setTeamObject(teamObj);
      setQuery(teamObj?.query)
      setName(teamObj?.name);
      setpost(teamObj?.post);
      setDescription(teamObj?.description);
      setStatus(teamObj?.status);
      setTeamId(teamObj?._id);
      setImageStr(teamObj.image);
    }

    return () => {
      dispatch(SETTEAMOBJ(null));
    };
  }, [teamObj]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5 className="blue-1 m-0">
            {teamObject && teamObject._id ? "Edit " : "Add "} Team
          </h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Description <span className="red">*</span>
          </label>
          <textarea
            className="form-control"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          >
            {" "}
          </textarea>
        </div>
        {/* <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
                    <label className="blue-1 fs-12">
                        Post <span className="red">*</span>
                    </label>
                    <input value={post} onChange={(event) => setpost(event.target.value)} type="text" className="form-control" />
                </div> */}
        <div className="col-12">
          <label>image (150x150)PX</label>
          <div>
            {imageStr.includes("base64") ? (
              <img style={{ height: 100 }} src={imageStr} alt="" />
            ) : (
              <img
                style={{ height: 100 }}
                src={generateFilePath(imageStr)}
                alt=""
              />
            )}
          </div>
          <FileUpload onFileChange={handleFileSet} />
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddTeam;
