import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import {
  SETSTATEOBJ,
  STATEDELETE,
  STATEGET
} from "../../../redux/actions/State/States.actions";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import AddCity from "./AddCity";
import {
  CITYDELETE,
  CITYGET,
  SETCITYOBJ,setCityStatus
} from "../../../redux/actions/City/City.actions";

import { toastError } from "../../../utils/toastUtils";
import { getCity } from "../../../services/City.service";

function ViewCity() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const cityArr = useSelector((state) => state.city.cities);
  const cityObj = useSelector((state) => state.city.citiesObj);
  const [ModalData, setModalData] = useState({});
  const [displayCityArr, setDisplayCityArr] = useState([]);
  const [query, setQuery] = useState("");
  
  const [cityMainArr, setCityMainArr] = useState([]);
  
  const cityPaginationObject = useSelector((state) => state.city.paginationObj);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  



  
  const handleCategoryEdit = (row) => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    row.query=queryOf
    dispatch(SETCITYOBJ(row));
  };





  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(CITYGET(queryOf));
      let { data: get } = await getCity(queryOf);
      if (get) {
        setCityMainArr(get?.data);
        setDisplayCityArr(get?.data);
        setPage(get?.page);
        setPerPage(get?.perPage);
        setTotalCount(get?.totalCount);
        setTotalPages(get?.totalPages);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(CITYDELETE(row._id,query));
    }
  };

  useEffect(() => {
 
    if (cityPaginationObject) {
      setPage(cityPaginationObject?.page);
      setPerPage(cityPaginationObject?.perPage);
      setTotalCount(cityPaginationObject?.totalCount);
      setTotalPages(cityPaginationObject?.totalPages);
    }
    return()=>{
      dispatch(setCityStatus(null))
    }
  }, [cityPaginationObject]);

  useEffect(() => {
    if (cityArr?.length >= 0) {
      setCityMainArr(cityArr);
      setDisplayCityArr(cityArr);
    }
   
  }, [cityArr]);


  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "State",
      selector: (row) => row?.stateObj?.name,
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <CustomButton
          greenBtn
          noIcon
          btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`}
          path={"/Location/View-City"}
        />
      ),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Location/View-City"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Location/View-City"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0"> */}
                  {/* {cityObj && cityObj.name ? "Edit " : "Add "} */}
                   {/* City */}
                {/* </h5> */}
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddCity />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">City List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    cityMainArr && cityMainArr.length > 0 ? cityMainArr : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewCity;
