import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill"; // ES6
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { getAllNestedCategories } from "../../../redux/actions/Category/Category.actions";
import { BrandGet } from "../../../redux/actions/Brand/brand.actions";
import {
  PRODUCTAdd,
  PRODUCTUpdate,setProductStatus
} from "../../../redux/actions/Product/Product.actions";

import { ATTRIBUTEGet } from "../../../redux/actions/Attribute/Attribute.actions";
import RelatedProduct from "./RelatedProduct";
import QuillEditor from "../../../utils/QuillEditor";
import { generateFilePath } from "../../Utility/utils";
import moment from "moment";

import { HSNTAXGET } from "../../../redux/actions/HsnTax/HsnTax.actions";
import { toastError } from "../../Utility/ToastUtils";
import { useNavigate } from "react-router-dom";

function UpdateGeneralProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [internalCode, setInternalCode] = useState("");
  const [productCode, setProductCode] = useState("");
  const [brandObj, setBrandObj] = useState({});

  //////product details
  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [sku, setSku] = useState("");
  const [productType, setProductType] = useState("");
  const [sizeChart, setsizeChart] = useState();

  const [despcription, setDespcription] = useState("");
  const [material, setMaterial] = useState("");
  const [Specifications, setSpecifications] = useState("");
  const [availableInventory, setAvailableInventory] = useState(0);
  const [gst, setGst] = useState("");
  const [hsn, setHsn] = useState("");
  const [hsnObj, setHsnObj] = useState("");
  const [mrp, setMrp] = useState(0);
  const [stock, setStock] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [ships_in_days, setShips_in_days] = useState(0);
  const [returnPolicy, setReturnPolicy] = useState("");
  const [dom, setDom] = useState("");
  const [shelfLife, setShelfLife] = useState(0);
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  //////size of product
  const [product_dimension_width, setProduct_Dimension_width] = useState(0);
  const [product_dimension_height, setProduct_Dimension_height] = useState(0);
  const [product_dimension_length, setProduct_Dimension_length] = useState(0);
  const [product_dimension_weight, setProduct_Dimension_weight] = useState(0);
  const [dimensionWeightType, dimensionSetweightType] = useState("");
  const [dimensionHeightType, dimensionSetHeightType] = useState("");

  const [artisan, setArtisan] = useState("");
  const [craft, setCraft] = useState("");
  const [bestSeller, setBestSeller] = useState(false);
  ///////size of packaging
  const [packaging_Length, setPackaging_Length] = useState(0);
  const [packaging_Width, setPackaging_Width] = useState(0);
  const [packaging_Height, setPackaging_Height] = useState(0);
  const [productDisplay, setproductDisplay] = useState("");

  ///////dead weight
  const [dead_weight, setDead_weight] = useState(0);
  ///////tags
  const [tags, setTags] = useState("");
  //////brand
  const [selectedBrandId, setSelectedBrandId] = useState("");
  //////category
  const [mainCategoryArr, setMainCategoryArr] = useState([]);
  const [mainAttributesArr, setMainAttributesArr] = useState([]);
  ///////////////////////////////////////////////////////////////
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");

  ///////////////////////////////////////////////////////////////
  const [videoLink, setVideoLink] = useState("");
  const [productImageStr, setProductImageStr] = useState("");
  const [specificationFile, setSpecificationFile] = useState("");
  const [displayAttributesArr, setDisplayAttributesArr] = useState([]);
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImage, setMetaImage] = useState("");
  const [status, setStatus] = useState("Publish");

  const productPaginationObject = useSelector(
    (state) => state.product.productStatusChangeSuccessFully
  );


  const [selectedCategoryArr, setSelectedCategoryArr] = useState([]);
  const [tabList, settabList] = useState([
    {
      tabName: "RELATED PRODUCT",
      active: false,
      render: <RelatedProduct name="Related Product" />,
    },
  ]);
  const [hsnTaxArr, setHsnTaxArr] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  ///////////////////////////////////////////////////////////////
  const authUser = useSelector((state) => state.auth.user);
  const brands = useSelector((state) => state.brand.brands);
  const categoryArr = useSelector((state) => state.category.categories);
  const productObj = useSelector((state) => state.product.productObj);
  const attributes = useSelector((state) => state.attribute.attributes);
  const hsnTaxes = useSelector((state) => state.hsnTax.hsnTaxs);








  const handleHsnTaxSelection = (obj) => {
    setHsn(obj._id);
    setHsnObj(obj);
    // setSelectedHscTaxId(obj._id);
  };

  const handleFilterChecked = (arr) => {
    if (arr.length > 0 && arr) {
      return arr.map((el) => {
        if (el.subCategoryArr && el.subCategoryArr.length > 0 && el.checked) {
          let tempArr = selectedCategoryArr;
          if (tempArr.some((el) => el != el._id)) {
            tempArr.push(el._id);
          }
          setSelectedCategoryArr([...tempArr]);
          return {
            ...el,
            categoryId: el._id,
            subCategoryArr: handleFilterChecked(el.subCategoryArr),
          };
        } else {
          if (el.checked) {
            let tempArr = selectedCategoryArr;
            if (tempArr.some((el) => el != el._id)) {
              tempArr.push(el._id);
            }
            setSelectedCategoryArr([...tempArr]);
            return { ...el, categoryId: el._id };
          }
        }
      });
    } else {
      return arr;
    }
  };

  const clearFunction = () => {
    try {
      setInternalCode("");
      setProductCode("");
      setName("");
      setSku("");
      setDespcription("");
      setMaterial("");
      setSpecifications("");
      setAvailableInventory(0);
      setProductType("");
      setGst("");
      setHsn("");
      setMrp(0);
      setStock(0);
      setSellingPrice(0);
      setShips_in_days(0);
      setReturnPolicy("");
      setDom("");
      setsizeChart();
      setShelfLife(0);
      setImageArr([{ image: "", imageAlt: "" }]);
      setProduct_Dimension_width(0);
      setProduct_Dimension_height(0);
      setProduct_Dimension_length(0);
      setProduct_Dimension_weight(0);
      setPackaging_Length(0);
      setPackaging_Width(0);
      setPackaging_Height(0);
      setDead_weight(0);
      setTags("");
      setSelectedBrandId("");
      setMainCategoryArr([]);
      setMainAttributesArr([]);
      setHsnTaxArr([]);

      setModalBox(false);
      setModalType("");
      setModalName("");
      setVideoLink("");
      setProductImageStr("");
      setSpecificationFile("");

      setDisplayAttributesArr([]);
      setMetaTitle("");
      setMetaDescription("");
      setMetaImage("");
      setStatus("Publish");
      setSelectedCategoryArr([]);
      setArtisan("");
      setCraft("");
      setBestSeller(false);
      setproductDisplay("");

      setDiscountType("");
      setDiscountValue("");
      dispatch(getAllNestedCategories());
      dispatch(BrandGet());
      dispatch(ATTRIBUTEGet());
      dispatch(HSNTAXGET());
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
       console.log(productPaginationObject,"===productPaginationObject=")
    if (productPaginationObject && productPaginationObject.message) {
      clearFunction();
      navigate("/Product/Product-List");
    }
      return () => {
      dispatch(setProductStatus(null));
    };
  }, [productPaginationObject]);



  const handleSubmit = async () => {
    try {
      let cat_arr = returnSelectedCategories(mainCategoryArr);
      let tempCategoryArr = handleFilterChecked(mainCategoryArr);
      // let tempCategoryArr = handleFilterChecked(mainCategoryArr)
      let obj = {
        internalCode,
        categoryArr: cat_arr.map((el) => {
          return { categoryId: el._id };
        }),
        productCode,
        name,
        slug,
        createdBy: authUser?._id,
        sku,
        productType,
        discountType,
        discountValue,
        description: despcription,
        material,
        specification: Specifications,
        gst,
        hsn,
        ships_in_days,
        returnPolicy,
        dom,
        shelfLife,
        imageArr,
        product_dimension_width,
        product_dimension_height,
        product_dimension_length,
        product_dimension_weight,
        packaging_Length,
        packaging_Width,
        packaging_Height,
        dimensionWeightType,
        dimensionHeightType,
        dead_weight,
        tags,
        videoLink,
        artisan,
        craft,
        productImageStr,
        specificationFile,
        status,
        metaTitle,
        metaDescription,
        metaImage,
        // sellingPrice: sellingPrice,
        brandId: selectedBrandId,
        productDisplay,
        sizeChart,
      };
      if (displayAttributesArr && displayAttributesArr.length > 0) {
        obj.attributesArr = displayAttributesArr;
        obj.sellingPrice = 0;
        obj.mrp = 0;
        obj.stock = 0;
      }
      // console.log(obj, "final update Obj-=-");
      dispatch(PRODUCTUpdate(obj, productObj._id));
    
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    dispatch(getAllNestedCategories());
    dispatch(BrandGet(`status=All`));
    dispatch(ATTRIBUTEGet());
    dispatch(HSNTAXGET(`status=All`));
  }, []);

  useEffect(() => {
    if (hsnTaxes && hsnTaxes.length > 0) {
      setHsnTaxArr(hsnTaxes);

      let object = {};
      if (productObj && productObj?.name) {
        hsnTaxes?.find((el, index) => {
          if (el._id == productObj.hsn) {
            object = { label: el?.hsn, value: el?._id };
          }
        });
      }
      setHsn(object?.value);
      setHsnObj(object);
    }
  }, [hsnTaxes]);

  useEffect(() => {
    if (categoryArr && categoryArr.length > 0) {
      setMainCategoryArr([...categoryArr]);
    }
  }, [categoryArr]);

  useEffect(() => {
    // console.log(attributes,"attributes get")
    if (attributes && attributes.length > 0) {
      setMainAttributesArr([...attributes]);
    }
  }, [attributes]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    // console.log(new_selected_arr,"categoryArrcategoryArrcategoryArr")
    let subCategories = arr.reduce(
      (acc, el) => [...acc, ...el.subCategoryArr.filter((el) => el.checked)],
      []
    );
    if (subCategories?.length) {
      return [...new_selected_arr, ...returnSelectedCategories(subCategories)];
    } else {
      return [...new_selected_arr];
    }
  };

  const handleBrandSelection = (obj) => {
    setSelectedBrandId(obj?._id);
    setBrandObj(obj);
  };

  const handleFileSet = (value, index, indexMain) => {
    // console.log(index, "===oidenxnn");
    let temparray = [...displayAttributesArr];
    // console.log(temparray,"===displayAttributesArr---=")
    // console.log(el ,"elele",index,"===el index---=")
    let findObj = temparray.find((el, inx) => inx == indexMain);
    findObj.imageArr[index].image = value;
    temparray[indexMain] = findObj;

    setDisplayAttributesArr(temparray);
    //=============================================
    // let tempArr = imageArr;
    // tempArr[index].image = value;
    // setImageArr([...tempArr]);
  };

  const handleproductImageAltEntry = (value, index, indexMain) => {
    // console.log(value, index);
    let temparray = [...displayAttributesArr];
    let findObj = temparray.find((el, inx) => inx == indexMain);
    findObj.imageArr[index].imageAlt = value;
    temparray[indexMain] = findObj;
    setDisplayAttributesArr(temparray);
  };

  // const handleproductImageAltEntry = (value, index) => {
  //   let tempArr = imageArr;
  //   tempArr[index].imageAlt = value;
  //   setImageArr([...tempArr]);
  // };

  const handlePdfFileSet = (value) => {
    setSpecificationFile(value);
  };
  const handleAttributeVariantChange = (index) => {
    let tempArr = [...mainAttributesArr];
    tempArr[index].checked = !tempArr[index].checked;
    setMainAttributesArr(tempArr);
  };

  // const handleAttributeVariantChange = (index) => {
  //   let tempArr = [...mainAttributesArr];
  //   tempArr[index].checked = !tempArr[index].checked;

  //   setMainAttributesArr(tempArr);
  // };
  const handleInnerAttributeVariantChange = (index, innerIndex) => {
    console.log(mainAttributesArr, "mainAttributesArr==");
    // console.log(innerIndex,"==innerIndex==",index,"===index===")
    let tempArr = [...mainAttributesArr];
    tempArr[index].attributeValueArr[innerIndex].checked =
      !tempArr[index].attributeValueArr[innerIndex].checked;
    // console.log(tempArr,"==temparr====")

    setMainAttributesArr([...tempArr]);
    generateCombinations(tempArr);
  };

  // const handleInnerAttributeVariantChange = (index, innerIndex) => {
  //   let tempArr = [...mainAttributesArr];
  //   tempArr[index].attributeValueArr[innerIndex].checked =
  //     !tempArr[index].attributeValueArr[innerIndex].checked;

  //   setMainAttributesArr([...tempArr]);
  //   generateCombinations(tempArr);
  // };

  const combine = (arrays) => {
    const result = arrays.reduce(
      (acc, arr) => {
        const temp = [];
        for (let i = 0; i < acc.length; i++) {
          for (let j = 0; j < arr.length; j++) {
            temp.push([...acc[i], arr[j]]);
          }
        }
        return temp;
      },
      [[]]
    );
    // console.log(result,"==========result============")
    return result.map((arr) => Object.assign({}, ...arr));
  };

  // const generateCombinations = (tempattributeArr) => {
  //   let finalArr = [];
  //   // console.log(tempattributeArr, "===tempattributeArr===")
  //   tempattributeArr.forEach((el) => {
  //     if (el.checked) {
  //       let tempArr = el.attributeValueArr.filter((elx) => elx.checked);
  //       if (tempArr.length) {
  //         finalArr.push(
  //           tempArr.map((el) => ({
  //             name: el?.name,
  //             attributeValueArr: [
  //               {
  //                 attributeId: el._id,
  //               },
  //             ],
  //             price: 0,
  //           }))
  //         );
  //       }
  //     }
  //   });
  //   // console.log(finalArr, "=====finalArr");
  //   if (finalArr.length) {
  //     const combinations = combine(finalArr);

  //     // console.log(combinations, "=====combinations===");
  //     setDisplayAttributesArr(prevState => [
  //       ...prevState,
  //       ...combinations.map((el) => ({
  //         ...el,
  //         price: 0,
  //         discount: 0,
  //         currentStock: 0,
  //         stockNo: 0,
  //       })),
  //     ]);
  //   }
  // };

  ////////////p n c generator

  const generateCombinations = (tempattributeArr) => {
    // console.log(tempattributeArr, "tempattributeArr");
    const combine = ([head, ...[headTail, ...tailTail]]) => {
      // https://stackoverflow.com/a/57015870
      // console.log(head,"=--==head==")

      if (!headTail) {
        return head?.map((el) => ({
          name: el.name,
          attributeValueArr: [...el.attributeValueArr],
          price: el?.price ? el?.price : 0,
          currentStock: el?.currentStock ? el?.currentStock : 0,
          imageArr: el?.imageArr ? el?.imageArr : [{ image: "" }]
        }));
      }

      const combined = headTail?.reduce((acc, x) => {
        return acc.concat(
          head.map((h) => ({
            name: `${h.name}-${x.name}`,
            attributeValueArr: [...h.attributeValueArr, ...x.attributeValueArr],
            price: h?.price ?? 0,
            currentStock: h?.currentStock ?? 0,
            imageArr: h?.imageArr ? h?.imageArr : [{ image: "" }]
          }))
        );
      }, []);
      // console.log(combined,"===combined===")
      return combine([combined, ...tailTail]);
    };

    let finalArr = [];

    tempattributeArr.forEach((el) => {
      if (el.checked) {
        let tempArr = el.attributeValueArr.filter((elx) => elx.checked);

        // console.log(tempArr, "tempAr--==-0r");
        if (tempArr.length) {
          finalArr.push(
            tempArr.map((el) => {
              let findExitingPRice = productObj?.attributesArr?.find(
                (e) => e?.attributeId == el?.attributeId
              )
              // console.log(findExitingPRice,"==findExitingPRice==23");
              return {
                name: el?.name,
                attributeValueArr: [
                  {
                    attributeId: el._id,
                  },
                ],
                price: findExitingPRice?.price ?? 0,
                currentStock: findExitingPRice?.currentStock ?? 0,
                imageArr: findExitingPRice?.imageArr ? findExitingPRice?.imageArr : [{ image: "" }]
              };
            })
          );

        }
      }
    });
    // console.log(finalArr, "=====finalArr");
    if (finalArr.length) {
      finalArr = combine(finalArr);
    } else {
      setDisplayAttributesArr([]);
    }

    // console.log(finalArr, 'FINAL ARR')
    if (finalArr.length) {
      setDisplayAttributesArr([
        ...finalArr.map((el) => ({
          ...el,
          price: el?.price ? el?.price : 0,
          discount: 0,
          currentStock: el?.currentStock ? el?.currentStock : 0,
          stockNo: 0,
          imageArr: el?.imageArr ? el?.imageArr : [{ image: "" }]
        })),
      ]);
    }
  };

  const handleDisplayAttributesArrChange = (e, index, key) => {
    let tempArr = [...displayAttributesArr];
    tempArr[index][key] = e;
    setDisplayAttributesArr([...tempArr]);
  };

  // const handleDisplayAttributesArrChange = (e, index, key) => {
  //   let tempArr = [...displayAttributesArr];
  //   tempArr[index][key] = e;
  //   setDisplayAttributesArr([...tempArr]);
  // };

  useEffect(() => {
    if (productObj && productObj?.name) {
      // console.log(productObj,"productObj get ")
      // console.log(hsnTaxArr,"hsnTaxArr get ===")
      // console.log(hsnTaxes,"hsnTaxeshsnTaxes== get ")
      let object = {};
      hsnTaxes?.find((el, index) => {
        if (el._id == productObj.hsn) {
          object = { label: el?.hsn, value: el?._id };
        }
      });
      setHsn(object?.value);
      setHsnObj(object);
      setDiscountType(productObj?.discountType);
      setDiscountValue(productObj?.discountValue);
      setName(productObj?.name);
      setInternalCode(productObj?.internalCode);
      setProductCode(productObj?.productCode);
      setSku(productObj?.sku);
      setDespcription(productObj?.description ? productObj?.description : "");
      setMaterial(productObj?.material ? productObj?.material : "");
      setSpecifications(
        productObj?.specification ? productObj?.specification : ""
      );
      setSlug(productObj?.slug);
      setAvailableInventory(productObj?.availableInventory);
      setGst(productObj?.gst);
      // console.log(object," productObj object?.value234")
      setMrp(productObj?.mrp);
      setStock(productObj?.stock);

      // setSellingPrice(productObj?.sellingPrice);
      setShips_in_days(productObj?.ships_in_days);
      setReturnPolicy(productObj?.returnPolicy);
      setDom(productObj?.dom);
      setShelfLife(productObj?.shelfLife);
      setProduct_Dimension_width(productObj?.product_dimension_width);
      setProduct_Dimension_height(productObj?.product_dimension_height);
      setProduct_Dimension_length(productObj?.product_dimension_length);
      setProduct_Dimension_weight(productObj?.product_dimension_weight);
      setPackaging_Length(productObj?.packaging_Length);
      setPackaging_Width(productObj?.packaging_Width);
      setPackaging_Height(productObj?.packaging_Height);
      setDead_weight(productObj?.dead_weight);
      dimensionSetHeightType(productObj?.dimensionWeightType);
      dimensionSetweightType(productObj?.dimensionHeightType);
      setDead_weight(productObj?.dead_weight);
      setTags(productObj?.tags);
      setMetaTitle(productObj?.metaTitle);
      setMetaDescription(productObj?.metaDescription);
      setMetaImage(productObj?.metaImage);
      setImageArr(productObj?.imageArr);
      setVideoLink(productObj?.videoLink);
      setStatus(productObj?.status);
      setArtisan(productObj?.artisan);
      setCraft(productObj?.craft);
      setsizeChart(productObj?.sizeChart);
      setproductDisplay(productObj?.productDisplay);
      setProductType(productObj?.productType);
    }
  }, [productObj]);

  useEffect(() => {
    if (brands && productObj) {
      // console.log(brands,"brandsget ")
      setSelectedBrandId(productObj?.brandId);
      let tempBrandObj = brands.find((el) => el._id == productObj?.brandId);
      if (tempBrandObj) {
        tempBrandObj.label = tempBrandObj?.name;
        tempBrandObj.value = tempBrandObj?._id;
        setBrandObj(tempBrandObj);
      }
    }
  }, [productObj, brands]);

  useEffect(() => {
    if (
      attributes &&
      attributes.length > 0 &&
      productObj &&
      productObj.attributesArr &&
      productObj.attributesArr.length > 0
    ) {
      let temp = handleAttributesArrSelectionOnInit(
        attributes,
        productObj?.attributesArr
      );
      setDisplayAttributesArr(productObj.attributesArr);
    }
  }, [productObj, attributes]);

  const handleAttributesArrSelectionOnInit = (
    existingAttributesArr,
    attributesArrFromDb
  ) => {
    for (const el of existingAttributesArr) {
      if (el.attributeValueArr && el.attributeValueArr.length > 0) {
        for (const ele of el.attributeValueArr) {
          if (
            attributesArrFromDb?.some((elx) =>
              elx?.attributeValueArr?.some((elm) => elm.attributeId == ele._id)
            )
          ) {
            ele.checked = true;
            el.checked = true;
          }
        }
      }
    }

    return existingAttributesArr;
  };

  // const handleAttributesArrSelectionOnInit = (
  //   existingAttributesArr,
  //   attributesArrFromDb
  // ) => {
  //   for (const el of existingAttributesArr) {
  //     if (el.attributeValueArr && el.attributeValueArr.length > 0) {
  //       for (const ele of el.attributeValueArr) {
  //         if (
  //           attributesArrFromDb.some((elx) =>
  //             elx.attributeValueArr.some((elm) => elm.attributeId == ele._id)
  //           )
  //         ) {
  //           ele.checked = ele.checked?!ele.checked:true;
  //           el.checked = el.checked?!el.checked:true;
  //         }
  //       }
  //     }
  //   }
  //   return existingAttributesArr;
  // };

  const handleCategorySelectOnInit = (
    selectedCategoryArrFromDB,
    categoryArr
  ) => {
    let tempArr = categoryArr.map((el) => {
      if (selectedCategoryArrFromDB.some((ele) => ele.categoryId == el._id)) {
        el.checked = el.checked ? !el.checked : true;
      }
      // else{
      //   el.checked =el.checked?!el.checked:
      // }
      if (el.subCategoryArr) {
        handleCategorySelectOnInit(
          selectedCategoryArrFromDB,
          el.subCategoryArr
        );
      }
      return el;
    });
  };

  useEffect(() => {
    if (productObj && categoryArr) {
      handleCategorySelectOnInit(productObj.categoryArr, categoryArr);
    }
  }, [categoryArr, productObj]);

  // const handleDiscountTypeChange = (obj) => {
  //   setSelectedDiscountType(obj.value);
  // };

  const options = [
    { value: "chocolate", label: "CGST" },
    { value: "strawberry", label: "IGST" },
    { value: "vanilla", label: "SGST" },
  ];

  const discount = [
    { value: "amount", label: "Amount" },
    { value: "percentage", label: "Percentage" },
  ];

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  // const handleImageObjAdd = () => {
  //   let tempArr = imageArr;
  //   tempArr.push({ image: "", imageAlt: "" });
  //   setImageArr([...tempArr]);
  // };

  const handleImageObjAdd = (el, index) => {
    let temparray = [...displayAttributesArr];
    // console.log(temparray,"===displayAttributesArr---=")
    // console.log(el ,"elele",index,"===el index---=")
    let findObj = temparray.find((el, inx) => inx == index);
    if (findObj.imageArr) {
      findObj.imageArr.push({ image: "", imageAlt: "" });
    } else {
      findObj.imageArr = [];
      findObj.imageArr.push({ image: "", imageAlt: "" });
    }
    // console.log(findObj,"findObj========")
    temparray[index] = findObj;
    // console.log(temparray, "++temparray");
    setDisplayAttributesArr(temparray);
  };
  const handleImageObjRemove = (el, index) => {
    let temparray = [...displayAttributesArr];
    let findObj = temparray.find((el, inx) => inx == index);
    if (findObj.imageArr) {
      findObj.imageArr.pop();
    }

    temparray[index] = findObj;
    setDisplayAttributesArr(temparray);
  };
  // const handleImageObjRemove = () => {
  //   let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
  //   setImageArr([...tempArr]);
  // };

  const handleNestedCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          el.subAttributesArr = handleRenderNestedCategory(
            el.subCategoryArr,
            id,
            value
          );
          return el;
        } else {
          return el;
        }
      }
    });
    setMainCategoryArr([...tempCategoryArr]);
  };

  const handleRenderCheckboxCategory = (obj) => {
    return (
      <div
        key={obj._id}
        className="col-12 mb-3"
        style={{ marginLeft: `${obj.level + 5}px` }}
      >
        <input
          className="form-check-input pointer"
          checked={obj.checked}
          value={tags}
          onChange={(event) =>
            handleNestedCategoryCheckBoxEvent(obj._id, event.target.checked)
          }
          type="checkbox"
        />
        <label style={{ paddingLeft: 5 }}>{obj.name}</label>
        {obj.checked &&
          obj.subCategoryArr &&
          obj.subCategoryArr.length > 0 &&
          obj.subCategoryArr.map((el) => {
            return handleRenderCheckboxCategory(el);
          })}
      </div>
    );
  };

  useEffect(() => {
    // console.log(discountType,"discountType",discountValue,"discountValue")
    if (productObj && productObj?.name) {
      setDiscountValue(productObj?.discountValue);
    } else {
      setDiscountValue("");
    }
  }, [discountType]);

  function handleCheckDiscountValue(e) {
    try {
      e.preventDefault();
      if (discountType == "Percentage") {
        if (e.target.value < 0 || e.target.value > 100) {
          toastError("Discount Value cannot be more then 100 % or less then 0");
          return;
        } else {
          setDiscountValue(e.target.value);
        }
      } else if (discountType == "None" || discountType == "") {
        setDiscountValue("");
      } else {
        setDiscountValue(e.target.value);
      }
    } catch (e) {
      toastError(e);
    }
  }

  return (
    <form className="form">
      <div className="row gy-4 gy-xxl-0">
        <div className="col-12 col-xxl-12">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">Product Information</h5>
              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Produc Name <span className="red">*</span>
                  </label>
                  <input
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Internal Code <span className="red">*</span>
                  </label>
                  <input
                    value={internalCode}
                    onChange={(event) => setInternalCode(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Slug <span className="red">*</span>
                  </label>
                  <input
                    value={slug}
                    onChange={(event) => setSlug(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <label>BRAND</label>
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-circle-plus"
                      btnName="ADD NEW"
                      changeClass="green fs-12 border-0 bg-white"
                      ClickEvent={(e) => {
                        e.preventDefault();
                        setModalBox(true);
                        setModalType("addBrandModal");
                        setModalName("Create Brand");
                      }}
                    />
                    <AddModal
                      ModalBox={ModalBox}
                      setModalBox={setModalBox}
                      name={ModalName}
                      ModalType={ModalType}
                    />
                  </div>
                  <Select
                    onChange={handleBrandSelection}
                    value={brandObj}
                    defaultValue={selectedBrandId}
                    options={
                      brands && brands.length > 0
                        ? brands.map((el) => ({
                          ...el,
                          label: el.name,
                          value: el._id,
                        }))
                        : []
                    }
                  />
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    GST <span className="red">*</span>
                  </label>
                  <input
                    value={gst}
                    onChange={(event) => setGst(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                {/* <div className="col-12 col-md-6 mb-3">
                  <label>
                    HSN CODE<span className="red">*</span>
                  </label>
                  <input
                    value={hsn}
                    onChange={(event) => setHsn(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div> */}

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    HSN CODE <span className="red">*</span>
                  </label>
                  {hsnTaxArr && hsnTaxArr.length > 0 && (
                    <Select
                      value={hsnObj}
                      onChange={handleHsnTaxSelection}
                      options={
                        hsnTaxArr && hsnTaxArr.length > 0
                          ? hsnTaxArr.map((el) => ({
                            ...el,
                            label: el.hsn,
                            value: el._id,
                          }))
                          : []
                      }
                    />
                  )}
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Date of manufacturing<span className="red">*</span>
                  </label>
                  <input
                    value={moment(dom).format("YYYY-MM-DD")}
                    onChange={(event) => setDom(event.target.value)}
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    SKU<span className="red">*</span>
                  </label>
                  <input
                    value={sku}
                    onChange={(event) => setSku(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Per Type (Meter,Piece)<span className="red">*</span>
                  </label>
                  <input
                    value={productType}
                    onChange={(event) => setProductType(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Product Display<span className="red">*</span>
                  </label>
                  <select
                    className="form-control"
                    value={productDisplay}
                    onChange={(e) => {
                      setproductDisplay(e.target.value);
                    }}
                  >
                    <option value="">None </option>
                    <option value="arrival">New Arrival</option>
                    <option value="bestseller">BestSeller</option>
                    <option value="Samagri">Samagri</option>
                  </select>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>Discount type</label>
                  <select
                    className="form-control"
                    value={discountType}
                    onChange={(e) => {
                      setDiscountType(e.target.value);
                    }}
                  >
                    <option value="None">None </option>
                    <option value="FlatOff">FlatOff</option>
                    <option value="Percentage">Percentage</option>
                  </select>
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>Discount Value</label>
                  <input
                    value={discountValue}
                    // onChange={(event) => setDiscountValue(event.target.value)}
                    onChange={(event) => handleCheckDiscountValue(event)}
                    type="number"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="border-bottom pb-3 mb-4 row">
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Shipping & Dimentions Info{" "}
                    <span className="red">*</span>
                  </h5>
                </div>

                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Ships In (Days)<span className="red">*</span>
                  </label>
                  <input
                    value={ships_in_days}
                    onChange={(event) => setShips_in_days(event.target.value)}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Length<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Length}
                    onChange={(event) =>
                      setPackaging_Length(event.target.value)
                    }
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Width<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Width}
                    onChange={(event) => setPackaging_Width(event.target.value)}
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Packaging Height<span className="red">*</span>
                  </label>
                  <input
                    value={packaging_Height}
                    onChange={(event) =>
                      setPackaging_Height(event.target.value)
                    }
                    type="number"
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <label>
                    Dead weight<span className="red">*</span>
                  </label>
                  <input
                    value={dead_weight}
                    onChange={(event) => setDead_weight(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes<span className="red">*</span>
                </label>
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={index}>
                        <input
                          checked={el.checked}
                          onChange={(event) =>
                            handleAttributeVariantChange(index)
                          }
                          className="form-check-input pointer"
                          type="checkbox"
                        />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>

                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input
                                      checked={ele.checked}
                                      onChange={(event) =>
                                        handleInnerAttributeVariantChange(
                                          index,
                                          index2
                                        )
                                      }
                                      className="form-check-input pointer"
                                      type="checkbox"
                                    />
                                    <label style={{ paddingLeft: 5 }}>
                                      {ele.label}
                                    </label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">Product Variants Info</h5>
                </div>
              )}
              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12" key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-5 mb-3">
                          <label>Variant Price</label>
                          <input
                            value={el.price}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "price"
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12 col-md-5 mb-3">
                          <label>Variant Stock</label>
                          <input
                            value={el.currentStock}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "currentStock"
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>

                        <div className="col-12 col-md-12 mb-3">
                          <h5 className="blue-1 mb-3">
                            Image Info <span className="red">*</span>
                          </h5>
                          <div className="row">
                            <div className="col-2 me-5">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() => handleImageObjAdd(el, index)}
                                isBtn
                                noIcon
                                btnName="+"
                              />
                            </div>
                            <div className="col-2">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() =>
                                  handleImageObjRemove(el, index)
                                }
                                isBtn
                                noIcon
                                btnName="-"
                              />
                            </div>

                          </div>
                          <div className="row">

                            {el?.imageArr &&
                              el?.imageArr.length > 0 &&
                              el?.imageArr.map((ele, indx) => {
                                return (
                                  <div
                                    key={indx}
                                    className="col-6 mb-3">
                                    <label>
                                      Image {indx + 1}<span className="red">*</span>
                                    </label>
                                    <div
                                      style={{
                                        marginTop: 20,
                                        // borderBottom: "grey 1px solid",
                                        paddingBottom: 15,
                                      }}
                                      className="row"
                                    >
                                      <div className="col-12">
                                        <img
                                          src={
                                            `${ele.image}`.includes("base64")
                                              ? ele.image
                                              : generateFilePath(ele.image)
                                          }
                                          style={{ height: 80 }}
                                        />
                                        <br />
                                        <br />
                                        <FileUpload
                                          onFileChange={(val) =>
                                            handleFileSet(val, indx, index)
                                          }
                                        />
                                      </div>
                                      <div className="col-12">
                                        <label>
                                          Variant Image alt
                                          <span className="red">*</span>
                                        </label>
                                        <input
                                          onChange={(e) =>
                                            handleproductImageAltEntry(
                                              e.target.value,
                                              indx, index
                                            )
                                          }
                                          value={ele?.imageAlt}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                        </div>

                      </div>
                    </div>
                  );
                })}

              {/* <div className="col-12 col-md-3 mb-3">
                <label>
                  Attributes<span className="red">*</span>
                </label>
                {console.log(mainAttributesArr,"mainAttributesArr  get ")}
                {mainAttributesArr &&
                  mainAttributesArr.length > 0 &&
                  mainAttributesArr.map((el, index) => {
                    return (
                      <div key={el._id}>
                        <input
                          checked={el.checked}
                          onChange={(event) =>
                            handleAttributeVariantChange(index)
                          }
                          className="form-check-input pointer"
                          type="checkbox"
                        />
                        <label style={{ paddingLeft: 5 }}>{el.name}</label>
                        {el.checked && (
                          <>
                            {el.attributeValueArr.length > 0 &&
                              el.attributeValueArr.map((ele, index2) => {
                                return (
                                  <div key={index2} style={{ marginLeft: 20 }}>
                                    <input
                                      checked={el.checked}
                                      onChange={(event) =>
                                        handleInnerAttributeVariantChange(
                                          index,
                                          index2
                                        )
                                      }
                                      className="form-check-input pointer"
                                      type="checkbox"
                                    />
                                    <label style={{ paddingLeft: 5 }}>
                                      {ele.label}
                                    </label>
                                  </div>
                                );
                              })}
                          </>
                        )}
                      </div>
                    );
                  })}
              </div>
              {displayAttributesArr && displayAttributesArr.length > 0 && (
                <div className="col-12 mb-0">
                  <h5 className="blue-1 mb-4">
                    Product Variants Info <span className="red">*</span>
                  </h5>
                </div>
              )}

              {displayAttributesArr &&
                displayAttributesArr.length > 0 &&
                displayAttributesArr.map((el, index) => {
                  return (
                    <div className="col-12 col-md-12" key={index}>
                      <div className="d-flex align-items-baseline justify-content-between">
                        <label>
                          {el.name}
                          <span className="red">*</span>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6 mb-3">
                          <label>Variant Price</label>
                          <input
                            value={el?.price}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "price"
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>
                        <div className="col-12 col-md-6 mb-3">
                          <label>Variant Stock</label>
                          <input
                            value={el?.currentStock}
                            onChange={(e) =>
                              handleDisplayAttributesArrChange(
                                e.target.value,
                                index,
                                "currentStock"
                              )
                            }
                            type="number"
                            className="form-control"
                          />
                        </div>

                        <div className="col-12 col-md-4 mb-3">
                          <h5 className="blue-1 mb-3">
                            Image Info <span className="red">*</span>
                          </h5>
                          <div className="row">
                            <div className="col-2 me-5">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() => handleImageObjAdd(el, index)}
                                isBtn
                                noIcon
                                btnName="+"
                              />
                            </div>
                            <div className="col-2">
                              <CustomButton
                                btntype="button"
                                ClickEvent={() =>
                                  handleImageObjRemove(el, index)
                                }
                                isBtn
                                noIcon
                                btnName="-"
                              />
                            </div>
                         
                          <div className="col-12 mb-4">
                            <label>
                              Variant Image<span className="red">*</span>
                            </label>
                            {el?.imageArr &&
                              el?.imageArr.length > 0 &&
                              el?.imageArr.map((ele, indx) => {
                                return (
                                  <div
                                    key={indx}
                                    style={{
                                      marginTop: 20,
                                      borderBottom: "grey 1px solid",
                                      paddingBottom: 15,
                                    }}
                                    className="row"
                                  >
                                    <div className="col-12">
                                      <img
                                        src={
                                          `${ele.image}`.includes("base64")
                                            ? ele.image
                                            : generateFilePath(ele.image)
                                        }
                                        style={{ height: 80 }}
                                      />
                                      <br />
                                      <br />
                                      <FileUpload
                                        onFileChange={(val) =>
                                          handleFileSet(val, indx, index)
                                        }
                                      />
                                    </div>
                                    <div className="col-12">
                                      <label>
                                        Variant Image alt
                                        <span className="red">*</span>
                                      </label>
                                      <input
                                        onChange={(e) =>
                                          handleproductImageAltEntry(
                                            e.target.value,
                                            indx,index
                                          )
                                        }
                                        value={ele?.imageAlt}
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })} */}

              <div className="border-bottom pb-3 mb-4 row">
                <h5 className="blue-1 mb-4">
                  Tags (Comma Separated) <span className="red">*</span>{" "}
                </h5>
                <div className="col-12 mb-3">
                  <input
                    value={tags}
                    onChange={(event) => setTags(event.target.value)}
                    type="text"
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Product Category <span className="red">*</span>
              </h5>
              {mainCategoryArr &&
                mainCategoryArr.length > 0 &&
                mainCategoryArr.map((el) => {
                  return handleRenderCheckboxCategory(el);
                })}
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <div className="col-12 mb-0">
                <h5 className="blue-1 mb-4">
                  Product (Dimentions) Weight Height Info{" "}
                </h5>
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight</label>
                <input
                  value={product_dimension_weight}
                  onChange={(event) =>
                    setProduct_Dimension_weight(event.target.value)
                  }
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-6 mb-3">
                <label>Weight Type</label>
                <select
                  className="form-control"
                  value={dimensionWeightType}
                  onChange={(e) => {
                    dimensionSetweightType(e.target.value);
                  }}
                >
                  <option value="grams">grams</option>
                  <option value="kg">Kg</option>
                </select>
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Length </label>
                <input
                  value={product_dimension_length}
                  onChange={(event) =>
                    setProduct_Dimension_length(event.target.value)
                  }
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Breadth </label>
                <input
                  value={product_dimension_width}
                  onChange={(event) =>
                    setProduct_Dimension_width(event.target.value)
                  }
                  type="number"
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Height </label>
                <input
                  type="number"
                  value={product_dimension_height}
                  onChange={(event) =>
                    setProduct_Dimension_height(event.target.value)
                  }
                  className="form-control"
                />
              </div>
              <div className="col-12 col-md-3 mb-3">
                <label>Dimensions Type</label>
                <select
                  className="form-control"
                  value={dimensionHeightType}
                  onChange={(e) => {
                    dimensionSetHeightType(e.target.value);
                  }}
                >
                  <option value="cm">Cm</option>
                  <option value="inches">Inches</option>
                </select>
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              {/* <h5 className="blue-1 mb-4">
                Price Info And Stock <span className="red">*</span>
                {console.log(displayAttributesArr, "displayAttributesArr234")}
              </h5> */}
              <>
                {/* {displayAttributesArr && !(displayAttributesArr.length > 0) && (
                  <>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        SELLING PRICE<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        min={0}
                        value={sellingPrice}
                        onChange={(event) =>
                          setSellingPrice(event.target.value)
                        }
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        MRP <span className="red">*</span>
                      </label>
                      <input
                        value={mrp}
                        onChange={(event) => setMrp(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>
                        STOCK <span className="red">*</span>
                      </label>
                      <input
                        value={stock}
                        onChange={(event) => setStock(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </>
                )} */}
              </>
            </div>

            {/* <div className="col-12 mb-3">
              <label>Size Chart</label>
              <br />
              <br />
              <img
                src={
                  `${sizeChart}`.includes("base64")
                    ? sizeChart
                    : generateFilePath(sizeChart)
                }
                style={{ height: 80 }}
              />
              <FileUpload onFileChange={(val) => setsizeChart(val)} />
            </div> */}
            {/* <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Artisan <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={artisan}
                  handleChange={(e) => setArtisan(e)}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Craft <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor value={craft} handleChange={(e) => setCraft(e)} />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Return Policy <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={returnPolicy}
                  handleChange={(e) => setReturnPolicy(e)}
                />
              </div>
            </div> */}
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Material <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={material}
                  handleChange={(e) => setMaterial(e)}
                />
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Description <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={despcription}
                  handleChange={(e) => setDespcription(e)}
                />
                {/* <ReactQuill theme="snow" onChange={(e) => setDespcription(e)} /> */}
              </div>
            </div>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-4">
                Specifications <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <QuillEditor
                  value={Specifications}
                  handleChange={(e) => setSpecifications(e)}
                />
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-4">
                SEO info <span className="red">*</span>
              </h5>
              <div className="col-12 mb-3">
                <label>META TITLE</label>
                <input
                  onChange={(e) => setMetaTitle(e.target.value)}
                  value={metaTitle}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="col-12 mb-3">
                <label>META DESCRIPTION</label>
                <textarea
                  onChange={(e) => setMetaDescription(e.target.value)}
                  value={metaDescription}
                  name="META DESCRIPTION"
                  className="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div className="col-12 mb-3">
                <label>META IMAGE (300X300)PX</label>
                <br />
                <br />
                <img
                  src={
                    `${metaImage}`.includes("base64")
                      ? metaImage
                      : generateFilePath(metaImage)
                  }
                  style={{ height: 80 }}
                />
                <br />
                <br />
                <FileUpload onFileChange={(val) => setMetaImage(val)} />
              </div>
              <div className="col-12">
                <CustomButton
                  btntype="button"
                  ClickEvent={handleSubmit}
                  isBtn
                  iconName="fa-solid fa-check"
                  btnName="Save"
                />
              </div>
            </div>
          </DashboardBox>
        </div>
        {/* <div className="col-12 col-xxl-4">
          <DashboardBox>
            <div className="border-bottom pb-3 mb-4 row">
              <h5 className="blue-1 mb-3">
                Product Image Info <span className="red">*</span>
              </h5>
              <div className="row">
                <div className="col-2 me-5">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageObjAdd}
                    isBtn
                    noIcon
                    btnName="+"
                  />
                </div>
                <div className="col-2">
                  <CustomButton
                    btntype="button"
                    ClickEvent={handleImageObjRemove}
                    isBtn
                    noIcon
                    btnName="-"
                  />
                </div>
              </div>
              <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                {imageArr &&
                  imageArr.length > 0 &&
                  imageArr.map((el, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          marginTop: 20,
                          borderBottom: "grey 1px solid",
                          paddingBottom: 15,
                        }}
                        className="row"
                      >
                        <div className="col-12">
                          <img
                            src={
                              `${el.image}`.includes("base64")
                                ? el.image
                                : generateFilePath(el.image)
                            }
                            style={{ height: 80 }}
                          />

                          <FileUpload
                            onFileChange={(val) => handleFileSet(val, index)}
                          />
                        </div>
                        <div className="col-12">
                          <label>
                            Product Image alt<span className="red">*</span>
                          </label>
                          <input
                            onChange={(e) =>
                              handleproductImageAltEntry(e.target.value, index)
                            }
                            value={el?.imageAlt}
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="row">
              <h5 className="blue-1 mb-3">Others Info</h5>
              <div className="col-12 mb-3">
                <label>
                  STATUS<span className="red">*</span>
                </label>
                <div className="d-flex">
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setStatus("Publish")}
                      checked={status == "Publish"}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status"
                      value="option1"
                      id="product-publish"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="product-publish"
                    >
                      Publish
                    </label>
                  </div>
                  <div className="form-check form-check-inline d-flex align-items-center pointer">
                    <input
                      onChange={(e) => setStatus("Pending")}
                      checked={status == "Pending"}
                      className="form-check-input pointer"
                      type="radio"
                      name="product-status"
                      value="option2"
                      id="product-pending"
                    />
                    <label
                      className="form-check-label fs-14 pointer"
                      htmlFor="product-pending"
                    >
                      Pending
                    </label>
                  </div>
                </div>
              </div>
             
            </div>
          </DashboardBox>
        </div> */}
      </div>
    </form>
  );
}

export default UpdateGeneralProduct;
