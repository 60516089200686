import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { downloadCSV } from "../Utility/CSV";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import tabClick from "../Utility/TabClick";
import { useDispatch, useSelector } from "react-redux";
import { usersGet } from "../../redux/actions/Users/users.actions";
import {
  updateUserKycStatus,
  updateUserStatus,
} from "../../services/users.service";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import CustomerDetail from "./CustomerDetail";
import { EditModal } from "../Utility/Modal";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { rolesObj } from "../../utils/roles";
import { Link } from "react-router-dom";
import { getUser } from "../../services/users.service";
import { Dashboard } from "@mui/icons-material";

function User() {
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const [displayUsersArr, setDisplayUsersArr] = useState([]);
  const [usersArr, setUsersArr] = useState([]);
  const [activeUsersArr, setActiveUsersArr] = useState([]);
  const [inActiveUsersArr, setInActiveUsersArr] = useState([]);
  const userArr = useSelector((state) => state.users.users);

  const role = useSelector((state) => state.auth.role);
  const [selectedData, setSelectedData] = useState(null);
  const userPaginationObject = useSelector(
    (state) => state.users.usersPaginationObj
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState("");
  const [tabFilter, setTabFilter] = useState("");

  const [tabList, settabList] = useState([
    {
      tabName: "All Users",
      active: true,
    },
    {
      tabName: "Active Users",
      active: false,
    },
    {
      tabName: "Inactive Users",
      active: false,
    },
  ]);

  const handleChangeActiveStatus = async (id, value) => {
    try {
      // console.log("reses get udapye1111");

      let { data: res } = await updateUserStatus(id, { isActive: value });

      // console.log(res, "res get udapye1111");
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();

        let temp = tabList.map((item, index) => {
          if (index == 0) {
            item.active = true;
          } else {
            item.active = false;
          }
          return item;
        });
        settabList([...temp]);
        // console.log("get get user");
      }
    } catch (err) {
      if (err.response.data.message) {
        // console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        // console.error(err.message);
        toastError(err.message);
      }
    }
  };

  const handleChangeApprovalStatusStatus = async (id, value) => {
    try {
      // console.log(value)
      let { data: res } = await updateUserStatus(id, { isApproved: value });
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }
  };

  const handleChangeKycStatus = async (id, value) => {
    try {
      let { data: res } = await updateUserKycStatus(id, { value: value });
      if (res.message) {
        toastSuccess(res.message);
        handleGetAllUsers();
      }
    } catch (err) {
      if (err.response.data.message) {
        console.error(err.response.data.message);
        toastError(err.response.data.message);
      } else {
        console.error(err.message);
        toastError(err.message);
      }
    }

    // console.log(id, value);
  };

  const handleModalSet = (e, row) => {
    e.preventDefault();
    setModalBox(true);
    setModalType("customer-detail");
    setModalName("Customer Information");
    setSelectedData(row);
  };

  const [users_columns, setUsers_columns] = useState([
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "NAME",
      selector: (row) =>
        `${
          row?.firstName && row?.lastName
            ? row?.firstName + row?.lastName
            : row?.firstName
            ? row?.firstName
            : row?.userName
            ? row?.userName
            : " "
        }`,
      width: "20%",
    },
    {
      name: "EMAIL",
      selector: (row) => row?.email,
      width: "25%",
    },
    {
      name: "PHONE",
      selector: (row) => (row?.phone || row?.phone != "" ? row?.phone : "NA"),
      width: "15%",
    },
    {
      name: "IS ACTIVE",
      button: true,
      cell: (row) => (
        <Switch
          onChange={(e) => handleChangeActiveStatus(row._id, e.target.checked)}
          checked={row.isActive}
        />
      ),
      width: "8%",
    },
  ]);

  const handleGetAllUsers = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      if (tabFilter) {
        queryOf = `${queryOf}&tabFilter=${tabFilter}`;
      }
      // console.log(queryOf,"queryOfget get query")
      let { data: get } = await getUser(queryOf);
      // console.log(get,"get user get");
      if (get?.success) {
        if (get?.data && get?.data.length >= 0) {
          setUsersArr(get?.data);
          setDisplayUsersArr(get?.data);
          // setActiveUsersArr(usersArr.filter((el) => el.isActive));
          // setInActiveUsersArr(usersArr.filter((el) => !el.isActive));
        }
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(page, perPage, query, "page, perPage,query");
    handleGetAllUsers();
  }, [page, perPage, query, tabFilter]);

  useEffect(() => {
    if (userArr && userArr.length >= 0) {
      setUsersArr(userArr);
      setDisplayUsersArr(userArr);
      setActiveUsersArr(usersArr.filter((el) => el.isActive));
      setInActiveUsersArr(usersArr.filter((el) => !el.isActive));
    }
    if (userPaginationObject) {
      setTotalPages(userPaginationObject?.totalPages);
      setTotalCount(userPaginationObject?.totalCount);
      setPage(userPaginationObject?.page);
    }
  }, [userArr, userPaginationObject]);

  // useEffect(() => {
  // if (role == rolesObj.ADMIN) {
  //   setUsers_columns((previousState) => [
  //     ...previousState,
  //     {
  //       name: "Action",
  //       cell: (row) => (
  //         <>
  //           <button
  //             className="btn btn-1  btn-sm  text-white"
  //             onClick={(e) => handleModalSet(e, row)}
  //           >
  //             <i className="fa-solid fa-eye"> </i>
  //           </button>
  //         </>
  //       ),
  //       width: "15%",
  //     },
  //   ]);
  // }

  // return () => {
  //   setUsers_columns([
  //     {
  //       name: "ID",
  //       selector: (row, index) => index + 1,
  //       sortable: true,
  //     },
  //            {
  //       name: "NAME",
  //       selector: (row) =>
  //         `${
  //           row?.firstName && row?.lastName
  //             ? row?.firstName +"  "+ row?.lastName
  //             : row?.firstName
  //             ? row?.firstName
  //             : row?.userName
  //             ? row?.userName
  //             : " "
  //         }`,
  //     },
  //     {
  //       name: "EMAIL",
  //       selector: (row) => row.email,
  //     },
  //     {
  //       name: "PHONE",
  //       selector: (row) => row.phone,
  //     },
  //         {
  //       name: "IS ACTIVE",
  //       button: true,
  //       cell: (row) => (
  //         <Switch
  //           onChange={(e) =>
  //             handleChangeActiveStatus(row._id, e.target.checked)
  //           }
  //           checked={row.isActive}
  //         />
  //       ),
  //     },

  //   ]);

  //   settabList([
  //     {
  //       tabName: "All Users",
  //       active: true,
  //     },
  //     {
  //       tabName: "Active Users",
  //       active: false,
  //     },
  //     {
  //       tabName: "Inactive Users",
  //       active: false,
  //     },
  //   ]);
  // };
  // }, [role, usersArr]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleGetTselectedTable = () => {
    try {
      // if (tabList.filter((el) => el.active)[0].tabName == "All Users") {
      return (
        <DataTable
          columns={users_columns}
          data={usersArr}
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerRowsChange}
        />
      );
    } catch (err) {
      toastError(err);
    }
  };

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="d-flex align-items-center justify-content-between mb-3 gap-2">
            <ul
              className="nav nav-pills dashboard-pills gap-2"
              id="pills-tab"
              role="tablist"
            >
              {tabList.map((item, i) => {
                return (
                  <li key={i}>
                    <CustomButton
                      navPills
                      btnName={item.tabName}
                      pillActive={item.active ? true : false}
                      path={item.path}
                      extraClass={item.extraClass}
                      ClickEvent={() => {
                        tabClick(i, tabList, settabList);
                        setTabFilter(item.tabName);
                      }}
                    />
                  </li>
                );
              })}
            </ul>
            <CustomButton
              isLink
              iconName="fa-solid fa-plus"
              btnName="Create User"
              path="/User-Create"
            />
          </div>
          <DashboardTable>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h5 className="blue-1 m-0">Customer</h5>
              <div className="d-flex align-items-center gap-3">
                <SearchBox
                  setQuery={(e) => {
                    handleFilterByQuery(e, ["name"]);
                  }}
                  query={query}
                  extraClass="bg-white"
                />
                {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="Customer CSV" path="/" small roundedPill downloadAble ClickEvent={() => downloadCSV(usersArr)} /> */}
              </div>
            </div>
            <EditModal
              ModalBox={ModalBox}
              data={selectedData}
              width="500px"
              setModalBox={setModalBox}
              name={ModalName}
              ModalType={ModalType}
            />

            {handleGetTselectedTable()}
          </DashboardTable>
        </div>
      </section>
    </main>
  );
}

export default User;
