import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as PRODUCTREVIEW from "../../actions/ProductReview/ProductReview.actions";


const initialState = {
  productReviews: null,
  productReviewObj: null,
  loading: false,
  productReviewPaginationObj: null,
  error: null,
};


export const ProductReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTREVIEW.PRODUCTREVIEW_ADD:
      return {
        ...state,
        loading: true,
      };

    case PRODUCTREVIEW.PRODUCTREVIEW_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };

    case PRODUCTREVIEW.PRODUCTREVIEW_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTREVIEW.UPDATE_PRODUCTREVIEW_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTREVIEW.UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS:
      // console.log(
      //   "UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS",
      //   "UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS"
      // );
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.data,
      };
    case PRODUCTREVIEW.UPDATE_PRODUCTREVIEW_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTREVIEW.GET_ALL_PRODUCTREVIEWS:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTREVIEW.GET_ALL_PRODUCTREVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.data,
        productPaginationObj: action.payload.paginationObj,
      };
    case PRODUCTREVIEW.GET_ALL_PRODUCTREVIEWS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTREVIEW.GET_RELATED_PRODUCTREVIEWS:
      return {
        ...state,
        products: [],
        loading: true,
      };
    case PRODUCTREVIEW.GET_RELATED_PRODUCTREVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        products: action.payload.data,
      };
    case PRODUCTREVIEW.GET_RELATED_PRODUCTREVIEWS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTREVIEW.DELETE_PRODUCTREVIEW_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTREVIEW.DELETE_PRODUCTREVIEW_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        products: [
          ...state?.PRODUCTREVIEWs?.filter(
            (el) => el?._id != action?.payload?.deletedPRODUCTREVIEW
          ),
        ],
        loading: false,
        error: null,
      };
    case PRODUCTREVIEW.DELETE_PRODUCTREVIEW_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case PRODUCTREVIEW.SET_PRODUCTREVIEW_OBJ:
      return {
        ...state,
        loading: true,
      };
    case PRODUCTREVIEW.SET_PRODUCTREVIEW_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        productObj: action.payload.data,
        loading: false,
        error: null,
      };
    case PRODUCTREVIEW.SET_PRODUCTREVIEW_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
