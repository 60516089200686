import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";
import { useSelector, useDispatch } from "react-redux";
import { BLOGCATEGORYGet } from "../../redux/actions/BlogCategory/BlogCategory.actions";
import { toastError } from "../../utils/toastUtils";

import {
  blogAdd,
  BLOGGet,
  blogUpdate,
  SetblogObj,
  BLOGDelete,
  setBlogStatus,
} from "../../redux/actions/Blog/Blog.actions";

import { tagGet } from "../../redux/actions/Tag/Tag.actions";
import { generateFilePath } from "../Utility/utils";

function AddBlog() {
  let dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState({});
  const [tags, setTags] = useState("");
  const [image, setImage] = useState("");
  const [prevBlogId, setPrevBlogId] = useState(null);
  const [publish, setPublish] = useState(false);
  const [popular, setPopular] = useState(false);

  const [isUpdateBlog, setIsUpdateBlog] = useState(false);
  const [authorName, setAuthorName] = useState("");
  const [blogCategory, setBlogCategory] = useState([]);
  const [blogCategoryArray, setBlogCategoryArray] = useState([]);

  const blogCategoryArr = useSelector(
    (state) => state.blogCategory.blogCategories
  );
  const blogObj = useSelector((state) => state.blog.BLOGObj);
  const blogStatusCheck = useSelector((state) => state.blog.blogStatus);

  const handleBlogCategoryGet = () => {
    dispatch(BLOGCATEGORYGet("get=All"));
  };

  useEffect(() => {
    handleBlogCategoryGet();
  }, []);

  const clearFunc = () => {
    try {
      setTitle("");
      setSlug("");
      setDescription("");
      setCategory({});
      setTags("");
      setImage("");
      setPrevBlogId("");
      setPublish(false);
      setPopular(false);
      setIsUpdateBlog(false);
      setAuthorName("");
      dispatch(SetblogObj(null));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    let options = [];

    if (blogCategoryArr) {
      for (let el of blogCategoryArr) {
        options.push({ value: el.name, label: el.name });
      }
    }
    setBlogCategoryArray(blogCategoryArr);
    setBlogCategory(options);

    if (blogObj?._id && blogObj?.blogCategoryId) {
      let categoryObj =
        blogCategoryArr &&
        blogCategoryArr.find((el) => el._id == blogObj?.blogCategoryId);
      // console.log(categoryObj,"categoryArr get ")
      if (categoryObj && categoryObj._id) {
        setCategory({
          value: categoryObj?.name,
          label: categoryObj?.name,
        });
      }
    }
  }, [blogCategoryArr]);


  useEffect(() => {
    // console.log(blogObj, "indised ");
    if (blogObj && blogObj._id) {
      setTitle(blogObj?.title);
      setSlug(blogObj?.slug);
      setAuthorName(blogObj?.authorName);
      setDescription(blogObj?.description);
      setImage(blogObj?.image);
      setTags(blogObj?.tags);
      setPublish(blogObj?.publish);
      setPopular(blogObj?.popular);
      setPrevBlogId(blogObj?._id);
      setIsUpdateBlog(true);
    }

    return () => {
      dispatch(SetblogObj(null));
    };
  }, [blogObj]);


  
  useEffect(() => {
    if (blogStatusCheck && blogStatusCheck.message) {
      clearFunc();
    }
    return () => {
      dispatch(setBlogStatus(null));
    };
  }, [blogStatusCheck]);




  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (`${title}` == "") {
        toastError("Title is mandatory");
        return;
      }

      if (`${slug}` == "") {
        toastError("Slug is mandatory");
        return;
      }
      if (`${authorName}` == "") {
        toastError("Author Name is mandatory");
        return;
      }

      if (`${description}` == "") {
        toastError("Description is mandatory");
        return;
      }

      if (`${tags}` == "") {
        toastError("tags is mandatory");
        return;
      }

      if (category.value == undefined) {
        toastError("category is mandatory");
        return;
      }
      if (`${image}` == "") {
        toastError("image is mandatory");
        return;
      }

      let obj = {
        title,
        slug,
        authorName,
        description,
        tags,
        category: category.value,
        image,
        publish,
        popular,
      };
      if (blogObj?._id) {
        dispatch(blogUpdate(prevBlogId, obj));
      } else {
        dispatch(blogAdd(obj));
      }
      // console.log(getResponse,"get Response blog")
    } catch (err) {
      toastError(err);
    }
  };

  const handleFileSet = (value) => {
    setImage(value);
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">
              {isUpdateBlog ? "Update" : "Add New "} Blog
            </h5>
            <div className="row gy-4 gy-xxl-0">
              <div className="col-12 col-xxl-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Blog Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        TITLE <span className="red">*</span>
                      </label>

                  
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      {/* {title} */}
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Author Name<span className="red">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={authorName}
                        onChange={(e) => setAuthorName(e.target.value)}
                      />
                    </div>
                    <div className="col-12">
                      <label>
                        DESCRIPTION<span className="red">*</span>
                      </label>
                      <ReactQuill
                        theme="snow"
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                      {/* <ReactQuill /> */}
                      {/* <QuillEditor theme="snow"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)} /> */}
                    </div>
                  </div>
                </DashboardBox>
              </div>
              <div className="col-12 col-xxl-4">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Basic Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        CATEGORY<span className="red">*</span>
                      </label>
                      {/* <Select options={options} /> */}
                      <Select
                        options={blogCategory}
                        value={category}
                        onChange={(e) => setCategory(e)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Tags (Comma Separated)<span className="red">*</span>
                      </label>
                      <input
                        value={tags}
                        onChange={(event) => setTags(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                      {/* <Select
                        options={options}
                        isMulti
                        onChange={(e) => setTags(e)}
                      /> */}

                      <div className="form-text fs-12 blue-1">
                        Suggested Tags
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <label>
                        Image(1000x500)px<span className="red">*</span>
                      </label>
                      <div
                        style={{
                          marginTop: 20,
                          borderBottom: "grey 1px solid",
                          paddingBottom: 15,
                        }}
                        className="row"
                      >
                        <div className="col-12">
                          <img
                            src={
                              `${image}`.includes("base64")
                                ? image
                                : generateFilePath(image)
                            }
                            style={{ height: 80 }}
                          />
                          <br />
                          <br />
                          <FileUpload
                            onFileChange={(val) => handleFileSet(val)}
                          />
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12 mb-3">
                      <label>
                        Image(1000x500)px<span className="red">*</span>
                      </label>
                      <FileUpload onFileChange={handleFileSet} />
                    </div> */}
                    {/* 
                    <div className="col-12">
                      <label>Status</label>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input checked={status} onChange={() => setStatus(!status)} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" />
                        <label className="form-check-label fs-14" htmlFor="active-banner">
                          Active
                        </label>
                      </div>
                    </div>
                     */}

                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="publish-checkbox"
                          checked={publish}
                          onChange={() => setPublish(!publish)}
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="publish-checkbox"
                        >
                          Publish
                        </label>
                        {/* <input checked={publish} onChange={() => setPublish(!publish)} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" /> */}
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="category-status"
                          value="option1"
                          id="popular-checkbox"
                          checked={popular}
                          onChange={() => setPopular(!popular)}
                        />
                        <label
                          className="form-check-label fs-14"
                          htmlFor="publish-checkbox"
                        >
                          Popular
                        </label>
                        {/* <input checked={publish} onChange={() => setPublish(!publish)} className="form-check-input" type="checkbox" name="category-status" value="option1" id="active-banner" /> */}
                      </div>
                    </div>

                    <div className="col-12">
                      <CustomButton
                        isBtn
                        iconName="fa-solid fa-check"
                        btnName="Save"
                        ClickEvent={handleSubmit}
                      />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddBlog;
