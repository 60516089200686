import React, { useState } from "react";
import { useDispatch } from "react-redux";
// import { uploadMultipleImages,MultiFileUpload,  handleFileSelection } from "../Utility/MultipleFileUpload"; 
import{add,get} from "../../services/MediaManager.service" 

import { toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import MultiFileUpload from "../Utility/MultipleFileUpload";
import { toastError } from "../Utility/ToastUtils";



export default function AddMedia() {
  const dispatch = useDispatch();
  const [filesArr, setFilesArr] = useState([]);



  const handleUploadImage = async () => {
    try {
      if (filesArr && filesArr.length > 0) {

        var formData = new FormData;
        for (var i = 0; i < filesArr.length; i++) {
          // formData.append('file', filesArr[i]);
          // formData.append('name', filesArr[i].name);
          formData.append('file', filesArr[i].link);
          formData.append('name', filesArr[i].name);
        }
        // let { data: res } = await uploadMultipleImages(formData)
        // let { data: res } = await MultiFileUpload(formData)
        let { data: res } = await add(formData)
        if (res.message) {
          toastSuccess(res.message)
          window.location.reload()
        }
      }
      else {
        toastError("Please add an image to upload")
      }
    }
    catch (err) {
      toastError(err)
    }
  }



  const handleDelete = (index) => {
    let tempArr = filesArr
    tempArr.splice(index, 1)
    setFilesArr([...tempArr])
  }



  const handleFileChange = (event) => {
    // console.log(event, "ASDASD");
    setFilesArr([...event])
    // handleUploadImage(event)
  };




  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row d-flex" style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 450 }}>
                  <MultiFileUpload onFileChange={handleFileChange} acceptImage={true} multiple={true} />
                </div>
                <div style={{ width: 450 }}>
                  <CustomButton
                    btnName={"Upload"}
                    isBtn
                    btntype="button"
                    iconName="fa-solid fa-check"
                    ClickEvent={() => handleUploadImage()} />
                </div>
              </form>
              <div className="container-fluid mt-5">

                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly" }}>

                  {
                    filesArr && filesArr.length > 0 && filesArr.map((el, index) => {
                      return (
                        <div className="m-1 grid-layout-container mb-4" key={index}>
                          <button onClick={() => { handleDelete(index) }}>X</button>
                          <img style={{ height: 150, width: 150 }} src={el.base64} alt="" />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
