import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  SETFAQCATEGORYOBJ,
  FAQCATEGORYADD,
  FAQCATEGORYUPDATE,setFaqCategoryStatus
} from "../../../redux/actions/FaqCategory/FaqCategory.actions";
import { countryList } from "../../../utils/Country";
import { toastError } from "../../../utils/toastUtils";

function AddFaqCategory({ makeChange }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [faqCategoryId, setFaqCategoryId] = useState("");
  const [query, setQuery] = useState("");

  const [categoryObject, SetCategoryObject] = useState({});

  const faqCategoryObject = useSelector(
    (state) => state.faqCategory.faqCategoryObj
  );
  const faqCategoryStatusCheck = useSelector(
    (state) => state.faqCategory.FaqCategoryStatusUpdate
  );

  const cleawFunc = () => {
    setName("");
    setFaqCategoryId("");
    SetCategoryObject({});
  };

  useEffect(() => {
    if (faqCategoryStatusCheck && faqCategoryStatusCheck.message) {
      dispatch(SETFAQCATEGORYOBJ(null));
      cleawFunc();
      
    }return()=>{
      dispatch(setFaqCategoryStatus(null))
    }
  }, [faqCategoryStatusCheck]);

  const handleAddCategory = async () => {
    try {
      if (`${name}` === "") {
        toastError("name is mandatory ");
        return;
      }
      let obj = {
        name,
      };
      if (categoryObject && categoryObject?._id) {
        dispatch(FAQCATEGORYUPDATE(faqCategoryId, obj, query));
      } else {
        dispatch(FAQCATEGORYADD(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (faqCategoryObject && faqCategoryObject._id) {
      setQuery(faqCategoryObject?.query);
      setName(faqCategoryObject?.name);
      SetCategoryObject(faqCategoryObject);
      setFaqCategoryId(faqCategoryObject?._id);
    }

    return () => {
      dispatch(SETFAQCATEGORYOBJ(null));
    };
  }, [faqCategoryObject]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5>
            {categoryObject && categoryObject?._id ? "Update" : "Add"} Faq
            Category
          </h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddFaqCategory;
