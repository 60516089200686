import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";

import {
  tagGet,
  TAGDelete,
  SetTAGObj,
  TAGAdd,
  TAGUpdate,
} from "../../../redux/actions/Tag/Tag.actions";
import { toastError } from "../../Utility/ToastUtils";


function Tags() {
  const tagArray = useSelector((state) => state.tag.tags);
  const tagObject = useSelector((state) => state.tag.tagObj);
  const [prevTagId, setPrevTagId] = useState(null);
  const [query, setQuery] = useState("");
  const [name, setName] = useState("");
  let dispatch = useDispatch();


  const tagPaginationObject = useSelector(
    (state) => state.tag.tagsPaginationObj
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [tagsArr, setTagsArr] = useState([]);


  const handleGet = () => {
    // console.log(`page=${page}&perPage=${perPage}`,"pppppppppppppppp")
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    dispatch(tagGet(queryOf));
  };


  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);




  useEffect(() => {
    if (tagObject && tagObject._id) {
      setName(tagObject?.name);
      setPrevTagId(tagObject?._id);
    }
   
  }, [tagObject,tagPaginationObject]);


  const handleTagDelete = (row) => {
    // console.log(row, "des");
    dispatch(TAGDelete(row));
  };
  const handlePageChange = (page) => { 
    setPage(page);
  };
 const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };


  const handleTagEdit = (row) => {
    dispatch(SetTAGObj(row));
  };


  const clearFunc = () => {
    setName("");
    dispatch(SetTAGObj({}));
  };

useEffect(()=>{
if(tagArray&&tagArray.length>=0){
  setTagsArr(tagArray)
}
  if (tagPaginationObject) {
    setPage(tagPaginationObject?.page);
    setPerPage(tagPaginationObject?.perPage);
    setTotalCount(tagPaginationObject?.totalCount);
    setTotalPages(tagPaginationObject?.totalPages);
  }
},[tagArray,tagPaginationObject])

  const handleSubmit = (e) => {
    try{
    e.preventDefault();
    let obj = {
      name,
    };

    if (tagObject?._id) {
      // console.log(obj, "drt");
      dispatch(TAGUpdate(prevTagId, obj));
    } else {
      dispatch(TAGAdd(obj));
    }
    clearFunc();
  }catch(err){
    toastError(err)
  }
};
const handleFilterByQuery = (e, requiredParametersArr) => {
  setQuery(e);
};

  const tag_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
    },

    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/SetUp/Tags"
          onDeleteClick={() => {
            handleTagDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleTagEdit(row);
          }}
          editPath="/SetUp/Tags"
        />
      ),
      width: "25%",
    },
  ];

  // const tag_data = [
  //   {
  //     id: "1",
  //     sl: "1",
  //     Name: "Nails",
  //   },
  //   {
  //     id: "2",
  //     sl: "2",
  //     Name: "Eyes",
  //   },
  //   {
  //     id: "3",
  //     sl: "3",
  //     Name: "Face",
  //   },
  //   {
  //     id: "4",
  //     sl: "4",
  //     Name: "Lips",
  //   },
  //   {
  //     id: "5",
  //     sl: "5",
  //     Name: "Nail polish",
  //   },
  //   {
  //     id: "6",
  //     sl: "6",
  //     Name: "Perfect Finish box(Nail P...",
  //   },
  //   {
  //     id: "7",
  //     sl: "7",
  //     Name: "Foundation",
  //   },
  //   {
  //     id: "8",
  //     sl: "8",
  //     Name: "LIQUID SINDOOR",
  //   },
  //   {
  //     id: "9",
  //     sl: "9",
  //     Name: "BEAUTY POP BOX LIP COLOR",
  //   },
  //   {
  //     id: "10",
  //     sl: "10",
  //     Name: "LIPSTIC A & B",
  //   },
  // ];

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">
                {tagObject && tagObject._id ? "Edit" : "Add"} Tag
              </h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label className="blue-1">
                      Name <span className="red">*</span>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </label>
                    {/* <input type="text" className="form-control" /> */}
                  </div>
                 
                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex gap-3 justify-content-between mb-3 align-items-center">
                <h5 className="blue-1 m-0">Tags List</h5>
                <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
              </div>
              <DashboardTable>
                <DataTable
                  columns={tag_columns}
                  data={tagsArr && tagsArr.length > 0 ? tagsArr : []}
                  // data={tag_data}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Tags;
