import axios from "axios" ;
import { axiosApiInstance } from "../App" ;
import { url } from "./url.service" ;

const serverUrl = url + "/PromotionalOffer" ;


export const addPromotionalOffer = (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const get = (query) => {
  return axiosApiInstance.get(`${serverUrl}/get?${query}`);
};

export const deleteById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const getAllBySellerId = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getAllBySellerId/${id}`);
};
