import {
  addHomePage,
  deleteHomePage,
  getHomePage,
  updateHomePage,
} from "../../../services/homePage.service";

export const HOMEPAGE_ADD = "HOMEPAGE_ADD";
export const HOMEPAGE_ADD_SUCCESS = "HOMEPAGE_ADD_SUCCESS";
export const HOMEPAGE_ADD_FAIL = "HOMEPAGE_ADD_FAIL";

export const GET_ALL_HOMEPAGES = "GET_ALL_HOMEPAGES";
export const GET_ALL_HOMEPAGES_SUCCESS = "GET_ALL_HOMEPAGES_SUCCESS";
export const GET_ALL_HOMEPAGES_FAIL = "GET_ALL_HOMEPAGES_FAIL";

export const UPDATE_HOMEPAGE_BY_ID = "UPDATE_HOMEPAGE_BY_ID";
export const UPDATE_HOMEPAGE_BY_ID_SUCCESS = "UPDATE_HOMEPAGE_BY_ID_SUCCESS";
export const UPDATE_HOMEPAGE_BY_ID_FAIL = "UPDATE_HOMEPAGE_BY_ID_FAIL";

export const SET_HOMEPAGE_OBJ = "SET_HOMEPAGE_OBJ";
export const SET_HOMEPAGE_OBJ_SUCCESS = "SET_HOMEPAGE_OBJ_SUCCESS";
export const SET_HOMEPAGE_OBJ_FAIL = "SET_HOMEPAGE_OBJ_FAIL";

export const GET_HOMEPAGE_BY_ID = "GET_HOMEPAGE_BY_ID";
export const GET_HOMEPAGE_BY_ID_SUCCESS = "GET_HOMEPAGE_BY_ID_SUCCESS";
export const GET_HOMEPAGE_BY_ID_FAIL = "GET_HOMEPAGE_BY_ID_FAIL";

export const DELETE_HOMEPAGE_BY_ID = "DELETE_HOMEPAGE_BY_ID";
export const DELETE_HOMEPAGE_BY_ID_SUCCESS = "DELETE_HOMEPAGE_BY_ID_SUCCESS";
export const DELETE_HOMEPAGE_BY_ID_FAIL = "DELETE_HOMEPAGE_BY_ID_FAIL";



export const HOMEPAGEAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: HOMEPAGE_ADD });
    let { data: response } = await addHomePage(formData);
    if (response) {
      // console.log(response);
      dispatch({
        type: HOMEPAGE_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: HOMEPAGE_ADD_FAIL, payload: err });
  }
};



export const HOMEPAGEGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_HOMEPAGES });
    let { data: response } = await getHomePage(formData);
    if (response) {
      // console.log(response?.data,'homepage2323-=4');
      dispatch({
        type: GET_ALL_HOMEPAGES_SUCCESS,
        payload: { data: response?.data, message: response?.message ,
          
        },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: HOMEPAGE_ADD_FAIL, payload: err });
  }
};

export const SetHOMEPAGEObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_HOMEPAGE_OBJ });
    if (formData) {
      dispatch({
        type: SET_HOMEPAGE_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_HOMEPAGE_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: SET_HOMEPAGE_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const HOMEPAGEUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_HOMEPAGE_BY_ID });
    let { data: response } = await updateHomePage(formData, id);
    if (response) {
      // console.log(response);
      dispatch({
        type: UPDATE_HOMEPAGE_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(HOMEPAGEGet())
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_HOMEPAGE_BY_ID_FAIL, payload: err });
  }
};

export const HOMEPAGEDelete = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_HOMEPAGE_BY_ID });
    let { data: response } = await deleteHomePage(formData, id);
    if (response) {
      // console.log(response, "response");
      dispatch({
        type: DELETE_HOMEPAGE_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(HOMEPAGEGet())
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_HOMEPAGE_BY_ID_FAIL, payload: err });
  }
};
