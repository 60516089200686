import {

  // addBuyNgetN
  // get
  // deleteById
  // updateById
  // getAllBySellerId

  addBuyNgetN,
  deleteById,
  get,
  updateById,
} from "../../../services/BuyNgetN.service";

export const BUYNANDGETN_ADD = "BUYNANDGETN_ADD";
export const BUYNANDGETN_ADD_SUCCESS = "BUYNANDGETN_ADD_SUCCESS";
export const BUYNANDGETN_ADD_FAIL = "BUYNANDGETN_ADD_FAIL";

export const GET_ALL_BUYNANDGETNS = "GET_ALL_BUYNANDGETNS";
export const GET_ALL_BUYNANDGETNS_SUCCESS =
  "GET_ALL_BUYNANDGETNS_SUCCESS";
export const GET_ALL_BUYNANDGETNS_FAIL = "GET_ALL_BUYNANDGETNS_FAIL";

export const UPDATE_BUYNANDGETN_BY_ID = "UPDATE_BUYNANDGETN_BY_ID";
export const UPDATE_BUYNANDGETN_BY_ID_SUCCESS =
  "UPDATE_BUYNANDGETN_BY_ID_SUCCESS";
export const UPDATE_BUYNANDGETN_BY_ID_FAIL =
  "UPDATE_BUYNANDGETN_BY_ID_FAIL";

export const SET_BUYNANDGETN_OBJ = "SET_BUYNANDGETN_OBJ";
export const SET_BUYNANDGETN_OBJ_SUCCESS = "SET_BUYNANDGETN_OBJ_SUCCESS";
export const SET_BUYNANDGETN_OBJ_FAIL = "SET_BUYNANDGETN_OBJ_FAIL";

export const GET_BUYNANDGETN_BY_ID = "GET_BUYNANDGETN_BY_ID";
export const GET_BUYNANDGETN_BY_ID_SUCCESS =
  "GET_BUYNANDGETN_BY_ID_SUCCESS";
export const GET_BUYNANDGETN_BY_ID_FAIL = "GET_BUYNANDGETN_BY_ID_FAIL";

export const DELETE_BUYNANDGETN_BY_ID = "DELETE_BUYNANDGETN_BY_ID";
export const DELETE_BUYNANDGETN_BY_ID_SUCCESS =
  "DELETE_BUYNANDGETN_BY_ID_SUCCESS";
export const DELETE_BUYNANDGETN_BY_ID_FAIL =
  "DELETE_BUYNANDGETN_BY_ID_FAIL";

export const BUYNANDGETNGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BUYNANDGETNS });
    let { data: response } = await get(formData);
    // console.log(response, "action data");
    if (response) {
      dispatch({
        type: GET_ALL_BUYNANDGETNS_SUCCESS,
        payload: { data: response.data,
           message: response.message,
          buyandGetPaginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
         },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: BUYNANDGETN_ADD_FAIL, payload: err });
  }
};

export const BUYNANDGETNADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: BUYNANDGETN_ADD });
    let { data: response } = await addBuyNgetN(formData);
    if (response) {
      dispatch({
        type: BUYNANDGETN_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(BUYNANDGETNGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: BUYNANDGETN_ADD_FAIL, payload: err });
  }
};

export const SETBUYNANDGETNOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_BUYNANDGETN_OBJ });
    if (formData) {
      dispatch({
        type: SET_BUYNANDGETN_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_BUYNANDGETN_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_BUYNANDGETN_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const BUYNANDGETNUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BUYNANDGETN_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateById(id, formData);
    if (response) {
      // console.log(response,"updatein action ");
      dispatch({
        type: UPDATE_BUYNANDGETN_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(BUYNANDGETNGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_BUYNANDGETN_BY_ID_FAIL, payload: err });
  }
};

export const BUYNANDGETNDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BUYNANDGETN_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_BUYNANDGETN_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(BUYNANDGETNGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_BUYNANDGETN_BY_ID_FAIL, payload: err });
  }
};
