import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/coupon";

export const addCoupon= (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getCoupons = (query) => {
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteCoupon = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};
export const updateCoupon = ( id,formData) => {
  // console.log(id,"idddd",formData,"formdata")
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
