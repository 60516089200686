import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";

import {
  FAQGet,
  SetFaqObj,
  FaqDelete,
} from "../../../redux/actions/Faq/Faq.action";

import { generateFilePath } from "../../Utility/utils";
import { getFaq } from "../../../services/Faq.service";
import { toastError } from "../../Utility/ToastUtils";

function Faq() {
  const dispatch = useDispatch();

  const faqArr = useSelector((state) => state.faq.faqs);
  const [faqArray, setFaqArray] = useState([]);
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const faqPaginationObject = useSelector(
    (state) => state.faq.faqPaginationObj
  );

  const handleGet = async () => {
    try{

      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
    // console.log(queryOf,"queryOf22323")
    // dispatch(FAQGet(queryOf));
    let { data: response } = await getFaq(queryOf);
    if (response) {
      setFaqArray(response?.data);
      setTotalPages(response?.totalPages);
      setTotalCount(response?.totalCount);
      setPage(response?.page);
    }
    }catch(err){
      toastError(err)
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handleEdit = (row) => {
    dispatch(SetFaqObj(row));
  };

  const handlefaqDelete = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(FaqDelete(id,query));
    }
  };

  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      // width: "1%",
      width: "50px",
    },
    {
      name: "Question",
      selector: (row) => row?.heading,
      // width: "10%",
      width: "450px",
    },
    {
      name: "Answer",
      selector: (row) => row?.description,
      // width: "10%",
      width: "450px",
    },
    {
      name: "category Name",
      selector: (row) => row?.categoryObj?.name,
      // width: "10%",
      width: "200px",
    },
    {
      name: "Action",
      // width: "10%",
      width: "100px",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/Faq/Faq-Create"
          onDeleteClick={() => handlefaqDelete(row._id)}
          deletePath="/Faq"
          remove
          Uniquekey={row._id}
        />
      ),
    },
  ];

  useEffect(() => {
    // console.log(faqArr,"faqArr")
    if (faqArr && faqArr.length >= 0) {
      setFaqArray(faqArr);
    }
    if (faqPaginationObject) {
      setTotalPages(faqPaginationObject?.totalPages);
      setTotalCount(faqPaginationObject?.totalCount);
      setPage(faqPaginationObject?.page);
    }
  }, [faqArr, faqPaginationObject]);

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Faq List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW FAQ"
                    path="/Faq/Faq-Create"
                    small
                    roundedPill
                  />
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={brand_columns}
                  data={faqArray && faqArray.length > 0 ? faqArray : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Faq;
