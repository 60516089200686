import axios from "axios" ;
import { axiosApiInstance } from "../App" ;
import { url } from "./url.service" ;

const serverUrl = url + "/MediaManager" ;


export const add = (formData) => {
  // console.log(formData,"dauormadata")
  return axiosApiInstance.post(serverUrl + "/", formData);
};


export const getData = (query) => {
  // console.log(query,"1query")
  return axiosApiInstance.get(`${serverUrl}/get?${query}`);
};


export const getObjById = (query) => {
  // console.log(query,"1query")
  return axiosApiInstance.get(`${serverUrl}/getById?id=${query}`);
};


export const deleteById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};


export const updateById = ( id,formData) => {   
         
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};


export const getAllBySellerId = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getAllBySellerId/${id}`);
};
