import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import PendingOrder from "../Order/TotalOrder/PendingOrder";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { getAllOrders } from "../../services/order.service";
import { ORDER_STATUS } from "../Utility/constants";
import moment from "moment/moment";
import { useParams } from "react-router-dom";


function UserOrders() {

    const [orders, setOrders] = useState([]);
    const [search, setSearch] = useState("")
    const [status, setStatus] = useState("Order")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    let {userId} = useParams();

    const getOrder = async () => {
        try {
            let query = ''
            if(status){
                query += `status=${status}`;
            }
            if(search){
                query += `&q=${search}`;
            }
            
            if(startDate){
                query += `&startDate=${startDate}`;
            }
            if(endDate){
                query += `&endDate=${endDate}`;
            }

            if(endDate){
                query += `&endDate=${endDate}`;
            }

            if(userId){
                query += `&userId=${userId}`;
            }


            const { data: res } = await getAllOrders(query);
            if (res) {
                setOrders(res.data.filter((el) => el.status != "CANCELLED"));
            }
        } catch (error) {
            console.error(error);
        }
    };

    
    useEffect(() => {
        getOrder();
    }, [userId]);
    useEffect(() => {
        getOrder();
    }, [search,status,startDate,endDate]);


    const PendingOrder_columns = [
        {
            name: "SL",
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: "Date",
            selector: (row) => new Date(row?.createdAt).toDateString(),
        },
        {
            name: "Order ID",
            selector: (row) => row?._id,
        },
        {
            name: "Phone",
            selector: (row) => row?.addressObj?.phone,
        },

        {
            name: "Total Amount",
            selector: (row) => row.totalAmount,
        },
        {
            name: "Order Status",
            button: true,
            width: "10%",
            cell: (row) => <CustomButton redBtn={row?.orderStatus == "CANCELLED"} greenBtn={row?.orderStatus != "CANCELLED"} btnName={row?.orderStatus} />,
        },
        {
            name: "Is Paid",
            button: true,
            width: "10%",
            cell: (row) => <CustomButton redBtn={row?.paymentObj?.paymentChk != 1} greenBtn={row?.paymentObj?.paymentChk == 1} btnName={row?.paymentObj?.paymentChk == 1 ? "PAID" : "PENDING"} />,
        },
        {
            name: "Action",
            cell: (row) => <CustomButton btnName={"View"} path={`/Order/Sale-Detail?orderId=${row?._id}`} isLink />,
        },
    ];

    return (
        <main>
            <section className="total-order" style={{ minHeight: "75vh" }}>
                <div className="container-fluid p-0">
                    <div className="row">
                        {/* <div className="col-12">
                            <div className="dashboard-box">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0">Orders</h5>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="d-flex gap-3 justify-content-between mb-4">
                                            <div className="w-100">
                                                <label>
                                                    Status
                                                </label>
                                                <select className="form-control"   value={status} onChange={(e) => setStatus(e.target.value)} >
                                                    <option value="Order"> All Orders</option>
                                                    {
                                                        Object.values(ORDER_STATUS).length > 0 && Object.values(ORDER_STATUS).map(el => <option>{el}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div className="w-100 d-flex gap-3 justify-content-between">
                                                <div className="w-100">
                                                    <label>
                                                        Start Date
                                                    </label>
                                                    <input type="date" className="form-control" value={moment(startDate).format('YYYY-MM-DD')} onChange={(e) => setStartDate(e.target.value)} />
                                                </div>
                                                <div className="w-100">
                                                    <label>
                                                        End Date
                                                    </label>
                                                    <input type="date" className="form-control"  value={moment(endDate).format('YYYY-MM-DD')} onChange={(e) => setEndDate(e.target.value)}/>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12" >
                            <DashboardTable className="mt-4">
                                <div className="d-flex gap-3 justify-content-between mb-4">
                                    <h5 className="blue-1 m-0"> User Orders</h5>
                                    <div className="search-field">
                                        <form action="#" className="form">
                                            <div
                                                className= "input-group"
                                            >
                                                <div className="input-group-text">
                                                    <i className="ion-ios-search-strong blue-1"></i>
                                                </div>
                                                <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} className="form-control" placeholder="Search" />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <DataTable columns={PendingOrder_columns} data={orders} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}

export default UserOrders;
