import { axiosApiInstance } from "../App";
import { url } from "./url.service";
// const serverUrl = url + "/productInternalOrder";
const serverUrlOrder = url + "/internalOrder";

// export const add = async (formData) => {
//   console.log(formData,"formData123")
//   return await axiosApiInstance.post(`${serverUrlOrder}/add`, formData);
// };

const serverUrl = url + "/internalOrder" ;


export const add = (formData) => {
  return axiosApiInstance.post(serverUrl + "/add", formData);
};

// export const add = (formData) => {
//   return axiosApiInstance.post(serverUrl + "/", formData);
// };

export const get = (id) => {
  return axiosApiInstance.get(`${serverUrlOrder}/get?`);
};

export const deleteById = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateById = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};


//==========================================================================

export const markOrderAsDelivered = (id) => {
  return axiosApiInstance.patch(`${serverUrl}/markOrderAsDelivered/${id}`);
};

export const cancelOrderById = (id) => {
  return axiosApiInstance.patch(`${serverUrl}/cancelOrderById/${id}`);
};
export const getInvoiceById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getInvoiceById/${id}`);
};
export const getById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};
