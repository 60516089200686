import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as HOMEPAGE from "../../actions/HomePage/HomePage.actions";

const initialState = {
  homePages: null,
  homePageObj: null,
  loading: false,
  error: null,
  homePagePaginationObj:null,
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOMEPAGE.HOMEPAGE_ADD:
      return {
        ...state,
        loading: true,
      };
    case HOMEPAGE.HOMEPAGE_ADD_SUCCESS:
      //   console.log(act);
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case HOMEPAGE.HOMEPAGE_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case HOMEPAGE.GET_ALL_HOMEPAGES:
      return {
        ...state,
        loading: true,
      };
    case HOMEPAGE.GET_ALL_HOMEPAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        homePages: action.payload.data,
        homePagePaginationObj:action.payload.paginationObj
      };
    case HOMEPAGE.GET_ALL_HOMEPAGES_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case HOMEPAGE.UPDATE_HOMEPAGE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case HOMEPAGE.UPDATE_HOMEPAGE_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case HOMEPAGE.UPDATE_HOMEPAGE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case HOMEPAGE.DELETE_HOMEPAGE_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case HOMEPAGE.DELETE_HOMEPAGE_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case HOMEPAGE.DELETE_HOMEPAGE_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case HOMEPAGE.SET_HOMEPAGE_OBJ:
      return {
        ...state,
        loading: true,
      };
    case HOMEPAGE.SET_HOMEPAGE_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        homePageObj: action.payload.data,
        loading: false,
        error: null,
      };
    case HOMEPAGE.SET_HOMEPAGE_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
