import { 
  // add
// get
// deleteById
// updateById
// markOrderAsDelivered
// cancelOrderById
// getInvoiceById
// getById
  
  add, deleteById, get, updateById } from "../../../services/InternalOrder.services";

export const INTERNALORDER_ADD = "INTERNALORDER_ADD";
export const INTERNALORDER_ADD_SUCCESS = "INTERNALORDER_ADD_SUCCESS";
export const INTERNALORDER_ADD_FAIL = "INTERNALORDER_ADD_FAIL";

export const GET_ALL_INTERNALORDERS = "GET_ALL_INTERNALORDERS";
export const GET_ALL_INTERNALORDERS_SUCCESS = "GET_ALL_INTERNALORDERS_SUCCESS";
export const GET_ALL_INTERNALORDERS_FAIL = "GET_ALL_INTERNALORDERS_FAIL";

export const UPDATE_INTERNALORDER_BY_ID = "UPDATE_INTERNALORDER_BY_ID";
export const UPDATE_INTERNALORDER_BY_ID_SUCCESS = "UPDATE_INTERNALORDER_BY_ID_SUCCESS";
export const UPDATE_INTERNALORDER_BY_ID_FAIL = "UPDATE_INTERNALORDER_BY_ID_FAIL";

export const SET_INTERNALORDER_OBJ = "SET_INTERNALORDER_OBJ";
export const SET_INTERNALORDER_OBJ_SUCCESS = "SET_INTERNALORDER_OBJ_SUCCESS";
export const SET_INTERNALORDER_OBJ_FAIL = "SET_INTERNALORDER_OBJ_FAIL";

export const GET_INTERNALORDER_BY_ID = "GET_INTERNALORDER_BY_ID";
export const GET_INTERNALORDER_BY_ID_SUCCESS = "GET_INTERNALORDER_BY_ID_SUCCESS";
export const GET_INTERNALORDER_BY_ID_FAIL = "GET_INTERNALORDER_BY_ID_FAIL";

export const DELETE_INTERNALORDER_BY_ID = "DELETE_INTERNALORDER_BY_ID";
export const DELETE_INTERNALORDER_BY_ID_SUCCESS = "DELETE_INTERNALORDER_BY_ID_SUCCESS";
export const DELETE_INTERNALORDER_BY_ID_FAIL = "DELETE_INTERNALORDER_BY_ID_FAIL";



export const INTERNALORDERGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_INTERNALORDERS });
    let { data: response } = await get(formData);
    // console.log(response, "response356")
    if (response) {

      dispatch({
        type: GET_ALL_INTERNALORDERS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: INTERNALORDER_ADD_FAIL, payload: err });
  }
};

export const INTERNALORDERADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: INTERNALORDER_ADD });
    let { data: response } = await add(formData);
    if (response) {
      dispatch(INTERNALORDERGET())
      dispatch({
        type: INTERNALORDER_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: INTERNALORDER_ADD_FAIL, payload: err });
  }
};



export const SETINTERNALORDEROBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_INTERNALORDER_OBJ });
    if (formData) {
      dispatch({
        type: SET_INTERNALORDER_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_INTERNALORDER_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_INTERNALORDER_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const INTERNALORDERUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_INTERNALORDER_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateById(id, formData);
    if (response) {
      // console.log(response);
      dispatch({
        type: UPDATE_INTERNALORDER_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(INTERNALORDERGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_INTERNALORDER_BY_ID_FAIL, payload: err });
  }
};

export const INTERNALORDERDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_INTERNALORDER_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response");
      dispatch({
        type: DELETE_INTERNALORDER_BY_ID_SUCCESS,
        payload: response
      });
      dispatch(INTERNALORDERGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_INTERNALORDER_BY_ID_FAIL, payload: err });
  }
};