import {
  addPrivacyPolicy,
  deletePrivacyPolicy,
  getPrivacyPolicy,
  updatePrivacyPolicy,
} from "../../../services/policy.service";

export const PRIVACYPOLICY_ADD = "PRIVACYPOLICY_ADD";
export const PRIVACYPOLICY_ADD_SUCCESS = "PRIVACYPOLICY_ADD_SUCCESS";
export const PRIVACYPOLICY_ADD_FAIL = "PRIVACYPOLICY_ADD_FAIL";

export const GET_ALL_PRIVACYPOLICY = "GET_ALL_PRIVACYPOLICYS";
export const GET_ALL_PRIVACYPOLICY_SUCCESS = "GET_ALL_PRIVACYPOLICYS_SUCCESS";
export const GET_ALL_PRIVACYPOLICY_FAIL = "GET_ALL_PRIVACYPOLICYS_FAIL";

export const UPDATE_PRIVACYPOLICY_BY_ID = "UPDATE_PRIVACYPOLICY_BY_ID";
export const UPDATE_PRIVACYPOLICY_BY_ID_SUCCESS =
  "UPDATE_PRIVACYPOLICY_BY_ID_SUCCESS";
export const UPDATE_PRIVACYPOLICY_BY_ID_FAIL =
  "UPDATE_PRIVACYPOLICY_BY_ID_FAIL";

export const SET_PRIVACYPOLICY_OBJ = "SET_PRIVACYPOLICY_OBJ";
export const SET_PRIVACYPOLICY_OBJ_SUCCESS = "SET_PRIVACYPOLICY_OBJ_SUCCESS";
export const SET_PRIVACYPOLICY_OBJ_FAIL = "SET_PRIVACYPOLICY_OBJ_FAIL";

export const GET_PRIVACYPOLICY_BY_ID = "GET_PRIVACYPOLICY_BY_ID";
export const GET_PRIVACYPOLICY_BY_ID_SUCCESS =
  "GET_PRIVACYPOLICY_BY_ID_SUCCESS";
export const GET_PRIVACYPOLICY_BY_ID_FAIL = "GET_PRIVACYPOLICY_BY_ID_FAIL";

export const DELETE_PRIVACYPOLICY_BY_ID = "DELETE_PRIVACYPOLICY_BY_ID";
export const DELETE_PRIVACYPOLICY_BY_ID_SUCCESS =
  "DELETE_PRIVACYPOLICY_BY_ID_SUCCESS";
export const DELETE_PRIVACYPOLICY_BY_ID_FAIL =
  "DELETE_PRIVACYPOLICY_BY_ID_FAIL";

export const privacyPolicyAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: PRIVACYPOLICY_ADD });
    // console.log(formData, "form raction ");
    let { data: response } = await addPrivacyPolicy(formData);
    if (response) {
      // console.log(response, "response raction ");
      dispatch({
        type: PRIVACYPOLICY_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(PRIVACYPOLICYGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: PRIVACYPOLICY_ADD_FAIL, payload: err });
  }
};

export const PRIVACYPOLICYGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PRIVACYPOLICY });
    let { data: response } = await getPrivacyPolicy(formData);
    if (response) {
      // console.log(response, "action get  ");
      dispatch({
        type: GET_ALL_PRIVACYPOLICY_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_PRIVACYPOLICY_FAIL, payload: err });
  }
};

export const SetprivacyPolicyObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_PRIVACYPOLICY_OBJ });
    if (formData) {
      // console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_PRIVACYPOLICY_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_PRIVACYPOLICY_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_PRIVACYPOLICY_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const privacyPolicyUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRIVACYPOLICY_BY_ID });
    // console.log(formData, "formData", id, "id");
    let { data: response } = await updatePrivacyPolicy(formData, id);
    if (response) {
      // console.log(response, "");
      dispatch({
        type: UPDATE_PRIVACYPOLICY_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(PRIVACYPOLICYGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_PRIVACYPOLICY_BY_ID_FAIL, payload: err });
  }
};

export const PrivacyPolicyDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_PRIVACYPOLICY_BY_ID });
    let { data: response } = await deletePrivacyPolicy(id);
    if (response) {
      // console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_PRIVACYPOLICY_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(PRIVACYPOLICYGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_PRIVACYPOLICY_BY_ID_FAIL, payload: err });
  }
};
