import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../Utility/FileUpload";
import QuillEditor from "../../utils/QuillEditor";
import { useSelector, useDispatch } from "react-redux";
import {
  newsletterAdd,
 
  newsletterGet,
 
  newsletterUpdate,
  SetnewsletterObj,

} from "../../redux/actions/newsLetter/NewsLetter.action";
import { generateFilePath } from "../Utility/utils";
function AddEmailTemplate() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageStr, setimageStr] = useState([]);
  const [newsLetterPreviousId, setNewsLetterPreviousId] = useState("");
  const [imageArr, setImageArr] = useState([{ image: "", imageAlt: "" }]);
  const [isnewsletterUpdate, setisNewsletterUpdate] = useState(false);
  const newLetterObj = useSelector((state) => state.newsLetter.NewsLetterObj);
  const newsLettterArr = useSelector((state) => state.newsLetter.NewsLetterArr);


  useEffect(() => {
    dispatch(newsletterGet('type=competitionTemplate'));
  }, []);

  useEffect(() => {
    if(newsLettterArr?.length >0 ){
    dispatch(SetnewsletterObj(newsLettterArr[0]));

    }
  }, [newsLettterArr]);
 
  // console.log(description, "description4");
  useEffect(() => {
    if (newLetterObj) {
      setTitle(newLetterObj.title);
      setDescription(newLetterObj.description);
      setNewsLetterPreviousId(newLetterObj._id);
      setisNewsletterUpdate(true);
      setImageArr(newLetterObj?.imageArr)
    }
    return () => {
      dispatch(SetnewsletterObj(null));
    };
  }, [newLetterObj]);

  const handleImageObjAdd = () => {
    let tempArr = imageArr
    tempArr.push({ image: "", imageAlt: "" })
    // console.log(tempArr, "asdas")
    setImageArr([...tempArr])
  }
  const handleImageObjRemove = () => {
    if (imageArr.length > 1) {

      let tempArr = imageArr.filter((el, index) => index != imageArr.length - 1);
      setImageArr([...tempArr])
    }
  }

  const handleFileSet = (value, index) => {
    let tempArr = imageArr;
    tempArr[index].image = value
    setImageArr([...tempArr]);
  };
  const dispatch = useDispatch();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let obj = {
        title,
        description,
        type:"competitionTemplate",
        imageArr
      };

      if (isnewsletterUpdate) {
        dispatch(newsletterUpdate(obj, newsLetterPreviousId));
      } else {
        dispatch(newsletterAdd(obj));
      }
    } catch (error) {
      // toastError(error)
      console.error(error);
    }
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4">Add New Email</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Email/Newsletter</h5>
                    <div className="col-12 mb-3">
                      <label>
                        TITLE <span className="red">*</span>
                      </label>
                      <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-12 mb-3">
                      <label>
                        SLUG<span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" />
                    </div> */}
                    <div className="col-12">
                      <label>
                        DESCRIPTION<span className="red">*</span>
                      </label>
                      <textarea
                        class="form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                    <div className="col-12 mb-3">
                <label>
                  Product Image<span className="red">*</span>
                </label>
                <div className="row">
                <div className="col-2 me-5">
                  <CustomButton btntype="button" ClickEvent={handleImageObjAdd} isBtn noIcon btnName="+" />
                </div>
                <div className="col-2">
                  <CustomButton btntype="button" ClickEvent={handleImageObjRemove} isBtn noIcon btnName="-" />
                </div>
              </div>
                {imageArr && imageArr.length > 0 && imageArr.map((el, index) => {
                  return (
                    <div key={index} style={{ marginTop: 20, borderBottom: "grey 1px solid", paddingBottom: 15 }} className="row">
                      <div className="col-12">
                        <img src={`${el.image}`.includes("base64") ? el.image : generateFilePath(el.image)} style={{ height: 80 }} />
                       
                        <br />
                        <br />
                        <FileUpload onFileChange={(val) => handleFileSet(val, index)} />
                      </div>
                    
                    </div>
                  )
                })}
              </div>
                  </div>
                  <div className="col-12 mt-4">
                    <CustomButton
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </DashboardBox>
              </div>
              {/* <div className="col-12 col-md-4">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Basic Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        CATEGORY<span className="red">*</span>
                      </label>
                      <Select options={options} />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Tags (Comma Separated)<span className="red">*</span>
                      </label>
                      <Select options={options} isMulti />

                      <div className="form-text fs-12 blue-1">Suggested Tags</div>
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Image(1000x500)px<span className="red">*</span>
                      </label>
                      <FileUpload />
                    </div>
                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="category-status" value="option1" id="publish-checkbox" />
                        <label className="form-check-label fs-14" htmlFor="publish-checkbox">
                          Publish
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <CustomButton isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div> */}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddEmailTemplate;
