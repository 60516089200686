import React, { useState, useEffect } from "react";
// import ReactQuill from "react-quill";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { DashboardBox } from "../../Utility/DashboardBox";
// import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";

import {
  FaqAdd,
  SetFaqObj,
  FaqUpdate,
} from "../../../redux/actions/Faq/Faq.action";

import { toastError, toastSuccess } from "../../Utility/ToastUtils";
// import { addFaq } from "../../../services/Faq.service";
import {
  SETFAQCATEGORYOBJ,
  FAQCATEGORYDELETE,
  FAQCATEGORYGET,
} from "../../../redux/actions/FaqCategory/FaqCategory.actions";
import Select from "react-select";

function AddFaq() {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [isUpdateFaq, setIsUpdateFaq] = useState(false);
  const [faqId, setFaqId] = useState("");
  const dispatch = useDispatch();

  const faqObj = useSelector((state) => state.faq.faqObj);
  // const [categoryIds, setCategoryId] = useState({ value: "", label: "" });
  const [categoryIds, setCategoryId] = useState("");
  const faqCategoryArray = useSelector(
    (state) => state.faqCategory.faqCategorys
  );

  const [categoryArray, setCategoryArray] = useState([]);
  const [categoryObj, setCategoryObj] = useState({});

  const handleGet = () => {
    dispatch(FAQCATEGORYGET());
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    // console.log(faqObj,"faqObj234")
    if (faqObj && faqObj._id) {
      setDescription(faqObj.description);
      setCategoryName(faqObj.categoryName);
      setHeading(faqObj.heading);
      setFaqId(faqObj._id);
      setIsUpdateFaq(true);
      setCategoryObj({
        value: faqObj?.categoryObj?._id,
        label: faqObj?.categoryObj?.name,
      });
      setCategoryId(faqObj?.categoryObj?._id);

      handleGet();
    }
    handleGet();
    return () => {
      dispatch(SetFaqObj(null));
    };
  }, [faqObj]);

  const clearFunc = () => {
    setCategoryObj({});
    setHeading("");
    setDescription("");
    setIsUpdateFaq("");
    setFaqId("");
    setCategoryId("");
    handleGet();
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (heading == "") {
        toastError("Question is mandatory");
        return;
      } else if (description == "") {
        toastError("Answer is mandatory");
        return;
      } else if (categoryIds == "" ) {
        toastError("Category is mandatory");
        return;
      }
      let obj = {
        heading,
        description,
        categoryId: categoryIds,
      };
      let responseData = {};
      // console.log(obj, "get faq boject");
      if (isUpdateFaq) {
        responseData = await dispatch(FaqUpdate(obj, faqId));
      } else {
        responseData = await dispatch(FaqAdd(obj));
      }
      if (responseData && responseData.message) {
        clearFunc();
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    const options = [];
    if (faqCategoryArray && faqCategoryArray.length > 0) {
      for (let el of faqCategoryArray) {
        options.push({ value: el?._id, label: el?.name });
      }
    }
    setCategoryArray(options);
  }, [faqCategoryArray]);

  return (
    <main>
      <section className="product-categoryId">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">
            {isUpdateFaq ? "Update" : "Add New "} Faq
          </h5>

          <form action="#" className="form">
            <div className="row">
              <div className="col-12 col-md-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Faq Information</h5>
                    <div className="col-12">
                      <label>
                        Question <span className="red">*</span>
                      </label>
                      <input
                        value={heading}
                        onChange={(event) => setHeading(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>Answer</label>
                      <textarea
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        CATEGORY<span className="red">*</span>
                      </label>
                      <Select
                        options={categoryArray}
                        value={categoryObj}
                        // defaultInputValue={categoryId}
                        onChange={(e) => {
                          setCategoryId(e.value);
                          setCategoryObj(e);
                        }}
                      />
                    </div>
                    <div className="col-12 mt-2">
                      <CustomButton
                        btntype="button"
                        ClickEvent={handleSubmit}
                        isBtn
                        iconName="fa-solid fa-check"
                        btnName="Save"
                      />
                    </div>
                  </div>
                </DashboardBox>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddFaq;
