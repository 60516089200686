import {
  // getObjById
  // getAllBySellerId
  // add
  // getData
  // getObjById
  // deleteById
  // updateById
  // getAllBySellerId

  add,
  deleteById,
  getData,
  updateById,
} from "../../../services/flashSales.services";

export const FLASHSALES_ADD = "FLASHSALES_ADD";
export const FLASHSALES_ADD_SUCCESS = "FLASHSALES_ADD_SUCCESS";
export const FLASHSALES_ADD_FAIL = "FLASHSALES_ADD_FAIL";

export const GET_ALL_FLASHSALESS = "GET_ALL_FLASHSALESS";
export const GET_ALL_FLASHSALESS_SUCCESS =
  "GET_ALL_FLASHSALESS_SUCCESS";
export const GET_ALL_FLASHSALESS_FAIL = "GET_ALL_FLASHSALESS_FAIL";

export const UPDATE_FLASHSALES_BY_ID = "UPDATE_FLASHSALES_BY_ID";
export const UPDATE_FLASHSALES_BY_ID_SUCCESS =
  "UPDATE_FLASHSALES_BY_ID_SUCCESS";
export const UPDATE_FLASHSALES_BY_ID_FAIL =
  "UPDATE_FLASHSALES_BY_ID_FAIL";

export const SET_FLASHSALES_OBJ = "SET_FLASHSALES_OBJ";
export const SET_FLASHSALES_OBJ_SUCCESS = "SET_FLASHSALES_OBJ_SUCCESS";
export const SET_FLASHSALES_OBJ_FAIL = "SET_FLASHSALES_OBJ_FAIL";

export const GET_FLASHSALES_BY_ID = "GET_FLASHSALES_BY_ID";
export const GET_FLASHSALES_BY_ID_SUCCESS =
  "GET_FLASHSALES_BY_ID_SUCCESS";
export const GET_FLASHSALES_BY_ID_FAIL = "GET_FLASHSALES_BY_ID_FAIL";

export const DELETE_FLASHSALES_BY_ID = "DELETE_FLASHSALES_BY_ID";
export const DELETE_FLASHSALES_BY_ID_SUCCESS =
  "DELETE_FLASHSALES_BY_ID_SUCCESS";
export const DELETE_FLASHSALES_BY_ID_FAIL =
  "DELETE_FLASHSALES_BY_ID_FAIL";

export const FLASHSALESGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_FLASHSALESS });
    let { data: response } = await getData(formData);
    // console.log(response, "action data");
    if (response) {
      dispatch({
        type: GET_ALL_FLASHSALESS_SUCCESS,
        payload: { data: response.data, message: response.message 
       ,   flashPaginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: FLASHSALES_ADD_FAIL, payload: err });
  }
};

export const FLASHSALESADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: FLASHSALES_ADD });
    let { data: response } = await add(formData);
    if (response) {
      dispatch({
        type: FLASHSALES_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(FLASHSALESGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: FLASHSALES_ADD_FAIL, payload: err });
  }
};

export const SETFLASHSALESOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_FLASHSALES_OBJ });
    if (formData) {
      dispatch({
        type: SET_FLASHSALES_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_FLASHSALES_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_FLASHSALES_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const FLASHSALESUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_FLASHSALES_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateById(id, formData);
    if (response) {
      // console.log(response,"updatein action ");
      dispatch({
        type: UPDATE_FLASHSALES_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(FLASHSALESGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_FLASHSALES_BY_ID_FAIL, payload: err });
  }
};

export const FLASHSALESDELETE = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FLASHSALES_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_FLASHSALES_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(FLASHSALESGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_FLASHSALES_BY_ID_FAIL, payload: err });
  }
};
