
import {
  AddReviewApi,
  deleteProductById,
  getAllRelatedProducts,
  getProductReview,
  upadteReviewStatus,
  // getProductReview
// upadteReviewStatus
// AddReviewApi
} from "../../../services/ProductReview.service";

export const PRODUCTREVIEW_ADD = "PRODUCTREVIEW_ADD";
export const PRODUCTREVIEW_ADD_SUCCESS = "PRODUCTREVIEW_ADD_SUCCESS";
export const PRODUCTREVIEW_ADD_FAIL = "PRODUCTREVIEW_ADD_FAIL";

export const GET_ALL_PRODUCTREVIEWS = "GET_ALL_PRODUCTREVIEWS";
export const GET_ALL_PRODUCTREVIEWS_SUCCESS = "GET_ALL_PRODUCTREVIEWS_SUCCESS";
export const GET_ALL_PRODUCTREVIEWS_FAIL = "GET_ALL_PRODUCTREVIEWS_FAIL";

export const UPDATE_PRODUCTREVIEW_BY_ID = "UPDATE_PRODUCTREVIEW_BY_ID";
export const UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS = "UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS";
export const UPDATE_PRODUCTREVIEW_BY_ID_FAIL = "UPDATE_PRODUCTREVIEW_BY_ID_FAIL";

export const SET_PRODUCTREVIEW_OBJ = "SET_PRODUCTREVIEW_OBJ";
export const SET_PRODUCTREVIEW_OBJ_SUCCESS = "SET_PRODUCTREVIEW_OBJ_SUCCESS";
export const SET_PRODUCTREVIEW_OBJ_FAIL = "SET_PRODUCTREVIEW_OBJ_FAIL";

export const GET_PRODUCTREVIEW_BY_ID = "GET_PRODUCTREVIEW_BY_ID";
export const GET_PRODUCTREVIEW_BY_ID_SUCCESS = "GET_PRODUCTREVIEW_BY_ID_SUCCESS";
export const GET_PRODUCTREVIEW_BY_ID_FAIL = "GET_PRODUCTREVIEW_BY_ID_FAIL";

export const DELETE_PRODUCTREVIEW_BY_ID = "DELETE_PRODUCTREVIEW_BY_ID";
export const DELETE_PRODUCTREVIEW_BY_ID_SUCCESS = "DELETE_PRODUCTREVIEW_BY_ID_SUCCESS";
export const DELETE_PRODUCTREVIEW_BY_ID_FAIL = "DELETE_PRODUCTREVIEW_BY_ID_FAIL";

export const GET_RELATED_PRODUCTREVIEWS = "GET_RELATED_PRODUCTREVIEWS";
export const GET_RELATED_PRODUCTREVIEWS_SUCCESS = "GET_RELATED_PRODUCTREVIEWS_SUCCESS";
export const GET_RELATED_PRODUCTREVIEWS_FAIL = "GET_RELATED_PRODUCTREVIEWS_FAIL";
// export const GET_ALL_NESTED_CATEGORIES = "GET_ALL_NESTED_CATEGORIES";
// export const GET_ALL_NESTED_CATEGORIES_SUCCESS = "GET_ALL_NESTED_CATEGORIES_SUCCESS";
// export const GET_ALL_NESTED_CATEGORIES_FAIL = "GET_ALL_NESTED_CATEGORIES_FAIL";


export const PRODUCTREVIEWAdd = (formData) => async (dispatch) => {
  try {
    // console.log(formData, "formData");
    dispatch({ type: PRODUCTREVIEW_ADD });
    let { data: response } = await AddReviewApi(formData);
    // // console.log(response, "response");
    if (response) {
      // // console.log(response);
      dispatch({
        type: PRODUCTREVIEW_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: PRODUCTREVIEW_ADD_FAIL, payload: err });
  }
};


export const PRODUCTREVIEWGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_PRODUCTREVIEWS });
    let { data: response } = await getProductReview(formData);
    if (response) {
      // // console.log(response);
      dispatch({
        type: GET_ALL_PRODUCTREVIEWS_SUCCESS,
        payload: {
          data: response.data,
          message: response.message,
          paginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: PRODUCTREVIEW_ADD_FAIL, payload: err });
  }
};

export const SetPRODUCTREVIEWObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_PRODUCTREVIEW_OBJ });
    if (formData) {
      dispatch({
        type: SET_PRODUCTREVIEW_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_PRODUCTREVIEW_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_PRODUCTREVIEW_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const PRODUCTREVIEWUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PRODUCTREVIEW_BY_ID });
    let { data: response } = await upadteReviewStatus(formData, id);
    if (response) {
      // console.log(response, "response");
      dispatch({
        type: UPDATE_PRODUCTREVIEW_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(PRODUCTREVIEWGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_PRODUCTREVIEW_BY_ID_FAIL, payload: err });
  }
};

// export const PRODUCTDREVIEWelete = (id) => async (dispatch) => {
//   try {
//     dispatch({ type: DELETE_PRODUCTREVIEW_BY_ID });
//     let { data: response } = await deleteProductById(id);
//     if (response) {
//       // console.log(response);
//       dispatch({
//         type: DELETE_PRODUCTREVIEW_BY_ID_SUCCESS,
//         payload: { message: response.message, deletedProductREVIEW: id },
//       });
//       dispatch(PRODUCTREVIEWGet());
//     }
//   } catch (err) {
//     console.error(err);
//     dispatch({ type: DELETE_PRODUCTREVIEW_BY_ID_FAIL, payload: err });
//   }
// };

