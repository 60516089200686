import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/states";

export const addStates = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getStates = (query) => {
    return axiosApiInstance.get(`${serverUrl}/getStates?${query}`);
};

export const deleteState = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateState = ( id,formData) => {
    // console.log(formData,"firmadata",id,"idddddd")
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

