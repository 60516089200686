import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";
import * as TEAM from "../../actions/Team/Team.actions";

const initialState = {
  teams: null,
  teamObj: null,
  loading: false,
  error: null,
  teamPaginationObject: null,
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEAM.TEAM_ADD:
      return {
        ...state,
        loading: true,
      };
    case TEAM.TEAM_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case TEAM.TEAM_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TEAM.GET_ALL_TEAMS:
      return {
        ...state,
        loading: true,
      };
    case TEAM.GET_ALL_TEAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        teams: action.payload.data,
        teamPaginationObject: action.payload.paginationObj,
      };
    case TEAM.GET_ALL_TEAMS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TEAM.DELETE_TEAM_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case TEAM.DELETE_TEAM_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TEAM.DELETE_TEAM_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TEAM.UPDATE_TEAM_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case TEAM.UPDATE_TEAM_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case TEAM.UPDATE_TEAM_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case TEAM.SET_TEAM_OBJ:
      return {
        ...state,
        loading: true,
      };
    case TEAM.SET_TEAM_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        teamObj: action?.payload?.data,
        loading: false,
        error: null,
        teamPaginationObject: null,





      };
    case TEAM.SET_TEAM_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TEAM.UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        teams: action.payload.data,
        teamPaginationObject: null,
      };

    default:
      return state;
  }
};
