import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import {
  SETSTATEOBJ,
  STATEDELETE as areaDELETE,
  STATEGET as areaGET,
} from "../../redux/actions/State/States.actions";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import AddTeam from "./AddTeam";
import {
  SETTEAMOBJ,
  TEAMDELETE,
  TEAMGET,setPaginationState
} from "../../redux/actions/Team/Team.actions";
import { generateFilePath } from "../Utility/utils";
import { getTeams } from "../../services/team.service";
import { toastError } from "../Utility/ToastUtils";

function ViewTeam() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const teamArr = useSelector((state) => state.team.teams);
  const teamObj = useSelector((state) => state.team.teamObj);
  const [ModalData, setModalData] = useState({});
  const [displayTeamArr, setDisplayTeamArr] = useState([]);
  const [query, setQuery] = useState("");
  const [teamMainArr, setTeamMainArr] = useState([]);
  
  const teamPaginationObject = useSelector(
    (state) => state.team.teamPaginationObject
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handlePageChange = (page) => {
    setPage(page);
  };
  
  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      //   dispatch(TEAMGET(queryOf));

      let { data: get } = await getTeams(queryOf);
      // console.log(get, "get teams");
      if (get && get?.success) {
        setTeamMainArr(get?.data);
        setDisplayTeamArr(get?.data);
      }
      setPage(get?.page ? get?.page : 1);
      setPerPage(get?.perPage ? get?.perPage : 10);
      setTotalCount(get?.totalCount);
      setTotalPages(get?.totalPages);
    } catch (err) {
      toastError(err);
    }
  };
  
    const handleCategoryEdit = (row) => {
      let queryOf = `page=${page}&perPage=${perPage}`;
      row.query=queryOf
      dispatch(SETTEAMOBJ(row));
    };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let queryOf = `page=${page}&perPage=${perPage}`;
      dispatch(TEAMDELETE(row._id,queryOf));
    }
  };

  useEffect(() => {
    if (teamArr && teamArr?.length >= 0) {
      setTeamMainArr(teamArr);
      setDisplayTeamArr(teamArr);
    }
   
  }, [teamArr]);


  useEffect(() => {
    if (teamPaginationObject) {
      setPage(teamPaginationObject?.page);
      setPerPage(teamPaginationObject?.perPage);
      setTotalCount(teamPaginationObject?.totalCount);
      setTotalPages(teamPaginationObject?.totalPages);
    }
    return()=>{
      dispatch(setPaginationState(null))
    } 
  }, [ teamPaginationObject]);


  

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Description",
      maxWidth: "211px",
      selector: (row) => <p> {row?.description}</p>,
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img
          height="84px"
          width="56px"
          alt={row.name}
          src={generateFilePath(row?.image)}
        />
      ),
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <CustomButton
          greenBtn
          noIcon
          btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`}
          path={"/Team"}
        />
      ),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Team"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Team"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0">
                  {teamObj && teamObj.name ? "Edit " : "Add "} Team
                </h5> */}
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddTeam />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Team List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    teamMainArr && teamMainArr.length > 0 ? teamMainArr : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewTeam;
