import React, { useState, useEffect } from "react";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import DataTable from "react-data-table-component";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import { generalModelStatuses } from "../../Utility/constants";
import {
  ATTRIBUTE_VALUEDelete,
  ATTRIBUTE_VALUEUpdate,
  ATTRIBUTE_VALUE_Add,
  ATTRIBUTE_VALUE_Get,
  SetATTRIBUTE_VALUEObj,
  setAttributeValueStatus
} from "../../../redux/actions/Attribute/Attribute.actions";
import { toastError } from "../../Utility/ToastUtils";

function AttributeValue() {
  const [name, setName] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);

  const attributeValueArr = useSelector(
    (state) => state.attribute.attributeValues
  );
  const attributeValueObj = useSelector(
    (state) => state.attribute.attributeValueObj
  );
  const attributeValuePaginationObj = useSelector(
    (state) => state.attribute.attributeValuePaginationObj
  );

 
  const AttributeAddOrUpdateSucces = useSelector(
    (state) => state.attribute.attributeAddOrUpdateSuccessful
  );

  const [selectedAttributeId, setSelectedAttributeId] = useState("");
  const [attributeValueObject, SetAttributeValueObject] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [query, setQuery] = useState("");
  const dispatch = useDispatch();

  const [attributeValueArray, setAttributeValueArray] = useState([]);
  const [mainArray, setMainArray] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  const clearFunc = () => {
    try {
      // dispatch(SetATTRIBUTE_VALUEObj(null));
      SetAttributeValueObject({});
      setName("");
      setSelectedAttributeId("");
      setIsUpdate(false);
      setStatus("");
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
    // console.log(AttributeAddOrUpdateSucces, "==AttributeAddOrUpdateSuccess==");
    if (AttributeAddOrUpdateSucces&&AttributeAddOrUpdateSucces?.message) {
      clearFunc();
      // console.log("insode-------------------")
    }
    return ()=>{
      dispatch(setAttributeValueStatus(null))
    }
  }, [AttributeAddOrUpdateSucces]);


  const handlePageChange = (page) => {
    setPage(page);
  };

  const fetchData = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      dispatch(ATTRIBUTE_VALUE_Get(queryOf));
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
    fetchData();
  }, [page, perPage, query]);


  useEffect(() => {
    SetAttributeValueObject(attributeValueObj);
  }, [attributeValueObj]);


  useEffect(() => {
    if (attributeValueArr && attributeValueArr.length >= 0) {
      setAttributeValueArray(attributeValueArr);
      setMainArray(attributeValueArr);
    }
  
  }, [attributeValueArr, ]);

  useEffect(() => {
  
    if (attributeValuePaginationObj) {
      setTotalPages(attributeValuePaginationObj?.totalPages);
      setTotalCount(attributeValuePaginationObj?.totalCount);
      setPage(attributeValuePaginationObj?.page);
    }
  }, [ attributeValuePaginationObj]);



  const handleSubmit = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      let obj = {
        name,
        status,
      };
      if (isUpdate) {
         let queryOf = `page=${page}&perPage=${perPage}`;
        dispatch(ATTRIBUTE_VALUEUpdate(obj, selectedAttributeId,queryOf));
      } else {
        dispatch(ATTRIBUTE_VALUE_Add(obj));
      }
    } catch (err) {
      // console.log(err, "get errri");
      toastError(err);
    }
  };

  const handleEdit = (row) => {
    dispatch(SetATTRIBUTE_VALUEObj(row));
  };

  const handleDeleteById = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(ATTRIBUTE_VALUEDelete(id));
    }
  };

  useEffect(() => {
    if (attributeValueObject && attributeValueObject._id) {
      setName(attributeValueObject?.name);
      setStatus(attributeValueObject?.status);
      setSelectedAttributeId(attributeValueObject?._id);
      setIsUpdate(true);
    }
    return () => {
      dispatch(SetATTRIBUTE_VALUEObj(null));
    };
  }, [attributeValueObject]);

  const product_sale_columns = [
    {
      name: "SL",
      cell: (row, index) => index + 1,
      sortable: false,
    },
    {
      name: "ATTRIBUTE NAME",
      selector: (row) => row?.name,
    },

    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row.id}
          remove
          edit
          deletePath="/Product/Attribute-Value"
          onDeleteClick={() => handleDeleteById(row._id)}
          isRedirected={true}
          onEditClick={() => handleEdit(row)}
          editPath="/Product/Attribute-Value"
        />
      ),
    },
  ];


  //   ====================================================================


  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row gy-4 gy-xxl-0">
            <div className="col-12 col-xxl-4">
              <h5 className="blue-1 mb-4">Attribute Value</h5>
              <DashboardBox>
                <form action="#" className="form row">
                  <div className="col-12">
                    <label className="blue-1 fs-12">
                      Name <span className="red">*</span>
                    </label>
                    <input
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      type="text"
                      className="form-control"
                    />
                  </div>
                  {/* <div className="col-12">
                    <label className="blue-1 fs-12">Status</label>
                    <div className="d-flex">
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="category-status"
                          value={status}
                          checked={status == generalModelStatuses.APPROVED}
                          onClick={() => setStatus(generalModelStatuses.APPROVED)}
                          id="category-Radio1"
                        />
                        <label className="form-check-label fs-14" htmlFor="category-Radio1">
                          Active
                        </label>
                      </div>
                      <div className="form-check form-check-inline d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="category-status"
                          value={status}
                          checked={status == generalModelStatuses.DECLINED}
                          onClick={() => setStatus(generalModelStatuses.DECLINED)}
                          id="category-Radio2"
                        />
                        <label className="form-check-label fs-14" htmlFor="category-Radio2">
                          Inactive
                        </label>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-12 mt-3">
                    <CustomButton
                      btntype="button"
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-xxl-8">
              <div className="d-flex gap-3 align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Attribute List</h5>
                <SearchBox
                  setQuery={(e) => {
                    handleFilterByQuery(e, ["name"]);
                  }}
                  query={query}
                  extraClass="bg-white"
                />
              </div>
              <DashboardTable>
                <DataTable
                  columns={product_sale_columns}
                  data={
                    attributeValueArray && attributeValueArray.length > 0
                      ? attributeValueArray
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AttributeValue;
