import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteById,
  /* getAllBySellerId , */ get,
} from "../../services/HsnTax.service";

import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";

import {
  HSNTAXGET,
  HSNTAXADD,
  SETHSNTAXOBJ,
  HSNTAXUPDATE,
  HSNTAXDELETE,
} from "../../redux/actions/HsnTax/HsnTax.actions";
// import {get } from "../../services/HsnTax.service"

export default function ViewHsnTax() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [offerArr, setOfferArr] = useState([]);
  const [orderArr, setOrderArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const hsnTaxArr = useSelector((state) => state.hsnTax.hsnTaxs);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const hsnTaxPaginationObject = useSelector(
    (state) => state.hsnTax.hsnTaxPaginationObj
  );

  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"queryOf22323")
      // dispatch(HSNTAXGET(queryOf));
      let { data: response } = await get(queryOf);
      // console.log(response, "get ==hsn code");
      if (response) {
        setOfferArr(response?.data);
        setMainArr(response?.data);
        // console.log(hsnTaxPaginationObject,"hsnTaxPaginationOb12")
        setTotalPages(response?.totalPages);
        setTotalCount(response?.totalCount);
        setPage(response?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);



  
  useEffect(() => {
    if (hsnTaxArr && hsnTaxArr.length >= 0) {
      setOfferArr(hsnTaxArr);
      setMainArr(hsnTaxArr)
    }
    if (hsnTaxPaginationObject) {
      setTotalPages(hsnTaxPaginationObject?.totalPages);
      setTotalCount(hsnTaxPaginationObject?.totalCount);
      setPage(hsnTaxPaginationObject?.page);
    }
  }, [hsnTaxArr,hsnTaxPaginationObject]);






  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "HSN",
      selector: (row) => row?.hsn,
      sortable: true,
    },
    {
      name: "CGST",
      sortable: true,
      selector: (row) => row?.cgst,
    },
    {
      name: "SGST",
      sortable: true,
      selector: (row) => row?.sgst,
    },

    {
      name: "IGST",
      selector: (row) => row?.igst,
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/ViewHsnTax"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => {
              dispatch(SETHSNTAXOBJ(row));
            }}
            edit
            editPath="/AddHsnTax"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
        </>
      ),
    },
  ];

  const handleDeleteById = async (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(HSNTAXDELETE(id,query));
    }
    
  };

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">View HSN</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={offerArr && offerArr.length > 0 ? offerArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
