import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as INTERNALORDER from "../../actions/InternalOrder/InternalOrder.actions";


const initialState = {
  internalOrders: null,
  internalOrderObj: null,
  loading: false,
  error: null,
};


export const internalOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case INTERNALORDER.INTERNALORDER_ADD:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.INTERNALORDER_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case INTERNALORDER.INTERNALORDER_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case INTERNALORDER.GET_ALL_INTERNALORDERS:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.GET_ALL_INTERNALORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        internalOrders: action.payload.data,
      };
    case INTERNALORDER.GET_ALL_INTERNALORDERS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case INTERNALORDER.DELETE_INTERNALORDER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.DELETE_INTERNALORDER_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case INTERNALORDER.DELETE_INTERNALORDER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case INTERNALORDER.SET_INTERNALORDER_OBJ:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.SET_INTERNALORDER_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        internalOrderObj: action.payload.data,
        loading: false,
        error: null,
      };
    case INTERNALORDER.SET_INTERNALORDER_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case INTERNALORDER.UPDATE_INTERNALORDER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case INTERNALORDER.UPDATE_INTERNALORDER_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case INTERNALORDER.UPDATE_INTERNALORDER_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
