import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import AddSellerCategory from "./AddSellerCategory";

import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { downloadCSV } from "../Utility/CSV";
import SearchBox from "../Utility/SearchBox";

import { AddModal } from "../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";

import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";

import {
  sellerCategoryAdd,
  sellerCategoryUpdate,
  SELLERCATEGORYGET,
} from "../../redux/actions/SellerCategory/SellerCategory.actions";

import { getData, deleteById } from "../../services/sellerCategorys.service";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

function SellerCategory() {
  // ==============================================================================================================
  const [ModalType, setModalType] = useState("");
  const [query, setQuery] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const [categoryArray, setCategoryArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [ModalData, setModalData] = useState({});

  const dispatch = useDispatch();
  const categoryArr = useSelector(
    (state) => state?.sellerCategory?.sellerCategorys
  );
  const categoryPaginationObj = useSelector(
    (state) => state?.sellerCategory?.sellerCategoryPaginationObj
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  const fetchData = async ( ) => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    // console.log(queryOf, "queryOfqueryOf,");
    dispatch(SELLERCATEGORYGET(queryOf));
  };


  useEffect(() => {
    fetchData();
  }, [page, perPage,query]);

  const handleCategoryEdit = (row) => {
    setModalData(row);
  };

  useEffect(() => {
   
    if (categoryArr && categoryArr?.length >= 0) {
      setCategoryArr(categoryArr);
      setMainArr(categoryArr);
    }
    if (categoryPaginationObj) {
      setTotalPages(categoryPaginationObj?.totalPages);
      setTotalCount(categoryPaginationObj?.totalCount);
      setPage(categoryPaginationObj?.page);
    }
  }, [categoryArr, categoryPaginationObj]);


  const handleCategoryDelete = async (row) => {
    try {
      let confirm = window.confirm("Do you really want to delete this item?");
      if (confirm) {
        let { data: res } = await deleteById(row?._id);
        if (res?.success) {
          toastSuccess(res?.message);
          dispatch(SELLERCATEGORYGET());
          // handleSellerCategoryGet();
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  const category_columns = [
    {
      name: "S.No.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Commission Percentage",
      selector: (row) => (row.commission ? row.commission : "NA"),
    },
    {
      name: "Logistics Fee",
      selector: (row) => (row.logisticsFee ? row.logisticsFee : "NA"),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Seller-category"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Seller-category"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    // console.log(e,"eeeeeeeeeeee")   
    setQuery(e);   
  };

  
  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
   
  };


  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Add Category</h5>
              </div>
              <DashboardBox>
                <AddSellerCategory data={ModalData} />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Category List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton
                    isLink
                    iconName="fa-solid fa-download"
                    btnName="CATEGORY CSV"
                    path="/Seller-Category-Bulk"
                    small
                    roundedPill
                    downloadAble
                  /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    categoryArray && categoryArray.length > 0
                      ? categoryArray
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SellerCategory;
