import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import { INTERNALORDERAdd } from "../../redux/actions/InternalOrder/InternalOrder.actions";

import { rolesObj } from "../../utils/roles";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";

import { getProducts } from "../../services/product.service";
import {add,
  get,
  deleteById,
  updateById,
  markOrderAsDelivered,
  cancelOrderById,
  getInvoiceById,
  getById ,}from "../../services/InternalOrder.services"


export default function InternalOrders() {

  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [selectAll, setSelectAll] = useState(false);

  const [name, setName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, SetAddress] = useState("");
  const [country, SetCountry] = useState("");
  const [state, SetState] = useState("");
  const [city, SetCity] = useState("");
  const [postalCode, SetPostalCode] = useState("");
  const [prevObjId, SetPrevObjId] = useState("");
  const [tag, setTag] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [shippingCharges, setShippingCharges] = useState(0);
  // const internalOrderObj = useSelector((state) => state.InternalOrder.internalOrderObj);
  const internalOrderObj = useSelector((state) => state.InternalOrder);


  useEffect(() => {
    if (internalOrderObj&&internalOrderObj._id) {
      setName(internalOrderObj?.name);
      setStatus(internalOrderObj?.status);
      setSelectAll(internalOrderObj?.selectAll);
      SetEmail(internalOrderObj?.email);
      SetPhone(internalOrderObj?.phone);
      SetAddress(internalOrderObj?.address);
      SetCountry(internalOrderObj?.country);
      SetState(internalOrderObj?.state);
      SetCity(internalOrderObj?.city);
      SetPostalCode(internalOrderObj?.postalCode);
      SetPrevObjId(internalOrderObj?._id);
    }
    // return () => {
    //     dispatch(SetINTERNALORDERObj(null));
    // };
  }, [internalOrderObj]);


  const handleProductSelect = (val) => {
    setSelectedProductArr([...val]);
  };

  useDispatch(() => {
    // console.log(setSelectedProductArr, "setSelectedProductArr1  ");
  }, [selectedProductArr]);

  const handleGetProductsAndAttributesAsProduct = async () => {
    try {
      let sellerId = authObj?.user?._id;
      if (authObj?.role == rolesObj.ADMIN) {
        sellerId = "";
      }
      // let { data: res } = await getProductsAndAttributesAsProduct(`sellerId=${sellerId}`);
      let { data: res } = await getProducts(`sellerId=${sellerId}`);
      if (res.data) {
        setProductInternalArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };


  useEffect(() => {
    if (productInternalArr && productInternalArr.length > 0 && selectAll) {
      setSelectedProductArr([
        ...productInternalArr.map((el) => {
          return { ...el, label: el?.name, value: el?.name };
        }),
      ]);
    } else {
      setSelectedProductArr([]);
    }
  }, [selectAll, productInternalArr]);


  const handleValueChange = (index, value, key) => {
    if (!(value < 0)) {
      let tempArr = selectedProductArr;
      tempArr[index][key] = value;
      setSelectedProductArr([...tempArr]);
    }
  };

  const percentage = (percent, total) => {
    return (percent / 100) * total;
  };

  useEffect(() => {
    handleGetProductsAndAttributesAsProduct();
  }, []);

  const calculateTotalQuantity = () => {
    return selectedProductArr.reduce((acc, el) => acc + parseInt(el.incomingStock), 0).toFixed(2);
  };
  const calculateTotal = () => {
    // console.log(selectedProductArr, "selectedProductArr12321");
    // console.log(
    //   selectedProductArr?.reduce(
    //     (acc, el) =>
    //       acc +
    //       parseInt(el?.incomingStock) *
    //         (parseInt(el?.price) + percentage(el?.gst, el?.price))
    //   ),
    //   "selectedProductArr"
    // );

    return selectedProductArr?.reduce((acc, el) => acc + parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price)), 0).toFixed(2);
  };
  const calculateGrandTotal = () => {
    return Math.round(selectedProductArr.reduce((acc, el) => acc + parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price)), 0) + parseInt(shippingCharges)).toFixed(2);
  };
  const calculateGrandWithoutGstTotal = () => {
    return (selectedProductArr.reduce((acc, el) => acc + parseInt(el.incomingStock) * parseInt(el.price), 0) + parseInt(shippingCharges)).toFixed(2);
  };
  const calculateRoundOff = () => {
    let final = (selectedProductArr.reduce((acc, el) => acc + parseInt(el.incomingStock) * (parseInt(el.price) + percentage(el.gst, el.price)), 0) + parseInt(shippingCharges)).toFixed(2);
    let result = final - Math.round(final);
    return Math.abs(result).toFixed(2);
  };

  const handleSubmit = async(e) => {
    try{

    
    e.preventDefault();
    if (name == "") {
      toastError("Name is mandatory");
      return;
    } else if (email == "") {
      toastError("Email is mandatory");
      return;
    } else if (address == "") {
      toastError("Address is mandatory");
      return;
    } else if (phone == "") {
      toastError("Phone is mandatory");
      return;
    } else if (country == "") {
      toastError("Country is mandatory");
      return;
    } else if (state == "") {
      toastError("State is mandatory");
      return;
    } else if (city == "") {
      toastError("City is mandatory");
      return;
    } else if (postalCode == "") {
      toastError("Postal code is mandatory");
      return;
    } else if (!(selectedProductArr.length > 0)) {
      toastError("Please select products to proceed");
      return;
    } else if (!selectedProductArr.every((el) => el?.gst > 0)) {
      toastError("Please add gst to all the products");
      return;
    } else if (!selectedProductArr.every((el) => el?.sellingPrice > 0)) {
      toastError("Please add selling price to all the products");
      return;
    } else if (!selectedProductArr.every((el) => el?.price > 0)) {
      toastError("Please add buying price to all the products");
      return;
    } else if (!selectedProductArr.every((el) => el?.incomingStock > 0)) {
      toastError("Please add stock to all the products");
      return;
    }
    let obj = {
      productsArr: selectedProductArr.map((el) => {
        let obj = {
          ...el,
          productId:el?._id,
          subTotal: (parseInt(el?.price) + percentage(el?.gst, el?.price)) * parseInt(el?.incomingStock),
          totalPriceWithGst: (parseInt(el?.price) + percentage(el?.gst, el?.price)) * parseInt(el?.incomingStock),
          totalPriceWithoutGst: parseInt(el?.price) * parseInt(el?.incomingStock),
        };
        return obj;
      }),
      sellerDetails: {
        name,
        email,
        phone,
        address,
        country,
        state,
        city,
        postalCode,
      },
      orderFor: "adminStockIn",
      sellerId: authObj?.user?._id,
      subTotal: parseFloat(calculateTotal()),
      totalPriceWithGst: parseFloat(calculateGrandTotal()),
      totalPriceWithoutGst: parseFloat(calculateGrandWithoutGstTotal()),
      discountValue: 0,
      total: parseFloat(calculateGrandTotal()),
      roundOff: parseFloat(calculateRoundOff()),
    };
// console.log(obj,"object12332")
let {data:res} = await add(obj)    
if(res.success){
  toastSuccess(res?.message)
}
    }catch(err){

      toastError(err)
    }
// dispatch(INTERNALORDERAdd(obj));

    // if (internalOrderObj && internalOrderObj?._id) {
    //   dispatch(INTERNALORDERUpdate(obj, prevObjId));
    // } else {
    //   dispatch(INTERNALORDERAdd(obj));
    // }
  };
  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="blue-1 m-0"> Products</h5>
                    <div style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}>
                      <input checked={selectAll} onChange={(e) => setSelectAll((prev) => !prev)} type="checkbox" />
                    </div>
                    <label>{selectAll == false ? "Select" : "Un-Select"} All</label>
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <label>
                      Please select products from the list
                      <span className="red">*</span>
                    </label>
                    <Select
                      options={[
                        ...productInternalArr.map((el) => {
                          return { ...el, label: el?.name, value: el?.name };
                        }),
                      ]}
                      isMulti
                      placeholder="Select from Products to order"
                      value={selectedProductArr}
                      onChange={(e) => {
                        // console.log(e, "asd");
                        handleProductSelect(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-1 ">Address Info</h5>
                  <div className="row">
                    {/* <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        If address already added please select from the list below <span className="red">*</span>
                      </label>
                      <Select
                        options={[
                          ...productInternalArr.map((el) => {
                            return { ...el, label: el?.name, value: el?.name };
                          }),
                        ]}
                        isMulti
                        placeholder="Select from Products to order"
                
                      />
                    </div> */}

                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input value={name} onChange={(event) => setName(event.target.value)} type="text" className="form-control" />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Email <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={email} onChange={(e) => SetEmail(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Phone <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={phone} onChange={(e) => SetPhone(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Address <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={address} onChange={(e) => SetAddress(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Country <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={country} onChange={(e) => SetCountry(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        State <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={state} onChange={(e) => SetState(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        City <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={city} onChange={(e) => SetCity(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Postal Code <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={postalCode} onChange={(e) => SetPostalCode(e.target.value)} />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Tag <span className="red">*</span>
                      </label>
                      <input type="text" className="form-control" value={tag} onChange={(e) => setTag(e.target.value)} />
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-9">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Selling Price</th>
                          <th scope="col">Buying Price</th>
                          <th scope="col">Gst</th>
                          <th scope="col">Current Stock</th>
                          <th scope="col">Stock</th>
                          <th scope="col">Total Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el.name}</th>
                                <td>
                                  <input type="number" className="form-control" value={el?.sellingPrice} onChange={(e) => handleValueChange(index, e.target.value, "sellingPrice")} />
                                </td>
                                <td>
                                  <input type="number" className="form-control" value={el?.price} onChange={(e) => handleValueChange(index, e.target.value, "price")} />
                                </td>
                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      !(e.target.value > 100) && handleValueChange(index, e.target.value, "gst");
                                    }}
                                    value={el?.gst}
                                  />
                                </td>
                                <td>
                                  <input type="number" className="form-control" onChange={(e) => handleValueChange(index, e.target.value, "stock")} disabled value={el?.stock} />
                                </td>
                                <td>
                                  <input type="number" className="form-control" onChange={(e) => handleValueChange(index, e.target.value, "incomingStock")} value={el?.incomingStock} />
                                </td>
                                <td>
                                  <input type="number" className="form-control" value={(parseInt(el.price) + percentage(el.gst, el.price)) * parseInt(el?.incomingStock)} disabled />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-3" style={{ borderLeft: "solid 1px rgba(0,0,0,0.1)" }}>
                    <h5 className="blue-1 m-0">Summary</h5>
                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Total Items</th>
                          <th scope="col">{calculateTotalQuantity()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Sub Total </th>
                          <th scope="col">{calculateTotal()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Discount </th>
                          <th scope="col">0</th>
                        </tr>
                        <tr>
                          <th scope="col">Total Shipping Charges</th>
                          <th>
                            <input type="number" className="form-control" value={shippingCharges} onChange={(e) => setShippingCharges(e.target.value)} />
                          </th>
                        </tr>
                        <tr>
                          <th scope="col">Grant Total </th>
                          <th scope="col">{calculateGrandTotal()}</th>
                        </tr>
                        <tr>
                          <th scope="col">Round Off </th>
                          <th scope="col">{calculateRoundOff()}</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
