import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/homePage";

export const addHomePage = (formData) => {
  return axiosApiInstance.post(serverUrl + "/addHomePage", formData);
};

export const getHomePage = (query) => {
  return axiosApiInstance.get(`${serverUrl}/get`);
};

export const deleteHomePage = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateHomePage = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
