import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../Utility/Button";
import { useDispatch, useSelector } from "react-redux";
import { STATEGET } from "../../redux/actions/State/States.actions";
import { CITYGET, CITYGETBYID } from "../../redux/actions/City/City.actions";
import { AREAGET, AREAGETById } from "../../redux/actions/Area/Area.actions";
import {
  COMPANYADDRESSADD,
  COMPANYADDRESSUPDATE,
  SETCOMPANYADDRESSOBJ,
} from "../../redux/actions/companyAddress/CompanyAddress.actions";

import { toastError } from "../Utility/ToastUtils";

function AddCompanyAddress({ makeChange }) {
  const dispatch = useDispatch();

  const [address, setAddress] = useState("");
  const [pinCode, setPinCode] = useState("");

  const [stateObj, setStateObj] = useState({});
  const [citiesObj, setCitiesObj] = useState({});
  const [areasObj, setAreasObj] = useState({});

  const [cityArr, setCityArr] = useState([]);
  const [stateArr, setStateArr] = useState([]);
  const [areaArr, setAreaArr] = useState([]);

  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [areaId, setAreaId] = useState("");

  const statesObj = useSelector((state) => state.states.statesObj);
  const cityObj = useSelector((state) => state.city.citiesObj);
  const areaObj = useSelector((state) => state.area.areasObj);

  const companyAddressObj = useSelector(
    (state) => state.companyAddress.companyAddresssObj
  );

  const statesArr = useSelector((state) => state.states.states);
  const citiesArr = useSelector((state) => state.city.cities);
  const areasArr = useSelector((state) => state.area.areas);

  const clearFunc = () => {
    setAddress("");
    setPinCode("");
    setStateObj({});
    setCitiesObj({});
    setAreasObj({});
    dispatch(SETCOMPANYADDRESSOBJ(null));
    dispatch(STATEGET());
    // dispatch(CITYGET());
    // dispatch(AREAGET());
  };

  const handleAddCompanyaddress = () => {
    try {
      if (address == "") {
        toastError("Addres is mandatory");
        return;
      } else if (stateId == "") {
        toastError("State is mandatory");
        return;
      } else if (cityId == "") {
        toastError("City is mandatory");
        return;
      } else if (areaId == "") {
        toastError("Area is mandatory");
        return;
      } else if (pinCode == "") {
        toastError("Pin Code is mandatory");
        return;
      }

      let obj = {
        address,
        stateId,
        areaId,
        cityId,
        pinCode,
      };
      // console.log(obj, "ware hoaw obj");

      if (companyAddressObj && companyAddressObj?._id) {
        dispatch(COMPANYADDRESSUPDATE(companyAddressObj._id, obj));
        dispatch(SETCOMPANYADDRESSOBJ(null));
      } else {
        dispatch(COMPANYADDRESSADD(obj));
      }
      clearFunc();
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(companyAddressObj, "companyAddressObj787");
    if (companyAddressObj && companyAddressObj._id) {
      setAddress(companyAddressObj?.address);
      setPinCode(companyAddressObj?.pinCode);
      setStateId(companyAddressObj?.stateId);
      setCityId(companyAddressObj?.cityId);
      setAreaId(companyAddressObj?.areaId);
      //   console.log(stateArr, "stateArrstateArr-=r");
      let cityObj = {
        label: companyAddressObj?.cityObj?.name,
        value: companyAddressObj?.cityObj?._id,
      };
      setCitiesObj(cityObj);
      let stateObj = {
        label: companyAddressObj?.stateObj?.name,
        value: companyAddressObj?.stateObj?._id,
      };
      setStateObj(stateObj);
      let areaObj = {
        label: companyAddressObj?.areaObj?.name,
        value: companyAddressObj?.areaObj?._id,
      };
      setAreasObj(areaObj);
    }

    return () => {
      dispatch(SETCOMPANYADDRESSOBJ(null));
    };
    //   }, [companyAddressObj, stateArr, cityArr, areaArr, citiesArr, areasArr]);
  }, [companyAddressObj]);

  useEffect(() => {
    dispatch(STATEGET(`status=All`));
  
  }, [companyAddressObj]);

  useEffect(() => {
    if (statesArr) {
      setStateArr(statesArr);
    }
  }, [statesArr]);

  useEffect(() => {
    if (citiesArr && citiesArr.length) {
      setCityArr(citiesArr);
    } else {
      setCityArr({});
    }
  }, [citiesArr]);

  useEffect(() => {
    // console.log("get areasArr ",areasArr ,)
    if (areasArr&&areasArr.length) {
      setAreaArr(areasArr);
    } else {
      setAreaArr({  });
    }
  }, [areasArr]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Address <span className="red">*</span>
          </label>
          <input
            value={address}
            onChange={(event) => setAddress(event?.target?.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className="col-12 col-md-12">
          <label className="blue-1 fs-12">
            State <span className="red">*</span>
          </label>
          {/* {console.log(stateArr,"stateArr234")} */}
          <Select
            options={
              stateArr &&
              stateArr?.length > 0 &&
              stateArr?.map((el) => {
                return { ...el, value: el?._id, label: el?.name };
              })
            }
            placeholder="Select from options"
            defaultInputValue={stateId}
            value={stateObj}
            onChange={(e) => {
              setStateId(e.value);
              setStateObj(e);
              setCitiesObj({})
              setAreasObj({})
              dispatch(CITYGETBYID(`stateId=${e.value}`));
              // setCityArr([]);
            }}
          />
        </div>
        <div className="col-12 col-md-12">
          <label className="blue-1 fs-12">
            City <span className="red">*</span>
          </label>
          {/* {console.log(cityArr, "cityArr235234 get b")} */}
          <Select
            options={
              cityArr &&
              cityArr?.length &&
              cityArr?.map((el) => {
                return { ...el, value: el?._id, label: el?.name };
              })
            }
            placeholder="Select from options"
            defaultInputValue={cityId}
            value={citiesObj}
            onChange={(e) => {
              // console.log(e, "asdcity");
              setCityId(e?.value);
              setCitiesObj(e);
              dispatch(AREAGETById(`cityId=${e?.value}`));
            }}
          />
        </div>
        <div className="col-12 col-md-12">
          <label className="blue-1 fs-12">
            Area <span className="red">*</span>
          </label>
          {/* {console.log(areaArr, "areaArr24234-==")} */}
          <Select
            options={
              areaArr &&
              areaArr?.length &&
              areaArr?.map((el) => {
                return { ...el, value: el?._id, label: el?.name };
              })
            }
            placeholder="Select from options"
            defaultInputValue={areaId}
            value={areasObj}
            onChange={(e) => {
              // console.log(e, "area")
              setAreaId(e?.value);
              setAreasObj(e);
            }}
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Pin Code <span className="red">*</span>
          </label>
          <input
            value={pinCode}
            onChange={(event) => setPinCode(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCompanyaddress}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddCompanyAddress;
