import {
//   addCompanyAddress,
// getCompanyAddress,
// deleteCompanyAddress,
// updateCompanyAddress,
 addCompanyAddress, deleteCompanyAddress, getCompanyAddress, updateCompanyAddress } from "../../../services/CompanyAddress.service";

export const COMPANYADDRESS_ADD = "COMPANYADDRESS_ADD";
export const COMPANYADDRESS_ADD_SUCCESS = "COMPANYADDRESS_ADD_SUCCESS";
export const COMPANYADDRESS_ADD_FAIL = "COMPANYADDRESS_ADD_FAIL";

export const GET_ALL_COMPANYADDRESS = "GET_ALL_COMPANYADDRESS";
export const GET_ALL_COMPANYADDRESS_SUCCESS = "GET_ALL_COMPANYADDRESS_SUCCESS";
export const GET_ALL_COMPANYADDRESS_FAIL = "GET_ALL_COMPANYADDRESS_FAIL";

export const UPDATE_COMPANYADDRESS_BY_ID = "UPDATE_COMPANYADDRESS_BY_ID";
export const UPDATE_COMPANYADDRESS_BY_ID_SUCCESS = "UPDATE_COMPANYADDRESS_BY_ID_SUCCESS";
export const UPDATE_COMPANYADDRESS_BY_ID_FAIL = "UPDATE_COMPANYADDRESS_BY_ID_FAIL";

export const SET_COMPANYADDRESS_OBJ = "SET_COMPANYADDRESS_OBJ";
export const SET_COMPANYADDRESS_OBJ_SUCCESS = "SET_COMPANYADDRESS_OBJ_SUCCESS";
export const SET_COMPANYADDRESS_OBJ_FAIL = "SET_COMPANYADDRESS_OBJ_FAIL";

export const GET_COMPANYADDRESS_BY_ID = "GET_COMPANYADDRESS_BY_ID";
export const GET_COMPANYADDRESS_BY_ID_SUCCESS = "GET_COMPANYADDRESS_BY_ID_SUCCESS";
export const GET_COMPANYADDRESS_BY_ID_FAIL = "GET_COMPANYADDRESS_BY_ID_FAIL";

export const DELETE_COMPANYADDRESS_BY_ID = "DELETE_COMPANYADDRESS_BY_ID";
export const DELETE_COMPANYADDRESS_BY_ID_SUCCESS = "DELETE_COMPANYADDRESS_BY_ID_SUCCESS";
export const DELETE_COMPANYADDRESS_BY_ID_FAIL = "DELETE_COMPANYADDRESS_BY_ID_FAIL";


export const COMPANYADDRESSGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMPANYADDRESS });
    let { data: response } = await getCompanyAddress(formData);
    if (response) {

      dispatch({
        type: GET_ALL_COMPANYADDRESS_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: COMPANYADDRESS_ADD_FAIL, payload: err });
  }
};


export const COMPANYADDRESSADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: COMPANYADDRESS_ADD });
    let { data: response } = await addCompanyAddress(formData);
    if (response) {
      dispatch(COMPANYADDRESSGET())
      dispatch({
        type: COMPANYADDRESS_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: COMPANYADDRESS_ADD_FAIL, payload: err });
  }
};



export const SETCOMPANYADDRESSOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_COMPANYADDRESS_OBJ });
    if (formData) {
      dispatch({
        type: SET_COMPANYADDRESS_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_COMPANYADDRESS_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_COMPANYADDRESS_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const COMPANYADDRESSUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COMPANYADDRESS_BY_ID });
    let { data: response } = await updateCompanyAddress(formData, id);
    if (response) {
      // // console.log(response);
      dispatch({
        type: UPDATE_COMPANYADDRESS_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(COMPANYADDRESSGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_COMPANYADDRESS_BY_ID_FAIL, payload: err });
  }
};

export const COMPANYADDRESSDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COMPANYADDRESS_BY_ID });
    let { data: response } = await deleteCompanyAddress(id);
    if (response) { 
      // console.log(response.message, "deleteCompanyAddress-=-=");
      dispatch({
        type: DELETE_COMPANYADDRESS_BY_ID_SUCCESS,
        payload: response
      });
      dispatch(COMPANYADDRESSGET());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_COMPANYADDRESS_BY_ID_FAIL, payload: err });
  }
};