import React, { useState, useEffect } from "react";
import CustomButton from "../../Utility/Button";
import FileUpload from "../../Utility/FileUpload";
import { DashboardBox } from "../../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  currencyAdd,
  currencyUpdate,
  SetcurrencyObj,
  setCurrencyStatus,
} from "../../../redux/actions/currency/Currency.action";

import { useNavigate } from "react-router-dom";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

function AddCurrency() {
  const dispatch = useDispatch();
  // const history = useHistory()
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [symbol, setSymbol] = useState("");
  const [convertRate, setConvertRate] = useState("");
  const [image, setImage] = useState("");
  const [isUpdateCurrency, setIisUpdateCurrency] = useState(false);
  const [currencyId, setCurrencyId] = useState("");
  const currencyObj = useSelector((state) => state.currency.CurrencyObj);
  const currencyStat = useSelector((state) => state.currency.currencyStatus);
  const [currencyStatus, setcurrencyStatus] = useState("");
  const currencyStatusCheck = useSelector(
    (state) => state.currency.currencyStatusUpdate
  );

  useEffect(() => {
    if (currencyObj) {
      setName(currencyObj.name);
      setCode(currencyObj.code);
      setSymbol(currencyObj.symbol);
      setImage(currencyObj.image);
      setConvertRate(currencyObj.convertRate);
      setIisUpdateCurrency(true);
      setCurrencyId(currencyObj._id);
    }
    return () => {
      dispatch(SetcurrencyObj(null));
    };
  }, [currencyObj]);

  const clearFunc = () => {
    setName("");
    setCode("");
    setSymbol("");
    setConvertRate("");
    setImage("");
    setIisUpdateCurrency("");
    setCurrencyId("");
  };

  // useEffect(() => {
  //   if (currencyStatus) {
  //     console.log(currencyStatus," get currencyStatus")
  //     clearFunc();
  //     // setTimeout(() => {
  //     //   setcurrencyStatus("");
  //     // }, 0);
  //   }
  // }, [currencyStatus]);

  // useEffect(() => {
  //   if (currencyStat) {
  //     setcurrencyStatus(currencyStat);
  //   }
  //   setTimeout(() => {
  //     setcurrencyStatus("");
  //   }, 100);
  // }, [currencyStat]);

  useEffect(() => {
    // console.log(currencyStatusCheck, "===currencyStatusCheck");
    if (currencyStatusCheck && currencyStatusCheck?.message) {
      clearFunc();
    }
    return () => {
      dispatch(setCurrencyStatus(null));
    };
  }, [currencyStatusCheck]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (name == "") {
        toastError("Name is mandatory");
        return;
      } else if (code == "") {
        toastError("Currency code is mandatory");
        return;
      } else if (symbol == "") {
        toastError("Symbol is mandatory");
        return;
      } else if (convertRate == "") {
        toastError("Comvert rate is mandatory");
        return;
      } else if (image == "") {
        toastError("Image is mandatory");
        return;
      }
      let obj = {
        name,
        code,
        symbol,
        convertRate,
        image,
      };

      if (isUpdateCurrency) {
        dispatch(currencyUpdate(obj, currencyId));
        // console.log(updateData, "get resonse 2222curren cy compoenet");
      } else {
        dispatch(currencyAdd(obj));
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleFileSet = (value) => {
    setImage(value);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">
            {isUpdateCurrency ? "Update" : "Add New "} Currency
          </h5>
          <DashboardBox className="col-12 col-md-8">
            <form action="#" className="form row">
              <div className="col-12">
                <label>
                  NAME<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>
                  CODE <span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
              <div className="col-12">
                <label>
                  SYMBOL<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                />
              </div>

              {/* <div className="col-12 mb-3">
                <label>
                  Country Flag Logo<span className="red">*</span>
                </label>
                <FileUpload onFileChange={handleFileSet} />
              </div> */}

              <div className="col-12">
                <label>
                  CONVERT RATE 1 INR = ?<span className="red">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={convertRate}
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      toastError("Value can't be less than 0 ");
                      return;
                    }
                    setConvertRate(e.target.value);
                  }}
                />
              </div>

              <div className="col-12 mb-3">
                <label>
                  Currency Image<span className="red">*</span>
                </label>
                <div
                  style={{
                    marginTop: 20,
                    borderBottom: "grey 1px solid",
                    paddingBottom: 15,
                  }}
                  className="row"
                >
                  <div className="col-12">
                    <img
                      src={
                        `${image}`.includes("base64")
                          ? image
                          : generateFilePath(image)
                      }
                      style={{ height: 80 }}
                    />
                    <br />
                    <br />
                    <FileUpload onFileChange={(val) => handleFileSet(val)} />
                  </div>
                </div>
              </div>

              <div className="col-12 mt-2">
                <CustomButton
                  isBtn
                  iconName="fa-solid fa-check"
                  btnName="Save"
                  ClickEvent={handleSubmit}
                />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default AddCurrency;
