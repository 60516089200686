import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getProductReview,
  upadteReviewStatus,
} from "../../services/ProductReview.service";

import { getreviewSetingByUserId } from "../../services/ReviewSettings.service";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import tabClick from "../Utility/TabClick";
import { toastError } from "../Utility/ToastUtils";

import {
  PRODUCTREVIEWAdd,
  PRODUCTREVIEWGet,
  SetPRODUCTREVIEWObj,
  PRODUCTREVIEWUpdate,
  PRODUCTDREVIEWelete,
} from "../../redux/actions/ProductReview/ProductReview.actions";

function ProductReview(e) {
  const dispatch = useDispatch();
  const [productReviewArr, setProductReviewArr] = useState([]);

  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");

  const review_columns = [
    {
      name: "Product Name",
      selector: (row) => row?.productName,
      // width: "15%",
    },
    {
      name: "Rating",
      selector: (row) => row?.rating,
      width: "8%",
    },
    {
      name: "Customer Feedback",
      cell: (row) => row?.message,
    },
    {
      name: "Status",
      button: true,
      cell: (row) => (
        <CustomButton
          isBtn
          btnName={row?.status}
          btntype="button"
          changeClass={row?.class}
          noIconMargin
          noIcon
        />
      ),
      width: "20%",
    },
    {
      name: "Customer & Time",
      selector: (row) =>
        `${row?.name} & ${new Date(row.createdAt).toDateString()}`,
      // width: "25%",
    },
    {
      name: "Approve",
      cell: (row) => (
        <ActionIcon
          approve
          decline
          isRedirected={true}
          onRejectClick={() => handleRejectProductReview(row._id)}
          approvePath={"/Review/Product-Review"}
          rejectPath={"/Review/Product-Review"}
          onApproveClick={() => handleApproveProductReview(row._id)}
          Uniquekey={row._id}
        />
      ),
      // width: "10%",
    },
  ];

  const [tabList, settabList] = useState([
    {
      tabName: "All Review",
      active: true,
    },
    {
      tabName: "Approved",
      active: false,
    },
    {
      tabName: "Pending",
      active: false,
    },
    {
      tabName: "Rejected",
      active: false,
    },
  ]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [filterData, setFilterData] = useState("");

  const productREviewArr = useSelector(
    (state) => state.ProductReview.productReviews
  );

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleGetProductReview = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      if (filterData) {
        queryOf = `${queryOf}&filterData=${filterData}`;
      }
      // console.log(queryOf, "queryOf2134");
      let { data: res } = await getProductReview(queryOf);
      // console.log(res,"resrevurw get product")
      if (res.data) {
        setProductReviewArr(res.data);
        setMainArr(res.data);
      }
      if (res) {
        setTotalPages(res?.totalPages);
        setTotalCount(res?.totalCount);
        setPage(res?.page);
      }
      // console.log(res, "handleGetProductReview")
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProductReview();
  }, [page, perPage, query, filterData]);

  const handleApproveProductReview = async (id) => {
    try {
      let obj = {
        status: "APPROVED",
      };
      let { data: res } = await upadteReviewStatus(id, obj);
      if (res.message) {
        handleGetProductReview();
      }
      // console.log(res, "handleApproveProductReview")
    } catch (err) {
      toastError(err);
    }
  };

  const handleRejectProductReview = async (id) => {
    try {
      let obj = {
        status: "REJECTED",
      };
      let { data: res } = await upadteReviewStatus(id, obj);
      if (res.message) {
        handleGetProductReview();
      }
      // console.log(res, "handleRejectProductReview")
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetTselectedTable = () => {
    try {
      // if (tabList?.filter((el) => el.active)[0].tabName == "All Review") {

      return (
        <DataTable
          columns={review_columns}
          data={productReviewArr}
          pagination
          paginationServer
          paginationTotalRows={totalCount}
          onChangePage={handlePageChange}
        />
      );
      // }
      //  else if (tabList?.filter((el) => el.active)[0].tabName == "Approve") {

      //   return (
      //     <DataTable
      //       columns={review_columns}
      //       data={productReviewArr?.filter(
      //         (el) => `${el.status}`.toLowerCase() == "approved"
      //       )}
      //       pagination
      //       paginationServer
      //       paginationTotalRows={totalCount}
      //       onChangePage={handlePageChange}
      //     />
      //   );
      // } else if (tabList?.filter((el) => el.active)[0].tabName == "Pending") {

      //   return (
      //     <DataTable
      //       columns={review_columns}
      //       data={productReviewArr?.filter(
      //         (el) => `${el.status}`.toLowerCase() == "pending"
      //       )}
      //       pagination
      //       paginationServer
      //       paginationTotalRows={totalCount}
      //       onChangePage={handlePageChange}
      //     />
      //   );
      // } else {

      //   return (
      //     <DataTable
      //       columns={review_columns}
      //       data={productReviewArr?.filter(
      //         (el) => `${el.status}`.toLowerCase() == "rejected"
      //       )}
      //       pagination
      //       paginationServer
      //       paginationTotalRows={totalCount}
      //       onChangePage={handlePageChange}
      //     />
      //   );
      // }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProductReview();
  }, []);

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">All Product Review List</h5>
                <CustomButton
                  isLink
                  iconName="fa-solid fa-plus"
                  btnName="Add Review"
                  path="/Review/Product-Review/Create-Review"
                  small
                  roundedPill
                />
                <div className="d-flex align-items-center gap-3">
                  <ul
                    className="nav nav-pills dashboard-pills justify-content-end"
                    id="pills-tab"
                    role="tablist"
                  >
                    {tabList.map((item, i) => {
                      return (
                        <li key={i}>
                          <CustomButton
                            navPills
                            btnName={item.tabName}
                            pillActive={item.active ? true : false}
                            ClickEvent={() => {
                              tabClick(i, tabList, settabList);
                              setFilterData(item?.tabName);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                {handleGetTselectedTable()}
                {/* <DataTable
                  columns={review_columns}
                  data={productReviewArr}
                  pagination
                /> */}
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ProductReview;
