import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as COMPANYADDRESS from "../../actions/companyAddress/CompanyAddress.actions";



const initialState = {
  companyAddresss: null,
  companyAddresssObj: null,
  loading: false,
  error: null,
};

export const companyAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMPANYADDRESS.COMPANYADDRESS_ADD:
      return {
        ...state,
        loading: true,
      };
    case COMPANYADDRESS.COMPANYADDRESS_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case COMPANYADDRESS.COMPANYADDRESS_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS:
      return {
        ...state,
        loading: true,
      };
    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        companyAddresss: action.payload.data,
      };
    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS:
      return {
        ...state,
        loading: true,
      };
    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        companyAddresss: action.payload.data,
      };
    case COMPANYADDRESS.GET_ALL_COMPANYADDRESS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMPANYADDRESS.DELETE_COMPANYADDRESS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case COMPANYADDRESS.DELETE_COMPANYADDRESS_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case COMPANYADDRESS.DELETE_COMPANYADDRESS_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case COMPANYADDRESS.UPDATE_COMPANYADDRESS_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case COMPANYADDRESS.UPDATE_COMPANYADDRESS_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case COMPANYADDRESS.UPDATE_COMPANYADDRESS_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };



    case COMPANYADDRESS.SET_COMPANYADDRESS_OBJ:
      return {
        ...state,
        loading: true,
      };      
    case COMPANYADDRESS.SET_COMPANYADDRESS_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        companyAddresssObj: action.payload.data,
        loading: false,
        error: null,
      };
    case COMPANYADDRESS.SET_COMPANYADDRESS_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
