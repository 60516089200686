import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as FLASHSALES from "../../actions/FlashSales/FlashSales.actions";


const initialState = {
  flashSaless: null,
  flashSalesObj: null,
  loading: false,
  error: null,
  flashSalesPaginationObj: null,
  

};


export const flashSalesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLASHSALES.FLASHSALES_ADD:
      return {
        ...state,
        loading: true,
      };
    case FLASHSALES.FLASHSALES_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case FLASHSALES.FLASHSALES_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FLASHSALES.GET_ALL_FLASHSALESS:
      return {
        ...state,
        loading: true,
      };
    case FLASHSALES.GET_ALL_FLASHSALESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        flashSaless: action.payload.data,
        flashSalesPaginationObj: action.payload.flashPaginationObj,
      };
    case FLASHSALES.GET_ALL_FLASHSALESS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FLASHSALES.DELETE_FLASHSALES_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FLASHSALES.DELETE_FLASHSALES_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FLASHSALES.DELETE_FLASHSALES_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FLASHSALES.SET_FLASHSALES_OBJ:
      return {
        ...state,
        loading: true,
      };
    case FLASHSALES.SET_FLASHSALES_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        flashSalesObj: action.payload.data,
        loading: false,
        error: null,
      };
    case FLASHSALES.SET_FLASHSALES_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FLASHSALES.UPDATE_FLASHSALES_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case FLASHSALES.UPDATE_FLASHSALES_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FLASHSALES.UPDATE_FLASHSALES_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
