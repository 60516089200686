import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import { getProductForSales } from "../../services/flashSales.service";
import { getProducts } from "../../services/product.service";
import { addPromotionalOffer } from "../../services/PromotionalOffer.service";
import { toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";
import { discountTypeArr, schemeType, schemeTypeArr } from "../Utility/utils";
import moment from "moment";

import {
  COMBOOFFERGET,
  COMBOOFFERADD,
  SETCOMBOOFFEROBJ,
  COMBOOFFERUPDATE,
  COMBOOFFERDELETE,
} from "../../redux/actions/ComboOffer/ComboOffer.actions";

export default function AddPromotionalOffer() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [selectAll, setSelectAll] = useState(false);
  const [name, setName] = useState("");

  const [email, SetEmail] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, SetAddress] = useState("");
  const [country, SetCountry] = useState("");
  const [state, SetState] = useState("");
  const [city, SetCity] = useState("");
  const [postalCode, SetPostalCode] = useState("");
  const [prevObjId, SetPrevObjId] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [selectedSchemeType, setSelectedSchemeType] = useState({
    label: schemeType.BUY_GET_PRODUCT,
    value: schemeType.BUY_GET_PRODUCT,
  });

  const comboOfferObj = useSelector((state) => state.comboOffer.comboOfferObj);
  const [discountTypeObj, setDiscountTypeObj] = useState({});
  const [discountValue, setDiscountValue] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    if (comboOfferObj && comboOfferObj._id) {
      // console.log(comboOfferObj, "comboOff123");
      setName(comboOfferObj?.name);
      setPrice(comboOfferObj?.price);
      setDiscountValue(comboOfferObj?.discountValue);
      setValidTill(moment(comboOfferObj?.validTill).format("YYYY-MM-DD"));
      setValidFrom(moment(comboOfferObj?.validFrom).format("YYYY-MM-DD"));
      // console.log(discountTypeArr.find((el,index)=>el.label==comboOfferObj?.discountType),'comboOff123')
      setDiscountTypeObj(
        discountTypeArr.find(
          (el, index) => el.label == comboOfferObj?.discountType
        )
      );
      setSelectedProductArr(comboOfferObj?.productArr);
      // setProductInternalArr([...comboOfferObj?.productArr]);
      setSelectedProductArr([
        ...comboOfferObj?.productArr.map((el) => {
          return {
            ...el,
            label: `${el?.name} | ${el?.sku}`,
            value: `${el?.name} | ${el?.sku}`,
          };
        }),
      ]);

      setStatus(comboOfferObj?.status);
      setSelectAll(comboOfferObj?.selectAll);
      SetEmail(comboOfferObj?.email);
      SetPhone(comboOfferObj?.phone);
      SetAddress(comboOfferObj?.address);
      SetCountry(comboOfferObj?.country);
      SetState(comboOfferObj?.state);
      SetCity(comboOfferObj?.city);
      SetPostalCode(comboOfferObj?.postalCode);
      SetPrevObjId(comboOfferObj?._id);
    }
    return () => {
      dispatch(SETCOMBOOFFEROBJ(null));
    };
  }, [comboOfferObj]);

  const handleProductSelect = (val) => {
    setSelectedProductArr([...val]);
  };

  const handleGetProductsAndAttributesAsProduct = async () => {
    try {
      let { data: res } = await getProducts(authObj?.user?._id);
      if (res.data) {
        setProductInternalArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetProductsAndAttributesAsProduct();
  }, []);

  const clearFunc = () => {
    setName("");
    setValidFrom("");
    setValidTill("");
    setSelectedProductArr([]);
    setDiscountValue("");
    setPrice("");
    setDiscountTypeObj({});
    dispatch(SETCOMBOOFFEROBJ());
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (name == "") {
        toastError("Name is mandatory");
        return;
      } else if (validFrom == "") {
        toastError("Valid From is mandatory");
        return;
      } else if (validTill == "") {
        toastError("Valid Till is mandatory");
        return;
      } else if (!(selectedProductArr.length > 0)) {
        toastError("Please select products to proceed");
        return;
      }
      let obj = {
        productArr: selectedProductArr.map((el) => {
          let tempObj = {
            productId: el._id,
            sku: el.sku,
            name: el.name,
            variantId: el.variantId,
          };
          return tempObj;
        }),
        name,
        discountType: discountTypeObj?.value,
        discountValue: discountValue,
        price,
        validFrom,
        validTill,
        sellerId: authObj?.user?._id,
      };
      // console.log(obj, "combo offer");
      if (comboOfferObj && comboOfferObj._id) {
        dispatch(COMBOOFFERUPDATE(obj, comboOfferObj._id));
        clearFunc();
      } else {
        dispatch(COMBOOFFERADD(obj));
        clearFunc();
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleCheckDiscount = (event) => {
    try {
      // console.log(discountTypeObj, "discountTypeObj2389");
      // console.log(event.target.value, "event.target.value32");
      if (discountTypeObj.label != "AMOUNT_OFF") {
        if (event.target.value >= 100) {
          toastError("Discount cannot be 100%");
          return;
        } else {
          setDiscountValue(event.target.value);
        }
      } else {
        setDiscountValue(event.target.value);
      }
    } catch (err) {
      toastError(err);
    }
  };


  const handleCheckDiscountType = (e) => {
    try {
      setDiscountTypeObj(e);
      setDiscountValue(0);
    } catch (err) {
      toastError(err);
    }
  };


  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="blue-1 m-0">
                      {comboOfferObj && comboOfferObj._id ? "EDIT" : "ADD"}
                      PRODUCT
                    </h5>
                    {/* <div
                      style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}
                    >
                      <input
                        checked={selectAll}
                        onChange={(e) => setSelectAll((prev) => !prev)}
                        type="checkbox"
                      />
                    </div> 
                    <label>
                      {selectAll == false ? "Select" : "Un-Select"} All
                    </label>*/}
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <label>
                      Please select products from the list
                      <span className="red">*</span>
                    </label>
                    <Select
                      options={[
                        ...productInternalArr.map((el) => {
                          return {
                            ...el,
                            label: `${el?.name} | ${el?.sku}`,
                            value: `${el?.name} | ${el?.sku}`,
                          };
                        }),
                      ]}
                      isMulti
                      placeholder="Select from Products "
                      value={selectedProductArr}
                      onChange={(e) => {
                        handleProductSelect(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-1 ">Other Info</h5>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Discount Type <span className="red">*</span>
                      </label>

                      <Select
                        options={discountTypeArr}
                        // defaultInputValue={discountTypeObj}
                        value={discountTypeObj}
                        onChange={(e) => handleCheckDiscountType(e)}
                      />
                    </div>

                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Discount Value <span className="red">*</span>
                      </label>
                      <input
                        value={discountValue}
                        onChange={(event) =>
                          // setDiscountValue(event.target.value)
                          handleCheckDiscount(event)
                        }
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Price <span className="red">*</span>
                      </label>
                      <input
                        value={price}
                        onChange={(event) => setPrice(event.target.value)}
                        type="number"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid From <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid Till <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validTill}
                        onChange={(e) => setValidTill(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </div>

                <hr />
                {/* <div className="row">
                  <div className="col-9">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Variant</th>
                          <th scope="col">Selling Price</th>
                          <th scope="col">Discount Value(%)</th>
                          <th scope="col">Current Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el.productName}</th>
                                <th scope="row">{el.variantName}</th>

                                <td>
                                  <input disabled type="number" className="form-control" value={el?.sellingPrice} />
                                </td>

                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      !(e.target.value > 100) && handleValueChange(index, e.target.value, "discountValue");
                                    }}
                                    value={el?.discountValue}
                                  />
                                </td>
                                <td>
                                  <input type="number" className="form-control" onChange={(e) => handleValueChange(index, e.target.value, "quantity")} disabled value={el?.quantity} />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
