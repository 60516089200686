import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as COMBOOFFER from "../../actions/ComboOffer/ComboOffer.actions";


const initialState = {
  comboOffers: null,
  comboOfferObj: null,
  loading: false,
  error: null,
  comboOfferPaginationObj: null,
};


export const comboOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMBOOFFER.COMBOOFFER_ADD:
      return {
        ...state,
        loading: true,
      };
    case COMBOOFFER.COMBOOFFER_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case COMBOOFFER.COMBOOFFER_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMBOOFFER.GET_ALL_COMBOOFFERS:
      return {
        ...state,
        loading: true,
      };
    case COMBOOFFER.GET_ALL_COMBOOFFERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        comboOffers: action.payload.data,
        comboOfferPaginationObj: action.payload.comboPaginationObj,
      };
    case COMBOOFFER.GET_ALL_COMBOOFFERS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMBOOFFER.DELETE_COMBOOFFER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case COMBOOFFER.DELETE_COMBOOFFER_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case COMBOOFFER.DELETE_COMBOOFFER_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case COMBOOFFER.SET_COMBOOFFER_OBJ:
      return {
        ...state,
        loading: true,
      };
    case COMBOOFFER.SET_COMBOOFFER_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        comboOfferObj: action.payload.data,
        loading: false,
        error: null,
      };
    case COMBOOFFER.SET_COMBOOFFER_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case COMBOOFFER.UPDATE_COMBOOFFER_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case COMBOOFFER.UPDATE_COMBOOFFER_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case COMBOOFFER.UPDATE_COMBOOFFER_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
