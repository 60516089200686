import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as AREA from "../../actions/Area/Area.actions";

const initialState = {
  areas: null,
  areasObj: null,
  loading: false,
  error: null,
  paginationObj: null,
};

export const areaReducer = (state = initialState, action) => {
  switch (action.type) {
    case AREA.AREA_ADD:
      return {
        ...state,
        loading: true,
      };
    case AREA.AREA_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case AREA.AREA_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
      

    case AREA.GET_AREA_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case AREA.GET_AREA_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        areas: action.payload.data,
      };
    case AREA.GET_AREA_BY_ID_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };




    case AREA.GET_ALL_AREA:
      return {
        ...state,
        loading: true,
      };
    case AREA.GET_ALL_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        areas: action.payload.data,
        paginationObj: action.payload.paginationObject,
      };
    case AREA.GET_ALL_AREA_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };




    case AREA.DELETE_AREA_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case AREA.DELETE_AREA_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case AREA.DELETE_AREA_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AREA.UPDATE_AREA_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case AREA.UPDATE_AREA_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case AREA.UPDATE_AREA_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case AREA.SET_AREA_OBJ:
      return {
        ...state,
        loading: true,
      };
    case AREA.SET_AREA_OBJ_SUCCESS:
      // toastSuccess(action.payload.message);
      return {
        ...state,
        areasObj: action.payload.data,
        loading: false,
        error: null,
      };
    case AREA.SET_AREA_OBJ_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
