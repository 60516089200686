import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import { generateFilePath } from "../Utility/utils";
import { getBlog } from "../../services/blog.service";
import { toastError } from "../../utils/toastUtils";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";

function Blog() {
  const blogArray = useSelector((state) => state.blog.blogs);
  let dispatch = useDispatch();
  const blogPaginationObject = useSelector(
    (state) => state.blog.blogPaginationObj
  );

  const [blogArr, setBlogArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleBlogGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}&role=ADMIN`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"queryOf22323")
      //   dispatch(BLOGGet(queryOf));
      let { data: get } = await getBlog(queryOf);
      // console.log(get, "page =get bl;og");
      if (get?.success) {
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
        setBlogArr(get?.data);
        setMainArr(get?.data);
        // setPerPage(get?.perPage);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(page, perPage, query, "page, blog===");
    handleBlogGet();
  }, [page, perPage, query]);

  useEffect(() => {
    if(blogArray&&blogArray.length>=0){
      setBlogArr(blogArray)
      setMainArr(blogArray)
    }
    if (blogPaginationObject) {
      setTotalPages(blogPaginationObject?.totalPages);
      setTotalCount(blogPaginationObject?.totalCount);
      setPage(blogPaginationObject?.page);
    }
   
  }, [blogArray, blogPaginationObject]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleBlogDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}&role="ADMIN"`;
      dispatch(blogDelete(row,query));
    }
  };
  const handleBlogEdit = (row) => {
    dispatch(SetblogObj(row));
  };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
    },
    {
      name: "Category",
      selector: (row) => row?.categoryName,
    },

    {
      name: "slug",
      grow: 0,
      selector: (row) => row?.slug,
    },
    {
      name: "image",
      grow: 0,
      cell: (row) => (
        <img
          height="84px"
          width="56px"
          alt={"ALT"}
          src={generateFilePath(row?.image)}
        />
      ),
    },
    {
      name: "Published",
      grow: 0,
      selector: (row) => (row?.publish ? "YES" : "NO"),
    },
    {
      name: "Tags",
      grow: 0,
      selector: (row) => row?.tags,
      width: "20%",
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          remove
          edit
          // detail
          detailpath="/Blog/View-Post"
          Uniquekey={row.id}
          deletePath="/Blog/post"
          onDeleteClick={() => {
            handleBlogDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleBlogEdit(row);
          }}
          editPath="/Blog/post/create"
        />
      ),
    },
  ];

  const blog_data = [
    {
      id: "1",
      Seq: "1",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "2",
      Seq: "2",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "3",
      Seq: "3",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "4",
      Seq: "4",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "5",
      Seq: "5",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "6",
      Seq: "6",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "7",
      Seq: "7",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "8",
      Seq: "8",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "9",
      Seq: "9",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "10",
      Seq: "10",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Blog List</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW BLOG"
                    path="/Blog/post/create"
                  />
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Blog;
