import {
  addTeams,
  deleteTeam,
  getTeams,
  updateTeam,
} from "../../../services/team.service";

export const TEAM_ADD = "TEAM_ADD";
export const TEAM_ADD_SUCCESS = "TEAM_ADD_SUCCESS";
export const TEAM_ADD_FAIL = "TEAM_ADD_FAIL";

export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_FAIL = "GET_ALL_TEAMS_FAIL";

export const UPDATE_TEAM_BY_ID = "UPDATE_TEAM_BY_ID";
export const UPDATE_TEAM_BY_ID_SUCCESS = "UPDATE_TEAM_BY_ID_SUCCESS";
export const UPDATE_TEAM_BY_ID_FAIL = "UPDATE_TEAM_BY_ID_FAIL";

export const SET_TEAM_OBJ = "SET_TEAM_OBJ";
export const SET_TEAM_OBJ_SUCCESS = "SET_TEAM_OBJ_SUCCESS";
export const SET_TEAM_OBJ_FAIL = "SET_TEAM_OBJ_FAIL";

export const GET_TEAM_BY_ID = "GET_TEAM_BY_ID";
export const GET_TEAM_BY_ID_SUCCESS = "GET_TEAM_BY_ID_SUCCESS";
export const GET_TEAM_BY_ID_FAIL = "GET_TEAM_BY_ID_FAIL";

export const DELETE_TEAM_BY_ID = "DELETE_TEAM_BY_ID";
export const DELETE_TEAM_BY_ID_SUCCESS = "DELETE_TEAM_BY_ID_SUCCESS";
export const DELETE_TEAM_BY_ID_FAIL = "DELETE_TEAM_BY_ID_FAIL";
export const UPDATE_STATUS_SUCCESS= "UPDATE_STATUS_SUCCESS";

export const TEAMGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_TEAMS });
    let { data: response } = await getTeams(formData);
    // console.log(response, "response356")
    if (response) {
      dispatch({
        type: GET_ALL_TEAMS_SUCCESS,
        payload: {
          data: response.data,
          message: response.message,
          paginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TEAM_ADD_FAIL, payload: err });
  }
};

export const TEAMADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: TEAM_ADD });
    let { data: response } = await addTeams(formData);
    if (response) {
      dispatch(TEAMGET());
      dispatch({
        type: TEAM_ADD_SUCCESS,
        payload: response.message,
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: TEAM_ADD_FAIL, payload: err });
  }
};

export const SETTEAMOBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_TEAM_OBJ });
    if (formData) {
      dispatch({
        type: SET_TEAM_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_TEAM_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_TEAM_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const TEAMUPDATE = (id, formData,query) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TEAM_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateTeam(id, formData);
    if (response) {
      // console.log(response);
      dispatch({
        type: UPDATE_TEAM_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(TEAMGET(query));
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_TEAM_BY_ID_FAIL, payload: err });
  }
};

export const TEAMDELETE = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEAM_BY_ID });
    let { data: response } = await deleteTeam(id);
    if (response) {
      // console.log(response, "response");
      dispatch({
        type: DELETE_TEAM_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(TEAMGET(query));
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_TEAM_BY_ID_FAIL, payload: err });
  }
};


export const setPaginationState = (formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_STATUS_SUCCESS, payload: formData  });
  } catch (err) {
   console.log(err)
  }
};

