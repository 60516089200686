import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
// import { FLASH_SALESDelete, FLASH_SALESGet } from "../../redux/actions/FlashSales/FlashSales.actions";
// import { getProductStockBySellerId } from "../../redux/actions/ProductStock/ProductStock.actions";
import {
  FLASHSALESGET,
  FLASHSALESDELETE,
} from "../../redux/actions/FlashSales/FlashSales.actions";
import { rolesObj } from "../../utils/roles";

import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { getData } from "../../services/flashSales.services";



export default function ViewFlashSales() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const stockArr = useSelector((state) => state.flashSales.flashSaless);
  const role = useSelector((state) => state.auth.role);

  const [orderArr, setOrderArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const flashDealPaginationObject = useSelector(
    (state) => state.flashSales.flashSalesPaginationObj
  );

  const handleGet = async () => {
    try{
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
    }
    // console.log(queryOf,"queryOf22323")
    // dispatch(FLASHSALESGET(queryOf));
    let { data: get } = await getData(queryOf);
    // console.log(get,"get =tosaydeal")
    if (get) {
      setOrderArr(get?.data);
      setMainArr(get?.data);
      setTotalPages(get?.totalPages);
      setTotalCount(get?.totalCount);
      setPage(get?.page);
      setPerPage(get?.perPage)
    }
  }catch(err){
    toastError(err)
  }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  useEffect(() => {
    // console.log(flashDealPaginationObject,"flashDealPaginationObject231  ")
    // console.log(stockArr,"stockArrstockArr  ")
    if (stockArr && stockArr.length >= 0) {
      setOrderArr(stockArr);
      setMainArr(stockArr);
    }
    if (flashDealPaginationObject) {
      setTotalPages(flashDealPaginationObject?.totalPages);
      setTotalCount(flashDealPaginationObject?.totalCount);
      setPage(flashDealPaginationObject?.page);
    }

  }, [stockArr,flashDealPaginationObject]);

  const handleDeleteById = async (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(FLASHSALESDELETE(id,query));
    }
  };

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Valid From",
      selector: (row) => new Date(row?.validFrom).toDateString(),
    },
    {
      name: "Valid Till",
      selector: (row) => new Date(row?.validTill).toDateString(),
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/ViewTodayDeals"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => {
              // dispatch(SetPRODUCTObj(row))
              // handleEdit(row)
            }}
            edit
            editPath={`/AddTodayDeals?saleId=${row?._id}`}
          />
          {/* <button className="btn " 
              onClick={(e) => handleModalSet(e, row)}>
          <i className="fa-solid fa-eye" >    </i></button> */}
        </>
      ),
    },
  ];

  // ==============================================================================================================
  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">View Deals</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={orderArr && orderArr.length > 0 ? orderArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

// const handleValueChange = (index, value, key) => {
//   console.log(index, "index", value, "value", key, "key");
//   let tempArr = selectedProductArr;
// console.log(tempArr,"tempArr23")
//   if (key == "discountType") {
//     tempArr[index][key] = value?.value;
//     tempArr[index]["discountTypeObj"] = value;
//     // console.log(tempArr, "TE");
//     setSelectedProductArr([...tempArr]);
//   } else if (key == "sellingPrice") {
//     tempArr[index][key] = value;
//     setSelectedProductArr([...tempArr]);
//   } else {
//     if (!(value < 0)) {
//       console.log(value, "value");
//       if (key == tempArr["discountType"].value) {
//         if (value?.value == "AMOUNT_OFF") {
//         } else {
//           if (value < 100) {
//             tempArr[index][key] = value;
//           }
//         }
//       }
//     }
//     tempArr[index][key] = value;
//     setSelectedProductArr([...tempArr]);
//   }
//   // console.log(tempArr);
// };
// useEffect(()=>{

//   if (productCategoriesArr) {
//     for (let el of productCategoriesArr) {
//       // console.log(el._id, " el._id32");
//       setOptions({...options, value: el._id, label: el.name });
//     }
//   }
// },[productCategoriesArr])
