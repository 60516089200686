import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  ATTRIBUTEAdd,
  ATTRIBUTEGet,
  ATTRIBUTE_Update,
  SetATTRIBUTE_VALUEObj,
  ATTRIBUTE_VALUE_Get,
  setAttributeValueStatus,
} from "../../../redux/actions/Attribute/Attribute.actions";

import { toastError } from "../../Utility/ToastUtils";

function AddAttribute() {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedAttributeIdArr, setSelectedAttributeIdArr] = useState([]);
  const [attributeValueId, setattributeValueId] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const attributeValueArr = useSelector(
    (state) => state.attribute.attributeValues
  );
  const attributeValueObject = useSelector(
    (state) => state.attribute.attributeValueObj
  );

  const AttributeAddOrUpdateSucces = useSelector(
    (state) => state.attribute.attributeAddOrUpdateSuccessful
  );

  const attributeStat = useSelector((state) => state.attribute.attributeStatus);

  const [attributeValueArray, setAttributeValueArray] = useState([]);
  const [attributeObject, setAttributeObject] = useState({});

  useEffect(() => {
    setAttributeValueArray(attributeValueArr);
  }, [attributeValueArr]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const clearFunc = () => {
    setName("");
    setDescription("");
    setStatus(generalModelStatuses.APPROVED);
    setattributeValueId("");
    dispatch(SetATTRIBUTE_VALUEObj(null));
    setSelectedAttributeIdArr([]);
    setAttributeValueArray([]);
    dispatch(ATTRIBUTE_VALUE_Get());
  };

  useEffect(() => {
    // console.log(AttributeAddOrUpdateSucces, "==AttributeAd==");
    if (AttributeAddOrUpdateSucces && AttributeAddOrUpdateSucces?.message) {
      clearFunc();
      // console.log("insode-------------------")
    }
    return () => {
      dispatch(setAttributeValueStatus(null));
    };
  }, [AttributeAddOrUpdateSucces]);

  // useEffect(() => {
  //   if (attributeStat) {
  //     // console.log(attributeValueStat, "productStat");
  //     clearFunc();
  //   }
  // }, [attributeStat]);

  useEffect(() => {
    // console.log(attributeValueObject, "attributeValueOj3214 odex")
    if (attributeValueObject && attributeValueObject._id) {
      setAttributeObject(attributeValueObject);
    }
  }, [attributeValueObject]);

  const handleSubmit = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      if (description == "") {
        toastError("Description is mandatory");
        return;
      }
      if (!(selectedAttributeIdArr?.length > 0)) {
        toastError("Attribute is mandatory , please select atleast one value");
        return;
      }
      let obj = {
        name,
        description,
        // attributeValueArr: selectedAttributeIdArr.map((el) => ({ attributeId: el._id })),
        status,
      };
      if (attributeObject && attributeObject?.name && attributeValueId) {
        obj.attributeValueArr = selectedAttributeIdArr.map((el) => ({
          attributeId: el.value,
        }));
      } else {
        obj.attributeValueArr = selectedAttributeIdArr.map((el) => ({
          attributeId: el._id,
        }));
      }

      if (attributeObject && attributeValueId) {
        let queryOf = `page=${page}&perPage=${perPage}`;
        //  let queryOf = `page=${page}`;
        dispatch(ATTRIBUTE_Update(obj, attributeValueId, queryOf));
      } else {
        dispatch(ATTRIBUTEAdd(obj));
      }
      // console.log(getResponse, "getResponse get attribute ");
    } catch (err) {
      // console.log(err,"ererr get ")
      toastError(err);
    }
  };

  useEffect(() => {
    if (attributeObject && attributeObject.name) {
      // console.log(attributeObject, "attributeObject3543");

      setName(attributeObject?.name);
      setDescription(attributeObject?.description);
      if (attributeObject && attributeObject?.attributeValueArr) {
        setSelectedAttributeIdArr([...attributeObject?.attributeValueArr]);
      }
      setattributeValueId(attributeObject?._id);
      setStatus(attributeObject?.status);
      setPage(attributeObject?.page);
      setPerPage(attributeObject?.perPage);
    }
    return () => {
      // alert("hgello")
      dispatch(SetATTRIBUTE_VALUEObj(null))
    };
  }, [attributeObject]);

  useEffect(() => {
    // dispatch(ATTRIBUTEGet());
    dispatch(ATTRIBUTE_VALUE_Get(`get=all`));
  }, []);

  const handleAttributeValueChange = (e) => {
    setSelectedAttributeIdArr(e);
  };

  return (
    <form action="#" className="form row">
      <div className="col-12">
        <label className="blue-1 fs-12">
          Name <span className="red">*</span>
        </label>
        <input
          value={name}
          onChange={(event) => setName(event.target.value)}
          type="text"
          className="form-control"
        />
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">DESCRIPTION</label>
        <textarea
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          className="form-control"
          rows="5"
        ></textarea>
      </div>
      <div className="col-12">
        <label className="blue-1 fs-12">Attribute</label>
        <Select
          styles={{ height: "100%" }}
          onChange={handleAttributeValueChange}
          value={selectedAttributeIdArr}
          closeMenuOnSelect={false}
          isMulti
          options={
            attributeValueArray && attributeValueArray.length > 0
              ? attributeValueArray.map((el) => ({
                  ...el,
                  label: el.name,
                  value: el._id,
                }))
              : []
          }
        />
      </div>
      {/* <div className="col-12">
        <label className="blue-1 fs-12">Status</label>
        <div className="d-flex">

          <div className="form-check form-check-inline d-flex align-items-center">
            <input className="form-check-input"
             type="radio" name="category-status"
             value={status}
              checked={status == generalModelStatuses.APPROVED} 
              onClick={() => setStatus(generalModelStatuses.APPROVED)} id="category-Radio1" />
            <label className="form-check-label fs-14" htmlFor="category-Radio1">
              Active
            </label>
          </div>



          <div className="form-check form-check-inline d-flex align-items-center">
            <input className="form-check-input" 
            type="radio" name="category-status" 
            value={status}
             checked={status == generalModelStatuses.DECLINED} 
            onClick={() => setStatus(generalModelStatuses.DECLINED)} id="category-Radio2" />
            <label className="form-check-label fs-14" htmlFor="category-Radio2">
              Inactive
            </label>
          </div>
        </div>
      </div> */}
      <div className="col-12 mt-2">
        <CustomButton
          btntype="button"
          ClickEvent={handleSubmit}
          isBtn
          iconName="fa-solid fa-check"
          btnName="Save"
        />
      </div>
    </form>
  );
}

export default AddAttribute;
