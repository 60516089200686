import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as SELLERCATEGORY from "../../actions/SellerCategory/SellerCategory.actions";

const initialState = {
  sellerCategorys: null,
  sellerCategoryObj: null,
  sellerCategoryPaginationObj:null,
  
  loading: false,
  error: null,
};


export const sellerCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELLERCATEGORY.SELLERCATEGORY_ADD:
      return {
        ...state,
        loading: true,
      };
    case SELLERCATEGORY.SELLERCATEGORY_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case SELLERCATEGORY.SELLERCATEGORY_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SELLERCATEGORY.GET_ALL_SELLERCATEGORYS:
      return {
        ...state,
        loading: true,
      };
    case SELLERCATEGORY.GET_ALL_SELLERCATEGORYS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sellerCategorys: action.payload.data,
        sellerCategoryPaginationObj:action.payload.paginationObj,
        // sellerCategorys: action.payload.data,
      };
      // case SELLERCATEGORY.GET_PAGINATION_DATA_SUCCESS:
      //   return{
      //     ...state,
      //     loading:false,
      //     error:null,
      //     sellerCategoryPaginationObj:action.payload.data
          
      //   }
    case SELLERCATEGORY.GET_ALL_SELLERCATEGORYS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SELLERCATEGORY.DELETE_SELLERCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SELLERCATEGORY.DELETE_SELLERCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SELLERCATEGORY.DELETE_SELLERCATEGORY_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SELLERCATEGORY.SET_SELLERCATEGORY_OBJ:
      return {
        ...state,
        loading: true,
      };
    case SELLERCATEGORY.SET_SELLERCATEGORY_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        sellerCategoryObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SELLERCATEGORY.SET_SELLERCATEGORY_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SELLERCATEGORY.UPDATE_SELLERCATEGORY_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SELLERCATEGORY.UPDATE_SELLERCATEGORY_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SELLERCATEGORY.UPDATE_SELLERCATEGORY_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
