import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import {
  FLASHSALESGET,
  FLASHSALESADD,
  SETFLASHSALESOBJ,
  FLASHSALESUPDATE,
  FLASHSALESDELETE,
} from "../../redux/actions/FlashSales/FlashSales.actions";

import {
  getFlashSaleById,
  // getProductForSales,
  add,
  getObjById,
  updateById,
} from "../../services/flashSales.services";

import { getProducts, getFilterPRoduct } from "../../services/product.service";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError, toastSuccess } from "../Utility/ToastUtils";
import { discountTypeArr } from "../Utility/utils";
import { useSearchParams } from "react-router-dom";
import moment from "moment";


export default function AddFlashSales() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectAll, setSelectAll] = useState(false);

  const [name, setName] = useState("");
  const [email, SetEmail] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTill, setValidTill] = useState("");
  const [prevObjId, SetPrevObjId] = useState("");

  const [productNameFilter, setProductNameFilter] = useState("");
  const authObj = useSelector((state) => state.auth);


  const handleProductSelect = (val) => {
    setSelectedProductArr([...val]);
  };

  const handleGetProductsAndAttributesAsProduct = async () => {
    try {
      // let { data: res } = await getProducts(authObj?.user?._id);
      let { data: res } = await getFilterPRoduct(
        `filterProduct=${productNameFilter}`
      );

      if (res.data) {
        // console.log(res.data, " filterDA");
        setProductInternalArr(res?.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  // useEffect(() => {
  //   if (!searchParams.get("saleId") || searchParams.get("saleId") == "") {
  //     if (productInternalArr && productInternalArr.length > 0 && selectAll) {
  //       setSelectedProductArr([
  //         ...productInternalArr.map((el) => {
  //           return {
  //             ...el,
  //             label: `${el?.name} `,
  //             value: `${el?.name} `,
  //           };
  //         }),
  //       ]);
  //     } else {
  //       setSelectedProductArr([]);
  //     }
  //   }
  // }, [selectAll, productInternalArr]);

  ////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////

  const handleValueChange = (index, value, key) => {
    let tempArr = selectedProductArr;
    // console.log(tempArr, "tempArr235");
    if (key == "discountType") {
      tempArr[index][key] = value?.value;
      tempArr[index]["discountTypeObj"] = value;
      setSelectedProductArr([...tempArr]);
    } else if (key == "price") {
      tempArr[index][key] = value;
      setSelectedProductArr([...tempArr]);
    } else if (key == "discountValue") {
      if (value > -1) {
        if (tempArr[index]["discountTypeObj"]?.value == "PERCENTAGE_OFF") {
          if (value > 100) {
            toastError("discount cannot be greater be 100%");
            return;
          } else {
            tempArr[index][key] = value;
            setSelectedProductArr([...tempArr]);
          }
        } else {
          if (+value > +tempArr[index]["price"]) {
            toastError("Discount cannot be more then Seling Price");
            return;
          } else {
            tempArr[index][key] = value;
            setSelectedProductArr([...tempArr]);
          }
        }
      } else {
        toastError("Value must be greater than 0");
        return;
      }
    }

    // else {
    //   if (!(value < 0)) {
    //     if (tempArr[index]["discountTypeObj"]?.value == "PERCENTAGE_OFF") {
    //       if (value >= 100) {
    //         toastError("discount cannot be greater be 100%");
    //         return;
    //       } else {
    //         tempArr[index][key] = value;
    //         setSelectedProductArr([...tempArr]);
    //       }
    //       if (key == "discountValue") {
    //         if (+value > +tempArr[index]["sellingPrice"]) {
    //           toastError("Discount cannot be more then Seling Price");
    //           return;
    //         }
    //       }
    //     }
    // else if(){

    // else if(key=="quantity"){

    //   tempArr[index][key] = value;
    //   setSelectedProductArr([...tempArr]);
    // }else {
    //   tempArr[index][key] = value;
    //   setSelectedProductArr([...tempArr]);
    // }
    // }
    // }
    // }
  };

  useEffect(() => {
    handleGetProductsAndAttributesAsProduct();
    handleGetCurrentFlashSale();
  }, []);

  const handleGetCurrentFlashSale = async () => {
    try {
      if (searchParams.get("saleId")) {
        const { data: res } = await getObjById(searchParams.get("saleId"));
        console.log(res, "23423res get");
        if (res) {
          setName(res?.data?.name);

          if (res?.data?.validTill) {
            setValidTill(moment(res?.data?.validTill).format("YYYY-MM-DD"));
          }
          if (res?.data?.validFrom) {
            setValidFrom(moment(res?.data?.validFrom).format("YYYY-MM-DD"));
          }
          // setSelectedProductArr([...res.data.productArr]);

          if (res.data.productArr && res.data.productArr.length > 0) {
            setSelectedProductArr([
              ...res?.data?.productArr?.map((el) => {
                return {
                  ...el,
                  label: `${el?.name} `,
                  value: `${el?.name} `,
                };
              }),
            ]);
          } else {
            setSelectedProductArr([]);
          }
        }
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleClear = () => {
    try {
      setSelectedProductArr([]);
      setName("");
      setValidFrom("");
      setValidTill("");
    } catch (err) {
      toastError(err);
    }
  };

  const handleSubmit = async (e) => {
    try {
      // console.log(selectedProductArr, "selectedProductArr ==get");
      e.preventDefault();
      if (name == "") {
        toastError("Name is mandatory");
        return;
      } else if (validFrom == "") {
        toastError("Valid From is mandatory");
        return;
      } else if (validTill == "") {
        toastError("Valid Till is mandatory");
        return;
      } else if (!(selectedProductArr.length > 0)) {
        toastError("Please select products to proceed");
        return;
      }
      //  else if (!selectedProductArr.every((el) => el.sellingPrice > 0)) {
      //   toastError("Please add selling price to all the products");
      //   return;
      // }
      else if (!selectedProductArr.every((el) => el.discountValue > 0)) {
        toastError("Please add discount Value to all the products");
        return;
      } else if (
        selectedProductArr.every(
          (el) => el?.discountType == "" || el?.discountType == undefined
        )
      ) {
        toastError("Please add discount Type to all the products");
        return;
      }
      const fromDate = new Date(validFrom);
      const toDate = new Date(validTill);
      if (toDate < fromDate) {
        toastError(" Valid From date must be less than valid till date");
        return;
      }

      let obj = {
        productArr: selectedProductArr.map((el) => {
          let tempObj = {
            name: el?.name,
            productId : el?.productId?el?.productId:  el?._id,
            // variantId: el?.variantId,
            discountValue: el?.discountValue,
            sellingPrice: el?.price?el?.price:el?.sellingPrice,
            discountType: el?.discountType,
            // currentStock: el?.stock,
            // sku: el?.sku,
          };
          return tempObj;
        }),
        name,
        validFrom,
        validTill,
        // sellerId: authObj?.user?._id,
      };

      // console.log(obj, "object2 get flashsales");
      if (searchParams.get("saleId")) {
        dispatch(FLASHSALESUPDATE(searchParams.get("saleId"), obj));
      } else {
        dispatch(FLASHSALESADD(obj));
      }
      handleClear();
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="blue-1 m-0"> Products</h5>
                    {/* <div
                      style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}
                    >
                      <input
                        checked={selectAll}
                        onChange={(e) => setSelectAll((prev) => !prev)}
                        type="checkbox"
                      />
                    </div>
                    <label>
                      {selectAll == false ? "Select" : "Un-Select"} All
                    </label> */}
                  </div>
                  <div style={{ marginTop: 5 }}>
                    <label>
                      Please select products from the list
                      <span className="red">*</span>
                    </label>
                    <Select
                      options={[
                        ...productInternalArr.map((el) => {
                          return {
                            ...el,
                            label: `${el?.name} `,
                            value: `${el?.name} `,
                          };
                        }),
                      ]}
                      isMulti
                      placeholder="Select from Products"
                      value={selectedProductArr}
                      onChange={(e) => {
                        handleProductSelect(e);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-1 ">Other Info</h5>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        Name <span className="red">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid From <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validFrom}
                        onChange={(e) => setValidFrom(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3 ">
                      <label>
                        Valid Till <span className="red">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={validTill}
                        onChange={(e) => setValidTill(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </div>

                <hr />
                <div className="row">
                  <div className="col-12">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          {/* <th scope="col">Variant</th> */}
                          {/* <th scope="col">Sku</th> */}
                          <th scope="col">Selling Price</th>
                          <th scope="col">Type</th>

                          <th scope="col">Discount Value(%)</th>
                          {/* <th scope="col">Current Stock</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {console.log(
                          selectedProductArr,
                          "selectedProductArr23534 get"
                        )} */}
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el?.name}</th>
                                {/* <th scope="row">{el?.sku}</th> */}

                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={el?.price?el?.price:el?.sellingPrice}
                                    onChange={(e) => {
                                      if (e.target.value < 0) {
                                        toastError("Value cannot be negative");
                                        return;
                                      }
                                      handleValueChange(
                                        index,
                                        e.target.value,
                                        "price"
                                      );
                                    }}
                                  />
                                </td>

                                <td>
                                  <Select
                                    options={discountTypeArr}
                                    defaultInputValue={el?.discountType}
                                    value={{
                                      label: el?.discountType,
                                      value: el.discountType,
                                    }}
                                    onChange={(e) =>
                                      handleValueChange(
                                        index,
                                        e,
                                        "discountType"
                                      )
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      // !(e.target.value > 100) &&
                                      handleValueChange(
                                        index,
                                        e.target.value,
                                        "discountValue"
                                      );
                                    }}
                                    value={el?.discountValue}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}

// export default function AddFlashSales() {

//   return (
//     <section className="product-category">
//       <div className="container-fluid p-0">
//         <div className="row d-flex px-3">
//           <div className="col-12 col-md-12">
//             <DashboardBox>
//               <form className="form row">
//                 <div className="col-12 col-md-6 pb-4">
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       alignItems: "center",
//                     }}
//                   >
//                     <h5 className="blue-1 m-0"> Products</h5>
//                     <div
//                       style={{ marginLeft: 25, marginTop: 3, marginRight: 5 }}
//                     >
//                       <input
//                         checked={selectAll}
//                         onChange={(e) => setSelectAll((prev) => !prev)}
//                         type="checkbox"
//                       />
//                     </div>
//                     <label>
//                       {selectAll == false ? "Select" : "Un-Select"} All
//                     </label>
//                   </div>
//                   <div style={{ marginTop: 5 }}>
//                     <label>
//                       Please select products from the list
//                       <span className="red">*</span>
//                     </label>
//                     {/* {console.log(productInternalArr,"productInternalArr get ")} */}
//                     <Select
//                       options={[
//                         ...productInternalArr.map((el) => {
//                           return {
//                             ...el,
//                             label: `${el?.name ? el?.name : ""}`,
//                             value: `${el?.name ? el?.name : ""}`,
//                           };
//                         }),
//                       ]}
//                       isMulti
//                       placeholder="Select from Products"
//                       value={selectedProductArr}
//                       onChange={(e) => {
//                         console.log(e, "eeeeee get");
//                         handleProductSelect(e);
//                       }}
//                       // onChange={handleProductSelect}
//                       onInputChange={(e) => handleInputChange(e)}
//                     />
//                     {/* <Select
//                       isMulti
//                       value={selectedProductArr}
//                       onChange={(e) => {
//                         console.log(e,"eeeeeeeeee get")
//                         // handleProductSelect(e.target?.value)
//                       }}
//                       onInputChange={ handleInputChange}
//                     /> */}
//                   </div>
//                 </div>
//                 <div className="col-12 col-md-6 pb-4">
//                   <h5 className="blue-1 mt-1 ">Other Info</h5>
//                   <div className="row">
//                     <div className="col-12 col-md-12 mb-3 ">
//                       <label>
//                         Name <span className="red">*</span>
//                       </label>
//                       <input
//                         value={name}
//                         onChange={(event) => setName(event.target.value)}
//                         type="text"
//                         className="form-control"
//                       />
//                     </div>
//                     <div className="col-12 col-md-6 mb-3 ">
//                       <label>
//                         Valid From <span className="red">*</span>
//                       </label>
//                       <input
//                         type="date"
//                         className="form-control"
//                         value={validFrom}
//                         onChange={(e) => setValidFrom(e.target.value)}
//                       />
//                     </div>
//                     <div className="col-12 col-md-6 mb-3 ">
//                       <label>
//                         Valid Till <span className="red">*</span>
//                       </label>
//                       <input
//                         type="date"
//                         className="form-control"
//                         value={validTill}
//                         onChange={(e) => setValidTill(e.target.value)}
//                       />
//                     </div>
//                   </div>
//                   <div className="col-12">
//                     <CustomButton
//                       btntype="button"
//                       iconName="fa-solid fa-check"
//                       btnName="Save"
//                       isBtn
//                       // small={makeChange ? true : false}
//                       ClickEvent={handleSubmit}
//                     />
//                   </div>
//                 </div>

//                 <hr />
//                 <div className="row">
//                   <div className="col-12">
//                     <h5 className="blue-1 m-0">Selected Product List</h5>

//                     <table class="table">
//                       <thead>
//                         <tr>
//                           <th scope="col">Name</th>
//                           {/* <th scope="col">Variant</th> */}
//                           {/* <th scope="col">Sku</th> */}
//                           <th scope="col" style={{ width: "15%" }}>
//                             Selling Price
//                           </th>
//                           <th scope="col">Type</th>

//                           <th scope="col">Discount Value(%)</th>
//                           {/* <th scope="col">Current Stock</th> */}
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {/* {console.log(
//                           selectedProductArr,
//                           "selectedProductArr get"
//                         )} */}
//                         {selectedProductArr &&
//                           selectedProductArr.length > 0 &&
//                           selectedProductArr.map((el, index) => {
//                             return (
//                               <tr key={index}>
//                                 <th scope="row">{el?.name}</th>
//                                 {/* <th scope="row">{el?.sku}</th> */}
//                                 <td>
//                                   <input
//                                     // style={{ width: "770%" }}
//                                     type="number"
//                                     className="form-control"
//                                     value={el?.price}
//                                     onChange={(e) => {
//                                       if (e.target.value < 0) {
//                                         toastError(
//                                           "Selling Price must be greater than 0"
//                                         );
//                                         return;
//                                       }
//                                       handleValueChange(
//                                         index,
//                                         e.target.value,
//                                         "sellingPrice"
//                                       );
//                                     }}
//                                   />
//                                 </td>
//                                 <td>
//                                   <Select
//                                     options={discountTypeArr}
//                                     defaultInputValue={el?.discountType}
//                                     value={{
//                                       label: el?.discountType,
//                                       value: el.discountType,
//                                     }}
//                                     onChange={(e) =>
//                                       handleValueChange(
//                                         index,
//                                         e,
//                                         "discountType"
//                                       )
//                                     }
//                                   />
//                                 </td>
//                                 <td>
//                                   <input
//                                     type="number"
//                                     className="form-control"
//                                     onChange={(e) => {
//                                       if (e.target.value < 0) {
//                                         toastError(
//                                           "Discount Value must be greater than 0"
//                                         );
//                                         return;
//                                       } else {
//                                         // !(e.target.value > 100) &&
//                                         handleValueChange(
//                                           index,
//                                           e.target.value,
//                                           "discountValue"
//                                         );
//                                       }
//                                     }}
//                                     value={el?.discountValue}
//                                   />
//                                 </td>
//                               </tr>
//                             );
//                           })}
//                       </tbody>
//                     </table>
//                   </div>
//                 </div>
//               </form>
//             </DashboardBox>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }
