import PendingOrder from "./PendingOrder";
import ConfirmedOrder from "./ConfirmedOrder";
import CompleteOrder from "./CompleteOrder";
import PendingPaymentOrder from "./PendingPaymentOrder";
import CancleOrder from "./CancleOrder";
import InhouseOrder from "./InhouseOrder";
import CustomButton from "../../Utility/Button";
import tabClick from "../../Utility/TabClick";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import { toastError, toastSuccess } from "../../../utils/toastUtils";
import ActionIcon from "../../Utility/ActionIcon";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";


// import { useHistory } from "react-router-dom";
// import Button from "@material-ui/core/Button";

import { Link, useNavigate, useParams } from "react-router-dom";

import { fetchAllOrders } from "../../../services/order.service";

function TotalOrder() {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [offerArr, setOfferArr] = useState([]);
  const [orderArr, setOrderArr] = useState([]);


  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const hsnTaxPaginationObject = useSelector(
    (state) => state.hsnTax.hsnTaxPaginationObj
  );

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      width: "5%",
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.createdAt)?.toLocaleString(),
    },
    {
      name: "User Name",
      sortable: true,
      selector: (row) =>
        row?.userObj?.firstName + "  " + row?.userObj?.lastName,
    },
    {
      name: "Grand Total",
      sortable: true,
      selector: (row) =>
        row?.currencyObj?.symbol + " " + row?.convertedTotalAmount,
    },
    {
      name: "Action",
      cell: (row) => (
        <>
            <Link to={`/Order/Sale-Detail/${row?._id}`}
          >
          <button
            className="btn btn-1  btn-sm  text-white"
            >
            <i className="fa-solid fa-eye"> </i>
          </button>
            </Link>
        </>
      ),
      width: "15%",
    },
   
  ];

  const [tabList, settabList] = useState([
    {
      tabName: "Pending Orders",
      active: true,
      render: <PendingOrder name="Pending Orders" />,
    },
    {
      tabName: "Confirmed Orders",
      active: false,
      render: <ConfirmedOrder name="Confirmed Orders" />,
    },
    {
      tabName: "Completed Orders",
      active: false,
      render: <CompleteOrder name="Completed Orders" />,
    },
    {
      tabName: "Pending Payment Orders",
      active: false,
      render: <PendingPaymentOrder name="Pending Payment Orders" />,
    },
    {
      tabName: "Refused/Cancelled Orders",
      active: false,
      render: <CancleOrder name="Refused/Cancelled Orders" />,
    },
    {
      tabName: "Inhouse Orders",
      active: false,
      render: <InhouseOrder name="Inhouse Orders" />,
    },
  ]);

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };

  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"queryO-order")   
      let { data: response } = await fetchAllOrders(queryOf);
      console.log(response, "====response===order==");
      if (response?.success) {
        setOrderArr(response?.data);
        setPage(response?.page)
        setPerPage(response?.perPage)
        setTotalCount(response?.totalCount)
        setTotalPages(response?.totalPages)


      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(page, "page,", perPage, ", perPage,query", query, ",location");
    handleGet();
  }, [page, perPage, query]);

  
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Total Order</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={orderArr && orderArr.length > 0 ? orderArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default TotalOrder;
