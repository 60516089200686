import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { generalModelStatuses } from "../Utility/constants";
import CustomButton from "../Utility/Button";
import FileUpload from "../Utility/FileUpload";

import {
  SELLERCATEGORYADD,
  SELLERCATEGORYGET,
  SELLERCATEGORYUPDATE,
} from "../../redux/actions/SellerCategory/SellerCategory.actions";

import { toastError, toastSuccess } from "../Utility/ToastUtils";




function AddSellerCategory({ data }) {
  const dispatch = useDispatch();
  const [sellerCategoryObj, setSellerCategoryObj] = useState({});
  const [name, setName] = useState("");
  const [commission, setCommission] = useState("");
  const [logisticsFee, setLogisticsFee] = useState("");

  const handleClearStates = () => {
    setName("");
    setCommission("");
    setLogisticsFee("");
    setSellerCategoryObj({});
  };

  const handleAddSellerCategory = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      if (commission == "") {
        toastError("Commission is mandatory");
        return;
      }
      // console.log(commission > 100, "commsission");
      if (commission > 100) {
        toastError("Commission cannot be more than 100");
        return;
      }

      let obj = {
        name,
        commission: parseInt(commission),
        logisticsFee: parseInt(logisticsFee),
      };

      if (sellerCategoryObj && sellerCategoryObj.name) {
        dispatch(SELLERCATEGORYUPDATE(sellerCategoryObj._id, obj));
        // dispatch(SETTESTIMONIALOBJ(null))
      } else {
        dispatch(SELLERCATEGORYADD(obj));
      }
      handleClearStates();

    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(data, "data");
    if (data && data.name) {
      setSellerCategoryObj(data);
      setName(data.name);
      setCommission(data.commission);
      setLogisticsFee(data.logisticsFee);
    }
  }, [data]);


  return (
    <div>
      <form className="form row">
        <div className={"col-12"}>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={"col-12 col-md-12"}>
          <label className="blue-1 fs-12">
            Commission (in percentage %)<span className="red">*</span>
          </label>
          <input
            value={commission}
            onChange={(event) => setCommission(event.target.value)}
            type="number"
            className="form-control"
          />
        </div>
        <div className={"col-12 col-md-12"}>
          <label className="blue-1 fs-12">
            Logistics Fee (Flat Amount)<span className="red">*</span>
          </label>
          <input
            value={logisticsFee}
            onChange={(event) => setLogisticsFee(event.target.value)}
            type="number"
            className="form-control"
          />
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddSellerCategory}
            iconName="fa-solid fa-check"
            btnName={`${
              sellerCategoryObj && sellerCategoryObj.name ? "Update" : "Save"
            }`}
            isBtn
          />
        </div>
      </form>
    </div>
  );
}

export default AddSellerCategory;
