import {
  addBlog,
  deleteBlog,
  getBlog,
  updateBlog,
} from "../../../services/blog.service";

export const BLOG_ADD = "BLOG_ADD";
export const BLOG_ADD_SUCCESS = "BLOG_ADD_SUCCESS";
export const BLOG_ADD_FAIL = "BLOG_ADD_FAIL";

export const GET_ALL_BLOGS = "GET_ALL_BLOGS";
export const GET_ALL_BLOGS_SUCCESS = "GET_ALL_BLOGS_SUCCESS";
export const GET_ALL_BLOGS_FAIL = "GET_ALL_BLOGS_FAIL";

export const UPDATE_BLOG_BY_ID = "UPDATE_BLOG_BY_ID";
export const UPDATE_BLOG_BY_ID_SUCCESS = "UPDATE_BLOG_BY_ID_SUCCESS";
export const UPDATE_BLOG_BY_ID_FAIL = "UPDATE_BLOG_BY_ID_FAIL";

export const SET_BLOG_OBJ = "SET_BLOG_OBJ";
export const SET_BLOG_OBJ_SUCCESS = "SET_BLOG_OBJ_SUCCESS";
export const SET_BLOG_OBJ_FAIL = "SET_BLOG_OBJ_FAIL";

export const GET_BLOG_BY_ID = "GET_BLOG_BY_ID";
export const GET_BLOG_BY_ID_SUCCESS = "GET_BLOG_BY_ID_SUCCESS";
export const GET_BLOG_BY_ID_FAIL = "GET_BLOG_BY_ID_FAIL";

export const DELETE_BLOG_BY_ID = "DELETE_BLOG_BY_ID";
export const DELETE_BLOG_BY_ID_SUCCESS = "DELETE_BLOG_BY_ID_SUCCESS";
export const DELETE_BLOG_BY_ID_FAIL = "DELETE_BLOG_BY_ID_FAIL";


export const UPDATE_BLOG_STATUS_SUCCESS = "UPDATE_BLOG_STATUS_SUCCESS";

export const BLOGGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_BLOGS });
    let { data: response } = await getBlog(formData);
    if (response) {
      // console.log(response, "action ");
      dispatch({
        type: GET_ALL_BLOGS_SUCCESS,
        payload: {
          data: response.data,
          message: response.message,
          paginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          },
        },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_BLOGS_FAIL, payload: err });
  }
};


export const blogAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_ADD });
    // console.log(formData, "form raction ");
    let { data: response } = await addBlog(formData);
    if (response) {
      // console.log(response, "response raction ");
      dispatch({
        type: BLOG_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch({
        type: UPDATE_BLOG_STATUS_SUCCESS,
        payload: response,
      });
      
      dispatch(BLOGGet());
      return response
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: BLOG_ADD_FAIL, payload: err });
  }
};


export const SetblogObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_BLOG_OBJ });
    if (formData) {
      // console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_BLOG_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_BLOG_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: SET_BLOG_OBJ_FAIL, payload: { message: "NOT FOUND" } });
  }
};

export const blogUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BLOG_BY_ID });
    // console.log(formData, "formData", id, "id");
    let { data: response } = await updateBlog(formData, id);
    if (response) {
      // console.log(response, "");
      dispatch({
        type: UPDATE_BLOG_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch({
        type: UPDATE_BLOG_STATUS_SUCCESS,
        payload: response,
      });
      dispatch(BLOGGet());
      return response
    }
  } catch (err) {
    console.error(err, "fdsafd");
    dispatch({ type: UPDATE_BLOG_BY_ID_FAIL, payload: err });
  }
};

export const blogDelete = (id,query) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BLOG_BY_ID });
    let { data: response } = await deleteBlog(id);
    if (response) {
      // console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_BLOG_BY_ID_SUCCESS,
        payload: response.msg,
      });
      dispatch(BLOGGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_BLOG_BY_ID_FAIL, payload: err });
  }
};

export const setBlogStatus = (formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_BLOG_STATUS_SUCCESS, payload: formData  });
  } catch (err) {
   
  }
};
