import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import AddFaqCategory from "./AddCategory";

import {
  SETFAQCATEGORYOBJ,
  FAQCATEGORYDELETE,
  FAQCATEGORYGET,setFaqCategoryStatus
} from "../../../redux/actions/FaqCategory/FaqCategory.actions";

import { getFaqCategory } from "../../../services/FaqCategory.service";
import { toastError } from "../../Utility/ToastUtils";

function FaqCategory() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);

  const faqCategoryArray = useSelector(
    (state) => state.faqCategory.faqCategorys
  );

  const faqCategoryObject = useSelector(
    (state) => state.faqCategory.faqCategoryObj
  );

  const [ModalData, setModalData] = useState({});

  const [displayShippingArr, setDisplayShippingArr] = useState([]);
  const [query, setQuery] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const faqCategoryPaginationObject = useSelector(
    (state) => state.faqCategory.faqcategoriesPaginationObj
  );

  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"queryOf22323")
      let { data: response } = await getFaqCategory(queryOf);
      // console.log(response,"get ====== faq categroy")
      if (response) {
        setDisplayShippingArr(response?.data);
        setTotalPages(response?.totalPages);
        setTotalCount(response?.totalCount);
        setPage(response?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleCategoryEdit = (row) => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    row.query=queryOf
    dispatch(SETFAQCATEGORYOBJ(row));
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      
      dispatch(FAQCATEGORYDELETE(row._id,query));
    }
  };

  useEffect(() => {
    // console.log(faqCategoryArray, "12312");
    if (faqCategoryArray && faqCategoryArray?.length >= 0) {
      setDisplayShippingArr(faqCategoryArray);
    }
   
  }, [faqCategoryArray]);

  useEffect(() => {
    // console.log(faqCategoryArray, "12312");
  
    if (faqCategoryPaginationObject) {
      setTotalPages(faqCategoryPaginationObject?.totalPages);
      setTotalCount(faqCategoryPaginationObject?.totalCount);
      setPage(faqCategoryPaginationObject?.page);
    }
    return ()=>{
      dispatch(setFaqCategoryStatus())
    }
    
  }, [faqCategoryPaginationObject]);

  
  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },

    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Faq/Category"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Faq/Category"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0"> */}
                {/* {faqCategoryObject && faqCategoryObject.name
                    ? "Edit "
                    : "Add"} */}
                {/* Faq Category */}
                {/* </h5> */}
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddFaqCategory />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Category List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    displayShippingArr && displayShippingArr.length > 0
                      ? displayShippingArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default FaqCategory;
