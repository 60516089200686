import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";
import { DashboardTable } from "../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import {
  BLOGGet,
  blogDelete,
  SetblogObj,
} from "../../redux/actions/Blog/Blog.actions";
import { getSubscriber } from "../../services/subscriber.service";
import { EditModal } from "../Utility/Modal";

function Subscriber() {
  let dispatch = useDispatch();
  const [blogArr, setBlogArr] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [ModalBox, setModalBox] = useState(false);
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [selectedData, setSelectedData] = useState(null);
  const [query, setQuery] = useState("");

  const handleBlogGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;

      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // console.log(queryOf,"get subsc")
      const { data: res } = await getSubscriber(queryOf);
      // console.log(res, " res res res res");
      setBlogArr(res?.data);
      setPage(res?.page);
      setPerPage(res?.perPage);
      setTotalCount(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {

      console.error(error);

    }
  };

  useEffect(() => {
    handleBlogGet();
  }, []);

  const handleBlogDelete = (row) => {
    // console.log(row, "des");
    dispatch(blogDelete(row));
  };
  const handleBlogEdit = (row) => {
    dispatch(SetblogObj(row));
  };

  useEffect(() => {
    handleBlogGet();
  }, [page, perPage, query]);

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleModalSet = (e, row) => {
    e.preventDefault();
    setModalBox(true);
    setModalType("subscriber-detail");
    setModalName("Subscriber Information");
    setSelectedData(row);
  };

  const blog_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "5%",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      width: "20%",
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toDateString(),
      width: "20%",
    },
    {
      name: "Action",
      cell: (row) => (
        <>
          <button
            className="btn btn-1  btn-sm  text-white"
            onClick={(e) => handleModalSet(e, row)}
          >
            <i className="fa-solid fa-eye"> </i>
          </button>
        </>
      ),
      width: "15%",
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  const blog_data = [
    {
      id: "1",
      Seq: "1",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "2",
      Seq: "2",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "3",
      Seq: "3",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "4",
      Seq: "4",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "5",
      Seq: "5",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "6",
      Seq: "6",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "7",
      Seq: "7",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "8",
      Seq: "8",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "9",
      Seq: "9",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
    {
      id: "10",
      Seq: "10",
      title: "Beauty Treatmen...",
      author: "Super Admin",
      published: "Fri, May 20, 2022 4:18 PM",
    },
  ];
  const handleFilterByQuery = (e, requiredParametersArr) => {
    // console.log(e, "get ndleFilterByQuery");
    setQuery(e);
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Subscriber List</h5>
                <div className="d-flex align-items-center gap-3">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e);
                    }}
                    // query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  // data={blog_data}
                  data={blogArr && blogArr.length > 0 ? blogArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
          <EditModal
            ModalBox={ModalBox}
            data={selectedData}
            width="500px"
            setModalBox={setModalBox}
            name={ModalName}
            ModalType={ModalType}
          />
        </div>
      </section>
    </main>
  );
}

export default Subscriber;
