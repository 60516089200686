import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";
import { DashboardTable } from "../../Utility/DashboardBox";
// import { getNewsletters } from "../../services/newsletter.service2";

import { useSelector, useDispatch } from "react-redux";

import {
  newsletterGet,
  SetnewsletterObj,
  newsletterDelete,
} from "../../../redux/actions/newsLetter/NewsLetter.action";

function MailTemplates() {
  const newsLettterArr = useSelector((state) => state.newsLetter.NewsLetterArr);
  // console.log(newsLettterArr, "newsleeter");
  const dispatch = useDispatch();

  const newsletterPaginationObject = useSelector(
    (state) => state.newsLetter.paginationObj
  );



  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [newsLetterArray, setNewsLetterArray] = useState([]);
  const [query, setQuery] = useState([]);

  const handleGet = () => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    dispatch(newsletterGet(queryOf));
  };



  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  useEffect(() => {
    // console.log(newsletterPaginationObject, "newsletterPaginationObject");
    if (newsLettterArr && newsLettterArr.length >= 0) {
      setNewsLetterArray(newsLettterArr);
      // setMainArr(newsLettterArr);
    }
    if (newsletterPaginationObject) {
      setPage(newsletterPaginationObject?.page);
      setPerPage(newsletterPaginationObject?.perPage);
      setTotalCount(newsletterPaginationObject?.totalCount);
      setTotalPages(newsletterPaginationObject?.totalPages);
    }
  }, [newsLettterArr, newsletterPaginationObject]);


  const handlePageChange = (page) => {
    setPage(page);
  };


  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleEdit = (obj) => {
    dispatch(SetnewsletterObj(obj));
  };

  const handleDelete = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(newsletterDelete(id));
    }
  };

  const blog_columns = [
    {
      name: "S.NO.",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      sortable: true,
    },
    {
      name: "description",
      cell: (row) => (
        <p>{row?.description?.replace("<p>", "").replace("</p>", "")}</p>
      ),
      sortable: false,
      searchable: false,
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/System-Setting/AddMailTemplate"
          onDeleteClick={() => handleDelete(row?._id)}
          deletePath="/System-Setting/MailTemplates"
          remove
          Uniquekey={row?._id}
        />
      ),
    },
  ];
  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
};
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Emails</h5>
                <div className="d-flex gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW Email"
                    path="/System-Setting/AddMailTemplate"
                  />
              <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_columns}
                  data={
                    newsLetterArray && newsLetterArray.length > 0
                      ? newsLetterArray
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default MailTemplates;
