// Faq
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/faqCategory";

export const addFaqCategory = (formData) => {
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getFaqCategory = (query) => {
  // console.log(query,"queryfaq --")
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

export const deleteFaqCategory = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updateFaqCategory = ( id,formData) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
