// PaymentGateway
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/paymentGateway";

export const addPaymentGateway = (formData) => {
  // // // console.log(formData, "inaxios");
  return axiosApiInstance.post(serverUrl + "/", formData);
};

export const getPaymentGateway = (query) => {
  return axiosApiInstance.get(`${serverUrl}/`);
};
// // console.log(getPaymentGateway, "get in axiso43");
export const deletePaymentGateway = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const updatePaymentGateway = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};
