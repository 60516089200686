import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  CATEGORYAdd,
  CATEGORYGet,
  CATEGORYUpdate,
  SetCATEGORYObj,
} from "../../../redux/actions/Category/Category.actions";
import {
  SETSTATEOBJ,
  STATEADD,
  STATEUPDATE,
  setStateStatus,
} from "../../../redux/actions/State/States.actions";
import { toastError } from "../../Utility/ToastUtils";

function AddStates({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [query, setQuery] = useState("");
  const [stateObject, setStateObject] = useState({});
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);

  const statesObj = useSelector((state) => state.states.statesObj);
  const statesUpdateCheck = useSelector(
    (state) => state.states.StateUpdateStatus
  );

  const clearFunc = () => {
    setName("");
    setStateObject({});
    setStatus(generalModelStatuses.APPROVED);
  };

  useEffect(() => {
    console.log(statesUpdateCheck,"===statesUpdateCheck====")
    if (statesUpdateCheck && statesUpdateCheck?.message) {
      dispatch(SETSTATEOBJ(null));
      clearFunc();
    }
    return () => {
      dispatch(setStateStatus(null));
    };
  }, [statesUpdateCheck]);

  const handleAddCategory = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      let obj = {
        name,
        status,
      };
      console.log(query, "queryqueryquery obj");
      if (stateObject && stateObject?._id) {
        dispatch(STATEUPDATE(stateObject._id, obj, query));
      } else {
        dispatch(STATEADD(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (statesObj && statesObj._id) {
      setQuery(statesObj?.query);
      setStateObject(statesObj);
      setName(statesObj?.name);
      setStatus(statesObj?.status);
    }

    return () => {
      dispatch(SETSTATEOBJ(null));
    };
  }, [statesObj]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5>{stateObject && stateObject?._id ? "Update" : "Add"} State</h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddStates;
