import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import {
  SETSTATEOBJ,
  STATEDELETE as areaDELETE,
  STATEGET as areaGET,
} from "../../../redux/actions/State/States.actions";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import AddShipping from "./AddShipping";
import {
  SETSHIPPINGOBJ,
  SHIPPINGDELETE,
  SHIPPINGGET,
} from "../../../redux/actions/Shipping/Shipping.actions";

function Shipping() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const shippingArr = useSelector((state) => state.shipping.shippings);
  const shippingObj = useSelector((state) => state.shipping.shippingObj);
  const [ModalData, setModalData] = useState({});
  const [displayShippingArr, setDisplayShippingArr] = useState([]);
  const [query, setQuery] = useState("");
  const [shippingMainArr, setShippingMainArr] = useState([]);


  const handleCategoryEdit = (row) => {
    // console.log(row,"fdsfs")
    dispatch(SETSHIPPINGOBJ(row));
  };



  const shippingPaginationObject = useSelector(
    (state) => state.shipping.shippingPaginationObj
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  const handleGet = () => {
    // console.log(`page=${page}&perPage=${perPage}`,"pppppppppppppppp")
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    dispatch(SHIPPINGGET(queryOf));
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);


  const handlePageChange = (page) => { 
    setPage(page);
  };
 const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(SHIPPINGDELETE(row._id));
    }
  };


 

  useEffect(() => {
    if (shippingArr?.length >= 0) {
      // console.log(shippingMainArr,"shippingMainArrshippingMainArrshippingMainArr")
      setShippingMainArr(shippingArr);
      setDisplayShippingArr(shippingArr);
    } if (shippingPaginationObject) {
      setPage(shippingPaginationObject?.page);
      setPerPage(shippingPaginationObject?.perPage);
      setTotalCount(shippingPaginationObject?.totalCount);
      setTotalPages(shippingPaginationObject?.totalPages);
    }

  }, [shippingArr,shippingPaginationObject]);

  
  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Min",
      selector: (row) => row?.min,
    },
    {
      name: "Max",
      selector: (row) => row?.max,
    },
    {
      name: "Cost per Order",
      selector: (row) => row?.price,
    },
    
    {
      name: "Country",
      selector: (row) => row?.country,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
    },
    // {
    //     name: "Status",
    //     minWidth: "210px",
    //     maxWidth: "211px",
    //     button: true,
    //     cell: (row) => <CustomButton greenBtn noIcon btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`} path={"/Location/View-Shipping"} />,
    // },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/SetUp/Shipping"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/SetUp/Shipping"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">
                  {shippingObj && shippingObj.name ? "Edit " : "Add "} Shipping
                </h5>
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddShipping />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Shipping List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    shippingMainArr && shippingMainArr.length > 0
                      ? shippingMainArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Shipping;
