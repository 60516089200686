import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
// import { addBuyNgetN } from "../../services/BuyNgetN.service";
// import { addPromotionalOffer } from "../../services/promotionalOffer.service";
import { addHsnTax } from "../../services/HsnTax.service";
import { toastSuccess } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { generalModelStatuses } from "../Utility/constants";
import { DashboardBox } from "../Utility/DashboardBox";
import { toastError } from "../Utility/ToastUtils";

// import { discountTypeArr, schemeType, schemeTypeArr } from "../Utility/utils";
// import { getProductForSales } from "../../services/flashSales.service";

import {
  HSNTAXGET,
  HSNTAXADD,
  SETHSNTAXOBJ,
  HSNTAXUPDATE,
  HSNTAXDELETE,
  setHSnTaxStatus,
} from "../../redux/actions/HsnTax/HsnTax.actions";

export default function AddHsnTax() {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [productInternalArr, setProductInternalArr] = useState([]);
  const [selectedProductArr, setSelectedProductArr] = useState([]);

  const [hsn, setHsn] = useState("");
  const [cgst, setCgst] = useState("");
  const [sgst, setSgst] = useState("");
  const [igst, setIgst] = useState("");

  const authObj = useSelector((state) => state.auth);
  const hsnTaxObj = useSelector((state) => state.hsnTax.hsnTaxObj);
  const hsnTaxStatusCheck = useSelector(
    (state) => state.hsnTax.hsnTaxStatusUpdate
  );

  const [offerArr, setOfferArr] = useState([
    { productToBuyArr: [], productToGetArr: [] },
  ]);

  useEffect(() => {
    if (hsnTaxObj && hsnTaxObj._id) {
      // console.log(hsnTaxObj,"hsnTaxObjhsnTaxObj")
      setHsn(hsnTaxObj?.hsn);
      setCgst(hsnTaxObj?.cgst);
      setSgst(hsnTaxObj?.sgst);
      setIgst(hsnTaxObj?.igst);
    }
    return () => {
      dispatch(SETHSNTAXOBJ(null));
    };
  }, [hsnTaxObj]);

  const handleProductSelect = (val, index, check) => {
    try {
      let tempOfferArr = [...offerArr];
      if (check == "BUY") {
        tempOfferArr[index].productToBuyArr = val;
      } else {
        tempOfferArr[index].productToGetArr = val;
      }
      setOfferArr([...tempOfferArr]);
    } catch (err) {
      toastError(err);
    }
  };

  // useDispatch(() => {
  //   // console.log(setSelectedProductArr, "setSelectedProductArr1  ");
  // }, [selectedProductArr]);

  const clearFunc = () => {
    setHsn("");
    setCgst("");
    setSgst("");
    setIgst("");
    dispatch(SETHSNTAXOBJ(null));
  };

  useEffect(() => {
    if (hsnTaxStatusCheck && hsnTaxStatusCheck?.message) {
      clearFunc();
    }
    return () => {
      dispatch(setHSnTaxStatus(null));
    };
  }, [hsnTaxStatusCheck]);

  const handleSubmit = async (e) => {
    try {
      if (hsn == "") {
        toastError("hsn is madatory");
        return;
      } else if (cgst == "") {
        toastError("cgst is madatory");
        return;
      } else if (sgst == "") {
        toastError("sgst is madatory");
        return;
      } else if (igst == "") {
        toastError("igst is madatory");
        return;
      }

      let obj = {
        hsn,
        cgst,
        sgst,
        igst,
      };
      // console.log(obj,"object3e21");
      if (hsnTaxObj && hsnTaxObj._id) {
        dispatch(HSNTAXUPDATE(obj, hsnTaxObj?._id));
      } else {
        dispatch(HSNTAXADD(obj));
      }
    } catch (error) {
      toastError(error);
    }
  };

  const handleOfferArrAdd = () => {
    let tempArr = [...offerArr];
    tempArr.push({ productToBuyArr: [], productToGetArr: [] });
    setOfferArr([...tempArr]);
  };

  const handleOfferArrDelete = () => {
    let tempArr = [...offerArr];
    tempArr.pop();
    setOfferArr([...tempArr]);
  };

  return (
    <section className="product-category">
      <div className="container-fluid p-0">
        <div className="row d-flex px-3">
          <div className="col-12 col-md-12">
            <DashboardBox>
              <form className="form row">
                <div className="col-12 col-md-6 pb-4">
                  <h5 className="blue-1 mt-1 ">
                    {hsnTaxObj && hsnTaxObj._id ? "Edit" : "Add"} Info
                  </h5>
                  <div className="row">
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        HSN <span className="red">*</span>
                      </label>
                      <input
                        value={hsn}
                        onChange={(event) => setHsn(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        CGST <span className="red">*</span>
                      </label>
                      <input
                        value={cgst}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Value can't be less than 0 ");
                            return;
                          }
                          setCgst(event.target.value);
                        }}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        SGST <span className="red">*</span>
                      </label>
                      <input
                        value={sgst}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Value can't be less than 0 ");
                            return;
                          }
                          setSgst(event.target.value);
                        }}
                        type="number"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12 col-md-12 mb-3 ">
                      <label>
                        IGST <span className="red">*</span>
                      </label>
                      <input
                        value={igst}
                        onChange={(event) => {
                          if (event.target.value < 0) {
                            toastError("Value can't be less than 0 ");
                            return;
                          }
                          setIgst(event.target.value);
                        }}
                        type="number"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <CustomButton
                      btntype="button"
                      iconName="fa-solid fa-check"
                      btnName="Save"
                      isBtn
                      // small={makeChange ? true : false}
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </div>

                <hr />
                {/* <div className="row">
                  <div className="col-9">
                    <h5 className="blue-1 m-0">Selected Product List</h5>

                    <table class="table">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Variant</th>
                          <th scope="col">Selling Price</th>
                          <th scope="col">Discount Value(%)</th>
                          <th scope="col">Current Stock</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedProductArr &&
                          selectedProductArr.length > 0 &&
                          selectedProductArr.map((el, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{el.productName}</th>
                                <th scope="row">{el.variantName}</th>

                                <td>
                                  <input disabled type="number" className="form-control" value={el?.sellingPrice} />
                                </td>

                                <td>
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      !(e.target.value > 100) && handleValueChange(index, e.target.value, "discountValue");
                                    }}
                                    value={el?.discountValue}
                                  />
                                </td>
                                <td>
                                  <input type="number" className="form-control" onChange={(e) => handleValueChange(index, e.target.value, "quantity")} disabled value={el?.quantity} />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div> */}
              </form>
            </DashboardBox>
          </div>
        </div>
      </div>
    </section>
  );
}
