import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { deleteById, get } from "../../services/PromotionalOffer.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";

import {
  COMBOOFFERGET,
  COMBOOFFERADD,
  SETCOMBOOFFEROBJ,
  COMBOOFFERUPDATE,
  COMBOOFFERDELETE,
} from "../../redux/actions/ComboOffer/ComboOffer.actions";

export default function ViewPromotionalOffer() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [offerArr, setOfferArr] = useState([]);
  const [orderArr, setOrderArr] = useState([]);
  const comboOfferArr = useSelector((state) => state.comboOffer.comboOffers);


  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const comboPaginationObject = useSelector((state)=>state.comboOffer.comboOfferPaginationObj)
 
  
  const handleGet = () => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    // console.log(queryOf,"queryOf22323")
    dispatch(COMBOOFFERGET(queryOf));
   
  };
  
  useEffect(() => {
    handleGet();
  }, [page, perPage,query]);




  useEffect(() => {
    // console.log(comboPaginationObject, "comboPaginationObject");
    if (comboOfferArr && comboOfferArr.length >= 0) {
      setOfferArr(comboOfferArr);
      setOrderArr(comboOfferArr);
    }
    if (comboPaginationObject) {
      setTotalPages(comboPaginationObject?.totalPages);
      setTotalCount(comboPaginationObject?.totalCount);
      setPage(comboPaginationObject?.page);
    }

  }, [comboOfferArr,comboPaginationObject]);

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Discount Type",
      sortable: true,
      selector: (row) => row?.discountType,
    },
    {
      name: "Discount Value",
      selector: (row) => row?.discountValue,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      sortable: true,
    },
    {
      name: "Valid From",
      selector: (row) => new Date(row?.validFrom).toDateString(),
    },
    {
      name: "Valid Till",
      selector: (row) => new Date(row?.validTill).toDateString(),
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/ViewPromotionalOffer"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => {
              dispatch(SETCOMBOOFFEROBJ(row))
              // handleEdit(row)
            }}
            edit
            editPath="/AddPromotionalOffer"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
        </>
      ),
    },
  ];

  const handleDeleteById = async (id) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      dispatch(COMBOOFFERDELETE(id));
    }
  };

  // ==============================================================================================================
  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
   
  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };
  
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">View Promotional Offer</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={offerArr && offerArr.length > 0 ? offerArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}        
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
