import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteById /*, getAllBySellerId */,
  get,
} from "../../services/BuyNgetN.service";


import {
  BUYNANDGETNGET,
  BUYNANDGETNADD,
  SETBUYNANDGETNOBJ,
  BUYNANDGETNUPDATE,
  BUYNANDGETNDELETE,
} from "../../redux/actions/BuyN&GetN/BuyN&GetN.actions";

import { toastError, toastSuccess } from "../../utils/toastUtils";
import ActionIcon from "../Utility/ActionIcon";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";

export default function ViewBuyNgetN() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  const [offerArr, setOfferArr] = useState([]);
  const [orderArr, setOrderArr] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const buyAndGetArr = useSelector((state) => state.buyNandGetN.buyNAndgetNs);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const buyNgetPaginationObject = useSelector(
    (state) => state.buyNandGetN.buyNandGetNPaginationObj
  );

  const handleGet = () => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    dispatch(BUYNANDGETNGET(queryOf));
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handleDeleteById = async (id) => {
    let confirm =window.confirm("Do you really want to delete this item?")
    if (confirm) {
      
      dispatch(BUYNANDGETNDELETE(id));
    }
  };

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
      sortable: true,
    },
    {
      name: "Buy Amount",
      sortable: true,
      selector: (row) => row?.schemeObj?.buyAmount,
    },
    {
      name: "Gst Amount",
      sortable: true,
      selector: (row) => row?.schemeObj?.gstAmount,
    },

    {
      name: "Valid From",
      selector: (row) => new Date(row?.validFrom).toDateString(),
    },
    {
      name: "Valid Till",
      selector: (row) => new Date(row?.validTill).toDateString(),
    },

    {
      name: "Action",
      width: "15%",
      cell: (row) => (
        <>
          <ActionIcon
            Uniquekey={row.id}
            remove
            deletePath="/ViewBuyNgetN"
            onDeleteClick={() => handleDeleteById(row._id)}
            isRedirected={true}
            onEditClick={() => {
              dispatch(SETBUYNANDGETNOBJ(row));
              // handleEdit(row)
            }}
            edit
            editPath="/AddBuyNgetN"
            // detail
            // detailClick={(e) => {
            //   e.preventDefault();
            // }}
          />
        </>
      ),
    },
  ];

  // ==============================================================================================================

  useEffect(() => {
    if (buyAndGetArr && buyAndGetArr.length >= 0) {
      setOfferArr(buyAndGetArr);
      setOrderArr(buyAndGetArr);
    }
    if (buyNgetPaginationObject) {
      setTotalPages(buyNgetPaginationObject?.totalPages);
      setTotalCount(buyNgetPaginationObject?.totalCount);
      setPage(buyNgetPaginationObject?.page);
    }
  }, [buyAndGetArr,buyNgetPaginationObject]);

  
  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };


  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };


  const handlePageChange = (page) => {
    // setCurrentPage(page);
    setPage(page);
  };


  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">View Buy N get N</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={offerArr && offerArr.length > 0 ? offerArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
