import {
  add,
  deleteById,
  get,
  update,
} from "../../../services/notification.service";

export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_ADD_SUCCESS = "NOTIFICATION_ADD_SUCCESS";
export const NOTIFICATION_ADD_FAIL = "NOTIFICATION_ADD_FAIL";

export const GET_ALL_NOTIFICATION = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATIONS_SUCCESS";
export const GET_ALL_NOTIFICATION_FAIL = "GET_ALL_NOTIFICATIONS_FAIL";

export const UPDATE_NOTIFICATION_BY_ID = "UPDATE_NOTIFICATION_BY_ID";
export const UPDATE_NOTIFICATION_BY_ID_SUCCESS =
  "UPDATE_NOTIFICATION_BY_ID_SUCCESS";
export const UPDATE_NOTIFICATION_BY_ID_FAIL = "UPDATE_NOTIFICATION_BY_ID_FAIL";

export const SET_NOTIFICATION_OBJ = "SET_NOTIFICATION_OBJ";
export const SET_NOTIFICATION_OBJ_SUCCESS = "SET_NOTIFICATION_OBJ_SUCCESS";
export const SET_NOTIFICATION_OBJ_FAIL = "SET_NOTIFICATION_OBJ_FAIL";

export const GET_NOTIFICATION_BY_ID = "GET_NOTIFICATION_BY_ID";
export const GET_NOTIFICATION_BY_ID_SUCCESS = "GET_NOTIFICATION_BY_ID_SUCCESS";
export const GET_NOTIFICATION_BY_ID_FAIL = "GET_NOTIFICATION_BY_ID_FAIL";

export const DELETE_NOTIFICATION_BY_ID = "DELETE_NOTIFICATION_BY_ID";
export const DELETE_NOTIFICATION_BY_ID_SUCCESS =
  "DELETE_NOTIFICATION_BY_ID_SUCCESS";
export const DELETE_NOTIFICATION_BY_ID_FAIL = "DELETE_NOTIFICATION_BY_ID_FAIL";

export const notificationAdd = (formData) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATION_ADD });
    // console.log(formData, "form raction ");
    let { data: response } = await add(formData);
    if (response) {
      // console.log(response, "response raction ");
      dispatch({
        type: NOTIFICATION_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(notificationGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: NOTIFICATION_ADD_FAIL, payload: err });
  }
};

export const notificationGet = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_NOTIFICATION });
    let { data: response } = await get(formData);
    if (response) {
      // console.log(response, "action get  ");
      dispatch({
        type: GET_ALL_NOTIFICATION_SUCCESS,
        payload: { data: response.data, message: response.message },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: GET_ALL_NOTIFICATION_FAIL, payload: err });
  }
};

export const SetnotificationObj = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_NOTIFICATION_OBJ });
    if (formData) {
      // console.log(formData, "formdAtaSetobj action");
      dispatch({
        type: SET_NOTIFICATION_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_NOTIFICATION_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    console.error(err);
    dispatch({
      type: SET_NOTIFICATION_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const notificationUpdate = (formData, id) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_NOTIFICATION_BY_ID });
    // console.log(formData, "formData", id, "id");
    let { data: response } = await update(formData, id);
    if (response) {
      // console.log(response, "res");
      dispatch({
        type: UPDATE_NOTIFICATION_BY_ID_SUCCESS,
        payload: response,
      });
      dispatch(notificationGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: UPDATE_NOTIFICATION_BY_ID_FAIL, payload: err });
  }
};

export const notificationDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_NOTIFICATION_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response daelete action ");
      dispatch({
        type: DELETE_NOTIFICATION_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(notificationGet());
    }
  } catch (err) {
    console.error(err);
    dispatch({ type: DELETE_NOTIFICATION_BY_ID_FAIL, payload: err });
  }
};
