import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { generalModelStatuses } from "../Utility/constants";
import FileUpload from "../Utility/FileUpload";
import SearchBox from "../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";
import {
  blogCategoryAdd,
  BLOGCATEGORYGet,
  BlogCategoryUpdate,
  SetBlogCategoryObj,
  BLOGCATEGORYDelete,
  setBlogCategoryStatus,
} from "../../redux/actions/BlogCategory/BlogCategory.actions";
import { toastError } from "../../utils/toastUtils";
import { getBlogCategory } from "../../services/blogCategory.service";

function BlogCategory() {
  let dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState("");
  const [blogCategoriesArr, setBlogCategoriesArr] = useState([]);
  const [displayCategoryArr, setDisplayCategoryArr] = useState([]);
  const [prevBlogCategoryId, setPrevBlogCategoryId] = useState(null);
  const [blogCategoryObject, setBlogCategoryObject] = useState({});
  const [blogCategoriesMainArr, setBlogCategoriesMainArr] = useState([]);

  const [query, setQuery] = useState("");

  const blogCategoryArr = useSelector(
    (state) => state.blogCategory.blogCategories
  );

  const blogCategoryPAginationObject = useSelector(
    (state) => state.blogCategory.blogCategoryPaginationObj
  );

  const blogCategoryObj = useSelector(
    (state) => state.blogCategory.blogCategoryObj
  );

  const blogCategoryStatusCheck = useSelector(
    (state) => state.blogCategory.blogCategoryStatusUpdate
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  useEffect(() => {
    if (blogCategoryObj && blogCategoryObj._id) {
      console.log(blogCategoryObj,"====blogCategoryObjblogCategoryObj")
      setBlogCategoryObject(blogCategoryObj);
    }
   
  }, [blogCategoryObj]);

  useEffect(() => {
    if (blogCategoryArr && blogCategoryArr?.length >= 0) {
      setBlogCategoriesArr(blogCategoryArr);
      setBlogCategoriesMainArr(blogCategoryArr);
    }
  }, [blogCategoryArr]);



  useEffect(() => {
    // console.log(blogCategoryPAginationObject, "blogCategoryPAginationObject");
    if (blogCategoryPAginationObject) {
      setTotalPages(blogCategoryPAginationObject?.totalPages);
      setTotalCount(blogCategoryPAginationObject?.totalCount);
      setPage(blogCategoryPAginationObject?.page);
    }
    return () => {
      dispatch(setBlogCategoryStatus(null));
    };
  }, [blogCategoryPAginationObject]);



  const handleBlogCategoryGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }

      // dispatch(BLOGCATEGORYGet(queryOf));
      // console.log(queryOf, "===queryOf=");
      let { data: get } = await getBlogCategory(queryOf);
      if (get.success) {
        setBlogCategoriesArr(get?.data);
        setBlogCategoriesMainArr(get?.data);
        setTotalPages(get?.totalPages);
        setTotalCount(get?.totalCount);
        setPage(get?.page);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    // console.log(page, perPage, query, "page, blogcate");
    handleBlogCategoryGet();
  }, [page, perPage, query]);


  const handleCategoryDelete = (row) => {
    try {
      let confirm = window.confirm("Do you really want to delete this item?");
      if (confirm) {
        let queryOf = `page=${page}&perPage=${perPage}`;
        dispatch(BLOGCATEGORYDelete(row, queryOf));
      }
    } catch (Err) {
      toastError(Err);
    }
  };

  useEffect(() => {
      if (blogCategoryObject && blogCategoryObject._id) {
        setCategoryName(blogCategoryObject?.name);
        setImage(blogCategoryObject?.image);
        setPrevBlogCategoryId(blogCategoryObject?._id);
      }
      return ()=>{
        dispatch(SetBlogCategoryObj(null))
      }

  }, [blogCategoryObject]);

  const handleCategoryEdit = (row) => {
    dispatch(SetBlogCategoryObj(row));
  };

  const handleFileSet = (value) => {
    setImage(value);
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
    { value: "vanilla32", label: "Vanilla1" },
  ];

  const blog_category_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
    },

    {
      name: "Category",
      selector: (row) => row?.name,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/Blog/Category"
          onDeleteClick={() => {
            handleCategoryDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            handleCategoryEdit(row);
          }}
          editPath="/Blog/Category"
        />
      ),
    },
  ];

  const blog_category_data = [
    {
      id: "1",
      Num: "1",
      category: "Nails",
      img: `${images.brand}`,
    },
    {
      id: "2",
      Num: "2",
      category: "Eyes",
      img: `${images.brand}`,
    },
    {
      id: "3",
      Num: "3",
      category: "Face",
      img: `${images.brand}`,
    },
    {
      id: "4",
      Num: "4",
      category: "Lips",
      img: `${images.brand}`,
    },
    {
      id: "5",
      Num: "5",
      category: "Nail polish",
      img: `${images.brand}`,
    },
    {
      id: "6",
      Num: "6",
      category: "Perfect Finish box(Nail P...",
      img: `${images.brand}`,
    },
    {
      id: "7",
      Num: "7",
      category: "Foundation",
      img: `${images.brand}`,
    },
    {
      id: "8",
      Num: "8",
      category: "LIQUID SINDOOR",
      img: `${images.brand}`,
    },
    {
      id: "9",
      Num: "9",
      category: "BEAUTY POP BOX LIP COLOR",
      img: `${images.brand}`,
    },
    {
      id: "10",
      Num: "10",
      category: "LIPSTIC A & B",
      img: `${images.brand}`,
    },
  ];

  const clearFunc = () => {
    try {
      setCategoryName("");
      setBlogCategoryObject({});
      dispatch(SetBlogCategoryObj(null));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    console.log(blogCategoryStatusCheck,"===blogCategoryStatusCheck==")
    if (blogCategoryStatusCheck && blogCategoryStatusCheck?.message) {
      clearFunc();
      // handleBlogCategoryGet();
    }
    // return () => {
    //   dispatch(setBlogCategoryStatus(null));
    // };
  }, [blogCategoryStatusCheck]);


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (`${categoryName}` == "") {
        toastError("Please fill Category Name");
        return;
      }
      let obj = {
        categoryName,
        image,
      };
      if (blogCategoryObject && blogCategoryObject?._id) {
        let queryOf = `page=${page}&perPage=${perPage}`;
        dispatch(BlogCategoryUpdate(prevBlogCategoryId, obj, queryOf));
      } else {
        dispatch(blogCategoryAdd(obj));
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row gy-4 gy-xxl-0">
            <div className="col-12 col-xxl-4">
              <h5 className="blue-1 mb-4">
                <h5>
                  {blogCategoryObject && blogCategoryObject?._id
                    ? "Edit  "
                    : "Add  "}
                  Blog Category
                </h5>
              </h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      CATEGORY NAME<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryName}
                      onChange={(event) => setCategoryName(event.target.value)}
                    />
                  </div>
                  {/* <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      SELECT PARENT CATEGORY <span className="red">*</span>
                    </label>
                    <Select
                      options={options}
                      value={parentCategorys}
                      onChange={(e) => setParentCategorys(e)}
                    />
                  </div> */}
                  {/* <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">Image</label>
                    <FileUpload onFileChange={handleFileSet} />
                  </div> */}

                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-xxl-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Category List</h5>
                <SearchBox
                  setQuery={(e) => {
                    handleFilterByQuery(e, ["name"]);
                  }}
                  query={query}
                  extraClass="bg-white"
                />
              </div>
              <DashboardTable>
                <DataTable
                  columns={blog_category_columns}
                  // data={blog_category_data}
                  data={
                    blogCategoriesArr && blogCategoriesArr.length > 0
                      ? blogCategoriesArr
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BlogCategory;
