import { toastError, toastSuccess } from "../../../components/Utility/ToastUtils";
import * as SHIPPING from "../../actions/Shipping/Shipping.actions";

const initialState = {
  shippings: null,
  shippingObj: null,
  loading: false,
  error: null,
  shippingPaginationObj: null,
};

export const shippingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHIPPING.SHIPPING_ADD:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING.SHIPPING_ADD_SUCCESS:
      toastSuccess(action.payload);

      return {
        ...state,
        loading: false,
      };
    case SHIPPING.SHIPPING_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SHIPPING.GET_ALL_SHIPPINGS:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING.GET_ALL_SHIPPINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        shippings: action.payload.data,
        shippingPaginationObj:action.payload.paginationObj
      };
    case SHIPPING.GET_ALL_SHIPPINGS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SHIPPING.DELETE_SHIPPING_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING.DELETE_SHIPPING_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SHIPPING.DELETE_SHIPPING_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SHIPPING.UPDATE_SHIPPING_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING.UPDATE_SHIPPING_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SHIPPING.UPDATE_SHIPPING_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SHIPPING.SET_SHIPPING_OBJ:
      return {
        ...state,
        loading: true,
      };
    case SHIPPING.SET_SHIPPING_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        shippingObj: action.payload.data,
        loading: false,
        error: null,
      };
    case SHIPPING.SET_SHIPPING_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
