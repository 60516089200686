import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import SearchBox from "../../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import {
  SETSTATEOBJ,
  STATEDELETE as areaDELETE,
  STATEGET as areaGET,
} from "../../../redux/actions/State/States.actions";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { AddModal } from "../../Utility/Modal";
import AddArea from "./AddArea";

import {
  AREADELETE,
  AREAGET,
  SETAREAOBJ,
} from "../../../redux/actions/Area/Area.actions";

import { getAreas } from "../../../services/Area.service";
import { toastError } from "../../Utility/ToastUtils";

function ViewArea() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const areaArr = useSelector((state) => state.area.areas);
  const areaObj = useSelector((state) => state.area.areaObj);
  const [ModalData, setModalData] = useState({});
  const [displayAreaArr, setDisplayAreaArr] = useState([]);
  const [query, setQuery] = useState("");

  const [areaMainArr, setAreaMainArr] = useState([]);
  
  const areaPaginationObject = useSelector((state) => state.area.paginationObj);
  
  
  
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  
  const handleCategoryEdit = (row) => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    row.query=queryOf
    dispatch(SETAREAOBJ(row));
  };

  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(AREAGET(queryOf));
      let { data: get } = await getAreas(queryOf);
      if (get) {
        if (get) {
          setAreaMainArr(get?.data);
          setDisplayAreaArr(get?.data);
          setPage(get?.page);
          setPerPage(get?.perPage);
          setTotalCount(get?.totalCount);
          setTotalPages(get?.totalPages);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(AREADELETE(row._id,query));
    }
  };

  useEffect(() => {
    // console.log(areaArr,"areaArr124")
    if (areaArr && areaArr?.length >= 0) {
      setAreaMainArr(areaArr);
      setDisplayAreaArr(areaArr);
    }
   
  }, [areaArr]);


  useEffect(() => {
    // console.log(areaArr,"areaArr124")
   
    if (areaPaginationObject) {
      setPage(areaPaginationObject?.page);
      setPerPage(areaPaginationObject?.perPage);
      setTotalCount(areaPaginationObject?.totalCount);
      setTotalPages(areaPaginationObject?.totalPages);
    }
  }, [ areaPaginationObject]);

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "State",
      selector: (row) => row?.stateObj?.name,
    },
    {
      name: "City",
      selector: (row) => row?.cityObj?.name,
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <CustomButton
          greenBtn
          noIcon
          btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`}
          path={"/Location/View-Area"}
        />
      ),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Location/View-Area"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Location/View-Area"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0"> */}
                    {/* {areaObj&&areaObj._id?"Edit":"Add"} */}
                  {/* Area</h5> */}
              </div>
              <DashboardBox>
                <AddArea />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Area List</h5>
                <div className="d-flex gap-3 align-items-center">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    areaMainArr && areaMainArr.length > 0 ? areaMainArr : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewArea;
