import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";

import { INTERNALORDERGET } from "../../redux/actions/InternalOrder/InternalOrder.actions";


import { cancelOrderById, markOrderAsDelivered } from "../../services/InternalOrder.services";
import { toastError } from "../../utils/toastUtils";
import CustomButton from "../Utility/Button";
import { DashboardTable } from "../Utility/DashboardBox";
import SearchBox from "../Utility/SearchBox";
import { toastSuccess } from "../Utility/ToastUtils";
import { addRupeeSymbol, MainOrderStatus } from "../Utility/utils";

export default function AllInternalOrders() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const authObj = useSelector((state) => state.auth);
  // const InternalOrderArr = useSelector((state) => state.InternalOrder.internalOrder);
  const InternalOrderArr = useSelector((state) => state.internalOrder.internalOrders);
  const [orderArr, setOrderArr] = useState([]);
  const [mainInternalOrderArr, setMainInternalOrderArr] = useState([]);
  // internalOrderObj.internalOrders

   
  const handleCategoryEdit = (row) => {
    // dispatch(SETAREAOBJ(row));
  };

  const handleGet = () => {
    dispatch(INTERNALORDERGET());
  };

  useEffect(() => {
    handleGet();
  }, []);


  useEffect(() => {
    if (InternalOrderArr) {
      // console.log(InternalOrderArr,"InternalOrderArr223233") 
      // setOrderArr(InternalOrderArr)
      // setOrderArr(InternalOrderArr.filter((el) => el.isInternalOrder));
      // setMainInternalOrderArr(InternalOrderArr.filter((el) => el.isInternalOrder));
      setOrderArr(InternalOrderArr);
      setMainInternalOrderArr(InternalOrderArr);
    }
  }, [InternalOrderArr]);


  const handleDelivered = async (id) => {
    try {
      const { data: res } = await markOrderAsDelivered(id);
      if (res) {
        toastSuccess(res.message);
        // dispatch(INTERNALORDERGet(authObj?.user?._id));
      }
    } catch (error) {
      toastError(error);
    }
  };
  const handleCancelOrder = async (id) => {
    try {
      let confirm = window.confirm("Do you really want to delete this item?");
      if (confirm) {
        
        const { data: res } = await cancelOrderById(id);
        if (res) {
          toastSuccess(res.message);
          // dispatch(INTERNALORDERGet(authObj?.user?._id));
        }
      }
    } catch (error) {
      toastError(error);
    }
  };
  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "OrderId",
      selector: (row) => row?.orderId,
    },
    {
      name: "Total Amount",
      selector: (row) => addRupeeSymbol(row?.totalPriceWithGst),
    },
    {
      name: "Status",
      selector: (row) => row?.orderStatus,
    },

    {
      name: "Invoice",
      // minWidth: "210px",
      // maxWidth: "211px",
      cell: (row) => (
        <CustomButton
          btntype="button"
          isLink
          path={`/MainInvoice?orderId=${row?._id}`}
          edit
          ClickEvent={() => {
            handleInvoiceGet(row?._id);
          }}
          btnName="Invoice"
        />
      ),
    },
    {
      name: "Action",
      // minWidth: "210px",
      // maxWidth: "211px",
      cell: (row) => (
        <>
          {row?.orderStatus == MainOrderStatus.ORDERED && (
            <>
              <CustomButton isLink path={`/Order/Sale-Detail?id=${row?._id}`} btnName="View" />
              <CustomButton
                btntype="button"
                isLink
                path={`/internal-order/AllInternalOrder`}
                edit
                ClickEvent={() => {
                  handleDelivered(row?._id);
                }}
                btnName="Mark Delivered"
              />
              <CustomButton
                btntype="button"
                isLink
                path={`/internal-order/AllInternalOrder`}
                edit
                ClickEvent={() => {
                  handleCancelOrder(row?._id);
                }}
                btnName="Cancel"
              />
              ,
            </>
          )}
        </>
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    let tempArr = mainInternalOrderArr.filter((el) => {
      for (const ele of requiredParametersArr) {
        if (`${el[`${ele}`.toLowerCase()]}`.toLowerCase().includes(`${e}`.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      }
    });
    setQuery(e);
    setOrderArr([...tempArr]);
  };

  const handleInvoiceGet = async (id) => {
    // try {
    //   const { data: res } = await getInvoiceById(id);
    //   if (res) {
    //     window.open(`${url}/${res.data}`);
    //   }
    // } catch (error) {
    //   toastError(error);
    // }
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">Internal Order</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              {/* {console.log(orderArr,"orderArr23542345")} */}
              <DashboardTable>
                <DataTable columns={category_columns} data={orderArr && orderArr.length > 0 ? orderArr : []} pagination />
              </DashboardTable>
              
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
