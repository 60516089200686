import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Switch } from "@mui/material";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { downloadCSV } from "../../Utility/CSV";
import SearchBox from "../../Utility/SearchBox";
import { DashboardTable } from "../../Utility/DashboardBox";
import { useSelector, useDispatch } from "react-redux";
import { generateFilePath } from "../../Utility/utils";
import {
  currencyGet,
  SetcurrencyObj,
  currencyDelete,
} from "../../../redux/actions/currency/Currency.action";
import { get } from "../../../services/currency.service";
import { toastError } from "../../Utility/ToastUtils";

function CurrencyList() {
  const CurrencyArr = useSelector((state) => state.currency.CurrencyArr);
  const currencyStat= useSelector((state) => state.currency.currencyStatus);
  const dispatch = useDispatch();
  const [currencyArray, setCurrencyArray] = useState([]);
  const [mainArr, setMainArr] = useState([]);
  const [query, setQuery] = useState("");

  const CurrencyPaginationObject = useSelector(
    (state) => state.currency.paginationObj
  );
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(currencyGet(queryOf));
      let { data: response } = await get(queryOf);
      if (response) {
        setCurrencyArray(response?.data);
        setMainArr(response?.data);
        setPage(response?.page);
        setPerPage(response?.perPage);
        setTotalCount(response?.totalCount);
        setTotalPages(response?.totalPages);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  // useEffect(() => {
  //   if (CurrencyArr && CurrencyArr.length >= 0) {
  //     setCurrencyArray(CurrencyArr);
  //     setMainArr(CurrencyArr);
  //   }
  //   if (CurrencyPaginationObject) {
  //     setPage(CurrencyPaginationObject?.page);
  //     setPerPage(CurrencyPaginationObject?.perPage);
  //     setTotalCount(CurrencyPaginationObject?.totalCount);
  //     setTotalPages(CurrencyPaginationObject?.totalPages);
  //   }
  // }, [CurrencyArr, CurrencyPaginationObject]);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleEdit = (obj) => {
    dispatch(SetcurrencyObj(obj));
  };
  const handleDelete = (id) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(currencyDelete(id));
      
      setTimeout(() => {
        
        handleGet();
      }, 100);
    }
  };

  const currency_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      width: "10%",
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      width: "15%",
    },
    {
      name: "CODE",
      selector: (row) => row?.code,
      width: "15%",
    },
    {
      name: "SYMBOL",
      selector: (row) => row?.symbol,
      width: "10%",
    },
    // {
    //   name: "ACTIVATE",
    //   button: true,
    //   cell: (row) => <Switch />,
    //   width: "15%",
    // },
    {
      name: "CONVERT RATE 1 INR = ?",
      selector: (row) => row?.convertRate,
      width: "20%",
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img height="84px" width="56px" src={generateFilePath(row?.image)} />
      ),
      width: "15%",
    },
    {
      name: "Action",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          edit
          onEditClick={() => handleEdit(row)}
          editPath="/SetUp/Currency-Create"
          onDeleteClick={() => handleDelete(row?._id)}
          deletePath="/SetUp/Currency-List"
          remove
          Uniquekey={row?.id}
        />
      ),
      width: "20%",
    },
  ];

  const currency_data = [
    {
      id: "1",
      Seq: "1",
      Name: "India",
      code: "INR",
      symbol: "₹",
      convert: "0 ₹",
    },
    {
      id: "2",
      Seq: "2",
      Name: "India",
      code: "INR",
      symbol: "₹",
      convert: "0 ₹",
    },
    {
      id: "3",
      Seq: "3",
      Name: "India",
      code: "INR",
      symbol: "₹",
      convert: "0 ₹",
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Currency List</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD NEW CURRENCY"
                    path="/SetUp/Currency-Create"
                    small
                    roundedPill
                  />
                  {/* <CustomButton
                    isLink
                    iconName="fa-solid fa-download"
                    btnName="CURRENCY CSV"
                    path="/"
                    small
                    roundedPill
                    downloadAble
                    ClickEvent={() => downloadCSV(currency_data)}
                  /> */}
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={currency_columns}
                  // data={currency_data}
                  data={
                    currencyArray && currencyArray.length > 0
                      ? currencyArray
                      : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CurrencyList;
