// local url

// export const url = "http://192.168.0.39:4065";
// export const url = "http://192.168.0.17:4065";
// export const url = "http://localhost:4065";



// 192.168.0.15
// export const url = "http://192.168.0.10:4065";   
// export const url = "http://192.168.0.47:4065"


// live url
// export const url = "https://samagri.ebslon.com/api"
export const url = "https://samagri.ebslonserver3.com/api"