import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
import { images } from "../Images/Images";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { generalModelStatuses } from "../Utility/constants";
import moment from "moment/moment";
import SearchBox from "../Utility/SearchBox";
import { useSelector, useDispatch } from "react-redux";

import {
  CouponAdd,
  CouponGet,
  CouponUpdate,
  SetCouponObj,
  CouponDelete,
} from "../../redux/actions/Coupon/Coupon.actions";

import { toastError } from "../Utility/ToastUtils";
import { Switch } from "@mui/material";

function Coupon() {
  let dispatch = useDispatch();
  const [name, setName] = useState("");
  const [discountType, setDiscountType] = useState("percentage");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const [limit, setLimit] = useState("");
  const [value, setVlaue] = useState(0);
  const [couponsArr, setCouponsArr] = useState([]);
  const [displayCouponArr, setDisplayCouponArr] = useState([]);
  const [couponId, setCouponId] = useState(null);

  const couponArr = useSelector((state) => state.coupon.coupons);
  const couponObj = useSelector((state) => state.coupon.couponsObj);
  const coupenPaginationObject = useSelector(
    (state) => state.coupon.paginationObj
  );

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState("");
  const [isUpdatedDoc, setIsUpdatedDoc] = useState(false);

  const handleGet = () => { 
    try{
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }   
      dispatch(CouponGet(queryOf));
    }catch(err){
      toastError(err)
    }
  };

  useEffect(() => {
    // console.log(coupenPaginationObject, "coupenPaginationObject234");
    if (couponArr?.length >= 0) {
      setCouponsArr(couponArr);
      setDisplayCouponArr(couponArr);
    }
    if (coupenPaginationObject) {
      setPage(coupenPaginationObject?.page);
      setPerPage(coupenPaginationObject?.perPage);
      setTotalCount(coupenPaginationObject?.totalCount);
      setTotalPages(coupenPaginationObject?.totalPages);
    }
  }, [couponArr, coupenPaginationObject]);

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(CouponDelete(row));
    }
  };

  useEffect(() => {
    if (couponObj && couponObj._id) {
      // console.log(couponObj,"couponObj12")
      setName(couponObj?.name);
      setStartDate(moment(couponObj?.startDate).format("YYYY-MM-DD"));
      setEndDate(moment(couponObj?.endDate).format("YYYY-MM-DD"));
      setDiscountType(couponObj?.discountType);
      setMinAmount(couponObj?.minAmount);
      setLimit(couponObj?.limit);
      setVlaue(couponObj?.value);
      setCouponId(couponObj?._id);
      setIsUpdatedDoc(true);
    } else {
      setName("");
      setStartDate("");
      setEndDate("");
      setDiscountType("percentage");
      setMinAmount("");
      setLimit(0);
      setVlaue("");
      setCouponId(null);
      setIsUpdatedDoc(false);
    }

    // return () => {
    //   dispatch(SetCouponObj(null));
    // };
  }, [couponObj]);

  const handleCategoryEdit = (row) => {
    dispatch(SetCouponObj(row));
  };

  const coupon_columns = [
    {
      name: "SL",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "9%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Type",
      selector: (row) => row?.discountType,
      //
    },
    {
      name: "Discount Value",
      selector: (row) => row?.value,
      width: "15%",
    },
    {
      name: "Limit",
      selector: (row) => row?.limit,
      width: "15%",
    },
    {
      name: "Min Amount",
      selector: (row) => row?.minAmount,
      width: "15%",
    },
    {
      name: "status",
      button: true,
      cell: (row) => (
        <Switch
          onChange={(e) => handleChangeActiveStatus(row._id, !row.status)}
          checked={row.status}
        />
      ),
      width: "11%",
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <ActionIcon
          remove
          edit
          Uniquekey={row.id}
          deletePath="/Coupon/Coupon"
          onDeleteClick={() => {
            // console.log(row, "delete");
            handleCategoryDelete(row._id);
          }}
          isRedirected={true}
          onEditClick={() => {
            // console.log(row, "asdf");
            handleCategoryEdit(row);
          }}
          editPath="/Coupon/Coupon"
        />
      ),
      width: "15%",
    },
  ];

  const handleChangeActiveStatus = async (id, value) => {
    dispatch(CouponUpdate(id, { status: value }));
  };

  const clearFunc = () => {
    setName("");
    setStartDate("");
    setEndDate("");
    setDiscountType("percentage");
    setMinAmount("");
    setLimit(0);
    setVlaue("");
    setCouponId(null);
    setIsUpdatedDoc(false);
    dispatch(SetCouponObj(null));
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      if (`${name}` == "") {
        toastError("name is mandatory");
        return;
      }
      if (`${value}` == "") {
        toastError("Discount value is mandatory");
        return;
      }
      if (`${startDate}` == "") {
        toastError("start Date is mandatory");
        return;
      }
      if (`${endDate}` == "") {
        toastError("end Date is mandatory");
        return;
      }
      if (`${limit}` == "") {
        toastError("Limit is mandatory");
        return;
      }

      if (`${discountType}` == "") {
        toastError("discountType is mandatory");
        return;
      }
      if (`${minAmount}` == "") {
        toastError("minmum Amount is mandatory");
        return;
      }

      let obj = {
        name,
        startDate,
        endDate,
        discountType,
        minAmount,
        limit,
        value,
      };
      if (couponId) {
        // console.log(obj, "drt");
        dispatch(CouponUpdate(couponId, obj));
      } else {
        dispatch(CouponAdd(obj));
      }
      clearFunc();
      dispatch(SetCouponObj(null));
    } catch (err) {
      toastError(err);
    }
  };

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  const handleCheckDiscountType = (event) => {
    try {
      // console.log(event.target.value, "event.target.value");
      setDiscountType(event.target.value);
      setVlaue(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckDisocuntValue = (event) => {
    try {
      let discountValue = event.target.value;
      // console.log(discountType, "discounttype234");
      if (discountType == "percentage") {
        if (discountValue >= 100) {
          toastError("Discount cannot be 100%");
          return;
        } else {
          setVlaue(discountValue);
        }
      } else {
        setVlaue(discountValue);
      }
      // setVlaue(event.target.value);
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <h5 className="blue-1 mb-4">{isUpdatedDoc?"Update ": "Add "} Coupon</h5>
              <DashboardBox>
                <form action="" className="form row">
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Discount NAME<span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Discount NAME"
                      className="form-control"
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Discount TYPE
                      <span className="red">*</span>
                    </label>
                    <div>
                      <input
                        type="radio"
                        value="percentage"
                        name="discountType"
                        checked={discountType == "percentage" ? true : false}
                        onChange={
                          (event) => handleCheckDiscountType(event)
                          // setDiscountType(event.target.value)
                        }
                      />
                      Percentage Off &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        value="flat"
                        name="discountType"
                        checked={discountType == "flat" ? true : false}
                        onChange={
                          (event) => handleCheckDiscountType(event)
                          // setDiscountType(event.target.value)
                        }
                      />
                      Flat Off
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Discount VALUE<span className="red">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Discount value"
                      className="form-control"
                      value={value}
                      onChange={(event) => handleCheckDisocuntValue(event)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Start Date <span className="red">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Coupon Start date"
                      className="form-control"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      value={startDate}
                      onChange={(event) => setStartDate(event.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      End Date <span className="red">*</span>
                    </label>
                    <input
                      type="date"
                      placeholder="Coupon End date"
                      className="form-control"
                      value={endDate}
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      onChange={(event) => setEndDate(event.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Limit <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Coupon Used Limit"
                      className="form-control"
                      name="limit"
                      value={limit}
                      onChange={(event) => setLimit(event.target.value)}
                    />
                  </div>

                  <div className="col-12 mb-3">
                    <label className="blue-1 fs-12">
                      Minimum Amount <span className="red">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Minimum Amount"
                      className="form-control"
                      name="minAmount"
                      value={minAmount}
                      onChange={(event) => setMinAmount(event.target.value)}
                    />
                  </div>

                  <div className="col-12">
                    <CustomButton
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="ADD"
                      ClickEvent={handleSubmit}
                    />
                  </div>
                </form>
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex justify-content-between mb-2 align-items-center">
                <h5 className="blue-1 m-0">Coupon List</h5>
                <SearchBox
                  setQuery={(e) => {
                    handleFilterByQuery(e, ["name"]);
                  }}
                  query={query}
                  extraClass="bg-white"
                />
              </div>
              <DashboardTable>
                <DataTable
                  columns={coupon_columns}
                  // data={blog_category_data}
                  data={couponsArr && couponsArr.length > 0 ? couponsArr : []}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Coupon;
