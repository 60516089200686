// ContactInfo
import { axiosApiInstance } from "../App";
import { url } from "./url.service";

const serverUrl = url + "/ContactUs";

// export const addContactInfo = (formData) => {
//   return axiosApiInstance.post(serverUrl + "/", formData);
// };

export const getContact = (query) => {
  return axiosApiInstance.get(`${serverUrl}/?${query}`);
};

// export const deleteContactInfo = (id) => {
//   return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
// };

// export const updateContactInfo = (formData, id) => {
//   return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
// };
