import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as BUYNANDGETN from "../../actions/BuyN&GetN/BuyN&GetN.actions";

const initialState = {
  buyNAndgetNs: null,
  buyNAndGetNObj: null,
  loading: false,
  error: null,
  buyNandGetNPaginationObj: null,
  };


export const buyNandGetNReducer = (state = initialState, action) => {
  switch (action.type) {
    case BUYNANDGETN.BUYNANDGETN_ADD:
      return {
        ...state,
        loading: true,
      };
    case BUYNANDGETN.BUYNANDGETN_ADD_SUCCESS:
      toastSuccess(action.payload);
      // console.log(action, "reducer");

      return {
        ...state,
        loading: false,
      };

    case BUYNANDGETN.BUYNANDGETN_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYNANDGETN.GET_ALL_BUYNANDGETNS:
      return {
        ...state,
        loading: true,
      };
    case BUYNANDGETN.GET_ALL_BUYNANDGETNS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        buyNAndgetNs: action.payload.data,
        buyNandGetNPaginationObj:action.payload.buyandGetPaginationObj
      };
    case BUYNANDGETN.GET_ALL_BUYNANDGETNS_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYNANDGETN.DELETE_BUYNANDGETN_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BUYNANDGETN.DELETE_BUYNANDGETN_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BUYNANDGETN.DELETE_BUYNANDGETN_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case BUYNANDGETN.SET_BUYNANDGETN_OBJ:
      return {
        ...state,
        loading: true,
      };
    case BUYNANDGETN.SET_BUYNANDGETN_OBJ_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        buyNAndGetNObj: action.payload.data,
        loading: false,
        error: null,
      };
    case BUYNANDGETN.SET_BUYNANDGETN_OBJ_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case BUYNANDGETN.UPDATE_BUYNANDGETN_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case BUYNANDGETN.UPDATE_BUYNANDGETN_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };
    case BUYNANDGETN.UPDATE_BUYNANDGETN_BY_ID_FAIL:
      toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
