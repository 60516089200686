import {

  // addPromotionalOffer,
  // get,
  // deleteById,
  // updateById,
  // getAllBySellerId,

  addPromotionalOffer,
  deleteById,
  get,
  updateById,
} from "../../../services/PromotionalOffer.service";

export const COMBOOFFER_ADD = "COMBOOFFER_ADD";
export const COMBOOFFER_ADD_SUCCESS = "COMBOOFFER_ADD_SUCCESS";
export const COMBOOFFER_ADD_FAIL = "COMBOOFFER_ADD_FAIL";

export const GET_ALL_COMBOOFFERS = "GET_ALL_COMBOOFFERS";
export const GET_ALL_COMBOOFFERS_SUCCESS =
  "GET_ALL_COMBOOFFERS_SUCCESS";
export const GET_ALL_COMBOOFFERS_FAIL = "GET_ALL_COMBOOFFERS_FAIL";

export const UPDATE_COMBOOFFER_BY_ID = "UPDATE_COMBOOFFER_BY_ID";
export const UPDATE_COMBOOFFER_BY_ID_SUCCESS =
  "UPDATE_COMBOOFFER_BY_ID_SUCCESS";
export const UPDATE_COMBOOFFER_BY_ID_FAIL =
  "UPDATE_COMBOOFFER_BY_ID_FAIL";

export const SET_COMBOOFFER_OBJ = "SET_COMBOOFFER_OBJ";
export const SET_COMBOOFFER_OBJ_SUCCESS = "SET_COMBOOFFER_OBJ_SUCCESS";
export const SET_COMBOOFFER_OBJ_FAIL = "SET_COMBOOFFER_OBJ_FAIL";

export const GET_COMBOOFFER_BY_ID = "GET_COMBOOFFER_BY_ID";
export const GET_COMBOOFFER_BY_ID_SUCCESS =
  "GET_COMBOOFFER_BY_ID_SUCCESS";
export const GET_COMBOOFFER_BY_ID_FAIL = "GET_COMBOOFFER_BY_ID_FAIL";

export const DELETE_COMBOOFFER_BY_ID = "DELETE_COMBOOFFER_BY_ID";
export const DELETE_COMBOOFFER_BY_ID_SUCCESS =
  "DELETE_COMBOOFFER_BY_ID_SUCCESS";
export const DELETE_COMBOOFFER_BY_ID_FAIL =
  "DELETE_COMBOOFFER_BY_ID_FAIL";

export const COMBOOFFERGET = (formData) => async (dispatch) => {
  try {
    dispatch({ type: GET_ALL_COMBOOFFERS });
    let { data: response } = await get(formData);
    // console.log(response, "action data");
    if (response) {
      dispatch({
        type: GET_ALL_COMBOOFFERS_SUCCESS,
        payload: { data: response.data, message: response.message,
          comboPaginationObj: {
            page: response.page,
            perPage: response.perPage,
            totalCount: response.totalCount,
            totalPages: response.totalPages,
          }, },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: COMBOOFFER_ADD_FAIL, payload: err });
  }
};

export const COMBOOFFERADD = (formData) => async (dispatch) => {
  try {
    dispatch({ type: COMBOOFFER_ADD });
    let { data: response } = await addPromotionalOffer(formData);
    if (response) {
      dispatch({
        type: COMBOOFFER_ADD_SUCCESS,
        payload: response.message,
      });
      dispatch(COMBOOFFERGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: COMBOOFFER_ADD_FAIL, payload: err });
  }
};

export const SETCOMBOOFFEROBJ = (formData) => async (dispatch) => {
  try {
    dispatch({ type: SET_COMBOOFFER_OBJ });
    if (formData) {
      dispatch({
        type: SET_COMBOOFFER_OBJ_SUCCESS,
        payload: { data: formData },
      });
    } else {
      dispatch({
        type: SET_COMBOOFFER_OBJ_SUCCESS,
        payload: { data: null },
      });
    }
  } catch (err) {
    // console.error(err);
    dispatch({
      type: SET_COMBOOFFER_OBJ_FAIL,
      payload: { message: "NOT FOUND" },
    });
  }
};

export const COMBOOFFERUPDATE = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_COMBOOFFER_BY_ID });
    // console.log(id, "id", formData, "form");
    let { data: response } = await updateById(id, formData);
    if (response) {
      // console.log(response,"updatein action ");
      dispatch({
        type: UPDATE_COMBOOFFER_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(COMBOOFFERGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: UPDATE_COMBOOFFER_BY_ID_FAIL, payload: err });
  }
};

export const COMBOOFFERDELETE = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_COMBOOFFER_BY_ID });
    let { data: response } = await deleteById(id);
    if (response) {
      // console.log(response, "response213123");
      dispatch({
        type: DELETE_COMBOOFFER_BY_ID_SUCCESS,
        payload: response.message,
      });
      dispatch(COMBOOFFERGET());
    }
  } catch (err) {
    // console.error(err);
    dispatch({ type: DELETE_COMBOOFFER_BY_ID_FAIL, payload: err });
  }
};
