import {
    addMailTemplate,
    deleteMailTemplate,
    getMailTemplates,
    updateMailTemplate,
    } from "../../../services/mailTemplate.service";
    
    export const MAIL_TEMPLATE_ADD = "MAIL_TEMPLATE_ADD";
    // SetMAIL_TEMPLATEObj;
    export const MAIL_TEMPLATE_ADD_SUCCESS = "MAIL_TEMPLATE_ADD_SUCCESS";
    export const MAIL_TEMPLATE_ADD_FAIL = "MAIL_TEMPLATE_ADD_FAIL";
    
    export const GET_ALL_MAIL_TEMPLATE = "GET_ALL_MAIL_TEMPLATE";
    export const GET_ALL_MAIL_TEMPLATE_SUCCESS = "GET_ALL_MAIL_TEMPLATE_SUCCESS";
    export const GET_ALL_MAIL_TEMPLATE_FAIL = "GET_ALL_MAIL_TEMPLATE_FAIL";
    
    export const UPDATE_MAIL_TEMPLATE_BY_ID = "UPDATE_MAIL_TEMPLATE_BY_ID";
    export const UPDATE_MAIL_TEMPLATE_BY_ID_SUCCESS =
      "UPDATE_MAIL_TEMPLATE_BY_ID_SUCCESS";
    export const UPDATE_MAIL_TEMPLATE_BY_ID_FAIL = "UPDATE_MAIL_TEMPLATE_BY_ID_FAIL";
    
    export const SET_MAIL_TEMPLATE_OBJ = "SET_MAIL_TEMPLATE_OBJ";
    export const SET_MAIL_TEMPLATE_OBJ_SUCCESS = "SET_MAIL_TEMPLATE_OBJ_SUCCESS";
    export const SET_MAIL_TEMPLATE_OBJ_FAIL = "SET_MAIL_TEMPLATE_OBJ_FAIL";
    
    export const GET_MAIL_TEMPLATE_BY_ID = "GET_MAIL_TEMPLATE_BY_ID";
    export const GET_MAIL_TEMPLATE_BY_ID_SUCCESS = "GET_MAIL_TEMPLATE_BY_ID_SUCCESS";
    export const GET_MAIL_TEMPLATE_BY_ID_FAIL = "GET_MAIL_TEMPLATE_BY_ID_FAIL";
    
    export const DELETE_MAIL_TEMPLATE_BY_ID = "DELETE_MAIL_TEMPLATE_BY_ID";
    export const DELETE_MAIL_TEMPLATE_BY_ID_SUCCESS =
      "DELETE_MAIL_TEMPLATE_BY_ID_SUCCESS";
    export const DELETE_MAIL_TEMPLATE_BY_ID_FAIL = "DELETE_MAIL_TEMPLATE_BY_ID_FAIL";
    
    export const MailTemplateAdd = (formData) => async (dispatch) => {
      try {
        dispatch({ type: MAIL_TEMPLATE_ADD });
        // console.log(formData, "formDatain redux action");
        let { data: response } = await addMailTemplate(formData);
        if (response) {
          // console.log(response, "response12");
          dispatch({
            type: MAIL_TEMPLATE_ADD_SUCCESS,
            payload: response.message,
          });
          dispatch(MailTemplateGet());
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: MAIL_TEMPLATE_ADD_FAIL, payload: err });
      }
    };
    
    export const MailTemplateGet = (formData) => async (dispatch) => {
      try {
        dispatch({ type: GET_ALL_MAIL_TEMPLATE });
        let { data: response } = await getMailTemplates(formData);
        if (response) {
          // console.log(response, "rspnse actin get");
          dispatch({
            type: GET_ALL_MAIL_TEMPLATE_SUCCESS,
            payload: { data: response.data, message: response.message },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: MAIL_TEMPLATE_ADD_FAIL, payload: err });
      }
    };
    
    export const SetMailTemplateObj = (formData) => async (dispatch) => {
      try {
        dispatch({ type: SET_MAIL_TEMPLATE_OBJ });
        // console.log(formData, "form action ");
        if (formData) {
          dispatch({
            type: SET_MAIL_TEMPLATE_OBJ_SUCCESS,
            payload: { data: formData },
          });
        } else {
          dispatch({
            type: SET_MAIL_TEMPLATE_OBJ_SUCCESS,
            payload: { data: null },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: SET_MAIL_TEMPLATE_OBJ_FAIL,
          payload: { message: "NOT FOUND" },
        });
      }
    };
    
    export const MailTemplateUpdate = (formData, id) => async (dispatch) => {
      try {
        dispatch({ type: UPDATE_MAIL_TEMPLATE_BY_ID });
        // console.log(formData, id, "id formdata");
        let { data: response } = await updateMailTemplate(formData, id);
        if (response) {
          dispatch({
            type: UPDATE_MAIL_TEMPLATE_BY_ID_SUCCESS,
            payload: response.message,
          });
          
          dispatch(MailTemplateGet());
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: UPDATE_MAIL_TEMPLATE_BY_ID_FAIL, payload: err });
      }
    };
    
    export const MailTemplateDelete = (id) => async (dispatch) => {
      try {
        dispatch({ type: DELETE_MAIL_TEMPLATE_BY_ID });
        let { data: response } = await deleteMailTemplate(id);
        if (response) {
          // console.log(response, "deleted actn");
          dispatch({
            type: DELETE_MAIL_TEMPLATE_BY_ID_SUCCESS,
            payload: response.message,
          });
          dispatch(MailTemplateGet());
        }
      } catch (err) {
        console.error(err);
        dispatch({ type: DELETE_MAIL_TEMPLATE_BY_ID_FAIL, payload: err });
      }
    };
    