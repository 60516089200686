import React, { useState, useEffect } from "react";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  HOMEPAGEAdd,
  HOMEPAGEUpdate,
  SetHOMEPAGEObj,
} from "../../../redux/actions/HomePage/HomePage.actions";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";

import { HOMEPAGEGet } from "../../../redux/actions/HomePage/HomePage.actions";
import { CATEGORYGet } from "../../../redux/actions/Category/Category.actions";


import Select from "react-select";

function AddHomePage() {
  const dispatch = useDispatch();

  const [link1, setLink1] = useState("");
  const [link2, setLink2] = useState("");
  const [button1, setButton1] = useState("");
  const [imageStr1, setImageStr1] = useState("");
  const [button2, setButton2] = useState("");

  const [category1, setCategory1] = useState({});
  const [category2, setCategory2] = useState({});
  const [category3, setCategory3] = useState({});

  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [link3, setLink3] = useState("");
  const [link4, setLink4] = useState("");

  const [category1Obj, setcategory1Obj] = useState(false);
  const [category2Obj, setcategory2Obj] = useState(false);
  const [category3Obj, setcategory3Obj] = useState(false);
  const [option1, setOption] = useState([]);

  const homePageObj = useSelector((state) => state.HomePage.homePages);
  const CategoryArr = useSelector((state) => state.category.categories);

  const handleFileSet1 = (value) => {
    setImageStr1(value);
  };

  const handleFileSet2 = (value) => {
    setImage2(value);
  };

  const handleFileSet3 = (value) => {
    setImage3(value);
  };

  useEffect(() => {
    // console.log(homePageObj, "get homePageAr222");
    if (homePageObj&&homePageObj._id) {
      // console.log("homePageArrmePageAr222");
      setLink1(homePageObj?.section2?.link1);
      setLink2(homePageObj?.section2?.link2);
      setButton1(homePageObj?.section2?.button1);
      setImageStr1(homePageObj?.section2?.image);
      setButton2(homePageObj?.section2?.button2);
      setCategory1(homePageObj?.section1?.category1);
      setCategory2(homePageObj?.section1?.category2);
      setCategory3(homePageObj?.section1?.category3);
      setImage2(homePageObj?.section3?.image2);
      setImage3(homePageObj?.section3?.image3);
      setLink3(homePageObj?.section3?.link3);
      setLink4(homePageObj?.section3?.link4);
      setcategory1Obj(homePageObj?.obj?.category1Obj);
      setcategory2Obj(homePageObj?.obj?.category2Obj);
      setcategory3Obj(homePageObj?.obj?.category3Obj);
    }
  }, [homePageObj]);

  const handleget = async () => {
    try {
      // dispatch(CATEGORYGet(`status=APPROVED`));
      dispatch(CATEGORYGet(`level=1`));
      dispatch(HOMEPAGEGet());
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleget();
  }, []);

  useEffect(() => {
    const optionsCategory = [];
    if (CategoryArr) {
      for (let el of CategoryArr) {
        optionsCategory.push({ value: el._id, label: el.name });
      }
    }
    setOption(optionsCategory);
  }, [CategoryArr]);


  const handleSubmit = (e) => {
    try {
      e.preventDefault();

      let obj = {
        section1: {
          category1: category1?.value ? category1?.value : category1Obj?.value,
          category2: category2?.value ? category2?.value : category2Obj?.value,
          category3: category3?.value ? category3?.value : category3Obj?.value,
        },
        section2: {
          link1,
          link2,
          button1,
          image: imageStr1,
          button2,
        },
        section3: {
          image2,
          image3,
          link3,
          link4,
        },
      };
      // console.log(obj, "objbobjbob");
      dispatch(HOMEPAGEAdd(obj));
    } catch (err) {
      toastError(err);
    }
  };

  // category1Obj
  const handlerCategroy = (e, name) => {
    try {
      // console.log(e, "eee", name);
      if (e.name == "category1") {
        setcategory1Obj(e);
      } else if (e.name == "category2") {
        setcategory2Obj(e);
      } else if (e.name == "category3") {
        setcategory3Obj(e);
      }
    } catch (err) {
      toastError(err);
    }
  };
  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <h5 className="blue-1 mb-4">Home Page Information</h5>
          <form action="#" className="form">
            <div className="row gy-4 gy-xxl-0">
              <div className="col-12 col-xxl-8 mb-0">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Section One </h5>
                    <div className="col-12">
                      <label>Category One</label>
                      {/* {console.log(category1Obj,"category1Obj")} */}
                      {CategoryArr && CategoryArr.length > 0 && (
                        <Select
                          // onChange={(e)=>setCategory1(e)}
                          nameOf="category1"
                          onChange={(e) => handlerCategroy(e)}
                          value={category1Obj?.label ? category1Obj : category1}
                          options={
                            CategoryArr && CategoryArr.length > 0
                              ? CategoryArr.map((el) => ({
                                  ...el,
                                  label: el.name,
                                  value: el._id,
                                  name:"category1"
                                }))
                              : []
                          }
                        />
                      )}
                    </div>
                    <div className="col-12">
                      <label>Category Two</label>
                      {CategoryArr && CategoryArr.length > 0 && (
                        <Select
                          // onChange={(e)=>setCategory2(e)}
                          onChange={(e) => handlerCategroy(e)}
                          value={category2Obj ? category2Obj : category2}
                          name="category2"
                          options={
                            CategoryArr && CategoryArr.length > 0
                              ? CategoryArr.map((el) => ({
                                  ...el,
                                  label: el.name,
                                  value: el._id,
                                  name:"category2"
                                }))
                              : []
                          }
                        />
                      )}
                      {/* <Select
                       options={
                        CategoryArr &&
                        CategoryArr.length > 0 &&
                        CategoryArr.map((el, index) => ({
                          ...el,
                          label: el.name,
                          value: el._id,
                        }  ) )  }
                        defaultInputValue={category2Obj}
                        value={category2}
                        onChange={(e) => setCategory2(e)}
                      /> */}
                    </div>
                    <div className="col-12">
                      <label>Category Three</label>

                      {CategoryArr && CategoryArr.length > 0 && (
                        <Select
                          onChange={(e) => handlerCategroy(e)}
                          value={category3Obj ? category3Obj : category3}
                          name="category3"
                          options={
                            CategoryArr && CategoryArr.length > 0
                              ? CategoryArr.map((el) => ({
                                  ...el,
                                  label: el.name,
                                  value: el._id,
                                  name:"category3"
                                }))
                              : []
                          }
                        />
                      )}
                      {/* <Select
                        options={
                          CategoryArr &&
                          CategoryArr.length > 0 &&
                          CategoryArr.map((el, index) => ({
                            ...el,
                            label: el.name,
                            value: el._id,
                          }  ) )  }
                        defaultInputValue={category3Obj}
                        value={category3}
                        onChange={(e) => setCategory3(e)}
                      /> */}
                    </div>
                  </div>
                </DashboardBox>

                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Section Two</h5>
                    <div className="col-12">
                      <label>Link One</label>
                      <input
                        value={link1}
                        onChange={(event) => setLink1(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12">
                      <label>Link two</label>
                      <input
                        value={link2}
                        onChange={(event) => setLink2(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>button One Text</label>
                      <input
                        value={button1}
                        onChange={(event) => setButton1(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>button two Text</label>
                      <input
                        value={button2}
                        onChange={(event) => setButton2(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>

                    <div className="col-12">
                      <label> header image (150x150)PX</label>
                      <br />
                      <br />
                      <img
                        src={
                          `${imageStr1}`.includes("base64")
                            ? imageStr1
                            : generateFilePath(imageStr1)
                        }
                        style={{ height: 80 }}
                      />
                      <br />
                      <br />
                      <FileUpload onFileChange={handleFileSet1} />
                    </div>
                  </div>
                </DashboardBox>

                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Section Three</h5>
                    <div className="col-12">
                      <label>Link three</label>
                      <input
                        value={link3}
                        onChange={(event) => setLink3(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label>Link Four</label>
                      <input
                        value={link4}
                        onChange={(event) => setLink4(event.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="col-12">
                      <label> image (150x150)PX</label>
                      <br />
                      <br />
                      <img
                        src={
                          `${image2}`.includes("base64")
                            ? image2
                            : generateFilePath(image2)
                        }
                        style={{ height: 80 }}
                      />
                      <br />
                      <br />

                      <FileUpload onFileChange={handleFileSet2} />
                    </div>
                    <div className="col-12">
                      <label> image (150x150)PX</label>
                      <br />
                      <br />
                      <img
                        src={
                          `${image3}`.includes("base64")
                            ? image3
                            : generateFilePath(image3)
                        }
                        style={{ height: 80 }}
                      />
                      <br />
                      <br />

                      <FileUpload onFileChange={handleFileSet3} />
                    </div>
                  </div>
                </DashboardBox>
                <div className="col-12 mt-2">
                  <CustomButton
                    btntype="button"
                    ClickEvent={(e) => handleSubmit(e)}
                    isBtn
                    iconName="fa-solid fa-check"
                    btnName="Save"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddHomePage;
