import React, { useState,useEffect } from "react";
import CustomButton from "../Utility/Button";
import { DashboardBox } from "../Utility/DashboardBox";
import FileUpload from "../Utility/FileUpload";
import {excelUploadProduct ,sampleProductFileDownload } from "../../services/product.service"
import { toastSuccess ,toastError} from "../Utility/ToastUtils";



function BulkProductUpload() {
  const [file, setFile] = useState("");

  const handleSetFile = async (data) => {
    // console.log(data,"datatatataat");
    setFile(data);
  }

 
    
    
  const handleSubmit = async () => {
    try {
      let formData = new FormData();
      formData.append("file", file)
// console.log(formData,"formData213 ")
      let { data: res } = await excelUploadProduct(formData);
      // console.log(res,"productobj 23424")

      if (res.success) {

        toastSuccess(res.message)
        // toastSuccess(res?.data[0])
        // let msg=res.data[0].split(",")
        // // console.log(msg,"smfgmsdmf")
        // for(let el of msg){
        //   toastError(el)
        // }
        // toastError(res.data[0])
      }

    }
    catch (err) {
      toastError(err)
    }
  }

  const handleSampleFileDownload = async (e) => {
    try {e.preventDefault()
      // console.log("errrrrrrrr")
        await sampleProductFileDownload();
    } catch (error) {
      toastError(error);
    }
  };
  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <DashboardBox>
            <h5 className="blue-1 mb-4">Bulk Product Upload</h5>
            <form action="" className="form row">
              <div className="col-12 mb-3">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <label>CSV FILE</label>
                  <CustomButton isBtn btntype="button" ClickEvent={(e) => handleSampleFileDownload(e)} downloadAble noIcon changeClass="btn p-0 m-0 fs-12 text-primary border-0" btnName="SAMPLE FILE DOWNLOAD" />
                </div>
                <FileUpload returnOriginal={true} onFileChange={handleSetFile}/>
                <div className="form-text fs-12 blue-1">
                  PLEASE DOWNLOAD THE SAMPLE FILE INPUT YOUR DESIRE INFORMATION
                  THEN UPLOAD. DON'T TRY TO UPLOAD DIFFERENT FILE FORMAT AND
                  INFORMATION
                </div>
              </div>
              <div className="col-12 text-center">
                <CustomButton 
                isBtn iconName='fa-solid fa-check'
                 btnName='Save'
                  ClickEvent={(e) => { e.preventDefault(); handleSubmit() }}    />
              </div>
            </form>
          </DashboardBox>
        </div>
      </section>
    </main>
  );
}

export default BulkProductUpload;
