import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../..//Utility/Button";
import { generalModelStatuses } from "../..//Utility/constants";
import FileUpload from "../..//Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";

import {
  SETSHIPPINGOBJ,
  SHIPPINGADD,
  SHIPPINGUPDATE,
} from "../../../redux/actions/Shipping/Shipping.actions";

import { countryList } from "../../../utils/Country";
import { toastError } from "../../../utils/toastUtils";

function AddShipping({ makeChange }) {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [min, setmin] = useState(0);
  const [max, setmax] = useState(0);
  const [country, setCountry] = useState("");

  const [price, setprice] = useState(0);
  const [shippingId, setShippingId] = useState("");
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);

  const shippingObj = useSelector((state) => state.shipping.shippingObj);

  const cleaRFunc = () => {
    setmin(0);
    setmax(0);
    setCountry("");
    setprice(0);
    setStatus(generalModelStatuses.APPROVED);
  };

  const handleAddCategory = () => {
    try {


      if (`${min}` =="") {
        toastError("min is mandatory");
        return;
      }
      if (`${max}` == "") {
        toastError("max is mandatory");
        return;
      }
      if (`${price}` == "") {
        toastError("Price is mandatory");
        return;
      }
      if (`${country}` == "") {
        toastError("Please Select Country");
        return;
      }
      let obj = {
        min,
        max,
        price,
        country,
        status,
      };
      // console.log(obj, "category obj", shippingId);

      if (shippingObj?._id) {
        dispatch(SHIPPINGUPDATE(shippingId, obj));
        dispatch(SETSHIPPINGOBJ(null));
      } else {
        dispatch(SHIPPINGADD(obj));
      }
      cleaRFunc();
      dispatch(SETSHIPPINGOBJ(null));
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    if (shippingObj) {
      setmin(shippingObj?.min);
      setmax(shippingObj?.max);
      setprice(shippingObj?.price);
      setStatus(shippingObj?.status);
      setCountry(shippingObj?.country);
      setShippingId(shippingObj?._id);
    }

    // return () => {
    //     dispatch(SETSHIPPINGOBJ(null));
    // };
  }, [shippingObj]);

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Min Weight (In kg)<span className="red">*</span>
          </label>
          <input
            value={min}
            onChange={(event) => setmin(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Max Weight (In kg) <span className="red">*</span>
          </label>
          <input
            value={max}
            onChange={(event) => setmax(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Cost per Order <span className="red">*</span>
          </label>
          <input
            value={price}
            onChange={(event) => setprice(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Country <span className="red">*</span>
          </label>
          <select
            className="form-control"
            value={country}
            onChange={(event) => setCountry(event.target.value)}
          >
            <option>Please Select Country</option>
            {countryList &&
              countryList.map((el) => (
                <option value={el.name}>{el.name}</option>
              ))}
          </select>
          {/* <input value={country} onChange={(event) => setCountry(event.target.value)} type="text" className="form-control" /> */}
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddShipping;
