  import {
  toastError,
  toastSuccess,
} from "../../../components/Utility/ToastUtils";

import * as HSNTAX from "../../actions/HsnTax/HsnTax.actions";


const initialState = {
  hsnTaxs: null,
  hsnTaxObj: null,
  loading: false,
  error: null,
  hsnTaxPaginationObj:null,
  hsnTaxStatusUpdate:null
};


export const hsnTaxReducer = (state = initialState, action) => {
  switch (action.type) {

    case HSNTAX.HSNTAX_ADD:
      return {
        ...state,
        loading: true,
      };

    case HSNTAX.HSNTAX_ADD_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
      };


    case HSNTAX.HSNTAX_ADD_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case HSNTAX.GET_ALL_HSNTAXS:
      return {
        ...state,
        loading: true,
      };


    case HSNTAX.GET_ALL_HSNTAXS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        hsnTaxs: action.payload.data,
        hsnTaxPaginationObj:action.payload.hsnTaxPagination
      };

    case HSNTAX.GET_ALL_HSNTAXS_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case HSNTAX.DELETE_HSNTAX_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case HSNTAX.DELETE_HSNTAX_BY_ID_SUCCESS:
      toastSuccess(action.payload);
      return {
        ...state,
        loading: false,
        error: null,
      };

    case HSNTAX.DELETE_HSNTAX_BY_ID_FAIL:
      toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case HSNTAX.SET_HSNTAX_OBJ:
      return {
        ...state,
        loading: true,
      };
    case HSNTAX.SET_HSNTAX_OBJ_SUCCESS:
      // toastSuccess(action.payload.message);
      return {
        ...state,
        hsnTaxObj: action.payload.data,
        loading: false,
        error: null,
      };
    case HSNTAX.SET_HSNTAX_OBJ_FAIL:
      // toastError(action.payload);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    
    
      case HSNTAX.UPDATE_HSNTAX_BY_ID:
      return {
        ...state,
        loading: true,
      };


    case HSNTAX.UPDATE_HSNTAX_BY_ID_SUCCESS:
      toastSuccess(action.payload.message);
      return {
        ...state,
        loading: false,
        error: null,
      };


    case HSNTAX.UPDATE_HSNTAX_BY_ID_FAIL:
      toastError(action.payload.response.data.message);
      // toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
   
      case HSNTAX.UPDATE_HSNTAX_STATUS_SUCCESS:
      // toastError(action.payload.data);
      return {
        ...state,
        loading: false,
        error: action.payload,
        hsnTaxStatusUpdate:action.payload
      };

    default:
      return state;
  }
};
