import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../Utility/ActionIcon";
import CustomButton from "../Utility/Button";
import SearchBox from "../Utility/SearchBox";

import { useDispatch, useSelector } from "react-redux";
import {
  SETSTATEOBJ,
  STATEDELETE as areaDELETE,
  STATEGET as areaGET,
} from "../../redux/actions/State/States.actions";
import { DashboardBox, DashboardTable } from "../Utility/DashboardBox";
import { AddModal } from "../Utility/Modal";
import AddWareHouse from "./AddCompany";
import {
  COMPANYADDRESSDELETE,
  COMPANYADDRESSGET,
  SETCOMPANYADDRESSOBJ,
} from "../../redux/actions/companyAddress/CompanyAddress.actions";



function ViewCompanyAddress() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const warehouseArr = useSelector(
    (state) => state.companyAddress.companyAddresss
  );
  const warehouseObj = useSelector(
    (state) => state.companyAddress.companyAddresssObj
  );
  const [ModalData, setModalData] = useState({});
  const [displayWareHouseArr, setDisplayWareHouseArr] = useState([]);
  const [query, setQuery] = useState("");
  
  const handleCategoryEdit = (row) => {
    // console.log(row,"rowwwwowo=")
    dispatch(SETCOMPANYADDRESSOBJ(row));
  };

  const [wareHouseArr, setWareHouseArr] = useState([]);

  const handleGet = () => {
    dispatch(COMPANYADDRESSGET());
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(COMPANYADDRESSDELETE(row._id));
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    if (warehouseArr && warehouseArr?.length >= 0) {
      setWareHouseArr(warehouseArr);
      setDisplayWareHouseArr(warehouseArr);
    }
  }, [warehouseArr]);

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row?.address,
    },
    {
      name: "State",
      selector: (row) => row?.stateObj?.name,
    },
    {
      name: "City",
      selector: (row) => row?.cityObj?.name,
    },
    {
      name: "Area",
      selector: (row) => row?.areaObj?.name,
    },
    {
      name: "Pin Code",
      selector: (row) => row?.pinCode,
    },

    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/company/View-companyAddress"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/company/View-companyAddress"
        />
      ),
    },
  ];

  // ==============================================================================================================

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Company Address</h5>
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddWareHouse />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0"> Details </h5>
                {/* <div className="d-flex gap-3 align-items-center">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div> */}
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    wareHouseArr && wareHouseArr.length > 0 ? wareHouseArr : []
                  }
                  pagination
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewCompanyAddress;
