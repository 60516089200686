import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { downloadCSV } from "../../Utility/CSV";
import SearchBox from "../../Utility/SearchBox";

import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import {
  CATEGORYDelete,
  CATEGORYGet,
  SetCATEGORYObj,
} from "../../../redux/actions/Category/Category.actions";
import { toastError } from "../../../utils/toastUtils";
import AddStates from "./AddStates";

import {
  SETSTATEOBJ,
  STATEDELETE,
  STATEGET,setStateStatus
} from "../../../redux/actions/State/States.actions";

import { getStates } from "../../../services/state.service";

function ViewStates() {
  // ==============================================================================================================
  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const statesArr = useSelector((state) => state.states.states);
  const stateObj = useSelector((state) => state.states.statesObj);
  const [ModalData, setModalData] = useState({});
  const [displayStateArr, setDisplayStatesArr] = useState([]);
  const [query, setQuery] = useState("");

  
  const [stateMainArr, setStateMainArr] = useState([]);
  
  const statePaginationObject = useSelector(
    (state) => state.states.paginationObj
    );
    
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    
    const handleCategoryEdit = (row) => {
      let queryOf = `page=${page}&perPage=${perPage}`;
      row.query=queryOf
      dispatch(SETSTATEOBJ(row));
    };
  const handleGet = async () => {
    try {
      let queryOf = `page=${page}&perPage=${perPage}`;
      if (query) {
        queryOf = `${queryOf}&filterQuery=${query}`;
      }
      // dispatch(STATEGET(queryOf));
      let { data: get } = await getStates(queryOf);
      if (get) {
        if (get) {
          setStateMainArr(get?.data);
          setDisplayStatesArr(get?.data);
          setPage(get?.page);
          setPerPage(get?.perPage);
          setTotalCount(get?.totalCount);
          setTotalPages(get?.totalPages);
        }
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGet();
  }, [page, perPage, query]);

  const handlePageChange = (page) => {
    setPage(page);
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  useEffect(() => {
  
    if (statePaginationObject) {
      setPage(statePaginationObject?.page);
      setPerPage(statePaginationObject?.perPage);
      setTotalCount(statePaginationObject?.totalCount);
      setTotalPages(statePaginationObject?.totalPages);
    }
    return ()=>{
      dispatch(setStateStatus(null))
    }
  }, [ statePaginationObject]);


  useEffect(() => {
    if (statesArr&&statesArr?.length >= 0) {
      setStateMainArr(statesArr);
      setDisplayStatesArr(statesArr);
    }
    
  }, [statesArr]);



  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      let query = `page=${page}&perPage=${perPage}`;
      dispatch(STATEDELETE(row._id,query));
    }
  };




  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => (
        <CustomButton
          greenBtn
          noIcon
          btnName={`${row.status == "APPROVED" ? "Active" : "InActive"}`}
          path={"/Location/View-States"}
        />
      ),
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => (
        <ActionIcon
          Uniquekey={row._id}
          remove
          edit
          deletePath="/Location/View-States"
          onDeleteClick={() => handleCategoryDelete(row)}
          isRedirected={true}
          onEditClick={() => handleCategoryEdit(row)}
          editPath="/Location/View-States"
        />
      ),
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    setQuery(e);
  };

  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12 col-md-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0"> */}
                  {/* {stateObj && stateObj?.name ? "Edit State" : "Add State"} */}
                  {/* State */}
                {/* </h5> */}
              </div>
              <DashboardBox>
                <AddStates />
              </DashboardBox>
            </div>
            <div className="col-12 col-md-8">
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h5 className="blue-1 m-0">States List</h5>
                <div className="d-flex gap-3 align-items-center">
                  <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  />
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={category_columns}
                  data={
                    stateMainArr && stateMainArr.length > 0 ? stateMainArr : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ViewStates;
