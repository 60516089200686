import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Select from "react-select";
// import { AddProductCategoryByProductId, getProducts, handleSaveProductsPositions, RemoveProductCategoryByProductId } from "../../../services/product.service";
import { getCategory, saveCategoryposition } from "../../../services/category.service";
import CustomButton from "../../Utility/Button";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { toastError, toastSuccess } from "../../Utility/ToastUtils";
import SelectNestedCategory from "./SelectNestedCategory";



export default function CategorySequence() {
    const [ModalType, setModalType] = useState("");
    const [ModalName, setModalName] = useState("");
    const [ModalBox, setModalBox] = useState(false);
    const [dataIsAdded, setDataIsAdded] = useState(false);
    const [displayProductsInSelectedCategoryArr, setDisplayProductsInSelectedCategoryArr] = useState([]);
    const [productsArr, setProductsArr] = useState([]);
    const [productCategoriesArr, setProductCategoriesArr] = useState([]);
    const [selectedProductCategoryObj, setSelectedProductCategoryObj] = useState("");
    const [productsNotInSelectedCategory, setProductsNotInSelectedCategory] = useState([]);
    const [parentCategoryId, setParentCategoryId] = useState("");

    const handleGetAllProductCategories = async () => {
        try {
            let arr = await getCategoryData();

            let pareantcategoryObj = {
                name: "Parent category",
                _id: 0,
            }
            arr.unshift(pareantcategoryObj);
            setProductCategoriesArr(arr);

        } catch (err) {
            toastError(err)
        }
    }

    const getCategoryData = async (level = 1, parentId = undefined) => {
        try {
            let query = `level=${level}`;
            if (parentId) {
                query = query + `&parentCategoryId=${parentId}`
            }
            query = query + `&showOnHomePage=true`
            const res = await getCategory(query);
            // console.log(res?.data)
            if (res?.data?.data) {
                return (res?.data?.data)
            }
        } catch (error) {
            console.error(error);
            toastError(error)
        }
        return ([])
    }


    const handleGetAllProducts = async () => {
        // try {
        //     let { data: res } = await getProducts();
        //     if (res.success) {
        //         setProductsArr(res.data);
        //         setDisplayProductsInSelectedCategoryArr(res.data);
        //         setProductsNotInSelectedCategory(res.data);
        //         if (selectedProductCategoryObj) {
        //             handleSetDisplayProductsOnInit(res.data)
        //         }
        //     }
        // } catch (err) {
        //     toastError(err)
        // }
    }


    const handleSetDisplayProductsOnInit = (data) => {
        let tempArr = []
        let tempArr2 = []
        if (selectedProductCategoryObj) {
            tempArr = data.filter(el => el.productCategoryIdArr.some(ele => ele.productCategoryId == selectedProductCategoryObj._id))
            tempArr2 = data.filter(el => el.productCategoryIdArr.every(ele => ele.productCategoryId != selectedProductCategoryObj._id))
        }
        else {
            tempArr = data
            tempArr2 = data
        }
        setDisplayProductsInSelectedCategoryArr(tempArr)
        setProductsNotInSelectedCategory(tempArr2)
    }
    const handleSetDisplayProducts = () => {
        let tempArr = []
        let tempArr2 = []
        if (selectedProductCategoryObj._id || selectedProductCategoryObj._id != "") {
            tempArr = productsArr.filter(el => el.productCategoryIdArr.some(ele => ele.productCategoryId == selectedProductCategoryObj._id))
            tempArr2 = productsArr.filter(el => el.productCategoryIdArr.every(ele => ele.productCategoryId != selectedProductCategoryObj._id))
        }
        else {
            tempArr = productsArr
            tempArr2 = productsArr
        }
        tempArr.sort((a, b) => {
            // console.log(
            //     a.productCategoryIdArr.find(el => `${el.productCategoryId}` == selectedProductCategoryObj._id).position,
            //     "a",
            //     b.productCategoryIdArr.find(el => `${el.productCategoryId}` == selectedProductCategoryObj._id).position,
            //     "b"
            // )
            return a.productCategoryIdArr.find(el => `${el.productCategoryId}` == selectedProductCategoryObj._id)?.position - b.productCategoryIdArr.find(el => `${el.productCategoryId}` == selectedProductCategoryObj._id)?.position
        })
        setDisplayProductsInSelectedCategoryArr(tempArr)
        setProductsNotInSelectedCategory(tempArr2)
    }

    useEffect(() => {
        handleSetDisplayProducts()
    }, [selectedProductCategoryObj])



    const handleMoveElementUp = (objectId) => {
        // console.log(selectedProductCategoryObj.value, "selectedProductCategoryObj")
        if (!selectedProductCategoryObj) {
            toastError("Please Select a product category first")
            return
        }
        let index = displayProductsInSelectedCategoryArr.findIndex(el => el._id == objectId)
        if (index != 0) {
            let obj = displayProductsInSelectedCategoryArr[index]
            let tempArr = displayProductsInSelectedCategoryArr.filter((el, innerIndex) => objectId != el._id)
            tempArr.splice(index - 1, 0, obj)
            setDisplayProductsInSelectedCategoryArr(tempArr)
        }
        else {
            toastError("Already at Top")
        }
    }
    const handleMoveElementDown = (objectId) => {
        // console.log(selectedProductCategoryObj.value, "selectedProductCategoryObj")

        if (!selectedProductCategoryObj) {
            toastError("Please Select a product category first")
            return
        }

        let index = displayProductsInSelectedCategoryArr.findIndex(el => el._id == objectId)
        // console.log(index, displayProductsInSelectedCategoryArr.length)
        if (index != displayProductsInSelectedCategoryArr.length - 1) {
            let obj = displayProductsInSelectedCategoryArr[index]
            let tempArr = displayProductsInSelectedCategoryArr.filter((el, innerIndex) => objectId != el._id)
            tempArr.splice(index + 1, 0, obj)
            setDisplayProductsInSelectedCategoryArr(tempArr)
        }
        else {
            toastError("Already at Bottom")
        }

    }

    const existing_product_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            minWidth: "80px",
            maxWidth: "80px",
            sortable: true,
        },
        {
            name: "Name",
            minWidth: "220px",
            maxWidth: "220px",
            selector: (row) => row.name,
        },
        {
            name: "Management",
            minWidth: "20px",
            cell: (row, index) => (
                <>
                    <CustomButton
                        greenBtn
                        noIcon
                        btnName="Move Up"
                        ClickEvent={(e) => {
                            e.preventDefault();
                            handleMoveElementUp(row._id)
                        }}
                    />
                    <CustomButton
                        greenBtn
                        noIcon
                        btnName="Move Down"
                        ClickEvent={(e) => {
                            e.preventDefault();
                            handleMoveElementDown(row._id)
                        }}
                    />

                    {/* <CustomButton
                        redBtn
                        noIcon
                        btnName="Remove From Category"
                        ClickEvent={(e) => {
                            e.preventDefault();
                            handleRemoveProductFromCategory(row._id)
                        }}
                    /> */}
                </>
            ),
        },
    ];


    const not_existing_product_columns = [
        {
            name: "SL",
            selector: (row, index) => `${index + 1}`,
            sortable: true,
        },
        {
            name: "Name",
            selector: (row) => row.name,
        },

        {
            name: "Position Management",
            minWidth: "20px",
            maxWidth: "200px",
            cell: (row) => (
                <>
                    <CustomButton
                        greenBtn
                        noIcon
                        btnName="Add To Category"
                        ClickEvent={(e) => {
                            e.preventDefault();
                            handleAddProductToCategory(row._id)
                        }}
                    />
                </>
            ),
        },
    ];


    const handleChange = async (Value) => {
        setSelectedProductCategoryObj(Value)
        let level = 1;
        let parentId = "";
        if (Value._id != 0) {
            level = 2;
            parentId = Value?._id
        }
        try {
            let arr = await getCategoryData(level, parentId);

            // console.log(arr, "caferesdfdsf")
            setDisplayProductsInSelectedCategoryArr(arr)


        } catch (err) {
            toastError(err)
        }
        // console.log(Value, "value")
    }





    useEffect(() => {
        // handleGetAllProducts()
        handleGetAllProductCategories()
        return () => {
            setProductCategoriesArr([]);
            setProductsArr([])
        }
    }, [])


    const handleAddProductToCategory = async (id) => {
        try {
            if (!selectedProductCategoryObj._id || selectedProductCategoryObj._id == "") {
                toastError("Please Select a product category first")
                return
            }
            let obj = {
                productCategoryId: selectedProductCategoryObj._id
            }
            // let { data: res } = await AddProductCategoryByProductId(id, obj);
            // if (res.success) {
            //     // handleGetAllProducts()   
            // }
        } catch (err) {
            toastError(err)
        }
    }


    const handleRemoveProductFromCategory = async (id) => {
        try {
            // console.log(selectedProductCategoryObj._id)
            if (!selectedProductCategoryObj._id || selectedProductCategoryObj._id == "") {
                toastError("Please Select a product category first")
                return
            }
            let obj = {
                productCategoryId: selectedProductCategoryObj._id
            }
            // let { data: res } = await RemoveProductCategoryByProductId(id, obj);
            // if (res.success) {
            //     handleGetAllProducts()
            // }
        } catch (err) {
            toastError(err)
        }
    }






    const handleSaveCategoryWisePositionForProducts = async () => {
        try {
            if (!selectedProductCategoryObj) {
                toastError("Please Select a product category first")
                return
            }
            let tempArr = displayProductsInSelectedCategoryArr.map((el, index) => {
                let obj = {
                    position: index + 1,
                    categoryId: el._id
                }
                return obj
            })

            let obj = {
                category: tempArr,
            }

            // console.log(tempArr, "tempArrtempArrtempArrtempArr")
            let { data: res } = await saveCategoryposition(selectedProductCategoryObj._id, obj)
            if (res.message) {
                toastSuccess(res.message)
            }
        } catch (err) {
            toastError(err)
        }
    }








    return (
        <main>
            <section className="product-category">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 col-md-4">

                            <div className="d-flex align-items-center justify-content-between mb-3">
                                <h5 className="blue-1 m-0">Product Categories</h5>
                            </div>

                            <DashboardBox>
                                <Select value={selectedProductCategoryObj} onChange={handleChange} options={productCategoriesArr && productCategoriesArr.length > 0 && productCategoriesArr.map((el, index) => ({ ...el, label: el.name, value: el._id }))} />
                            </DashboardBox>

                            {/* <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
                                <h5 className="blue-1 m-0">Products Not In Category</h5>
                            </div>

                            <DashboardBox>
                                <DataTable columns={not_existing_product_columns} data={productsNotInSelectedCategory} pagination />
                            </DashboardBox> */}

                        </div>
                        <div className="col-12 col-md-8">
                            <div className="d-flex gap-3 justify-content-between mb-4 align-items-center">
                                <h5 className="blue-1 m-0">Products In Category</h5>
                                <CustomButton
                                    greenBtn
                                    noIcon
                                    btnName="Save Positions"
                                    ClickEvent={(e) => {
                                        e.preventDefault();
                                        handleSaveCategoryWisePositionForProducts()
                                        // handleAddProductToCategory(row._id)
                                    }}
                                />
                            </div>
                            <DashboardTable>
                                <DataTable columns={existing_product_columns} data={displayProductsInSelectedCategoryArr} pagination />
                            </DashboardTable>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
}
