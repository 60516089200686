import React, { useEffect, useState,useContext,   } from "react";
import DataTable from "react-data-table-component";
import AddCategory from "./AddCategory";
import ActionIcon from "../../Utility/ActionIcon";
import CustomButton from "../../Utility/Button";
import { downloadCSV } from "../../Utility/CSV";
import SearchBox from "../../Utility/SearchBox";

import { AddModal } from "../../Utility/Modal";
import { useSelector, useDispatch } from "react-redux";
import { DashboardBox, DashboardTable } from "../../Utility/DashboardBox";
import { CATEGORYDelete, CATEGORYGet, SetCATEGORYObj } from "../../../redux/actions/Category/Category.actions";
import { toastError } from "../../../utils/toastUtils";


import { RouteContext } from "../../../routes/AuthorizedRoutes";

import { useParams, useLocation } from "react-router-dom";






function Category() {
  // ==============================================================================================================
  
    const [routeUrl, setRouteUrl] = useContext(RouteContext);
    const location = useLocation()

  const dispatch = useDispatch();
  const [ModalType, setModalType] = useState("");
  const [ModalName, setModalName] = useState("");
  const [ModalBox, setModalBox] = useState(false);
  const categoryArr = useSelector((state) => state.category.categories);
  const [ModalData, setModalData] = useState({});
  const [displayCategoryArr, setDisplayCategoryArr] = useState([]);
  const [query, setQuery] = useState("");

  const handleCategoryEdit = (row) => {
    dispatch(SetCATEGORYObj(row));
    setIsUpdateCategory(true)
  };

  const [categoriesArr, setCategoriesArr] = useState([]);
  const [isUpdateCategory, setIsUpdateCategory] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);


  const handleCategoryGet = () => {
    if(query?.trim()!=""){
      dispatch(CATEGORYGet(`page=${page}&perPage=${perPage}&searchFilter=${query}`));
    }else{
      dispatch(CATEGORYGet(`page=${page}&perPage=${perPage}`));
    }
    // setRouteUrl(location?.pathname)
  };

  const handleCategoryDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?")
    if (confirm) {
      let query=`page=${page}&perPage=${perPage}`
      dispatch(CATEGORYDelete(row._id,query))
    }
  }


  useEffect(() => {
    handleCategoryGet()
  }, [])



useEffect(()=>{
  handleCategoryGet()
},[query])

  useEffect(() => { 
    // console.log(categoryArr,"==categoryArr==")
    if (categoryArr&&categoryArr?.length>=0) {
      setCategoriesArr(categoryArr)
      setDisplayCategoryArr(categoryArr)
    }
   
  }, [categoryArr])

  const category_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "7%"
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Parent Category",
      selector: (row) => (row.parentCategoryName ? row.parentCategoryName : "NA"),
    },
    {
      name: "Level",
      selector: (row) => row?.level,
      width: "5%"
    },
    {
      name: "Status",
      minWidth: "210px",
      maxWidth: "211px",
      button: true,
      cell: (row) => <CustomButton greenBtn noIcon btnName={row.status == "APPROVED" ? "Active" : "Inactive"} path={"/Product/Category"} />,
    },
    {
      name: "Action",
      minWidth: "210px",
      maxWidth: "211px",
      cell: (row) => <ActionIcon Uniquekey={row._id} remove edit deletePath="/Product/Category"
        onDeleteClick={() => handleCategoryDelete(row)} isRedirected={true}
        onEditClick={() =>
          handleCategoryEdit(row)

        }
        editPath="/Product/Category" />,
    },
  ];

  // ==============================================================================================================

  const handleFilterByQuery = (e, requiredParametersArr) => {
    //  let tempArr = displayCategoryArr.filter(el => {
    //   for (const ele of requiredParametersArr) {
    //     if (`${el.name}`.toLowerCase().includes(`${e}`.toLowerCase())
    //       || `${el.parentCategoryName}`.toLowerCase().includes(`${e}`.toLowerCase())
    //       || `${el.level}`.toLowerCase().includes(`${e}`.toLowerCase())
    //     ) {
    //       return true
    //     } else {
    //       return false
    //     }

    //   }
    // })
    setQuery(e)
    // setCategoriesArr([...tempArr])
  }


  return (
    <main>
      <AddModal
        ModalBox={ModalBox}
        setModalBox={setModalBox}
        name={ModalName}
        ModalType={ModalType}
        data={ModalData}
      />
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row gy-4 gy-xxl-0">
            <div className="col-12 col-xxl-4">
              <div className="d-flex align-items-center justify-content-between mb-3">
                {/* <h5 className="blue-1 m-0">{isUpdateCategory?"Update":"Add"} Category</h5> */}
                {/* <CustomButton isLink iconName="fa-solid fa-plus" btnName="BULK CATEGORY UPLOAD" path="/Product/Bulk-Category-Upload" roundedPill small /> */}
              </div>
              <DashboardBox>
                <AddCategory />
              </DashboardBox>
            </div>
            <div className="col-12 col-xxl-8">
              <div className="d-flex align-items-end justify-content-between mb-2">
                <h5 className="blue-1 m-0">Category List</h5>
                <div className="d-flex gap-3 align-items-center">
                  {/* <CustomButton isLink iconName="fa-solid fa-download" btnName="CATEGORY CSV" path="/Product/Bulk-Category-Upload" small roundedPill downloadAble /> */}
                  <SearchBox setQuery={(e) => { handleFilterByQuery(e, ["name"]); }} query={query} extraClass="bg-white" />
                </div>
              </div>
              <DashboardTable>
                <DataTable 
                columns={category_columns} 
                data={categoriesArr && categoriesArr.length > 0 ? categoriesArr : []} 
                pagination />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Category;
