import React, { useEffect, useState } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { generalModelStatuses } from "../../Utility/constants";
import FileUpload from "../../Utility/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import {
  CATEGORYAdd,
  CATEGORYGet,
  CATEGORYUpdate,
  SetCATEGORYObj,
  setCategoryStatus,
} from "../../../redux/actions/Category/Category.actions";

import SelectNestedCategory from "./SelectNestedCategory";
import { toastError } from "../../Utility/ToastUtils";
import { generateFilePath } from "../../Utility/utils";


function AddCategory({ makeChange }) {

  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [slug, setSlug] = useState("");
  const [title, setTitle] = useState("");
  const [isSearchable, setIsSearchable] = useState(false);
  const [status, setStatus] = useState(generalModelStatuses.APPROVED);
  const [toggleSubCategory, setToggleSubCategory] = useState(false);
  const [subcategoryArr, setSubcategoryArr] = useState([]);
  const [parentCategoryId, setParentCategoryId] = useState("");
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState("");

  const [level, setlevel] = useState(1);
  const [imageStr, setImageStr] = useState("");
  const [bannerImageStr, setBannerImageStr] = useState("");
  const [addSubCategory, setaddSubCategory] = useState(false);

  const [prevCategoryObj, setPrevCategoryObj] = useState(null);
  const [prevCategoryId, setPrevCategoryId] = useState(null);
  const categoryArr = useSelector((state) => state.category.categories);
  const categoryObj = useSelector((state) => state.category.categoryObj);
  const CategoryAddOrUpdateSuccess = useSelector(
    (state) => state.category.CategoryAddOrUpdateSuccessful
  );

  const [isUpdateCategory, setIsUpdateCategory] = useState(false);

  const [showOnHomePage, setShowOnHomePage] = useState(false);

  const [addhomepageCategory, setAddhomepageCategory] = useState(false);

  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [categoryObject, setCategoryObject] = useState({});


  const clearFunc = () => {
    try {
      setName("");
      setSlug("");
      setTitle("");
      setDescription("");
      setHeading("");
      setImageStr("");
      setCategoryObject({});
      dispatch(SetCATEGORYObj(null));
      dispatch(setCategoryStatus(null));
      setIsUpdateCategory(false);
      setaddSubCategory(false);
      setParentCategoryId("");
    } catch (err) {
      toastError(err);
    }
  };


  const handleAddCategory = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory");
        return;
      }
      if (heading == "") {
        toastError("Heading is mandatory");
        return;
      }
      if (description == "") {
        toastError("Description is mandatory");
        return;
      }
      if (slug == "") {
        toastError("Slug is mandatory");
        return;
      }
      // if (imageStr == "") {
      //   toastError("Image is mandatory");
      //   return;
      // }

      let obj = {
        name,
        slug,
        status,
        heading,
        description,
        showOnHomePage,
        isSearchable,
        imageStr,
        // addhomepageCategory,
        // bannerImageStr,
        parentCategoryId: parentCategoryId,
      };
      // console.log(obj, "category obj get");
      // let getResponse = {};
      // if (categoryObject && categoryObject?._id) {
      //   getResponse = await dispatch(CATEGORYUpdate(prevCategoryId, obj));
      // } else {
      //   getResponse = await dispatch(CATEGORYAdd(obj));
      // }

      if (categoryObject && categoryObject?._id) {
        dispatch(CATEGORYUpdate(prevCategoryId, obj));
      } else {
        dispatch(CATEGORYAdd(obj));
      }
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    // console.log(CategoryAddOrUpdateSuccess,"==CategoryAddOrUpdateSuccess==")
    if (CategoryAddOrUpdateSuccess && CategoryAddOrUpdateSuccess?.message) {
      clearFunc();
    }
    return () => {
      dispatch(setCategoryStatus(null));
    };
  }, [CategoryAddOrUpdateSuccess]);

  useEffect(() => {
    if (categoryObj && categoryObj._id) {
      // console.log(categoryObj, "categoryObj34 get");
      setCategoryObject(categoryObj);
      setName(categoryObj?.name);
      setSlug(categoryObj?.slug);
      setHeading(categoryObj?.heading);
      setDescription(categoryObj?.description);
      setlevel(categoryObj?.level);
      setIsSearchable(categoryObj?.isSearchable);
      setStatus(categoryObj?.status);
      setShowOnHomePage(categoryObj?.showOnHomePage);
      // setTitle(categoryObj?.title ? categoryObj?.title : "");
      setImageStr(categoryObj?.categoryImage);
      // setBannerImageStr(categoryObj?.bannerImage)
      // setDescription(categoryObj?.description ? categoryObj?.description : "");
      // if (categoryObj && categoryObj?.parentCategoryId?.trim()?.length > 3) {
      //   console.log(categoryObj?.parentCategoryId, " =23get parentCategoryId");
      //   setSelectedParentCategoryId(categoryObj?.parentCategoryId);
      //   setPrevCategoryObj({
      //     label: categoryArr?.find(
      //       (el) => el._id == categoryObj?.parentCategoryId
      //     )?.name,
      //     value: categoryObj.parentCategoryId,
      //   });
      //   setaddSubCategory(true);
      // } else {
      //   setaddSubCategory(false);
      // }
      // console.log(categoryArr,"===categoryArr===")
      if (categoryObj && categoryObj?.parentCategoryArr?.length) {
        // console.log(categoryObj?.parentCategoryId, " =23get parentCategoryId");
        // console.log(categoryArr?.find(
          // (el) => el._id == categoryObj?.parentCategoryId
        // )?.name, " =====23get ");
        setSelectedParentCategoryId(categoryObj?.parentCategoryId);
        setPrevCategoryObj({
          label: categoryArr?.find(
            (el) => el._id == categoryObj?.parentCategoryId
          )?.name,
          value: categoryObj?.parentCategoryId,
        });
        setaddSubCategory(true);
      } else {
        setaddSubCategory(false);
      }
      // if (categoryObj && categoryObj?.parentCategoryId) {
      //   setSelectedParentCategoryId(categoryObj?.parentCategoryId);
      //   setPrevCategoryObj({
      //     label: categoryArr?.find(
      //       (el) => el._id == categoryObj?.parentCategoryId
      //     )?.name,
      //     value: categoryObj.parentCategoryId,
      //   });
      //   setaddSubCategory(true);
      // }
      setPrevCategoryId(categoryObj?._id);
      setIsUpdateCategory(true);
    }

    return () => {
      dispatch(SetCATEGORYObj(null));
    };
  }, [categoryObj]);





  useEffect(() => {
    dispatch(CATEGORYGet());
  }, []);


  const handleFileSet = (value) => {
    setImageStr(value);
  };

  const handleBannerSet = (value) => {
    setBannerImageStr(value);
  };

  return (
    <div className={makeChange ? "makeChange" : ""}>
      <form className="form row">
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <h5 className="blue-1 m-0">
            {isUpdateCategory ? "Update" : "Add"} Category
          </h5>
          <label className="blue-1 fs-12">
            Name <span className="red">*</span>
          </label>
          <input
            value={name}
            onChange={(event) => setName(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Heading <span className="red">*</span>
          </label>
          <input
            value={heading}
            onChange={(event) => setHeading(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Description <span className="red">*</span>
          </label>
          <input
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>

        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            SLUG <span className="red">*</span>
          </label>
          <input
            value={slug}
            onChange={(event) => setSlug(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        {/* <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Title <span className="red">*</span>
          </label>
          <input
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            type="text"
            className="form-control"
          />
        </div>
        <div className={makeChange ? "col-12 col-md-6" : "col-12"}>
          <label className="blue-1 fs-12">
            Description <span className="red">*</span>
          </label>
          <input
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            type="text"
            className="form-control"
          />
        </div> */}
        {/* <div className={makeChange ? "col-12" : "col-12"}>
          <label className="blue-1 fs-12">
            ICON (TO USE THEMEFY ICON PLEASE TYPE HERE OR SELECT FONTAWESOME
            FROM LIST)
          </label>
          <input type="text" className="form-control" />
        </div> */}
        {/* <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">SEARCHABLE</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="category-status"
                id="category-searchable-Radio1"
                checked={isSearchable}
                onClick={() => setIsSearchable(true)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-searchable-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                name="category-status"
                id="category-searchable-Radio2"
                checked={!isSearchable}
                onClick={() => setIsSearchable(false)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-searchable-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div> */}
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Show on Homepage</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={showOnHomePage}
                onClick={() => setShowOnHomePage(true)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Yes
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={!showOnHomePage}
                onClick={() => setShowOnHomePage(false)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                No
              </label>
            </div>
          </div>
        </div>
        <div className={makeChange ? "col-12 col-md-4" : "col-12"}>
          <label className="blue-1 fs-12">Status</label>
          <div className="d-flex">
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                checked={status == generalModelStatuses.APPROVED}
                onClick={() => setStatus(generalModelStatuses.APPROVED)}
                type="radio"
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio1"
              >
                Active
              </label>
            </div>
            <div className="form-check form-check-inline d-flex align-items-center">
              <input
                className="form-check-input"
                type="radio"
                checked={status == generalModelStatuses.DECLINED}
                onClick={() => setStatus(generalModelStatuses.DECLINED)}
              />
              <label
                className="form-check-label fs-14"
                htmlFor="category-Radio2"
              >
                Inactive
              </label>
            </div>
          </div>
        </div>
        <div
          className={
            makeChange ? "col-12 col-md-4 d-flex align-items-end" : "col-12"
          }
        >
          {/* <div className="form-check form-check-inline d-flex align-items-center "style={{marginBottom:"10px"}}> */}
          {/* <input
              className="form-check-input"
              type="checkbox"
              name="category-status"
              value="option1"
              id="add-as-sub-category"
              checked={addhomepageCategory}
              onChange={(e) => {
                if (e.target.checked) {
                  setAddhomepageCategory(true);
                } else {
                  setAddhomepageCategory(false);
                }
              }}
            /> */}
          {/* <label
              className="form-check-label fs-14 pointer"
              htmlFor="add-on-homepage"
            >
              Add on homepage 
            </label> */}
          {/* </div> */}

          <div className="form-check form-check-inline d-flex align-items-center pointer">
            <input
              className="form-check-input"
              type="checkbox"
              name="category-status"
              value="option1"
              id="add-as-sub-category"
              checked={addSubCategory}
              onChange={(e) => {
                if (e.target.checked) {
                  setaddSubCategory(true);
                } else {
                  setaddSubCategory(false);
                }
              }}
            />
            <label
              className="form-check-label fs-14 pointer"
              htmlFor="add-as-sub-category"
            >
              Add as Sub Category
              {/* {parentCategoryId} {selectedParentCategoryId} */}
            </label>
          </div>
        </div>
        {/* {console.log(selectedParentCategoryId,"selectedParentCategoryId get ")} */}
        {/* {console.log(parentCategoryId,"parentCategoryId get ")} */}
        {addSubCategory && (
          <SelectNestedCategory
            onChange={(val) => setParentCategoryId(val)}
            categoryId={selectedParentCategoryId}
          />
        )}

        <div className="col-12">
          <label className="blue-1 fs-12">UPLOAD PHOTO</label>
          <br />
          <br />
          <img
            src={
              `${imageStr}`.includes("base64")
                ? imageStr
                : generateFilePath(imageStr)
            }
            style={{ width: 280 }}
          />
          <br />
          <br />
          <FileUpload onFileChange={handleFileSet} />
          <div className="form-text fs-12">(Ratio: (225 X 225)PX)</div>

          {/* {
            level == 1 && (
              <>
                <label className="blue-1 fs-12">UPLOAD Banner</label>
                <br />
                <br />
                <img
                  src={
                    `${bannerImageStr}`.includes("base64")
                      ? bannerImageStr
                      : generateFilePath(bannerImageStr)
                  }
                  style={{ height: 80 }}
                />

                <FileUpload onFileChange={handleBannerSet} />
              </>
            )
          } */}
        </div>
        <div className="col-12">
          <CustomButton
            btntype="button"
            ClickEvent={handleAddCategory}
            iconName="fa-solid fa-check"
            btnName="Save"
            isBtn
            small={makeChange ? true : false}
          />
        </div>
      </form>
    </div>
  );
}

export default AddCategory;
