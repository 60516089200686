import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
const serverUrl = url + "/productReview";


export const getProductReview = (query) => {
    // console.log(query,'query2312')
    return axiosApiInstance.get(`${serverUrl}/getReview?${query}`);
};

export const upadteReviewStatus = (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/upadteReviewStatus/${id}`, obj);
};

export const AddReviewApi = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/`, obj)
}