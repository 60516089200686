import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ActionIcon from "../../Utility/ActionIcon";
import { images } from "../../Images/Images";
import CustomButton from "../../Utility/Button";
import { DashboardTable } from "../../Utility/DashboardBox";
import SearchBox from "../../Utility/SearchBox";
import { useDispatch, useSelector } from "react-redux";
import {
  HOMEPAGEGet,
  SetHOMEPAGEObj,
  HOMEPAGEDelete,
} from "../../../redux/actions/HomePage/HomePage.actions";
// import {
//   HOMEPAGEAdd,
//   HOMEPAGEUpdate,
//   SetHOMEPAGEObj,

  
//   // HOMEPAGEGet
//   // SetHOMEPAGEObj
  
//   // HOMEPAGEDelete
// } from "../../../redux/actions/HomePage/HomePage.actions";

import { generateFilePath } from "../../Utility/utils";

function HomePage() {
  const dispatch = useDispatch();

  const bannerArr = useSelector((state) => state.banner.banners);
  const bannerPaginationObject = useSelector(
    (state) => state.banner.bannerPaginationObj
  );
  const [bannerArray, setBannerArray] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [bannerMainArr, setBannerMainArr] = useState([]);


  useEffect(() => {
    let queryOf = `page=${page}&perPage=${perPage}`;
    if (query) {
      queryOf = `${queryOf}&filterQuery=${query}`;
    }
    dispatch(HOMEPAGEGet(queryOf));
  }, [page, perPage,query]);


  

  useEffect(() => {
    // console.log(bannerPaginationObject,"bannerPaginationObject")
    // console.log(bannerArr,"bannerArr")

    setBannerArray(bannerArr);
    setBannerMainArr(bannerArr);

    if (bannerPaginationObject) {
      setTotalPages(bannerPaginationObject?.totalPages);
      setTotalCount(bannerPaginationObject?.totalCount);
      setPage(bannerPaginationObject?.page);
    }
  }, [bannerArr, bannerPaginationObject]);

  const handleEdit = (row) => {
    dispatch(SetHOMEPAGEObj(row));
  };

  const handleBlogDelete = (row) => {
    let confirm = window.confirm("Do you really want to delete this item?");
    if (confirm) {
      dispatch(HOMEPAGEDelete(row));
    }
  };

  const brand_columns = [
    {
      name: "ID",
      selector: (row, index) => index + 1,
      sortable: true,
      width: "10%",
    },
    {
      name: "Name",
      selector: (row) => row?.name,
    },
    {
      name: "Url",
      selector: (row) => row?.url,
    },
    {
      name: "Description",
      selector: (row) => row?.description,
    },
    {
      name: "Image",
      grow: 0,
      cell: (row) => (
        <img
          height="84px"
          width="56px"
          alt={row.name}
          src={generateFilePath(row.image)}
        />
      ),
    },
    {
      name: "Status",
      button: true,
      width: "20%",
      selector: (row) => (row?.status ? "Active" : "Inactive"),
      // cell: (row) => <CustomButton greenBtn btnName={row.status ? "Active" : "Inactive"} isRedirected={true} editPath="/Banners" />,
    },
    {
      name: "Action",
      width: "20%",
      cell: (row) => (
        <ActionIcon
          isRedirected={true}
          onEditClick={() => handleEdit(row)}
          editPath="/Banners/Banner-Create"
          onDeleteClick={() => handleBlogDelete(row?._id)}
          deletePath="/Banners"
          remove
          edit
          Uniquekey={row.id}
        />
      ),
    },
  ];

  const handleFilterByQuery = (e, requiredParametersArr) => {
    // if (e == "") {
    //   dispatch(HOMEPAGEGet());
    // }

    // let tempArr = bannerMainArr.filter((el) => {
    //   if (
    //     `${el.name}`.toLowerCase().includes(`${e}`.toLowerCase()) ||
    //     `${el.url}`.toLowerCase().includes(`${e}`.toLowerCase()) ||
    //     `${el.description}`.toLowerCase().includes(`${e}`.toLowerCase())
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // });
    setQuery(e);
    // setBannerArray([...tempArr]);
  };
  const handlePageChange = (pageValue) => {
    // setCurrentPage(page);
    dispatch(HOMEPAGEGet(`page=${pageValue}&perPage=${perPage}`));
    setPage(pageValue);
  };
     
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  return (
    <main>
      <section className="product-category">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="blue-1 m-0">Home Page Data</h5>
                <div className="d-flex align-items-center gap-3">
                  <CustomButton
                    isLink
                    iconName="fa-solid fa-plus"
                    btnName="ADD HOME PAGE"
                    path="/homePage/homePage-Create"
                    small
                    roundedPill
                  />
                  {/* <SearchBox
                    setQuery={(e) => {
                      handleFilterByQuery(e, ["name"]);
                    }}
                    query={query}
                    extraClass="bg-white"
                  /> */}
                </div>
              </div>
              <DashboardTable>
                <DataTable
                  columns={brand_columns}
                  data={
                    bannerArray && bannerArray.length > 0 ? bannerArray : []
                  }
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  onChangePage={handlePageChange}  onChangeRowsPerPage={handlePerRowsChange}
                />
              </DashboardTable>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default HomePage;
