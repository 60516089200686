import React, { useState, useEffect } from "react";
import Select from "react-select";
import CustomButton from "../../Utility/Button";
import { DashboardBox } from "../../Utility/DashboardBox";
import ReactQuill from "react-quill"; // ES6
import FileUpload from "../../Utility/FileUpload";
import QuillEditor from "../../../utils/QuillEditor";
import { useSelector, useDispatch } from "react-redux";

import {
  newsletterAdd,
  newsletterUpdate,
  SetnewsletterObj,
} from "../../../redux/actions/newsLetter/NewsLetter.action";

import { EMAIL_TEMPLATE_TYPES } from "../../Utility/constants";
import { toastError } from "../../Utility/ToastUtils";

function AddMailTemplate() { 
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [newsLetterPreviousId, setNewsLetterPreviousId] = useState("");
  const [isnewsletterUpdate, setisNewsletterUpdate] = useState(false);

  const newLetterObj = useSelector((state) => state.newsLetter.NewsLetterObj);
  // console.log(description, "description4");newsLetter.NewsLetterObj
  useEffect(() => {
    // console.log(newLetterObj, "newLetterObj123");
    if (newLetterObj&&newLetterObj._id) {
      setTitle(newLetterObj.title);
      setDescription(newLetterObj.description);
      setNewsLetterPreviousId(newLetterObj._id);
      setisNewsletterUpdate(true);
    }
    // return () => {
    //   dispatch(SetnewsletterObj(null));
    // };
  }, [newLetterObj]);



  const cleaRFunc = () => {
    setTitle("");
    setDescription("");
    setisNewsletterUpdate(false);
    dispatch(SetnewsletterObj(null));
  };


  const handleSubmit = async (e) => {

    try{

    e.preventDefault();
    try {
      if (title == "") {
        toastError("Title is mandatry");
        return;
      } else if (description == "") {
        toastError("description is mandatory");
        return;
      }
      let obj = {
        title,
        description,
      };
      // console.log(obj, "object123");
      if (isnewsletterUpdate) {
        dispatch(newsletterUpdate(obj, newsLetterPreviousId));
        dispatch(SetnewsletterObj(null))
      } else {
        dispatch(newsletterAdd(obj));
      }
      cleaRFunc();
    } catch (error) {
      toastError(error)
      // console.error(error);
    }
  }
catch(err){toastError(err)}
  };

  return (
    <main>
      <section className="product-category" style={{ minHeight: "75vh" }}>
        <div className="container-fluid p-0">
          <form action="#" className="form">
            <h5 className="blue-1 mb-4"> {isnewsletterUpdate?"Update":"Add"} Email Template</h5>
            <div className="row">
              <div className="col-12 col-md-8">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Email Template</h5>
                    {/* <div className="col-12 mb-3">
                      <label>
                        Type <span className="red">*</span>
                      </label>{console.log(Object,"object123")}
                      <select className="form-control">
                        <option>Please Select </option>
                        {Object.values(EMAIL_TEMPLATE_TYPES).length > 0 &&
                          Object.values(EMAIL_TEMPLATE_TYPES).map((type, i) => (
                            <option>{type}</option>
                          ))}
                      </select>
                    </div> */}
                    <div className="col-12 mb-3">
                      <label>
                        Subject <span className="red">*</span>
                      </label>
                      <input
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="col-12 mb-3">
                      <label>
                        short code (use these to get your neccessary info){" "}
                        <br />
                        {`{GIFT_CARD_NAME}, {SECRET_CODE}, {USER_FIRST_NAME}, {USER_EMAIL}, {EMAIL_SIGNATURE}, {ORDER_TRACKING_NUMBER}, {WEBSITE_NAME}, {RESET_LINK},{VERIFICATION_LINK}, {CUSTOM_MESSAGE}
                        `}
                      </label>
                    </div> */}
                    <div className="col-12">
                      <label>
                        DESCRIPTION<span className="red">*</span>
                      </label>
                      <QuillEditor
                        theme="snow"
                        value={description}
                        handleChange={(e) => setDescription(e)}
                      />
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <CustomButton
                      ClickEvent={handleSubmit}
                      isBtn
                      iconName="fa-solid fa-check"
                      btnName="Save"
                    />
                  </div>
                </DashboardBox>
              </div>
              {/* <div className="col-12 col-md-4">
                <DashboardBox>
                  <div className="row">
                    <h5 className="blue-1 mb-4">Basic Info</h5>
                    <div className="col-12 mb-3">
                      <label>
                        CATEGORY<span className="red">*</span>
                      </label>
                      <Select options={options} />
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Tags (Comma Separated)<span className="red">*</span>
                      </label>
                      <Select options={options} isMulti />

                      <div className="form-text fs-12 blue-1">Suggested Tags</div>
                    </div>
                    <div className="col-12 mb-3">
                      <label>
                        Image(1000x500)px<span className="red">*</span>
                      </label>
                      <FileUpload />
                    </div>
                    <div className="col-12 mb-3">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="checkbox" name="category-status" value="option1" id="publish-checkbox" />
                        <label className="form-check-label fs-14" htmlFor="publish-checkbox">
                          Publish
                        </label>
                      </div>
                    </div>
                    <div className="col-12">
                      <CustomButton isBtn iconName="fa-solid fa-check" btnName="Save" />
                    </div>
                  </div>
                </DashboardBox>
              </div> */}
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default AddMailTemplate;
